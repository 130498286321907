import { useContext } from "react";
import { ProjectCreatorStoreContext } from "@stores";

export const useProjectCreatorStore = () => {
  const context = useContext(ProjectCreatorStoreContext);
  if (context === null) {
    throw new Error("ProjectCreatorStoreContext cannot be null, please add a context provider");
  }
  return context;
};
