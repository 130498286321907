import { APIMethod, fetchData } from "@client";

export async function moveProjectVersionComment(
  uuid,
  user,
  version,
  path,
  newPath
) {
  try {
    return await fetchData(
      "/v1/ws/project/comments/move",
      APIMethod.PUT,
      {
        mapRequestKeysToSnakeCase: false,
        data: {
          uuid,
          user,
          version,
          path,
          newPath
        },
      }
    );
  } catch(e) {
    console.error(e);
  }
}