import { useMemo } from "react";
import { bool, object, number, arrayOf, oneOfType, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tables } from "project-structure";
import { chunk } from "lodash";
import { CELL_WIDTHS, ELEMENT_TYPES, getTimelineChartSegments } from "@utils";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  TitleCell,
  Row, RowGroup,
  WorkTypeResourceSelect,
  TimelineValueCell, useEstimateTableSettings
} from "@tools";
import { TimelineNestedRowActions } from "./components/TimelineNestedRowActions";
import { TeamMembersTag } from "../TeamMembersTag/TeamMembersTag";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";

export const TimelineNestedRow = observer(({
  parentIds,
  resource,
  isLastGroupRow,
  tableDepth,
  turnOff,
  parentColor,
}) => {
  const { t } = useTranslation();
  const structure = useEstimateStructure();
  const { allowEdition } = useEstimateEditorSettings();
  const { timelineLength } = useEstimateTableSettings();
  
  const { modifier, timelineHoursKey } = structure.settings;

  const {
    id,
    name,
    presentedTimeline,
    teamMembers,
    maxTimelineHours,
    timelineStartMonth,
    parsedMta,
  } = resource;

  const usesTimelinePercent = resource.parent?.usesTimelinePercent || false;
  
  const maxHours = useMemo(() => (
    maxTimelineHours / modifier
  ), [maxTimelineHours, modifier]);
  
  const chunkedTimeline = {
    months: chunk(presentedTimeline?.weeks || [], 4),
    startMonth: presentedTimeline?.startMonth || 0,
    startWeek: presentedTimeline?.startWeek || 0,
  };
  
  const timelineChartSegments = getTimelineChartSegments(
    presentedTimeline,
    parsedMta[timelineHoursKey],
    modifier,
    usesTimelinePercent
  );
  const fillerMonths = Math.max(timelineLength - (chunkedTimeline?.months.length || 0), 0);
  
  return (
    <RowGroup
      originTableId={Tables.TIMELINE}
      parentIds={[parentIds[0], `T${parentIds[1]}`]}
      tableDepth={tableDepth}
      isStatic={!allowEdition}
      elementId={id}
      elementType={ELEMENT_TYPES.TIMELINE_RESOURCE}
      className="timeline"
    >
      <Row
        useExpandCell
        expandOnClick
        isLastGroupRow={isLastGroupRow}
        tableDepth={tableDepth}
        visible={!turnOff}
        presetClass="preset-rowTimeline"
      >
        <TitleCell
          pinnedLeft={tableDepth}
          maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
          minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
          allowOverflowDisplay
          actions={
            <TimelineNestedRowActions
              resource={resource}
              parentUsesTimelinePercent={usesTimelinePercent}
            />
          }
        >
          {allowEdition ? (
            <WorkTypeResourceSelect
              resource={resource}
              noMinWidth
              displayTeamMembers
              timelineAutofocus
            />
          ) : (
            <Grid
              item
              container
              alignItems="center"
              className={classnames(
                "name",
                "preset-timelineTitleText",
                !name?.length && "semi-transparent"
              )}
            >
              {name || t("common.unnamed")}
              <TeamMembersTag teamMembers={teamMembers} />
            </Grid>
          )}
        </TitleCell>
        {
          chunkedTimeline?.months.map((month, i) => (
            <TimelineValueCell
              key={`month${i}`}
              monthIndex={i}
              month={month}
              maxHours={maxHours}
              timelineStarted={!timelineStartMonth || i >= timelineStartMonth }
              allowEdition={ allowEdition && !usesTimelinePercent }
              element={resource}
              visible={!turnOff}
              color={parentColor}
              timelineChartSegments={timelineChartSegments}
            />
          ))
        }
        
        {
          [...new Array(fillerMonths).keys()]
            .map((_, i) => (
              <TimelineValueCell
                key={`month${i}`}
                monthIndex={chunkedTimeline.months.length + i}
                visible={!turnOff}
              />
            ))
        }
        {/*<TitleCell minWidth={0} />*/}
      </Row>
    </RowGroup>
  );
});

TimelineNestedRow.propTypes = {
  resource: object.isRequired,
  tableDepth: number.isRequired,
  isLastGroupRow: bool,
  turnOff: bool,
  parentColor: string,
  parentIds: arrayOf(oneOfType([number, string])),
};
