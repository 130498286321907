import { useMemo } from "react";
import { bool, number, func, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { addProjectObserverQuery, assignUserToWorkTypeQuery, removeUserFromWorkTypeQuery, setAssignedUserWorkTypeVisibility } from "@query";
import { useFeatureGuardian, useEditorWebsocket } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { AsyncPicker, Checkbox, TooltipIcon, UserItem } from "@components";
import { LOCKED_FEATURES } from "@utils";
import { Divider, Grid } from "@material-ui/core";

export const UserPermits = observer(({
  isOpen,
  onClose,
  workTypeName,
  workTypeId
}) => {
  const { t } = useTranslation();
  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  const { projectUuid } = useParams();
  const { nonOwnerTeamMembers, workTypesWithStatus } = editorStore;

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ALLOCATION
  );

  const selectedUsers = useMemo(
    () =>
      workTypesWithStatus
        .filter((permit) => permit.id === workTypeId)
        .map((permit) =>
          nonOwnerTeamMembers.find(
            (user) => user.uuid === permit.estimatorUuid
          )
        )
        .filter((user) => user),
    [workTypesWithStatus]
  );
  
  const userSeesOtherWorkTypes = useMemo(() => (
    Boolean( (selectedUsers[0] && editorStore.estimateUserWorkTypeVisibility?.[selectedUsers[0].id]) ?? true)
  ), [selectedUsers, selectedUsers[0], editorStore.estimateUsers, editorStore.estimateUserWorkTypeVisibility]);

  const handlePermitGrant = async (user) => {
    if(!projectUuid) return;
    const allowed = await checkAccess();
    if (!allowed) return;
    if(!editorStore.isEstimateUser(user.uuid))
      await addProjectObserverQuery(projectUuid, user.email);
    socket?.addNewPermit(workTypeId, user.uuid);
    editorStore.addLocalPermit(workTypeId, user.uuid);
    await assignUserToWorkTypeQuery(projectUuid, workTypeId, user.uuid);
  };

  const handlePermitRevoke = async (userUuid) => {
    if(!projectUuid) return;
    const allowed = await checkAccess();
    if (!allowed) return;
    socket?.removePermit(workTypeId, userUuid);
    editorStore.removeLocalPermit(workTypeId, userUuid);
    await removeUserFromWorkTypeQuery(projectUuid, workTypeId, userUuid);
    if(!userSeesOtherWorkTypes && !editorStore.hasAssignedWorkTypes(userUuid))
      await handleUserWorkTypeVisibility();
  };

  const handleUserWorkTypeVisibility = async () => {
    socket?.setEstimateUserWorkTypeVisibility(selectedUsers[0].id, !userSeesOtherWorkTypes);
    editorStore.setEstimateUserWorkTypeVisibility(selectedUsers[0].id, !userSeesOtherWorkTypes);
    await setAssignedUserWorkTypeVisibility(projectUuid, selectedUsers[0].uuid, !userSeesOtherWorkTypes);
  }

  return (
    <>
      <AsyncPicker
        type="modal"
        isOpen={isOpen}
        inputName="user-search"
        selected={selectedUsers}
        inputLabel={t("views.active.search_tag")}
        labelKey="fullname"
        idKey="uuid"
        ContentItem={UserItem}
        title={`${t("views.editor.workType_permits")}${workTypeName ? " - "+workTypeName : ""}`}
        noItemsText={t("views.active.popup.no_members")}
        options={nonOwnerTeamMembers.slice()}
        onClickAway={onClose}
        onAdd={handlePermitGrant}
        onRemove={handlePermitRevoke}
        width={400}
      >
        <Grid 
          item container
          className="px-6"
          alignItems="center"
        >
          <Checkbox
            name="workTypeVisibility"
            checked={!userSeesOtherWorkTypes}
            onChange={handleUserWorkTypeVisibility}
            disabled={!selectedUsers.length}
            label={<span>
              <span>{t("Hide other estimates from the estimator")}</span>
              <TooltipIcon color="primary">
                {t("If you select this option, the assigned estimator will not see other work types and their estimates than the work type he is assigned to.")}
              </TooltipIcon>
            </span>}
          />
          <Divider className="mt-4 -mx-6" style={ { width: "calc(100% + 48px)" }} />
        </Grid>
      </AsyncPicker>
      <FeatureAlert />
    </>
  );
});

UserPermits.propTypes = {
  workTypeId: number.isRequired,
  workTypeName: string,
  isOpen: bool,
  onClose: func,
};
