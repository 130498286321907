import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useEstimateEditorSettings,
  useVisibilityModeCellClassName,
  ValueCell,
  VisibilityButton,
} from "@tools";

export const TeamCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { hideSummaryTeam } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTeam);
  
  return (
    <ValueCell
      wider
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-teamMembers">
        {getFieldName("pt-teamMembers")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={-8}
          hidden={hideSummaryTeam}
          onChange={structure.visibility.setHideSummaryTeam}
          extendPadding={extendPadding}
        />
      }
    </ValueCell>
  )
})

TeamCell.propTypes = {
  extendPadding: bool,
}