import { string } from "prop-types";
import { observer } from "mobx-react";
import { Columns } from "project-structure";
import { useEstimateEditorSettings, useEstimateStructure } from "@tools";
import {
  CostCell,
  HoursCell,
  RateCell,
  TimeCell,
  TeamMembersCell,
  CompanyCostCell,
  CompanyUnitCostCell,
  CompanyProfitabilityCell,
  CompanyProfitCell
} from "./cells";

export const ColumnFactory = observer(({ name }) => {
  
  const { isProposal, isSellerOrClient } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const {
    hasTeamMembersCol,
    hasTimeCol,
    currency,
    companyCurrency
  } = structure.settings;
  const {
    apply,
    hideSummaryCost,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryTeam,
    hideSummaryTime,
  } = structure.visibility;
  
  switch (name) {
    case Columns.COST:
      if(isProposal && apply && hideSummaryCost)
        return <></>
      return isSellerOrClient && <CostCell />;
      
    case Columns.HOURS:
      if(isProposal && apply && hideSummaryHours)
        return <></>
      return <HoursCell isWorkType />;
      
    case Columns.RATE:
      if(isProposal && apply && hideSummaryRate)
        return <></>
      return isSellerOrClient && <RateCell />;
      
    case Columns.TEAM:
      if(isProposal && apply && hideSummaryTeam)
        return <></>
      return hasTeamMembersCol && <TeamMembersCell wider />;
      
    case Columns.TIME:
      if(isProposal && apply && hideSummaryTime)
        return <></>
      return hasTimeCol && <TimeCell />;
      
    case Columns.C_COST:
      return isSellerOrClient && <CompanyCostCell />;
    case Columns.C_UNIT_COST_INNER:
      return isSellerOrClient
        && <CompanyUnitCostCell useCompanyCurrency />;
    case Columns.C_UNIT_COST:
      return isSellerOrClient && currency !== companyCurrency
        && <CompanyUnitCostCell />;
    case Columns.C_PROFIT:
      return isSellerOrClient && <CompanyProfitCell />;
    case Columns.C_PROFITABILITY:
      return isSellerOrClient && <CompanyProfitabilityCell />;
  }
})

ColumnFactory.propTypes = {
  name: string.isRequired,
};
