import { useRef, useState } from "react";
import { bool, number, object, string } from "prop-types";
import { generateEntryId } from "project-structure";
import { useTranslation } from "react-i18next";
import { useStores } from "@hooks";
import { useLibraryPusher } from "@tools";
import { Alert, MenuButton, PopMenu } from "@components";
import { CircularProgress, IconButton } from "@material-ui/core";
import { UserStoryGenerator } from "./UserStoryGenerator";
import { Menu } from "@assets";
import { Add, FileCopy } from "@material-ui/icons";
import classnames from "classnames";


export const BreakdownRowActionMenu = ({
  element,
  className,
  tableDepth,
  isLibrary
}) => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  
  const [showMenu, setShowMenu] = useState(false);
  
  const anchor = useRef(null);
  
  const {
    isLoading,
    libraryPushAlertStatus,
    libraryPushSuccess,
    addToLibrary,
    closeAlert,
    closeSuccessAlert,
  } = useLibraryPusher({ element, tableDepth });
  
  const handleDuplicate = () => {
    element.duplicateSelf(
      generateEntryId(),
      element.name || t("common.unnamed")
    );
  };
  
  const loading = isLoading && !showMenu;
  
  return <>
    <IconButton
      ref={anchor}
      onClick={() => setShowMenu(!showMenu)}
      className={classnames("button-menu noDrag hoverIcon opaque", className)}
      color="primary"
      size="small"
      tabIndex={-1}
    >
      { loading
        ? <CircularProgress size={18} />
        : <Menu />
      }
    </IconButton>
    <PopMenu
      anchor={anchor.current}
      show={showMenu}
      onClickAway={() => setShowMenu(false)}
    >
      {!isLibrary && tableDepth > 0 && (
        <MenuButton
          icon={isLoading ? <CircularProgress size={18} /> : <Add color="primary" />}
          onClick={addToLibrary}
        >
          {t("views.editor.add_to_library")}
        </MenuButton>
      )}
      <MenuButton
        icon={<FileCopy color="primary" />}
        onClick={handleDuplicate}
      >
        {t("views.editor.duplicate", {
          type: t("proposal_parts.module_levels.level_" + tableDepth),
        })}
      </MenuButton>
      <UserStoryGenerator element={element} menuAction />
      <Alert
        isOpen={!!libraryPushAlertStatus}
        title={t(libraryPushAlertStatus)}
        acceptText={t("common.close")}
        onAccept={closeAlert}
      />
      <Alert
        isOpen={libraryPushSuccess}
        title={t(
          `alerts.library.added_${
            userStore.isModerator ? "moderator" : "pending"
          }`,
          { name: element.name }
        )}
        acceptText={t("common.close")}
        onAccept={closeSuccessAlert}
      />
    </PopMenu>
  </>
}

BreakdownRowActionMenu.propTypes = {
  element: object.isRequired,
  className: string,
  tableDepth: number,
  isLibrary: bool,
}