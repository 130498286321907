import { useEffect, useMemo, useState } from "react";
import { getCurrencies, getCurrency } from "project-structure";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { COMPANY_SETTINGS } from "@client";
import { TextField } from "@components";
import { Autocomplete } from "@material-ui/lab";

export const Currency = () => {
  
  const currencies = useMemo(getCurrencies, []);
  
  useEffect(() => {
    (async () => {
      const c = await getCompanySettingsQuery(COMPANY_SETTINGS.CURRENCY_DEF);
      setCurrency(getCurrency(c || "USD"));
      setLoading(false);
    })();
  }, []);
  
  const [isLoading, setLoading] = useState(true);
  const [currency, setCurrency] = useState(null);
  
  const handleCurrencyChange = (_, v) => {
    setCurrency(v);
    setCompanySettingsQuery(COMPANY_SETTINGS.CURRENCY_DEF, v.code);
  };
  
  return (
    <Autocomplete
      id="currencySelect"
      options={currencies}
      value={currency}
      disabled={isLoading}
      onChange={handleCurrencyChange}
      getOptionLabel={(o) => `${o.name} (${o.symbolStart || o.symbolEnd})`}
      fullWidth
      autoHighlight
      forcePopupIcon={true}
      disableClearable
      renderInput={(params) => <TextField {...params} />}
    />
  );
}