import { string, number } from "prop-types";
import { Tables } from "project-structure";
import {
  useEstimateEditorSettings,
  useEstimateStructure,
  TableSummary,
  TableTimeline,
  TableBreakdown,
} from "@tools";

export const EditorTablesFactory = ({
  index,
  tableName,
  ...tableProps
}) => {
  const structure = useEstimateStructure();
  const { breakdownTableIndex } = useEstimateEditorSettings();
  
  const { visibility, usesAnyValue } = structure;
  
  const {
    apply,
    hideBreakdown,
    hideTimeline,
  } = visibility;
  
  switch (tableName) {
    case Tables.SUMMARY:
      return <TableSummary
        {...tableProps}
        blockExpansions={tableProps.blockExpansions || tableProps.useProfitability}
        tableBreakdownNext={breakdownTableIndex === index+1}
        tableBreakdownPrev={breakdownTableIndex === index-1}
      />;
    case Tables.TIMELINE:
      if(tableProps.isProposal && apply && (hideTimeline || !usesAnyValue))
        return <></>
      return <TableTimeline
        tableBreakdownNext={breakdownTableIndex === index+1}
        tableBreakdownPrev={breakdownTableIndex === index-1}
        {...tableProps}
        tableIndex={index}
      />;
    case Tables.BREAKDOWN:
      if(!tableProps.hasEditorPrivileges && apply && hideBreakdown)
        return <></>
      return <TableBreakdown {...tableProps} />;
  }
};

EditorTablesFactory.propTypes = {
  index: number,
  tableName: string,
};
