import { memo, useEffect, useRef, useState, useTransition } from "react";
import { bool, number, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useCheckEstimateEditorAccess, useEditorWebsocket } from "@hooks";
import { useEstimateEditorStore, useTooltipHoverActions } from "@tools";
import { Button, ActionPopMenu, Alert, TextClearInput } from "@components";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { FileCopy, Pencil, Trash, Lock } from "@assets";
import { LockOpenOutlined, LockOutlined, Visibility, VisibilityOff } from "@material-ui/icons";
import useStyle from "./VersionButton.style";
import classnames from "classnames";

export const VersionButton = memo(({
  identifier,
  name,
  selected,
  editable,
  removable,
  hideable,
  clickable,
  isDragged,
  used,
  locked,
  editVisibility,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  
  const [isPending, startTransition] = useTransition()

  const [alertVisible, showAlert] = useState(false);
  const [newName, setNewName] = useState(name);
  const [isEdited, setEditState] = useState(false);
  const [isSaving, setSavingState] = useState(false);
  
  const { isOwner } = useCheckEstimateEditorAccess(editorStore.estimateUsers, editorStore.workTypesWithStatus);
  
  const disableInteraction = isEdited || isDragged || isSaving;

  const {
    hover,
    handleHoverIn,
    handleHoverOut,
    handlePopupClose,
    handleTimeoutClear,
  } = useTooltipHoverActions(disableInteraction);

  const ref = useRef();

  useEffect(() => {
    setNewName(name);
  }, [name]);

  const handleVersionChange = async () => {
    startTransition(async() => {
      if (selected || isSaving || !clickable) return;
      
      if(socket?.editionPrivileges) {
        socket.requestExistingVersion(identifier);
        return;
      }
      // setSavingState(true);
      await editorStore.setCurrentVersion(identifier);
      // setSavingState(false);
    })
  }

  const handleVersionRemoval = async (e) => {
    e.stopPropagation();
    if (isSaving) return;
    showAlert(false);
    setSavingState(true);

    socket?.requestVersionRemoval(identifier);
  }

  const handleRemovalAlert = () => {
    if (isSaving) return;
    showAlert(true);
  }

  const handleVersionVisibility = () => {
    editorStore.setVersionHidden(identifier, !used);
    socket?.requestVersionVisibility(identifier, used ? 0 : 1);
  }
  
  const handleVersionLock = () => {
    editorStore.setEditionLock(identifier, !locked);
    socket?.requestVersionLock(identifier, locked ? 0 : 1);
  }
  
  const handleEditState = () => {
    setEditState(true);
    handleHoverOut();
  }

  const handleNameChange = (v) => {
    setNewName(v.replace(/[\\^{}`]/g, ""));//.slice(0, 30));
  }

  const commitNewName = async () => {
    if (newName && newName !== name) {
      socket?.requestVersionRename(identifier, newName.trim());
      editorStore.editVersionName(identifier, newName.trim());
    } else setNewName(name);

    setEditState(false);
  }
  
  return (
    <>
      <Button
        ref={ref}
        variant="outlined"
        size="large"
        onClick={!disableInteraction ? handleVersionChange : undefined}
        onDoubleClick={
          editable && !disableInteraction ? handleEditState : undefined
        }
        icon={isSaving || isPending ? <CircularProgress size={24} /> : <FileCopy />}
        onMouseEnter={editable && !isDragged ? handleHoverIn : undefined}
        onMouseLeave={editable && !isDragged ? handlePopupClose : undefined}
        component="div"
        disableRipple={disableInteraction}
        disableFocusRipple
        className={classnames("button-version", {
          "preset-version": !selected,
          "preset-version-active": selected,
          noDrag: isEdited,
          "m-1": true,
          [classes.selected]: selected,
          [classes.hidden]: !used && editVisibility,
        })}
      >
        {isEdited ? (
          <TextClearInput
            focused
            acceptOnClickAway
            confirmOnEnter
            value={newName}
            className={classes.nameInput}
            onChange={handleNameChange}
            onAccept={commitNewName}
            maxLength={50}
          />
        ) : (
          name
        )}
        <Grid item container className={classes.badgeContainer}>
          {
            editable && !disableInteraction && !editVisibility && !used &&
            <Tooltip title={t("views.editor.versions.hidden")} placement="right">
              <Grid
                item container
                alignItems="center"
                justifyContent="center"
                className={classes.offBadge}
              >
                <VisibilityOff className="text-md" />
              </Grid>
            </Tooltip>
          }
          {
            editable && !disableInteraction && locked &&
            <Tooltip title={t("views.editor.versions.locked")} placement="right">
              <Grid
                item container
                alignItems="center"
                justifyContent="center"
                className={classes.offBadge}
              >
                <Lock className="text-md" />
              </Grid>
            </Tooltip>
          }
        </Grid>
      </Button>
      {editable && !disableInteraction && (
        <ActionPopMenu
          show={hover}
          anchor={ref?.current}
          onMouseEnter={handleTimeoutClear}
          onMouseLeave={handleHoverOut}
          placement="bottom-end"
        >
          {
            !editVisibility &&
            <Button
              onClick={() => setEditState(true)}
              tooltipText={t("views.editor.versions.rename")}
            >
              <Pencil />
            </Button>
          }
          {
            !editVisibility && isOwner &&
            <Button
              onClick={handleVersionLock}
              tooltipText={t(`views.editor.versions.${locked ? "unlock" : "lock"}`)}
            >
              {
                locked ? <LockOpenOutlined /> : <LockOutlined />
              }
            </Button>
          }
          {
            !editVisibility &&
            <Button
              onClick={handleRemovalAlert}
              disabled={!removable}
              tooltipText={t("views.editor.versions.remove")}
            >
              <Trash color={removable ? "error" : undefined} />
            </Button>
          }
          {
            editVisibility &&
            <Button
              onClick={handleVersionVisibility}
              disabled={!hideable && used}
              tooltipText={t(`views.editor.visibility.${used ? "hide" : "show"}_this`, {thing: t("views.editor.visibility.version")})}
            >
              {
                used
                  ? <Visibility color={!hideable && used ? undefined : "primary"} />
                  : <VisibilityOff color={!hideable && used ? undefined : "primary"} />
              }
            </Button>
          }
        </ActionPopMenu>
      )}
      <Alert
        isOpen={alertVisible}
        title={t("alerts.editor.confirm_version_removal")}
        onAccept={handleVersionRemoval}
        onCancel={() => showAlert(false)}
      />
    </>
  );
});

VersionButton.propTypes = {
  identifier: number.isRequired,
  name: string.isRequired,
  index: number.isRequired,
  selected: bool,
  editable: bool,
  removable: bool,
  hideable: bool,
  clickable: bool,
  isDragged: bool,
  used: bool,
  locked: bool,
  editVisibility: bool,
};
