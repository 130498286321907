import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { useEstimateEditorSettings, useEstimateStructure, ValueCell } from "@tools";

export const CompanyCostCell = observer(({
  element,
  displayCellContent,
  extendPadding,
}) => {
  const { allowEdition } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const { usesTwoValues } = structure;
  const { currencyObj, roundPrice } = structure.settings;
  const { displayCompanyCost, displayCompanyCostMax, showMaxCompanyCost, hasOnlyCost } = element;

  const handleCompanyCostChange = (cost, isMax) => {
    const key = isMax ? "max" : usesTwoValues ? "min" : "avg";
    element.setCompanyCost(cost, key);
  };
  
  return (
    <ValueCell
      wider
      widest={usesTwoValues}
      displayContent={displayCellContent}
      value={displayCompanyCost}
      valueMax={displayCompanyCostMax}
      displayMax={showMaxCompanyCost}
      onValueChange={handleCompanyCostChange}
      editable={allowEdition && hasOnlyCost}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      max={10000000}
      parser={parsePrice}
      inputClassName="input-company-cost"
      extendPadding={extendPadding}
    />
  );
});

CompanyCostCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  extendPadding: bool,
};
