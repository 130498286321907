import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateEditorSettings,
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  PriceCell,
  VisibilityButton,
} from "@tools";

export const CostCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
    useProfitability,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { usesTwoValues, visibility } = structure;
  const { hideSummaryCost } = visibility;
  const className = useVisibilityModeCellClassName(hideSummaryCost);
  
  return (
    <PriceCell
      widest={usesTwoValues}
      isHeader
      size="xSmall"
      className={className}
      extendPadding={extendPadding}
      style={{ cursor: allowEdition && "grab" }}
    >
      <span className="preset-tableSummaryHeader pt-cost">
        {getFieldName(useProfitability ? "pt-revenue" : "pt-cost")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={20}
          hidden={hideSummaryCost}
          onChange={structure.visibility.setHideSummaryCost}
        />
      }
    </PriceCell>
  )
})

CostCell.propTypes = {
  extendPadding: bool,
}