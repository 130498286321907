import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(() => ({
  
  root: {
    boxSizing: "border-box",
    cursor: "text",
    background: "transparent",
  },
  moreButton: {
    whiteSpace: "nowrap",
  },
}));

export default useStyle;