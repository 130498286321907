
export const DEFAULT_WORK_TYPES = [ 5, 6 ];

export const MAX_VERSIONS = 10;

export const LIBRARY_LEVELS = [
  "module",
  "feature",
  "task"
];

export const TABLE_DRAGGABLE_CONTAINER = "col-draggable";

export const EDITOR_CONTAINER_ID = "editorContainer";
export const TABLE_CONTAINER_ID = "tableContainer";
export const PROPOSAL_STEPPER_ID = "proposalStepper";

export const PDF_CONTAINER_ID = "pdfViewer";

export const MAX_MODULE_NAME = 100;

export const DEFAULT_WT_RESOURCES = [
  "System Architect",
  "Senior Developer",
  "Regular Developer",
  "Junior Developer",
  "DevOps Engineer",
  "Project Manager",
  "Business Analyst",
  "QA Engineer",
  "UX/UI Designer",
];

export const WT_STATUS = {
  PENDING: "PENDING",
  ESTIMATED: "ESTIMATED",
};

export const ELEMENT_TYPES = {
  TASK: "t",
  FEATURE: "f",
  MODULE: "m",
  SECTION: "s",
  WORK: "w",
  RESOURCE: "r",
  TIMELINE_WORK: "tw",
  TIMELINE_RESOURCE: "tr",
  BILLING: "bil",
  FIXED_PRICE: "fix",
};

export const TEMPLATE_TYPE = {
  BLANK: "blank",
  XLS: "xls",
};

export const PROJECT_TYPE = {
  MINI: "mini",
  STANDARD: "standard",
  LIBRARY: "library"
};

export const PROJECT_COLUMNS = {
  TODO: "todo",
  IN_PROGRESS: "in_progress",
  DONE: "done"
};

export const PROJECT_COLUMN_COLORS = {
  TODO: "#EAA844",
  IN_PROGRESS: "#2F7CF6",
  DONE: "#41C413"
};

export const PROJECT_STATUS = {
  ...PROJECT_COLUMNS,
  ARCHIVE: "archive",
  REMOVED: "removed",
};

export const MIN_FILLER_MONTHS = 9;

export const BREAKDOWN_ELEMENT_CODES = [
  "s",
  "m",
  "f",
  "t"
]