import { bool, string, object } from "prop-types";
import { DragIndicator } from "@material-ui/icons";
import classnames from "classnames";
import useStyle from "./DragHandle.style";

export const DragHandle = ({
  absolute,
  backgroundPrimary,
  className,
  showOnHover,
  style,
}) => {
  const classes = useStyle();

  return (
    <DragIndicator
      className={classnames(
        "dragHandle",
        classes.handle,
        showOnHover && "hoverIcon",
        absolute && classes.absolute,
        backgroundPrimary && classes.backgroundPrimary,
        className
      )}
      style={style}
    />
  );
};

DragHandle.propTypes = {
  className: string,
  backgroundPrimary: bool,
  absolute: bool,
  showOnHover: bool,
  style: object,
};
