import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { home } from "@paths";
import { moveTemplateVersionComment } from "@query";
import { useStores, useWorkspaceWebsocket } from "@hooks";
import {
  EstimateStructureProvider,
  EstimateEditorSettingsProvider,
  EditorContainer,
  EditorContent,
  useEstimateEditorStore,
} from "@tools";
import { Alert } from "@components";

export const TemplateEditorView = observer(() => {
  const {t} = useTranslation();
  const editorStore = useEstimateEditorStore();
  const { userStore } = useStores();
  const socket = useWorkspaceWebsocket();
  const navigate = useNavigate();
  const { templateId } = useParams();

  const { isRemoved, currentEstimateStructure } = editorStore;
  
  const onCommentMove = async (versionKey, oldPlace, newPlace) => {
    await moveTemplateVersionComment(
      templateId,
      userStore.data.uuid,
      versionKey, oldPlace, newPlace
    );
  }
  
  return (
    <EstimateStructureProvider value={currentEstimateStructure}>
      <EstimateEditorSettingsProvider
        hasEditorPrivileges={socket?.connectionOnline}
        hasSellerPrivileges
        allowInteractions
        // estimateIdentifier={templateId}
      >
        <EditorContainer showUserMovement>
          <EditorContent useTemplateToolbar onCommentMove={onCommentMove} />
        </EditorContainer>
      </EstimateEditorSettingsProvider>
      {
        isRemoved &&
        <Alert
          isOpen
          title={t("alerts.editor.template_removed")}
          acceptText={t("alerts.editor.close_editor")}
          onAccept={() => navigate(home)}
        />
      }
    </EstimateStructureProvider>
  );
});