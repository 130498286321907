import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { getProfitColor } from "@utils";
import { useEstimateEditorSettings, useEstimateStructure, ValueCell } from "@tools";
import { useTheme } from "@material-ui/core/styles";

export const ProfitCell = observer(({
  element,
  displayCellContent,
  extendPadding,
}) => {
  const theme = useTheme();
  const { allowEdition } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const { usesTwoValues } = structure;
  const { currencyObj, roundPrice } = structure.settings;
  const { displayCompanyProfit, displayCompanyProfitMax, showMaxCompanyProfit, companyProfitability } = element;
  
  const color = useMemo(() => (
    getProfitColor(companyProfitability, theme)
  ), [companyProfitability, theme]);

  const handleCompanyProfitChange = (profit, isMax) => {
    const key = isMax ? "max" : usesTwoValues ? "min" : "avg";
    element.setCompanyProfit(profit, key);
  };
  
  return (
    <ValueCell
      wider
      widest={usesTwoValues}
      displayContent={displayCellContent}
      value={displayCompanyProfit}
      valueMax={displayCompanyProfitMax}
      displayMax={showMaxCompanyProfit}
      onValueChange={handleCompanyProfitChange}
      editable={allowEdition}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      allowNegative
      max={10000000}
      style={{color}}
      inputClassName="input-profit"
      extendPadding={extendPadding}
    />
  );
});

ProfitCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  extendPadding: bool,
};
