import { useMemo } from "react";
import { string, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DESCRIPTION_MODES, SECTION_FORMATS } from "project-structure";
import { TABLE_DRAGGABLE_CONTAINER, CELL_WIDTHS } from "@utils";
import {
  useEstimateStructure,
  usePresetFieldNames,
  HeaderRow,
  TitleCell,
  DescriptionCell, useEstimateEditorSettings, useEstimateTableSettings,
} from "@tools";
import Grid from "@material-ui/core/Grid";
import { BudgetTrackingHeaderWorkType } from "./components/BudgetTrackingHeaderWorkType";
import classnames from "classnames";

export const BudgetTrackingTableHeader = observer(({
  section,
  tableIdentifier,
}) => {
  const { useVisibilityCell, isProposal, blockExpansions } = useEstimateEditorSettings();
  const { descriptionVisible, useExpandCell } = useEstimateTableSettings();
  const structure = useEstimateStructure();
  const { settings } = structure;

  const {
    viewLevel,
    showBreakdownColumns,
    sectionFormat,
    descriptionMode,
  } = settings;

  const {
    btTaskWorkTypes,
    allChildrenExpanded,
    sectionModulesHaveChildren,
    isOpened,
  } = section;

  const { t } = useTranslation();
  const { getFieldName } = usePresetFieldNames(isProposal);

  const expanded = useMemo(
    () => (sectionFormat !== SECTION_FORMATS.ROW || isOpened) && allChildrenExpanded,
    [allChildrenExpanded, isOpened, sectionFormat]
  );

  const showDescriptionCell = useMemo(
    () => descriptionMode === DESCRIPTION_MODES.COLUMN && descriptionVisible,
    [descriptionMode, descriptionVisible]
  );

  const expandAllChildren = () => section.expandAllChildren(!expanded);

  const handleTitleCellRescale = (w) => {
    structure.settings.setTitleCellWidth(w);
  };
  const handleDescriptionCellRescale = (w) => {
    structure.settings.setDescriptionCellWidth(w);
  };
  return (
    <HeaderRow
      id={tableIdentifier}
      useExpandCell={useExpandCell}
      hideVisibilitySwitch
      useVisibilityCell={useVisibilityCell}
      expandable={!blockExpansions && viewLevel > 1}
      expanded={expanded}
      onExpand={expandAllChildren}
    >
      <TitleCell
        allowOverflowDisplay={descriptionMode < DESCRIPTION_MODES.COLUMN}
        minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
        maxWidth={CELL_WIDTHS.DESCRIPTION}
        pinnedLeft={!isProposal || sectionModulesHaveChildren ? 1 : 0}
      >
        <span className="preset-tableHeader pt-module">
          {getFieldName("pt-module", 0)}
        </span>
      </TitleCell>

      <DescriptionCell
        minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
        isHeader
        size="xSmall"
        setWidth={handleDescriptionCellRescale}
      >
        <span className="preset-tableHeader ml-0-5">{t("common.desc")}</span>
      </DescriptionCell>

      <Grid
        className={classnames(TABLE_DRAGGABLE_CONTAINER, "wt-container")}
        item
        container
        wrap="nowrap"
        alignItems="center"
        style={{ width: "initial" }}
      >
        {showBreakdownColumns &&
          btTaskWorkTypes?.map((workType) => (
            <BudgetTrackingHeaderWorkType
              key={workType.id}
              workType={workType}
              section={section}
            />
          ))}
      </Grid>
    </HeaderRow>
  );
});

BudgetTrackingTableHeader.propTypes = {
  section: object.isRequired,
  tableIdentifier: string.isRequired,
};
