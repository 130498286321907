import { useEffect, useState } from "react";
import { LabelEditor } from "@components";
import { Grid, CircularProgress } from "@material-ui/core";
import {
  createProjectTypeQuery,
  getAllProjectTypesQuery,
  removeProjectTypeQuery,
  updateProjectTypeQuery
} from "@query";

export const ProjectTypes = () => {

  const [isLoading, setLoading] = useState(true);
  const [projectTypes, setProjectTypes] = useState([]);

  useEffect(() => {
    (async () => {
      let t = await getAllProjectTypesQuery();
      setProjectTypes(t);
      setLoading(false);
    })();
  }, []);
  
  const handleCreate = async (name, color, font) => {
    return await createProjectTypeQuery(name, color, font)
  }
  
  const handleRemove = async (id) => {
    await removeProjectTypeQuery(id);
  }
  
  const handleUpdate = async (id, name, color, font) => {
    await updateProjectTypeQuery(id, name, color, font);
  }

  if(isLoading)
    return <Grid
      item container
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress size={24} />
    </Grid>

  return (
    <LabelEditor
      labelList={projectTypes}
      onCreate={handleCreate}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
    />
  );
};