import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  root: {
    overflowX: "hidden",
    position: "relative",
    zIndex: 12,
    backgroundColor: theme.props.background,
    "&:not(:hover) .dragHandle, &:not(:hover) .removeButton": {
      display: "none",
    },
    padding: "4px 0",
  },
  divider: {
    width: "100%",
    backgroundColor: theme.props.text.border,
  },
  handleAlt: {
    fontSize: 24,
    cursor: "grab",
    color: theme.props.text.alt,
    backgroundColor: "transparent",
  },
  colored: {
    backgroundColor: theme.palette.primary.main + "4d",
  },
  box: {
    margin: "0 12px",
    color: "#fff",
  },
  actionsContainer: {
    position: "absolute",
    right: 4,
    padding: 3,
    backgroundColor: theme.props.background,
  },
}));

export default useStyle;
