import { useEffect, useState } from "react";
import { bool, node, object } from "prop-types";
import { useStores } from "@hooks";
import { Navigate } from "react-router-dom";
import { home } from "@paths";
import { checkPrivilegesQuery } from "@query";

export const RouteProtector = ({
  children,
  adminOnly,
  moderatorOnly,
  accessedFeature
}) => {
  const { userStore } = useStores();

  const [checked, setChecked] = useState(false);
  const [hasAccess, setAccess] = useState(false);

  const checkAccess = async () => {
    let access = true;
    if (adminOnly && !userStore.isAdmin)
      access = false;
    if (moderatorOnly && !userStore.isModerator)
      access = false;

    if (accessedFeature && access) {
      const res = await checkPrivilegesQuery(accessedFeature.serverCode);
      if (!res.isAllowed) access = false;
    }

    setAccess(access);
    setChecked(true);
  };

  useEffect(() => {
    checkAccess();
  }, []);

  if (!checked) return <></>;

  return hasAccess ? children : <Navigate to={home} replace />;
};

RouteProtector.propTypes = {
  children: node,
  adminOnly: bool,
  moderatorOnly: bool,
  accessedFeature: object,
};
