import classnames from "classnames";
import { Button } from "@components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { bool, func, node, string } from "prop-types";

export const SettingsButton = ({
  titleKey,
  path,
  icon,
  onClick,
  className,
  forceActive,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  
  const active = forceActive || path && location.pathname === path;
  
  return (
    <Button
      size="large"
      variant="text"
      icon={icon}
      to={path}
      onClick={onClick}
      color={active ? "primary" : "secondary"}
      className={classnames(
        "justify-left font-normal display-block w-auto mb-1",
        active && "bg-primary-lighter font-bold",
        className
      )}
    >
      {t(`views.settings.menu.${titleKey}`)}
    </Button>
  );
}

SettingsButton.propTypes = {
  titleKey: string.isRequired,
  icon: node.isRequired,
  path: string,
  onClick: func,
  className: string,
  forceActive: bool,
}