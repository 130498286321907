import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useEstimateStructure, ValueCell } from "@tools";
import { useBudgetTrackingStore } from "@views/Projects/BudgetTracking/providers/BudgetTrackingStore";
import { TooltipIcon } from "@components";
import { Grid } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import useStyle from "./ExpandTitleHourCell.style";
import classnames from "classnames";

export const ExpandTitleHourCell = observer(() => {
  const { hours } = useBudgetTrackingStore();
  const budgetTracking = useBudgetTrackingStore();
  const structure = useEstimateStructure();
  const { t } = useTranslation();
  const classes = useStyle();

  const { timeModifier } = structure.settings;
  const open_hours = () => {
    return budgetTracking.setHour(!hours);
  };

  return (
    <ValueCell
      isHeader
      className={classnames(
        "p-0",
        classes.cellContainer,
        hours && classes.cellExpanded
      )}
    >
      <Grid item container direction="column">
        <span className="preset-timelineHeader">{t(`times.${timeModifier === 1 ? "hour" : "day"}`)}</span>
        {hours && (
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            {["views.bt.estimate", "views.bt.spent", "views.bt.diffh", "views.bt.diff"].map((week) => (
              <span
                key={week}
                className={classnames(
                  "preset-timelineHeader my-1",
                  classes.weekCell
                )}
              >
                {t(week)}
              </span>
            ))}
          </Grid>
        )}
      </Grid>
      <TooltipIcon
        icon={
          <ExpandMore
            className={classnames(
              "expand transition -rotate-90",
              hours && "rotate-90"
            )}
          />
        }
        onClick={open_hours}
        className={classnames(
          "noDrag opaque absolute",
          classes.button,
          !hours && "cellHoverIcon",
        )}
        color="primary"
      >
        {t("times.hour")}
      </TooltipIcon>
    </ValueCell>
  );
});

ExpandTitleHourCell.propTypes = {};
