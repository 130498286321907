import { Grid } from "@material-ui/core";
import useStyle from "./SettingsRow.style";
import { bool, node } from "prop-types";
import classnames from "classnames";

export const SettingsRow = ({
  children,
  header,
  bordered,
  disabled,
}) => {
  
  const classes = useStyle();
  
  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className={classnames(
        header ? classes.headerRow : classes.row,
        bordered && classes.bordered,
        disabled && classes.disabled,
      )}
    >
      { children }
    </Grid>
  )
}

SettingsRow.propTypes = {
  children: node.isRequired,
  header: bool,
  bordered: bool,
  disabled: bool,
}