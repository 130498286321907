import { useState } from "react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import { authorizeUserQuery } from "@query";
import { register, retrievePassword } from "@paths";
import { LoginForm } from "@forms";
import { Alert, ClearButton } from "@components";
import { OrDivider } from "@views/Auth/components/OrDivider/OrDivider";
import { MicrosoftSSO } from "@views/Auth/components/MicrosoftSSO/MicrosoftSSO";

export const Login = () => {
  const { t } = useTranslation();
  const { userStore, stateStore } = useStores();

  const [alertText, setAlertText] = useState("");

  const signIn = async (data) => {
    try {
      const userData = await authorizeUserQuery(data);
      userStore.saveUserData(userData);
      if(!stateStore.memorizedAction)
        userStore.checkWorkspacesAndRedirect();
      
      await userStore.checkUserTrial();
      userStore.setLoginProcessStatus(true);
    } catch (e) {
      if (e.response?.data?.errors) {
        const errors = e?.response.data.errors;
        if (errors) return { errors };
      } else {
        console.error(e);
        setAlertText(t("errors.network"));
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="space-between"
      wrap="nowrap"
      className="h-full"
    >
      <Grid item container>
        <h1 className="w-full pb-8 text-center">
          { t("routes.login_full") }
        </h1>
        <LoginForm onSubmit={ signIn }/>
        
        <OrDivider/>
        <MicrosoftSSO/>
        
        <ClearButton
          fullWidth
          to={ retrievePassword }
          className="mt-2 justify-center h-36"
        >
          { t("views.login.no_password") }
        </ClearButton>
      </Grid>
      <Grid
        item container
        direction="column"
        alignItems="center"
        className="mt-6"
      >
        <p className="text-lg">
          { t("views.login.no_account") }
        </p>
        <ClearButton href={process.env.REACT_APP_SIGN_UP_ALT_LINK} to={register}>
          { t("views.login.sign_here") }
        </ClearButton>
      </Grid>
      <Alert
        isOpen={Boolean(alertText)}
        title={alertText}
        acceptText={t("common.close")}
        onAccept={() => setAlertText("")}
      />
    </Grid>
  );
};
