import { bool, func } from "prop-types";
import { useEffect, useRef } from "react";
import { observer } from "mobx-react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack"
import { useCheckEstimateEditorAccess, useStores } from "@hooks";
import {
  EditorTablesFactory,
  EditorUsers,
  Versioning,
  TableSummary,
  ProjectTableToolbar,
  TemplateTableToolbar,
  useEstimateEditorSettings,
  useEstimateEditorStore,
  useEstimateStructure,
  useHistoryManagerKeybinds,
} from "@tools";
import { TABLE_CONTAINER_ID, SNACKBAR_VERSION_LOCKED } from "@utils";
import useStyle from "../../styles/EditorContent.style";
import classnames from "classnames";

export const EditorContent = observer(({
  useTemplateToolbar,
  onCommentMove,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { stateStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const structure = useEstimateStructure();
  
  const {
    breakdownTableIndex,
    useProfitability,
    isProposal,
    readOnly,
  } = useEstimateEditorSettings();
  
  useHistoryManagerKeybinds(onCommentMove);
  
  const { settings } = structure;
  const { visibleTables } = settings;

  const { currentVersionLocked, isArchived, estimateUsers } = editorStore;
  const { isObserver } = useCheckEstimateEditorAccess(estimateUsers);
  
  const tableRef = useRef(null);
  
  useEffect(() => {
    if(!currentVersionLocked) {
      closeSnackbar(stateStore.lockedProjectVersionSnackbar);
      stateStore.lockedProjectVersionSnackbar = undefined;
    }
    else if(currentVersionLocked && !stateStore.lockedProjectVersionSnackbar && !isArchived && isObserver) {
      stateStore.lockedProjectVersionSnackbar = enqueueSnackbar(
        t("alerts.editor.version_locked"),
        {
          key: SNACKBAR_VERSION_LOCKED,
          variant: "info",
          persist: true,
        }
      );
    }
  }, [currentVersionLocked]);
  
  return (
    <Grid
      id={TABLE_CONTAINER_ID}
      item
      container
      direction="column"
      alignItems="center"
      className={classnames(
        "preset-page", 
        classes.scrollableContainer
      )}
      ref={tableRef}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
      >
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classnames(
            "preset-paper",
            classes.titleContainer,
            breakdownTableIndex === 0 && classes.titleContainerLast
          )}
          id="titleBar"
        >
          <Grid
            item container
            justifyContent="flex-end"
            className={classnames(classes.titleBarRoot)}
          >
            {
              useTemplateToolbar
                ? <TemplateTableToolbar />
                : <ProjectTableToolbar isSharedVersion={readOnly} />
            }
            {!isProposal && <EditorUsers />}
            <Versioning />
          </Grid>
        </Grid>
        {
          useProfitability
            ? <TableSummary tableIndex={0} />
            : visibleTables.map(({ name }, index) => (
              <EditorTablesFactory
                key={name}
                index={index}
                tableName={name}
              />
            ))
        }
      </Grid>
    </Grid>
  );
});

EditorContent.propTypes = {
  useTemplateToolbar: bool,
  onCommentMove: func,
}