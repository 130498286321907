import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateStructure,
  useSummaryTeamInputTooltipAlert,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  ValueCell,
} from "@tools";
import useStyle from "../../TeamMembersCell.style"
import classnames from "classnames";

export const TeamMembersCell = observer(({
  element,
  extendPadding,
}) => {
  const { allowEdition, useProfitability } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const tooltip = useSummaryTeamInputTooltipAlert(allowEdition);
  const classes = useStyle();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryTeam);
  
  const { usesAnyValue } = structure;
  const { isTimelineTableUsed } = structure.settings;
  
  const handleTeamMembersChange = (members) => {
    element.setTeamMembers(members);
  };

  return (
    <ValueCell
      wider
      displayContent
      min={1}
      emptyInputs={!usesAnyValue}
      value={element.teamMembers || 1}
      onValueChange={handleTeamMembersChange}
      editable={allowEdition && !useProfitability && !isTimelineTableUsed}
      className={className}
      textPresetClass={classnames("preset-summaryText", classes.input)}
      inputClassName="input-team-members"
      extendPadding={extendPadding}
      { ...tooltip }
    />
  );
});

TeamMembersCell.propTypes = {
  element: object.isRequired,
  extendPadding: bool,
};