import { useEffect, useRef, useState } from "react";
import { func, node } from "prop-types";
import { useSnackbar } from "notistack";
import { applyPatch } from "mobx-state-tree";
import { useTranslation } from "react-i18next";
import { CoreCommands, ProjectEditorCommands, EditorSocketContext, ProjectCommands } from "@client";
import {
  createProjectVersionComment,
  editProjectVersionComment,
  moveProjectVersionComment,
  removeProjectVersionComment,
  removeProjectVersionCommentByPath,
  saveProjectCoverQuery
} from "@query";
import { PROJECT_STATUS, SNACKBAR_WEBSOCKET_RESTORED } from "@utils";
import { useStores, useWorkspaceWebsocket, useCheckEstimateEditorAccess } from "@hooks";
import { useEstimateEditorStore } from "@tools"
import { drawUserMovement } from "./utils/drawUserMovement";

export const EditorSocketProvider = ({
  children,
  reloadProjectData
}) => {

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userStore, stateStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const {
    init,
    socket,
    connectionOnline,
    sendAction,
    requestProjectRename,
  } = useWorkspaceWebsocket();
  
  const [isSetup, setSetupStatus] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasConnectionError, setConnectionError] = useState(false);
  const [joinEdition, setJoinEdition] = useState(false);
  const editionPrivileges = useRef({
    joinEdition: false,
    joinComments: false
  });

  const lastSentAction = useRef(null);
  const projectJoined = useRef(null);
  const connectionOnlineSnackbar = useRef(null);
  const connectionRestoredSnackbar = useRef(null);
  
  const { hasEditorPrivileges } = useCheckEstimateEditorAccess(editorStore.estimateUsers);
  
  // --------- connections ---------
  
  const onProjectJoinError = ({ message }) => {
    console.error("ERROR WHILE JOINING PROJECT: ", message)
    stateStore.setProjectCreatorProgressError(true, message);
  }
  
  const onProjectCreatorProgress = ({ progress, stepName }) => {
    stateStore.setProjectCreatorProgress(progress, stepName);
  }
  
  const joinProposal = (projectHash) => {
    setJoinEdition(false);
    editionPrivileges.current = {
      joinEdition: false,
      joinComments: Boolean(projectHash)
    }
    
    setupProjectEvents(
      true,
      false,
      Boolean(projectHash),
    );
    
    socket.current.emit(CoreCommands.JOIN_PROPOSAL, {
      commentHash: projectHash,
      ...editionPrivileges.current,
    });
    projectJoined.current = { projectUuid: projectHash, projectHash };
  };
  
  const joinProject = (projectUuid, projectHash, isCreated, versionKey) => {
    setJoinEdition(true);
    editionPrivileges.current = {
      joinEdition: true,
      joinComments: hasEditorPrivileges && projectHash
    }
    
    console.log("JOINING PROJECT", projectUuid);
    
    setupProjectEvents(
      true,
      true,
      hasEditorPrivileges && projectHash,
    );
    
    socket.current.emit(CoreCommands.JOIN_PROJECT, {
      projectUuid,
      versionKey,
      newProject: !isCreated,
      commentHash: projectHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
      ...editionPrivileges.current,
    });
    projectJoined.current = { projectUuid, projectHash };
  };
  
  const closeProject = () => {
    if(!projectJoined.current)
      return;
    console.log("CLOSING PROJECT");
    setupProjectEvents(
      false,
      editionPrivileges.current.joinEdition,
      editionPrivileges.current.joinComments,
    );
    editionPrivileges.current = undefined;
    socket.current.emit(CoreCommands.CLOSE_PROJECT, {
      projectUuid: projectJoined.current.projectUuid,
      commentHash: projectJoined.current.projectHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      ...editionPrivileges.current,
    });
    projectJoined.current = null;
    
    lastSentAction.current = "END";
    closeSnackbar(connectionOnlineSnackbar.current);
    closeSnackbar(connectionRestoredSnackbar.current);
    setUsers([]);
  };
  
  const setupProjectEvents = (on, openEditionChannel, openCommentsChannel) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    
    // socket.current.on(CoreCommands.CONNECT_ERROR, onDisconnect);
    // socket.current.on(CoreCommands.DISCONNECT, onDisconnect);
    socket.current.on(CoreCommands.ACTION_ERROR, onError);
    socket.current.on(ProjectEditorCommands.USER_JOINED, onJoined);
    socket.current.on(ProjectEditorCommands.USER_LEFT, onLeft);
    // socket.current.on(CoreCommands.CONNECTION, onReconnect);
    // socket.current.on(CoreCommands.RECONNECTION, onReconnect);
    socket.current.on(CoreCommands.JOIN_PROJECT, onProjectJoinError); //only join errors will come on this channel
    socket.current.on(ProjectCommands.PROJECT_PROGRESS, onProjectCreatorProgress);
    socket.current.on(ProjectCommands.PROJECT_WAS_CREATED, onProjectCreationEnd);
    
    if(openEditionChannel || !on) {
      socket.current.on(ProjectEditorCommands.USER_MOVE, drawUserMovement);
      socket.current.on(ProjectEditorCommands.PATCH, onStructurePatch);
      socket.current.on(ProjectEditorCommands.VERSION_LIST, onReceiveVersionList);
      socket.current.on(ProjectEditorCommands.VERSION_REQUEST, onReceiveVersion);
      socket.current.on(ProjectEditorCommands.VERSION_NEW, onCreateVersion);
      socket.current.on(ProjectEditorCommands.VERSION_REMOVE, onRemoveVersion);
      socket.current.on(ProjectEditorCommands.VERSION_RENAME, onRenameVersion);
      socket.current.on(ProjectEditorCommands.VERSION_ORDER, onReorderVersion);
      socket.current.on(ProjectEditorCommands.VERSION_LOCK, onVersionLock);
      socket.current.on(ProjectEditorCommands.VERSION_VISIBILITY, onVersionVisibility);
      socket.current.on(CoreCommands.WORKTYPE_ADD, onAddNewWorkType);
      socket.current.on(CoreCommands.WORKTYPE_EDIT, onWorkTypeChange);
      socket.current.on(ProjectEditorCommands.PERMIT_ADD, onWorkTypeAssignment);
      socket.current.on(ProjectEditorCommands.PERMIT_REMOVE, onWorkTypeRevoke);
      socket.current.on(ProjectEditorCommands.PERMIT_STATUS, onWorkTypeStatus);
      socket.current.on(ProjectEditorCommands.PERMIT_USER_VISIBILITY, onUserWorkTypesStatus);
      socket.current.on(ProjectEditorCommands.COVER_ADD, onCoverAdd);
      socket.current.on(ProjectEditorCommands.COVER_REMOVE, onCoverRemove);
      socket.current.on(ProjectEditorCommands.COVER_PAGES, onCoverUsedPagesUpdate);
      socket.current.on(ProjectEditorCommands.COVER_SCALE, onCoverScaleUpdate);
      socket.current.on(ProjectEditorCommands.SHARE_SETTINGS, onShareSettingsUpdate);
      
      socket.current.on(ProjectCommands.USER_ADD, onProjectUserAdd);
      socket.current.on(ProjectCommands.USER_REMOVE, onProjectUserRemove);
      socket.current.on(ProjectCommands.USER_PROMOTE, onProjectUserPromote);
      
      socket.current.on(ProjectCommands.PROJECT_RENAME, onProjectRename);
      socket.current.on(ProjectCommands.PROJECT_REMOVE, onProjectRemove);
      socket.current.on(ProjectCommands.PROJECT_ARCHIVE, onProjectArchive);
      socket.current.on(ProjectCommands.PROJECT_STATUS, onProjectStatusUpdate);
    }
    if(openCommentsChannel || !on) {
      socket.current.on(ProjectEditorCommands.COMMENT_ADD, onCommentAdd);
      socket.current.on(ProjectEditorCommands.COMMENT_EDIT, onCommentEdit);
      socket.current.on(ProjectEditorCommands.COMMENT_REMOVE, onCommentRemove);
      socket.current.on(ProjectEditorCommands.COMMENT_REMOVE_PATH, onCommentPathRemove);
      socket.current.on(ProjectEditorCommands.COMMENT_MOVE, onCommentMove);
    }
    
    setSetupStatus(true);
  }

  const resetProjectData = async () => {
    if(!projectJoined.current) return;
    const { hash, estimateData } = await reloadProjectData();
    socket.current.emit(CoreCommands.JOIN_PROJECT, {
      projectUuid: estimateData.uuid,
      versionKey: editorStore.currentVersionKey,
      commentHash: hash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
      ...editionPrivileges.current,
    });
    projectJoined.current = { projectUuid: estimateData.uuid, projectHash: hash };
  }
  
  useEffect(() => {
    if(isSetup && connectionOnline) {
      onReconnect();
    }
    
    if(!connectionOnline) {
      onDisconnect();
    }
  }, [connectionOnline]);

  const onReconnect = async () => {
    console.log("EDITOR RECONNECTED");
   
    if(editionPrivileges.current.joinEdition && projectJoined.current) {
      await resetProjectData();
      closeSnackbar(connectionOnlineSnackbar.current);
      connectionRestoredSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_restored"),
        {
          key: SNACKBAR_WEBSOCKET_RESTORED,
          variant: "success",
          autoHideDuration: 2000
        }
      );
    }
  };

  const onDisconnect = () => {
    console.log("EDITOR DISCONNECTED");
    if(lastSentAction.current !== "END" && editionPrivileges.current.joinEdition) {
      closeSnackbar(connectionRestoredSnackbar.current);
      connectionOnlineSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_offline"),
        {
          persist: true,
          variant: "warning"
        }
      );
    }
    setUsers([]);
  };

  const madeIdenticalAction = (command, payload, additionalChecks) => {
    if (!lastSentAction.current) return false;
    return (
      lastSentAction.current.command === command &&
      lastSentAction.current.timestamp > payload.timestamp &&
      (!additionalChecks || additionalChecks(lastSentAction.current.payload))
    );
  };

  const saveAndSendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    const timestamp = new Date().getTime();
    payload.timestamp = timestamp;
    payload.projectUuid = projectJoined.current.projectUuid;
    lastSentAction.current = { command, payload, timestamp };
    sendAction(command, payload);
  };

  // --------- remote actions ---------

  const onJoined = (userData) => {
    console.log("USER JOINED", userData);
    setUsers((l) => [...l, ...userData])
  };

  const onLeft = (userSocketIds) => {
    console.log("USER LEFT", userSocketIds);
    setUsers((l) => l.filter((u) => !userSocketIds.includes(u.socketId)));
  };
  
  const onProjectCreationEnd = ({ status }) => {
    editorStore.setCreatedStatus(status);
  }
  
  const onError = (data) => {
    console.error("ERROR => ", data.message);
    setConnectionError(true);
  }
  
  const onStructurePatch = (data) => {
    
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === data.versionKey
    )?.structure;
    
    if (structure) try {
      structure.historyManager.withoutUndo(() => {
        data.patches.forEach(p => {
          if(p.removesElement && p.identifier)
          structure.historyManager.clearByTargetId(p.identifier)
        })
        applyPatch(structure, data.patches);
      })
    } catch(e) {
      console.log("Error: applyPatch", e);
    }
  };

  const onReceiveVersionList = ({ message, versions, projectMetadata }) => {
    if(message)
      console.error(message);
    if(versions)
        editorStore.setVersions(versions);
    if(projectMetadata?.workTypes.length)
        editorStore.addCustomEstimateWorkTypes(projectMetadata.workTypes);
  }
  
  const onReceiveVersion = ({ message, versionData }) => {
    if(message)
      console.error(message);
    if(versionData)
      editorStore.setVersionData(versionData);
    
    stateStore.resetProjectCreatorProgress();
  };

  const onCreateVersion = ({ versionData, userSocketId }) => {
    editorStore.addNewVersion(versionData, userSocketId === socket.current.id);
  };

  const onRemoveVersion = ({ versionKey, newVersionData, versionName, userSocketId }) => {
    if(newVersionData && editorStore.currentVersion === versionKey) {
      editorStore.setVersionData(newVersionData);
      if(socket.current.id !== userSocketId)
        enqueueSnackbar(
          <p>
            {t("common.version")}
            <b>{` ${versionName} `}</b>
            {t("alerts.editor.version_removed")}
          </p>,
          { variant: "warning", autoHideDuration: 2000 }
        );
    }
    editorStore.removeSelectedVersion(versionKey);
  };

  const onRenameVersion = (data) => {
    if (
      madeIdenticalAction(
        ProjectEditorCommands.VERSION_RENAME,
        data,
        (p) => p.versionKey === data.versionKey
      )
    )
      return;
    const { versionKey, newName } = data;
    editorStore.editVersionName(versionKey, newName);
  };

  const onReorderVersion = (data) => {
    if (madeIdenticalAction(ProjectEditorCommands.VERSION_ORDER, data))
      return;
    editorStore.updateVersionOrder(data.newOrder);
  };
  
  const onVersionLock = (data) => {
    editorStore.setEditionLock(data.versionKey, data.locked);
  }
  
  const onVersionVisibility = (data) => {
    editorStore.setVersionHidden(data.versionKey, data.visible);
  }

  const onProjectRename = (data) => {
    if (madeIdenticalAction(ProjectCommands.PROJECT_RENAME, data)) return;
    editorStore.setEstimateName(data.newName);
  };
  
  const onProjectRemove = ({ projectUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setEstimateStatus(PROJECT_STATUS.REMOVED);
  }
  
  const onProjectArchive = ({ projectUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setEstimateStatus(PROJECT_STATUS.ARCHIVE);
  }
  
  const onProjectStatusUpdate = ({ projectUuid, status }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    editorStore.setEstimateStatus(status);
  }
  
  const onProjectUserAdd = ({ projectUuid, ...userData }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    const observer = [...editorStore.estimateUsers.observer];
    observer.push(userData)
    editorStore.setEstimateUsers({
      ...editorStore.estimateUsers,
      observer
    })
  }
  
  const onProjectUserPromote = ({ projectUuid, userUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    const newOwner = editorStore.estimateUsers.observer.find((u) => u.uuid === userUuid);
    const observer = [...editorStore.estimateUsers.observer].filter((u) => u.uuid !== userUuid);
    if (editorStore.estimateUsers.owner && editorStore.estimateUsers.owner?.uuid !== editorStore.estimateUsers.author.uuid)
      observer.push({ ...editorStore.estimateUsers.owner });
    
    editorStore.setEstimateUsers({
      ...editorStore.estimateUsers,
      owner: newOwner,
      observer
    });
  };
  
  const onProjectUserRemove = ({ projectUuid, userUuid }) => {
    if(projectUuid !== projectJoined.current.projectUuid)
      return;
    
    let newOwner = editorStore.estimateUsers.owner;
    const observer = [...editorStore.estimateUsers.observer].filter((u) => u.uuid !== userUuid);
    if (newOwner && userUuid === newOwner.uuid) {
      observer.push({ ...newOwner });
      newOwner = editorStore.estimateUsers.author;
    }
    
    editorStore.setEstimateUsers({
      ...editorStore.estimateUsers,
      owner: newOwner,
      observer
    });
  };

  const onAddNewWorkType = ({ id, name, backgroundColor }) => {
    editorStore.addCustomWorkType(id, name, backgroundColor);
  };

  const onWorkTypeChange = ({ id, name, backgroundColor }) => {
    editorStore.updateWorkTypeData(id, name, backgroundColor);
  };

  const onWorkTypeAssignment = ({ workTypeId, userUuid }) => {
    editorStore.addLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeRevoke = ({ workTypeId, userUuid }) => {
    editorStore.removeLocalPermit(workTypeId, userUuid);
  };

  const onWorkTypeStatus = ({ workTypeId, status }) => {
    editorStore.updateLocalPermit(workTypeId, status);
  };

  const onUserWorkTypesStatus = ({ userId, status }) => {
    editorStore.setEstimateUserWorkTypeVisibility(userId, status);
  };

  const onCoverAdd = (cover) => {
    editorStore.setPdfDocument(cover);
  };

  const onCoverRemove = () => {
    editorStore.removeDocument();
  };

  const onCoverUsedPagesUpdate = ({ docId, usedPages, tablePos }) => {
    editorStore.setUsedPages(usedPages, docId);
    if(tablePos)
      editorStore.setTablePos(tablePos, docId);
  };

  const onCoverScaleUpdate = ({ scale }) => {
    editorStore.setScale(scale.scale, scale.scaleValue, false);
  };
  
  const onCommentAdd = ({ versionKey, commentData }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.addComment(commentData);
      })
  };
  
  const onCommentEdit = ({ versionKey, commentData }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        const { id, body, parentId } = commentData;
        structure.updateComment(id, body, parentId);
      })
  };
  
  const onCommentRemove = ({ versionKey, commentId, parentId }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.removeComment(commentId, parentId);
      })
  };
  
  const onCommentPathRemove = ({ versionKey, place }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.removeCommentByPath(place);
      })
  };
  
  const onCommentMove = ({ versionKey, place, oldPlace }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.updateCommentPath(oldPlace, place);
      })
  };
  
  const onShareSettingsUpdate = ({ settings }) => {
    editorStore.setAllShareLinkSettings(JSON.parse(settings));
  };

  // --------- USER ACTIONS ---------

  const sendMoveData = (
    clientX,
    clientY,
    elementType,
    elementPath,
    elementId
  ) => {
    sendAction(ProjectEditorCommands.USER_MOVE, {
      id: userStore.data.id,
      vW: window.innerWidth,
      // vH: containerHeight,
      x: clientX,
      y: clientY,
      elementType,
      elementPath,
      elementId,
    });
  };
  
  const requestStructurePatch = (patches, metadata) => {
    saveAndSendAction(ProjectEditorCommands.PATCH, {
      versionKey: editorStore.currentVersionKey,
      timestamp: new Date().getTime(),
      patches,
      metadata,
    });
  };

  const requestExistingVersion = (versionKey) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_REQUEST, {
      projectUuid: projectJoined.current.projectUuid,
      userUuid: userStore.userUuid,
      versionKey,
    });
  };
  
  const requestNewVersion = (copyComments) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_NEW, {
      originVersionKey: editorStore.currentVersionKey,
      copyComments,
    });
  };

  const requestVersionRename = (versionKey, newName) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_RENAME, { versionKey, newName });
  };

  const requestVersionRemoval = (versionKey) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_REMOVE, { versionKey });
  };

  const requestVersionVisibility = (versionKey, visible) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_VISIBILITY, { versionKey, visible });
  };

  const requestVersionLock = (versionKey, locked) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_LOCK, { versionKey, locked });
  };

  const requestVersionReorder = (movedVersionKey, newOrder) => {
    saveAndSendAction(ProjectEditorCommands.VERSION_ORDER, { movedVersionKey, newOrder });
  };

  const addNewPermit = (workTypeId, userUuid) => {
    saveAndSendAction(ProjectEditorCommands.PERMIT_ADD, { workTypeId, userUuid });
  };

  const removePermit = (workTypeId, userUuid) => {
    saveAndSendAction(ProjectEditorCommands.PERMIT_REMOVE, { workTypeId, userUuid });
  };

  const setEstimateUserWorkTypeVisibility = (userId, status) => {
    saveAndSendAction(ProjectEditorCommands.PERMIT_USER_VISIBILITY, { userId, status: status ? 1 : 0 });
  };

  const setPermitStatus = (workTypeId, status) => {
    saveAndSendAction(ProjectEditorCommands.PERMIT_STATUS, { workTypeId, status });
  };

  const addPdfCover = (cover) => {
    saveAndSendAction(ProjectEditorCommands.COVER_ADD, {
      projectUuid: projectJoined.current.projectUuid,
      cover,
    });
  };

  const removeCover = () => {
    saveAndSendAction(ProjectEditorCommands.COVER_REMOVE, {
      projectUuid: projectJoined.current.projectUuid,
    });
  };
  
  const saveCoverChanges = async ({ usedPages, tablePos, scale }) => await (
     saveProjectCoverQuery(
      projectJoined.current.projectUuid,
      userStore.userUuid,
      editorStore.pdfDocument.id,
      {
        ...editorStore.pdfDocument.file,
        usedPages: usedPages || editorStore.usedPdfPages,
        tablePos: tablePos || editorStore.proposalTablePosition,
        scale: scale || editorStore.scale,
      }
    )
  )

  const updateCoverPages = async (docId, usedPages, tablePos) => {
    await saveCoverChanges({ usedPages, tablePos });
    saveAndSendAction(ProjectEditorCommands.COVER_PAGES, { docId, usedPages, tablePos });
  };

  const updateCoverScale = async (docId, scaleObj) => {
    await saveCoverChanges({ scale: scaleObj.scale });
    saveAndSendAction(ProjectEditorCommands.COVER_SCALE, { docId, scaleObj });
  };
  
  const requestCommentAdd = async (userUuid, place, body, mentions, internal, parentId) => {
    const versionKey = editorStore.currentVersionKey;
    const commentData = await createProjectVersionComment(
      projectJoined.current.projectUuid,
      userUuid, versionKey, place, body, mentions, internal, parentId
    );
    saveAndSendAction(ProjectEditorCommands.COMMENT_ADD, { versionKey, commentData });
  };
  
  const requestCommentEdit = async (commentId, userUuid, place, body) => {
    const versionKey = editorStore.currentVersionKey;
    await editProjectVersionComment(commentId, projectJoined.current.projectUuid, userUuid, versionKey, place, body);
    saveAndSendAction(ProjectEditorCommands.COMMENT_EDIT, {
      versionKey,
      commentData :{
        id: commentId,
        userUuid,
        place,
        body,
      },
    });
  };
  
  const requestCommentRemove = async (commentId, userUuid, parentId) => {
    const versionKey = editorStore.currentVersionKey;
    await removeProjectVersionComment(
      commentId, projectJoined.current.projectUuid, userUuid, versionKey
    );
    saveAndSendAction(ProjectEditorCommands.COMMENT_REMOVE, {
      versionKey, commentId, parentId, userUuid,
    });
  };
  
  const requestCommentPathRemove = async (place) => {
    const versionKey = editorStore.currentVersionKey;
    await removeProjectVersionCommentByPath(
      projectJoined.current.projectUuid,
      userStore.data.uuid,
      versionKey, place,
    );
    saveAndSendAction(ProjectEditorCommands.COMMENT_REMOVE_PATH, { versionKey, place });
  };
  
  const requestCommentMove = async (oldPlace, place) => {
    const versionKey = editorStore.currentVersionKey;
    await moveProjectVersionComment(
      projectJoined.current.projectUuid,
      userStore.data.uuid,
      versionKey, oldPlace, place,
    );
    saveAndSendAction(ProjectEditorCommands.COMMENT_MOVE, { versionKey, oldPlace, place });
  };
  
  const requestShareSettingsUpdate = (settings) => {
    saveAndSendAction(ProjectEditorCommands.SHARE_SETTINGS, {
      settings: JSON.stringify(settings)
    });
  };

  const value = {
    users,
    hasConnectionError,
    editionPrivileges: joinEdition,
    initSocket: init,
    closeProject,
    joinProject,
    joinProposal,
    
    sendMoveData,
    requestStructurePatch,
    requestExistingVersion,
    requestNewVersion,
    requestVersionRename,
    requestVersionRemoval,
    requestVersionReorder,
    requestVersionVisibility,
    requestVersionLock,
    requestEstimateRename: requestProjectRename,
    addNewPermit,
    removePermit,
    setEstimateUserWorkTypeVisibility,
    setPermitStatus,
    addPdfCover,
    removeCover,
    updateCoverPages,
    updateCoverScale,
    requestCommentAdd,
    requestCommentEdit,
    requestCommentRemove,
    requestCommentPathRemove,
    requestCommentMove,
    requestShareSettingsUpdate,
  };

  return (
    <EditorSocketContext.Provider value={value}>
      {children}
    </EditorSocketContext.Provider>
  );
};

EditorSocketProvider.propTypes = {
  children: node.isRequired,
  reloadProjectData: func,
};
