import { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEditorWebsocket, useCheckEstimateEditorAccess } from "@hooks";
import { useEstimateEditorStore, EstimateSettings } from "@tools"
import { CircularProgress, Grid } from "@material-ui/core";
import { Alert, Button, TextClearInput } from "@components";
import { VisibilityMode } from "./components/VisibilityMode";
import { SaveOutlined } from "@material-ui/icons";
import useStyle from "./TableToolbar.style";
import classnames from "classnames";
import { saveTemplateStructureQuery } from "@query";

export const TemplateTableToolbar = observer(() => {
  const classes = useStyle();
  const { templateId } = useParams();
  const editorStore = useEstimateEditorStore();
  const { t } = useTranslation();
  const socket = useEditorWebsocket();

  const [alertText, setAlertText] = useState("");
  const [titleEditorFocused, focusTitleEditor] = useState(undefined);
  const [editedTitle, setEditedTitle] = useState("");
  const [isLoading, setLoadingState] = useState("");

  const {
    estimateName,
    estimateUsers,
  } = editorStore;

  const { isBusinessUser, isObserver } = useCheckEstimateEditorAccess(estimateUsers);

  useEffect(() => {
    if (estimateName) setEditedTitle(estimateName.replace(/<(.|\n)*?>/g, ""));
  }, [estimateName]);

  const changeTemplateName = async () => {
    if (estimateName !== editedTitle) {
      await socket?.requestEstimateRename(templateId, editedTitle);
      editorStore.setEstimateName(editedTitle);
    }
    focusTitleEditor(false);
  };

  const handleTitleFocus = () => {
    focusTitleEditor(true);
  };
  
  const handleTemplateSave = async () => {
    setLoadingState(true);
    await saveTemplateStructureQuery(templateId);
    setLoadingState(false);
  }

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      name="table_title"
    >
      <Grid item container direction="column" className="w-max max-w-full">
        <h1 className="preset-title w-max max-w-full mb-2">
          <TextClearInput
            onFocus={handleTitleFocus}
            onAccept={changeTemplateName}
            onChange={setEditedTitle}
            value={editedTitle}
            confirmOnEnter
            acceptOnClickAway
            focused={titleEditorFocused}
            containerClass={classes.projectTitleEditor}
            className="w-max max-w-full"
          />
        </h1>
      </Grid>
      <Grid
        item
        container
        justifyContent="flex-end"
        className={classes.actionContainer}
      >
        { isBusinessUser && isObserver && <VisibilityMode /> }
        { isBusinessUser && isObserver && <EstimateSettings /> }
        <Button
          icon={isLoading ? <CircularProgress size={24} className="text-alt" /> : <SaveOutlined />}
          onClick={handleTemplateSave}
          className={classnames(classes.shareButton)}
          name={t("views.editor.dialogs.share.accept")}
          size="large"
          variant="contained"
          color="primary"
        >
          {t("views.editor.save")}
        </Button>
        <Alert
          title={alertText}
          isOpen={!!alertText}
          onAccept={() => setAlertText("")}
          acceptText={t("common.close")}
        />
      </Grid>
    </Grid>
  );
});