import { useEffect, useState } from "react";
import { arrayOf, bool, func, number, object, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getAllProjectTypesQuery } from "@query";
import {
  Button,
  ControlledTextField,
  Autocomplete,
  InfoLabel,
  ControlledCheckbox,
  Label,
} from "@components";
import { Divider, Grid, CircularProgress, IconButton } from "@material-ui/core";
import { Settings } from "@assets";
import { ProjectTypesDialog } from "@dialogs/ProjectTypesDialog";
import { useStores } from "@hooks";
import { NewProjectTypeDialog } from "@dialogs";

const newTemplateFormSchema = (t, defaultValues) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.project.name.template"))
      .default(defaultValues?.name),
    description: yup
      .string()
      .nullable(true)
      .default(defaultValues?.description),
    types: yup.array().of(yup.number()).default([]),
    copyComments: yup.boolean(),
  });

export const NewTemplateForm = observer(({
  onSubmit,
  onCancel,
  onNewTypeCreate,
  defaultValues,
  projectTypes: fetchedProjectTypes=[],
  edition=false,
  hasComments=false,
}) => {
  const { t } = useTranslation();
  const { userStore } = useStores();
  
  const [tmpName, setTmpName] = useState([]);
  const [projectTypes, setProjectTypes] = useState(fetchedProjectTypes);
  const [projectTypesDialogVisible, showProjectTypesDialog] = useState(false);
  const [newProjectTypeDialogVisible, showNewProjectTypeDialog] = useState(false);

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(newTemplateFormSchema(t)),
    defaultValues: defaultValues || {
      description: "",
      name: "",
      types: [],
      copyComments: false,
    },
  });
  
  const { isAdmin } = userStore;

  useEffect(() => {
    if(!fetchedProjectTypes?.length)
    (async () => {
      const res = await getAllProjectTypesQuery(true);
      setProjectTypes(res);
    })();
  }, []);
  
  const handleNewProjectType = (name) => {
    setTmpName(name);
    showNewProjectTypeDialog(true);
  }
  
  const confirmNewProjectType = (newProjectType) => {
    if(newProjectType) {
      setProjectTypes(( l ) => [ ...l, newProjectType ]);
      const selected = getValues("types") || [];
      setValue("types", [ ...selected, newProjectType.id])
      onNewTypeCreate?.(newProjectType);
    }
    
    setTmpName(null);
    showNewProjectTypeDialog(false);
  }

  const submitHandler = async (data) => {
    const result = await onSubmit(data);

    if (result) {
      const { errors } = result;

      errors?.forEach(({ field, message }) => {
        setError(field, { message });
      });
    }
  };

  return (
    <form
      autoComplete="on"
      aria-label="newTemplate form"
      onSubmit={handleSubmit(submitHandler)}
    >
      <Grid item container spacing={1} direction="column" wrap="nowrap" >
        <Grid item container xs={12}>
          <InfoLabel label="forms.template.name" />
          <ControlledTextField
            name="name"
            control={control}
            placeholder={t("forms.template.name_enter")}
          />
        </Grid>
        <Grid item container xs={12}>
          <InfoLabel label="forms.template.description" />
          <ControlledTextField
            name="description"
            control={control}
            placeholder={t("forms.template.description_enter")}
            multiline
            size="small"
          />
        </Grid>
        {/*<Grid item container xs={12}>*/}
        {/*  <InfoLabel label="forms.template.category" />*/}
        {/*  <Controller*/}
        {/*    name="category"*/}
        {/*    control={control}*/}
        {/*    render={({ field: { onChange, value, name } }) => (*/}
        {/*      <Autocomplete*/}
        {/*        id="categorySelect"*/}
        {/*        value={value}*/}
        {/*        options={categories}*/}
        {/*        inputPlaceholder={t("forms.template.category_enter")}*/}
        {/*        fullWidth*/}
        {/*        idKey="id"*/}
        {/*        labelKey="name"*/}
        {/*        name={name}*/}
        {/*        onChange={onChange}*/}
        {/*        groupBy={(option) => option.parent.toString()}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</Grid>*/}
        <Grid item container xs={12}>
          <InfoLabel
            label="forms.template.types"
            titleAction={isAdmin && <IconButton
              size="small"
              color="primary"
              onClick={() => showProjectTypesDialog(true)}
              className="ml-1"
            >
              <Settings className="text-sm" />
            </IconButton>}
          />
          {
            projectTypesDialogVisible &&
            <ProjectTypesDialog
              open={projectTypesDialogVisible}
              projectTypes={projectTypes}
              onChange={setProjectTypes}
              onClose={() => showProjectTypesDialog(false)}
            />
          }
          {
            newProjectTypeDialogVisible &&
            <NewProjectTypeDialog
              defaultName={tmpName}
              open={newProjectTypeDialogVisible}
              onClose={confirmNewProjectType}
            />
          }
          <Controller
            name="types"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Autocomplete
                id="projectTypesSelect"
                value={value || []}
                options={projectTypes}
                inputPlaceholder={t("forms.template.types_enter")}
                fullWidth
                multiple
                idKey="id"
                labelKey="name"
                limitTags={4}
                name={name}
                onChange={onChange}
                onOptionCreate={handleNewProjectType}
                renderTagsOnList
                noOptionsText={t("views.settings.menu_full.no_project_types")}
                renderOption={({ option, ...tagProps }) => <Label
                  {...tagProps}
                  {...option}
                />}
              />
            )}
          />
        </Grid>
        {
          hasComments &&
          <>
            <Divider className="my-0 -mx-6" />
            <Grid item container xs={12} className="my-4">
              <InfoLabel label="forms.template.comments" />
              
              <ControlledCheckbox
                name="copyComments"
                control={control}
                label={t("forms.template.comments_yes")}
              />
            </Grid>
          </>
        }
      </Grid>
      
      <Divider className="mt-2 -mx-6 mb-6" />

      <Grid item container justifyContent="flex-end">
        {
          !!onCancel &&
          <Button
            variant="outlined"
            onClick={onCancel}
            className="mr-2"
          >
            {t("common.cancel")}
          </Button>
        }
        <Button
          isSubmit
          name="submit"
          variant="contained"
          endIcon={
            isSubmitting ? (
              <CircularProgress
                aria-label="progress indicator"
                size={20}
                color="inherit"
              />
            ) : undefined
          }
        >
          {
            edition
              ? t("common.save")
              : t("views.project.project_template.title")
          }
        </Button>
      </Grid>
    </form>
  );
});

NewTemplateForm.propTypes = {
  onSubmit: func.isRequired,
  onCancel: func,
  onNewTypeCreate: func,
  defaultValues: object,
  hasComments: bool,
  projectTypes: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    backgroundColor: string.isRequired,
    fontColor: string.isRequired,
  })),
};
