import { memo, useMemo } from "react";
import { bool, number, string } from "prop-types";
import { HtmlParser } from "@utils";
import { TooltipIcon } from "@components";
import { Badge, IconButton } from "@material-ui/core";
import { Info } from "@assets";
import classnames from "classnames";
import useStyle from "./TaskContentTooltip.style";

export const TaskContentTooltip = memo(({
  description="",
  hasDescription,
  childrenWithContent,
  hideBadge,
  className,
  disableTooltip,
}) => {
  
  const classes = useStyle();
  
  const noBadge = useMemo(
    () => hideBadge || childrenWithContent === 0,
    [hideBadge, childrenWithContent]
  );

  if (noBadge && !hasDescription) return <></>;

  return (
    <Badge
      color="secondary"
      invisible={noBadge}
      badgeContent={childrenWithContent}
      overlap="circular"
      max={9}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {hasDescription ? (
        <TooltipIcon
          color="primary"
          className={classnames("button-description-view noDrag preset-taskActionButton", className)}
          interactive={!disableTooltip}
          tooltipClassName={classnames("ql-root", classes.contentTooltip)}
          disabled={disableTooltip}
        >
          {HtmlParser(description)}
        </TooltipIcon>
      ) : (
        <IconButton
          color="primary"
          size="small"
          tabIndex={-1}
          className={classnames("preset-taskActionButton", className)}
        >
          <Info />
        </IconButton>
      )}
    </Badge>
  );
});

TaskContentTooltip.propTypes = {
  description: string,
  hasDescription: bool,
  childrenWithContent: number,
  hideBadge: bool,
  className: string,
  disableTooltip: bool,
};
