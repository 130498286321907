import { object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { TextClearInput } from "@components";
import { useEffect, useRef, useState } from "react";
import { MAX_MODULE_NAME } from "@utils";

export const LibraryTaskNameEditor = observer(({ element }) => {
  const { t } = useTranslation();
  const { name, autoFocus } = element;
  
  const [searchValue, setSearchValue] = useState("");
  const [taskNameAlreadyUsed, setUsedValue] = useState(false);
  const [focused, setFocused] = useState(autoFocus);
  
  const anchorEl = useRef(null);
  
  useEffect(() => {
    if (autoFocus) {
      setFocused(true);
      element.removeAutofocus();
    }
  }, [autoFocus]);
  
  useEffect(() => {
    setSearchValue(name?.replace(/<(.|\n)*?>/g, ""));
  }, [name]);
  
  /** Finds best match for provided "searchValue"; triggers on input blur */
  const handleModuleSelect = async () => {
    if (focused) {
      if (taskNameAlreadyUsed) {
        setUsedValue(false);
        setSearchValue(name);
      } else if (searchValue !== name) element.setName(searchValue.trim());
      setFocused(false);
    }
  };
  
  const handleInputChange = (v) => {
    const value = v.slice(0, MAX_MODULE_NAME);
    setSearchValue(value);
    // setUsedValue(structure.getExistingStructureLevelSibling(element.id, value, parentIds.length));
    setUsedValue(element.hasSibling(value));
  };
  
  const handleFocus = () => {
    setFocused(true);
  };

  return (
    <TextClearInput
      onChange={handleInputChange}
      value={searchValue}
      focused={focused}
      error={taskNameAlreadyUsed}
      onFocus={handleFocus}
      onAccept={handleModuleSelect}
      placeholder={t("views.editor.dialogs.features.new_name_library")}
      ref={anchorEl}
      confirmOnEnter
      acceptOnClickAway
    />
  );
});

LibraryTaskNameEditor.propTypes = {
  element: object.isRequired,
};
