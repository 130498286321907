import { useContext } from "react";
import { WorkspaceSocketContext } from "@client";

export const useWorkspaceWebsocket = () => {
  const context = useContext(WorkspaceSocketContext);
  if (context === null) {
    throw new Error("WorkspaceSocketContext cannot be null, please add a context provider");
  }
  return context;
};
