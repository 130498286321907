import { useMemo } from "react";
import { bool, string, node, arrayOf, oneOfType } from "prop-types";
import { Divider, Grid } from "@material-ui/core";
import { DragHandle } from "@components";
import useStyle from "./SectionTitle.style";
import classnames from "classnames";

export const SectionTitle = ({
  id,
  children,
  useDragHandle,
  colored,
  spacing,
  actions,
}) => {
  const classes = useStyle();

  const hasActions = useMemo(() => !!actions, [actions]);

  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      className={classnames("preset-section", classes.root, spacing && "mb-6")}
      id={id}
    >
      <Grid item container style={{ width: 36 }}>
        <Divider
          className={classnames(classes.divider, colored && classes.colored)}
        />
      </Grid>
      {useDragHandle && (
        <DragHandle
          absolute
          backgroundPrimary
          className="preset-page preset-noedit"
        />
      )}
      <Grid item className={classes.box}>
        {children}
      </Grid>
      <Grid item container>
        <Divider
          className={classnames(classes.divider, colored && classes.colored)}
        />
      </Grid>
      {hasActions && actions}
    </Grid>
  );
};

SectionTitle.propTypes = {
  id: string,
  children: node,
  useDragHandle: bool,
  colored: bool,
  spacing: bool,
  actions: oneOfType([arrayOf(node), node]),
};
