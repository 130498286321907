import { arrayOf, bool, func, object, oneOfType, string } from "prop-types";
import { Tag } from "../Tag/Tag";

export const TagList = ({
  tags,
  color,
  idKey = "id",
  nameKey = "name",
  ...tagProps
}) => (
  <>
    {tags.map((t) => {
      const isString = typeof t === "string";
      return (
        <Tag
          {...tagProps}
          key={isString ? t : t[idKey]}
          text={isString ? t : t[nameKey]}
          id={isString ? undefined : t[idKey]}
          color={color || (isString ? undefined : t.backgroundColor)}
          className="my-0-5 mx-1p"
        />
      );
    })}
  </>
);

TagList.propTypes = {
  tags: arrayOf(oneOfType([string, object])).isRequired,
  onRemove: func,
  onClick: func,
  forceDot: bool,
  ellipsisOnOverflow: bool,
  useMaxWidth: bool,
  color: string,
  idKey: string,
  nameKey: string,
};
