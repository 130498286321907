import { observer } from "mobx-react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { home } from "@paths";
import { useCheckEstimateEditorAccess, useStores, useWorkspaceWebsocket } from "@hooks";
import { EstimateStructureProvider, useEstimateEditorStore, EditorRoot, useEstimatePresetStore } from "@tools";
import { PdfUploadProgress, Alert } from "@components";
import { usePresetStyles } from "@styles";
import usePresetEditorStyle from "@tools/EstimateEditor/components/PresetEdition/PresetEditor.style";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { SNACKBAR_ESTIMATE_ARCHIVED } from "@utils";
import { moveProjectVersionComment } from "@query";

export const ProjectEditorView = observer(() => {
  const {t} = useTranslation();
  const editorStore = useEstimateEditorStore();
  const presetStore = useEstimatePresetStore();
  const { userStore } = useStores();
  const socket = useWorkspaceWebsocket();
  const navigate = useNavigate();
  const { projectUuid } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    isArchived,
    isRemoved,
    isRestored,
    currentEstimateStructure,
    proposalStep,
    estimateUsers,
  } = editorStore;
  
  const { selectedPresetData, selectedPresetFont } = presetStore;

  const {
    hasEditorPrivileges,
    hasSellerPrivileges,
    isObserver
  } = useCheckEstimateEditorAccess(estimateUsers);
  
  usePresetStyles(selectedPresetData, selectedPresetFont, !!proposalStep);
  usePresetEditorStyle(proposalStep === 1);
  
  useEffect(() => {
    if (isArchived)
      enqueueSnackbar(t("alerts.editor.archive_readonly"), {
        variant: "info",
        key: SNACKBAR_ESTIMATE_ARCHIVED,
        persist: true,
      });
    else {
      closeSnackbar(SNACKBAR_ESTIMATE_ARCHIVED)
    }
  }, [isArchived]);
  
  useEffect(() => {
    if (!isArchived && !isObserver)
      enqueueSnackbar(t("alerts.editor.readonly_info"), {
        variant: "info",
        autoHideDuration: 6000,
      });
    
  }, [isObserver]);
  
  const onCommentMove = async (versionKey, oldPlace, newPlace) => {
    await moveProjectVersionComment(
      projectUuid,
      userStore.data.uuid,
      versionKey, oldPlace, newPlace
    );
  }
  
  return (
    <EstimateStructureProvider value={currentEstimateStructure}>
      <EditorRoot
        hasEditorPrivileges={ hasEditorPrivileges }
        hasSellerPrivileges={ hasSellerPrivileges }
        isProposal={ !!proposalStep }
        
        readOnly={ !socket?.connectionOnline || isArchived || !!proposalStep }
        allowInteractions={ socket?.connectionOnline && !isArchived && proposalStep !== 1 }
        forceAllowVersionSelect={ isArchived }
        
        showStepper={ socket?.connectionOnline && !isArchived && hasSellerPrivileges && hasEditorPrivileges }
        showUserMovement={ proposalStep === 0 }
        showPresetToolbar={ proposalStep === 1 }
        showPdfCover={ proposalStep === 2 }
        onCommentMove={ onCommentMove }
      />
      <PdfUploadProgress />
      {
        isRemoved &&
        <Alert
          isOpen
          title={t("alerts.editor.project_removed")}
          acceptText={t("alerts.editor.close_editor")}
          onAccept={() => navigate(home)}
        />
      }
      {
        isRestored &&
        <Alert
          isOpen
          title={t("alerts.editor.project_restored")}
          acceptText={t("common.close")}
          onAccept={() => editorStore.setRestored()}
        />
      }
    </EstimateStructureProvider>
  );
});