import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { CircularProgress, Grid } from "@material-ui/core";
import { TextClearInput, SettingsRow } from "@components";

export const NewDomain = ({
  domain,
  isLoading,
  domainFocused,
  saveDomain,
  handleDomainChange,
  handleDomainFocus,
}) => {
  
  const { t } = useTranslation();
  
  return <SettingsRow bordered>
    <Grid xs={5} sm={3}>
      {t("views.settings.projects.domain_enter")}
    </Grid>
    <Grid
      item container
      alignItems="center"
      wrap="nowrap"
      xs={7} sm={9}
      className="relative"
    >
      <TextClearInput
        value={domain}
        onChange={handleDomainChange}
        onAccept={saveDomain}
        confirmOnEnter
        acceptOnClickAway
        focused={domainFocused}
        onFocus={handleDomainFocus}
        disabled={isLoading}
        className={"mr-8"}
        placeholder={t("views.settings.projects.domain_placeholder")}
        filterChars={["/", ":"]}
      />
      { isLoading && <CircularProgress size={18} className="absolute r-0" /> }
    </Grid>
  </SettingsRow>;
}

NewDomain.propTypes = {
  domain: string,
  isLoading: bool,
  domainFocused: bool,
  handleDomainChange: func,
  saveDomain: func,
  handleDomainFocus: func,
}