import { observer } from "mobx-react";
import { BREAKDOWN_TABLE_DEPTHS, Tables } from "project-structure";
import { CELL_WIDTHS } from "@utils";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  useEstimateTableSettings,
  ChatThread,
  TitleCell,
  RowGroup,
  Row,
} from "@tools";
import { ColumnFactory } from "./components/ColumnFactory";
import { TitleInput } from "./components/TitleInput";

export const FixedPriceRow = observer(() => {
  const {
    useProfitability,
    showChatThread,
    allowEdition,
    useVisibilityCell,
  } = useEstimateEditorSettings();
  const {
    useExpandCell,
    columns,
  } = useEstimateTableSettings();
  const structure = useEstimateStructure();
  const { turnOffFixedPrice } = structure.settings;

  const handleFixedPriceVisibility = () =>
    structure.settings.setTurnOffFixedPrice(!turnOffFixedPrice);
  
  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      tableDepth={BREAKDOWN_TABLE_DEPTHS.MODULE}
      isStatic
      presetClass="preset-accentSummary"
      elementId="fixedPrice"
      elementType="fix"
    >
      <Row
        tableDepth={BREAKDOWN_TABLE_DEPTHS.MODULE}
        isLastGroupRow
        isFirstGroupRow
        useVisibilityCell={useVisibilityCell}
        useExpandCell={useExpandCell}
        presetClass="preset-rowSummary"
        visible={!turnOffFixedPrice}
        onVisibilityToggle={handleFixedPriceVisibility}
        noArrow
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          pinnedLeft={useExpandCell ? 1 : 0}
          actions={showChatThread && (
            <ChatThread
              rowThread
              path="fixedCosts"
            />
          )}
        >
          <TitleInput
            allowEdition={allowEdition}
            useExpandCell={useExpandCell}
          />
        </TitleCell>
        {
          columns.map(({ name }) => (
            <ColumnFactory
              key={name}
              name={name}
            />
          ))
        }
      </Row>
    </RowGroup>
  );
});