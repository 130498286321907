import { memo, useEffect, useMemo, useRef, useState } from "react";
import { arrayOf, bool, func, node, object, oneOf, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { HtmlParser, quillBlocksRegex, textOverflows, trimEmptyRows } from "@utils";
import { useResizeObserver } from "@hooks";
import { Grid, Link } from "@material-ui/core";
import { QuillEditor } from "./components/QuillEditor/QuillEditor";
import useStyle from "./WysiwygEditor.style";
import classnames from "classnames";

export const WysiwygEditor = memo(({
  value: defaultValue,
  name,
  onChange,
  onTextChange,
  onFocus,
  onBlur,
  noEmptyHtmlStrings,
  changeOnClickAway,
  className,
  style,
  readOnly,
  autoFocus,
  forcedFocus,
  actions,
  shorten,
  showMore,
  onShowMore,
  direction,
  alignItems,
  useBorder,
  moreButtonClassName,
  blurOnEnter,
  ...toPass
}) => {
  
  const {t} = useTranslation();
  const classes = useStyle();
  
  const [ value, setValue ] = useState(defaultValue || "");
  const [ mentions, setMentions ] = useState(defaultValue || "");
  const [ focused, setFocused] = useState(autoFocus && !readOnly);
  const [ showFull, setShowFull] = useState(!shorten || showMore);
  const [ overflows, setOverflows] = useState(!shorten);
  
  const quillRef = useRef();
  const containerRef = useResizeObserver(
    (el) => setOverflows(textOverflows(el.clientWidth, value, 14))
  );
  
  const contentLines = useMemo(() => (
    !value
      ? []
      : value.match(quillBlocksRegex) || [value]
  ), [value]);
  
  const displayValue = useMemo(() => {
    if(!contentLines?.[0]) return "";
    return contentLines[0]
      .replace(/<li>/g, "<p>")
      .replace(/<\/li>/g, "</p>");
  }, [value, contentLines]);
  
  const showMoreButton = useMemo(() => (
    shorten && !focused &&
    (contentLines?.length > 1 || overflows)
  ), [shorten, focused, contentLines, overflows]);
  
  useEffect(() => {
    setShowFull(!shorten || showMore);
  }, [showMore, shorten])
  
  useEffect(() => {
    if(!defaultValue) {
      setValue(""); return;
    }
    setValue(defaultValue.match(quillBlocksRegex) ? defaultValue : `<p>${defaultValue}</p>`);
  }, [defaultValue]);
  
  useEffect(() => {
    if(typeof forcedFocus === "boolean") {
      if ( !forcedFocus && focused )
        commitChange();
      setFocused(forcedFocus);
    }
  }, [forcedFocus]);
  
  const commitChange = (passedValue, passedMentions) => {
    let localValue = passedValue || value;
    const localMentions = passedMentions || mentions;
    
    if(noEmptyHtmlStrings)
      localValue = trimEmptyRows(localValue.trim());
    
    localValue = localValue.replace(/<a href=/g, '<a target="_blank" href=')
    const isDifferent = Boolean(localValue.length && defaultValue?.toString() !== localValue);
    onChange(localValue, isDifferent, localMentions);
  }
  
  const handleChange = (val, mentions) => {
    setValue(val);
    setMentions(mentions);
    onTextChange?.(val, mentions);
    if(changeOnClickAway || blurOnEnter)
      return;
    commitChange(val, mentions);
  }
  
  const handleFocus = () => {
    setFocused(true);
    onFocus && onFocus();
  }
  
  const handleBlur = (reason) => {
    if(reason === "clickAway" && changeOnClickAway)
      commitChange();
    if(reason === "enter" && blurOnEnter)
      commitChange();
    setFocused(false);
    setShowFull(!shorten);
    onBlur && onBlur();
  }
  
  const handleShowMore = () => {
    onShowMore && onShowMore(!showFull);
    setShowFull(!showFull);
  }
  
  return (
    <Grid
      item container
      wrap="nowrap"
      style={style}
      direction={direction || (showFull ? "column" : "row")}
      alignItems={alignItems || (showFull ? "flex-end" : "center")}
      className={classnames(
        "noDrag",
        classes.root,
        className
      )}
      ref={containerRef}
    >
      {actions}
      {
        readOnly
          ? <Grid
            item container
            className={classnames("ql-root ql-editor", !showFull && "ql-ellipsis")}
          >
            { HtmlParser(value) }
          </Grid>
          : <QuillEditor
            ref={quillRef}
            name={name}
            value={value}
            displayValue={displayValue}
            showDisplayValue={shorten && !showFull && !focused}
            focused={focused}
            useBorder={useBorder}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onTextChange={handleChange}
            popupAnchor={containerRef.current}
            blurOnEnter={blurOnEnter}
            {...toPass}
          />
      }
      {
        showMoreButton &&
        <Link
          className={classnames(
            "noDrag text-xs cursor-pointer p-0-5 px-0 ",
            useBorder && "ml-2",
            classes.moreButton,
            moreButtonClassName
          )}
          onClick={handleShowMore}
        >
          { showFull ? t("common.see_less") : t("common.see_more") }
        </Link>
      }
    </Grid>
  )
});

WysiwygEditor.propTypes = {
  value: string,
  name: string.isRequired,
  onChange: func.isRequired,
  onTextChange: func,
  onFocus: func,
  onBlur: func,
  placeholder: string,
  readOnly: bool,
  blockContentButton: bool,
  autoFocus: bool,
  changeOnClickAway: bool,
  changeOnLooseForcedFocus: bool,
  noEmptyHtmlStrings: bool,
  toolbarVariant: oneOf(["none", "inline", "popup"]),
  className: string,
  moreButtonClassName: string,
  style: object,
  children: node, // Toolbar children
  endAdornment: node, // Toolbar children
  actions: node,
  shorten: bool,
  showPlaceholderOnBlurred: bool,
  onShowMore: func,
  editorMentionUsers: arrayOf(object),
  editorMentionSearchKeys: arrayOf(string),
  toolbarPlacement: oneOf(["top", "bottom"]),
  direction: oneOf(["row", "column"]),
  alignItems: oneOf(["flex-start", "center", "flex-end"]),
  forcedFocus: bool,
  useBorder: bool,
  showMore: bool,
  blurOnEnter: bool,
}
