import { makeStyles } from "@material-ui/core/styles";
import { DEFAULT_FONT_NAME } from "@utils";

const useStyle = makeStyles(( theme ) => ({
  editor: {
    width: "100%",
    boxSizing: "border-box",
    fontSize: "inherit",
    fontWeight: "inherit",
    color: "inherit",
    fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif !important`,
  },
  placeholderOnFocus: {
    "&:not(.focused) .ql-editor.ql-blank::before": {
      display: "none",
    }
  },
  
  bordered: {
    padding: 2,
    marginTop: 4,
    marginBottom: 4,
    borderRadius: 1,
    alignItems: "center",
    outlineOffset: 2,
    "&:hover": {
      outlineWidth: 2,
      outlineStyle: "solid",
      outlineColor: theme.palette.primary.main,
    }
  },
  borderedUnfocused: {
    outlineWidth: 1,
    outlineStyle: "solid",
    outlineColor: theme.palette.text.main,
  },
  borderedFocused: {
    outlineWidth: 2,
    outlineStyle: "solid",
    outlineColor: theme.palette.primary.main,
  },
  mentionList: {
    width: 416,
  }
}));

export default useStyle;