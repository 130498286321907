import { ActionButton } from "@components";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { LOCKED_FEATURES } from "@utils";
import { useFeatureGuardian } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { CircularProgress } from "@material-ui/core";
import { Visibility } from "@material-ui/icons";

export const VisibilityMode = observer(() => {
  
  const { t } = useTranslation();
  const editorStore = useEstimateEditorStore();
  
  const { checkAccess, isChecking, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.VISIBILITY
  );
  
  const { visibilityMode } = editorStore;
  
  const handleVisibilityMode = async () => {
    if(!visibilityMode) {
      if(!editorStore.allowVisibility) {
        const allowed = await checkAccess();
        if(!allowed)
          return;
        editorStore.setAllowVisibility(allowed);
      }
      
    }
    editorStore.useVisibilityMode(!editorStore.visibilityMode);
  };
  
  return (
    <>
      <ActionButton
        square
        icon={isChecking ? <CircularProgress size={20} /> : <Visibility />}
        color={visibilityMode ? "primary" : "secondary"}
        onClick={handleVisibilityMode}
        name={t(`views.editor.visibility.title${visibilityMode ? "_back" : ""}`)}
        tooltip
      />
      <FeatureAlert />
    </>
  )
})