import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useEstimateStructure } from "@tools";
import { TotalContainer } from "../components";

export const TotalCompanyCost = observer(({ showDivider }) => {
  const structure = useEstimateStructure();
  
  const {
    settings,
    usesTwoValues,
    displayTotalCompanyCost,
    displayTotalCompanyCostMax
  } = structure;
  const { currencyObj, roundHours } = settings;
  
  const showMaxValue = usesTwoValues && displayTotalCompanyCost !== displayTotalCompanyCostMax;
  
  return (
    <TotalContainer
      totalName="Cost"
      min={displayTotalCompanyCost}
      max={displayTotalCompanyCostMax}
      showMaxValue={showMaxValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      textSmaller
      showDecimals={!roundHours}
      showDivider={showDivider}
    />
  );
})

TotalCompanyCost.propTypes = {
  showDivider: bool,
}