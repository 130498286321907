import { useContext } from "react";
import { ArchivedProjectsSocketContext } from "@client";

export const useArchivedProjectsWebsocket = () => {
  const context = useContext(ArchivedProjectsSocketContext);
  if (context === null) {
    throw new Error("ArchivedProjectsSocketContext cannot be null, please add a context provider");
  }
  return context;
};
