import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function saveTemplateStructureQuery(
  templateId,
) {
  const res = await fetchData("/api/template/save", APIMethod.POST, {
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
    params: {
      id: templateId,
    },
  });

  return res.results;
}
