import { APIMethod, fetchData } from "@client";

export async function createProjectVersionComment(
  uuid,
  user, // @note: uuid!!
  version,
  place,
  body,
  mentions,
  internal,
  parent,
) {
  const res = await fetchData(
    "v1/ws/project/comments",
    APIMethod.POST,
    {
      removeEmptyKeys: true,
      data: {
        uuid,
        version,
        place,
        body,
        notify: mentions ? JSON.stringify(mentions) : undefined,
        user,
        parent,
        internal: internal ? 1 : 0,
      }
    }
  );
  return res.results;
}