import { ExpandMore } from "@material-ui/icons";
import { Tag, TextField } from "@components";
import {
  default as MuiAutocomplete,
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { useEffect, useMemo, useState } from "react";
import {
  arrayOf,
  string,
  number,
  object,
  oneOfType,
  bool,
  func,
} from "prop-types";
import { CircularProgress } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import { simplifyTextString } from "@utils";
import { omit } from "lodash";

export const Autocomplete = ({
  value: defaultValue,
  inputValue,
  name,
  options,
  labelKey="name",
  idKey="id",
  colorKey="backgroundColor",
  disableClearable,
  disableSearch,
  multiple,
  fieldClassName,
  onOptionCreate,
  onChange,
  onInputChange,
  getOptionDisabled,
  labelFormat,
  dataLoading,

  inputLabel,
  inputPlaceholder,
  InputProps,
  inputProps,
  error,
  helperText,
  renderOption,
  renderTagsOnList,
  noOptionsText,
  ...muiAutocompleteProps
}) => {
  const { t } = useTranslation();
  const filter = createFilterOptions();

  const [objectValue, setObjectValue] = useState(multiple ? [] : null);

  const freeSolo = useMemo(() => !!onOptionCreate, []);

  useEffect(() => {
    if (!multiple)
      setObjectValue(
        options.find((option) => option[idKey] === defaultValue) || null
      );
    else
      setObjectValue(
        defaultValue.map(v => options.find((option) => v === option[idKey])) || []
      );
  }, [defaultValue, options]);

  const handleChange = async (_, value) => {
    if (value?.length) {
      let newValue = multiple ? value.slice(-1)[0] : value;

      if (typeof newValue === "string") {
        const existingOption = filter(
          options.filter(
            (o) =>
              !(multiple
                ? defaultValue.includes(o[idKey])
                : defaultValue === o[idKey])
          ),
          { inputValue: newValue, getOptionLabel }
        )[0];

        if (!existingOption && !onOptionCreate) return;

        if (
          !existingOption &&
          value.find(
            (o) =>
              typeof o !== "string" &&
              o[labelKey].toLowerCase() === newValue.toLowerCase()
          )
        )
          return;

        if (existingOption) value[value.length - 1] = existingOption;

        newValue = existingOption || {
          [idKey]: "input",
          [labelKey]: "",
          INPUT_TEXT: newValue,
        };
      }

      if (onOptionCreate && newValue.INPUT_TEXT) {
        try {
          const data = await onOptionCreate(newValue.INPUT_TEXT);
          if(!data)
            return;
          newValue = { ...data, [labelKey]: newValue.INPUT_TEXT };
          if (multiple) value[value.length - 1] = newValue;
          else value = newValue;
        } catch (e) {
          return;
        }
      }
    }
    setObjectValue(value);
    onChange &&
      onChange(!multiple ? value?.[idKey] : value?.map((v) => v[idKey]), value);
  };

  const handleInputChange = (_, value) => {
    onInputChange && onInputChange(value);
  };
  
  const handleItemRemove = (item) => {
    if(!multiple) return;
    const value = objectValue.filter(i => i[idKey] !== item);
    setObjectValue(value)
    onChange &&
    onChange(value?.map((v) => v[idKey]), value, item);
  }

  const getOptionLabel = (option) => {
    const l = (option[labelKey] || option[idKey] || "").toString();
    return labelFormat ? labelFormat(l, option) : l;
  };

  const checkAndShowTagCreationPrompt = (localOptions, params) => {
    const filtered = filter(localOptions, params);

    const { inputValue } = params;
    const isExisting = options.some(
      (option) => simplifyTextString(inputValue) === simplifyTextString(option[labelKey])
    );
    
    if (inputValue !== "" && !isExisting) {
      filtered.push({
        [idKey]: "input",
        [labelKey]: `${t("common.add_new")} "${inputValue}"`,
        INPUT_TEXT: inputValue,
      });
    }

    return filtered;
  };
  
  const handleOptionRender = (options, getTagProps) => options
    .map((option, index) => {
      const tagProps = getTagProps({ index });
      const disabled = tagProps.disabled;
      const className = "my-0-5 mx-0-5";
      if(renderOption)
        return renderOption({ option, className, disabled, onRemove: handleItemRemove });
      
      return <Tag
        key={option.id}
        disabled={disabled}
        className={className}
        text={option[labelKey]}
        id={option[idKey]}
        color={option[colorKey]}
        onRemove={handleItemRemove}
        ellipsisOnOverflow
        useMaxWidth
      />
    })
  
  const handleListOptionRender = (option, state) =>  {
    if(renderOption)
      return renderOption({ option, disabled: state.selected });
    
    return <Tag
      key={option.id}
      disabled={state.selected}
      text={option[labelKey]}
      id={option[idKey]}
      color={option[colorKey]}
      ellipsisOnOverflow
      useMaxWidth
    />
  }

  return (
    <MuiAutocomplete
      {...muiAutocompleteProps}
      /* {...(options.length === 0 && freeSolo ? { PaperComponent: (props) => <EmptyTextPaperComponent {...props} noOptionsText={noOptionsText} /> } : {})} */
      // disableCloseOnSelect
      // disableListWrap
      value={objectValue}
      inputValue={inputValue}
      options={options}
      autoHighlight
      disableClearable={freeSolo || disableClearable}
      filterSelectedOptions
      multiple={multiple}
      popupIcon={
        dataLoading ? (
          <CircularProgress aria-label="progress indicator" size={24} />
        ) : (
          <ExpandMore />
        )
      }
      forcePopupIcon={true}
      onChange={handleChange}
      onInputChange={handleInputChange}
      freeSolo={freeSolo}
      filterOptions={freeSolo ? checkAndShowTagCreationPrompt : undefined}
      selectOnFocus={freeSolo}
      clearOnBlur={freeSolo}
      handleHomeEndKeys={freeSolo}
      noOptionsText={noOptionsText}
      getOptionLabel={getOptionLabel}
      getOptionSelected={(option, value) => {
        return !!value?.[idKey] && option[idKey] === value[idKey];
      }}
      getOptionDisabled={getOptionDisabled}
      renderTags={handleOptionRender}
      renderOption={renderTagsOnList && handleListOptionRender}
      renderInput={(params) => {
        
        return (
          <TextField
            {...params}
            name={name}
            error={error}
            helperText={helperText}
            className={fieldClassName}
            InputProps={{
              ...params.InputProps,
              ...InputProps,
              className: classnames(
                params.InputProps.className,
                InputProps?.className
              ),
            }}
            inputProps={{
              ...(
                disableSearch
                  ? omit(params.inputProps, "onChange")
                  : params.inputProps
              ),
              ...inputProps,
              className: classnames(
                params.inputProps.className,
                inputProps?.className
              ),
            }}
            label={inputLabel}
            placeholder={!defaultValue?.length ? inputPlaceholder : undefined}
            // placeholder={!defaultValue?.length ? (freeSolo && !options.length ? noOptionsText : inputPlaceholder) : undefined}
          />
        );
      }}
    />
  );
};

Autocomplete.propTypes = {
  id: string,
  value: oneOfType([string, number, arrayOf(oneOfType([string, number]))])
    .isRequired,
  options: arrayOf(object).isRequired,
  idKey: string,
  name: string,
  labelKey: string,
  colorKey: string,
  multiple: bool,
  disableClearable: bool,
  disableSearch: bool,
  dataLoading: bool,

  onChange: func, // (id/idList, object/objectList, removedItemId) => void
  getOptionDisabled: func,
  labelFormat: func,
  onInputChange: func,
  onOptionCreate: func,
  inputValue: string,
  fieldClassName: string,
  inputLabel: string,
  inputPlaceholder: string,
  InputProps: object,
  inputProps: object,
  error: bool,
  helperText: string,
  noOptionsText: string,
  renderOption: func, /** @note: needs to accept the following props: "option", "className", "disabled", "onRemove" */
  renderTagsOnList: bool,
};

// const EmptyTextPaperComponent = ({
//   noOptionsText,
//   ...paperProps
// }) => {
//
//   const {t} = useTranslation();
//
//   return (
//     <Paper {...paperProps}>
//       <div className="p-4 transparent-3">
//         {noOptionsText || t("common.no_options")}
//       </div>
//     </Paper>
//   )
// };
//
// EmptyTextPaperComponent.propTypes = {
//   noOptionsText: string,
// }