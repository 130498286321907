import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { EstimateStructureProvider, useEstimateEditorStore } from "@tools";
import { useLibraryStore } from "./providers";
import { useLibraryStructureListener } from "./hooks";
import { PageCircularProgress } from "@components";
import { LibraryContainer } from "./components/LibraryContainer";

export const LibraryView = observer(() => {
  const libraryStore = useLibraryStore();
  const editorStore = useEstimateEditorStore();

  const [isLoading, setLoading] = useState(true);
  const [structure, setStructure] = useState(null);

  useEffect(() => {
    onLoad();
  }, [libraryStore.pending]);

  const onLoad = async () => {
    setLoading(true);
    const s = await libraryStore.initStructure(editorStore.workTypes);
    setStructure(s);
    setLoading(false);
  };

  useLibraryStructureListener(structure, true);

  return isLoading || !structure ? (
    <PageCircularProgress />
  ) : (
    <EstimateStructureProvider value={structure}>
      <LibraryContainer />
    </EstimateStructureProvider>
  );
});
