import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { DESCRIPTION_MODES } from "project-structure";
import { useEstimateStructure } from "@tools";
import { Button } from "@material-ui/core";
import { DescriptionCell, DescriptionRow, Info } from "@assets";

export const DescriptionVariantSelector = ({ onEditorBlur }) => {
  
  const {t} = useTranslation();
  const {settings} = useEstimateStructure();
  
  const handleClick = (mode) => () => {
    onEditorBlur?.();
    settings.setDescriptionMode(mode);
  }
  
  return (
    <>
     <span className="text-sm transparent-3 mx-3">
       {t("views.editor.desc_mode")}
     </span>
      <Button
        color="primary"
        variant={settings.descriptionMode === DESCRIPTION_MODES.TOOLTIP ? "contained" : "outlined"}
        className="p-0-5 m-1 text-xl"
        style={{width: 32, height: 32, minWidth: 32}}
        onClick={handleClick(DESCRIPTION_MODES.TOOLTIP)}
      >
        <Info />
      </Button>
      <Button
        color="primary"
        variant={settings.descriptionMode === DESCRIPTION_MODES.ROW ? "contained" : "outlined"}
        className="p-0-5 m-1 text-2xl"
        style={{width: 32, height: 32, minWidth: 32}}
        onClick={handleClick(DESCRIPTION_MODES.ROW)}
      >
        <DescriptionRow />
      </Button>
      <Button
        color="primary"
        variant={settings.descriptionMode === DESCRIPTION_MODES.COLUMN ? "contained" : "outlined"}
        className="p-0-5 m-1 text-2xl"
        style={{width: 32, height: 32, minWidth: 32}}
        onClick={handleClick(DESCRIPTION_MODES.COLUMN)}
      >
        <DescriptionCell />
      </Button>
    </>
  )
}

DescriptionVariantSelector.propTypes = {
  onEditorBlur: func,
}