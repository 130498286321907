import { APIMethod, fetchData } from "@client";

export async function removeTemplateVersionCommentByPath(
  templateId,
  user,
  version,
  path
) {
  return await fetchData(
    "/v1/ws/template/comments/path",
    APIMethod.DELETE,
    {
      data: {
        templateId,
        user,
        version,
        path
      },
    }
  );
}