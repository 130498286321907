import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@components";
import { arrayOf, bool, number, object, shape, string } from "prop-types";

export const IssueTypeSelect = ({
  control,
  level,
  issueTypes,
  parentIssueLevels
}) => {
  const { t } = useTranslation();
  
  const lowestParentLevel = useMemo(() => (
    parentIssueLevels.reduce((minLevel, parentMap) => {
      const parentLvl = issueTypes.find(iT => iT.id === parentMap)?.lvl;
      const newLevel = typeof parentLvl === "number" ? parentLvl : 2;
      return newLevel < minLevel ? newLevel : minLevel;
    }, 2)
  ), [parentIssueLevels]);
  
  const filterOptions = (value) => {
    const option = issueTypes.find(iT => iT.id === value );
    if(!option) return false;
    return getOptionDisabled(option);
  }
  
  const getOptionDisabled = (option) => {
    if(lowestParentLevel === 1) return (option.lvl < 0 || option.lvl === 1)
    if(lowestParentLevel === -1) return (lowestParentLevel < option.lvl)
    return (lowestParentLevel <= option.lvl) || (option.lvl === -1 && lowestParentLevel === 2);
  }
  
  return (
    <Controller
      control={control}
      name={`level${level}Map`}
      render={({ field: { onChange, value, name },  fieldState: { error } }) => {
        if(value) {
          let newVal = filterOptions(value) ? undefined : value;
          if(newVal !== value && onChange)
            onChange(newVal);
        }
        
        return <Autocomplete
          value={value}
          options={issueTypes}
          name={name}
          idKey="id"
          labelKey="name"
          inputLabel={t("views.bt.type")}
          getOptionDisabled={getOptionDisabled}
          fullWidth
          onChange={onChange}
          size="small"
          style={{width: 192}}
          fieldClassName="m-0"
          error={!!error}
          helperText={error?.message}
        />
      }}
    />
  )
}

IssueTypeSelect.propTypes = {
  control: object.isRequired,
  level: number.isRequired,
  parentIssueLevels: arrayOf(string).isRequired,
  issueTypes: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    lvl: number.isRequired,
    subtask: bool
  })).isRequired
}