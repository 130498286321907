import { makeStyles } from "@material-ui/core/styles";
import { CELL_WIDTHS } from "@utils";

const useStyle = makeStyles(() => ({
  valueCell: {
    width: CELL_WIDTHS.VALUE.SMALL,
    minWidth: CELL_WIDTHS.VALUE.SMALL,
  },
  cellSmall: {
    width: CELL_WIDTHS.VALUE.TINY,
    minWidth: CELL_WIDTHS.VALUE.TINY,
  },
  cellMini: {
    width: CELL_WIDTHS.VALUE.MINI,
    minWidth: CELL_WIDTHS.VALUE.MINI,
  },
  cellMedium: {
    width: CELL_WIDTHS.VALUE.MEDIUM,
    minWidth: CELL_WIDTHS.VALUE.MEDIUM,
  },
  cellLarge: {
    width: CELL_WIDTHS.VALUE.LARGE,
    minWidth: CELL_WIDTHS.VALUE.LARGE,
  },
  valueContainerBackground: {
    borderRadius: 5,
    backgroundColor: "#FFF",
    display: "flex",
    padding: "4px",
  },
}));

export default useStyle;
