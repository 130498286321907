import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DEFAULT_WT_RESOURCES, lastResourceNameUsage, simplifyTextString } from "@utils";
import { useSearchableList } from "@hooks"
import { TextClearInput, SearchableListContent, SearchableListItem } from "@components";
import { TeamMembersTag } from "../TableTimeline/components/TeamMembersTag/TeamMembersTag";
import Grid from "@material-ui/core/Grid";

export const WorkTypeResourceSelect = observer(({
  resource,
  noMinWidth,
  displayTeamMembers,
  timelineAutofocus
}) => {
  const { t } = useTranslation();
  const { name, autoFocus, autoFocusTimeline, teamMembers } = resource;

  const handleNewWorkType = async ({ name }) => {
    resource.changeName(name);
  };

  const { containerProps, inputProps, looseFocus } = useSearchableList({
    defaultValue: name,
    autoFocus: timelineAutofocus ? autoFocusTimeline : autoFocus,
    onAutoFocus: timelineAutofocus
      ? resource.removeAutofocusTimeline
      : resource.removeAutofocus,
    onChange: handleNewWorkType,
  });

  const selectExistingWorkType = (name) => async () => {
    looseFocus();
    const lastNameUsage = lastResourceNameUsage(
      resource.parent?.resources,
      name
    );
    const newName =
      lastNameUsage >= 0 ? `${name} ${lastNameUsage + 1}` : name;
    resource.changeName(newName);
  };

  const availableWorkTypes = useMemo(
    () =>
      name === inputProps.value
        ? DEFAULT_WT_RESOURCES
        : DEFAULT_WT_RESOURCES.filter(
            (name) =>
              simplifyTextString(name).indexOf(
                simplifyTextString(inputProps.value)
              ) >= 0
          ),
    [inputProps.value]
  );

  return (
    <>
      <Grid item container alignItems="center">
        <TextClearInput
          {...inputProps}
          acceptOnClickAway
          confirmOnEnter
          placeholder={
            inputProps.focused
              ? t("views.editor.dialogs.resources.focused")
              : t("views.editor.dialogs.resources.placeholder")
          }
          noMinWidth={noMinWidth}
          containerClass="name"
          className="w-max -m-0-5"
          preventClickAwayOnElement="#resourceSelector"
          inputClassName="input-name"
        />
        {displayTeamMembers && <TeamMembersTag teamMembers={teamMembers} />}
      </Grid>
      <SearchableListContent {...containerProps} id="resourceSelector">
        {availableWorkTypes.map((name) => {
          return (
            <SearchableListItem
              key={name}
              onClick={selectExistingWorkType(name)}
            >
              {name}
            </SearchableListItem>
          );
        })}
      </SearchableListContent>
    </>
  );
});

WorkTypeResourceSelect.propTypes = {
  resource: object.isRequired,
  displayTeamMembers: bool,
  noMinWidth: bool,
};
