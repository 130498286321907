import { bool, string, number, node, object, func, arrayOf, oneOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { BREAKDOWN_TABLE_DEPTHS } from "project-structure";
import { SortableList } from "@components";
import { SummaryRowGroup } from "../SummaryRowGroup/SummaryRowGroup";
import { SummaryNestedRow } from "../SummaryNestedRow/SummaryNestedRow";

export const SummaryRowGroupList = observer(({
  isLastGroupRow,
  listElements,
  disabled,
  onReorder,
  children,
  parentIds,
  tableDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  ...elementProps
}) => {
  return (
    <>
      <SortableList
        list={listElements}
        path={parentIds.join("/")}
        group={parentIds.join("/")}
        onListReorder={onReorder}
        displayAsColumnFlexbox
        disabled={disabled}
      >
        {
        listElements?.map((child, i) =>
          tableDepth
          ? (
            <SummaryNestedRow
              key={`element${i}${child.id}`}
              resource={child}
              isLastGroupRow={isLastGroupRow && i === listElements.length - 1}
              parentIds={parentIds}
              {...elementProps}
            />
          ) : (
            <SummaryRowGroup
              key={`element${i}${child.id}`}
              workType={child}
              parentIds={parentIds}
              {...elementProps}
            />
          )
        )}
      </SortableList>
      {children}
    </>
  );
});

SummaryRowGroupList.propTypes = {
  tableDepth: oneOf([
    BREAKDOWN_TABLE_DEPTHS.SECTION,
    BREAKDOWN_TABLE_DEPTHS.MODULE,
    BREAKDOWN_TABLE_DEPTHS.FEATURE,
    BREAKDOWN_TABLE_DEPTHS.TASK,
  ]),
  listElements: arrayOf(object),
  onReorder: func,
  disabled: bool,
  isLastGroupRow: bool,
  parentIds: arrayOf(oneOfType([number, string])),
  children: node,
};
