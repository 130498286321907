import {
  bool,
  string,
  number,
  object,
  node,
  func,
  arrayOf,
  oneOfType, oneOf,
} from "prop-types";
import { Close } from "@material-ui/icons";
import classnames from "classnames";
import useStyle from "./Label.style";
import { useMemo, useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const Label = ({
  name,
  id,
  backgroundColor,
  fontColor,
  className,
  style,
  onRemove,
  onClick,
  maxWidth,
  size="large",
  ellipsisOnOverflow = false,
  useMaxWidth = false,
}) => {
  const classes = useStyle();
  
  const [isRemoving, setRemovalState] = useState(false);
  
  const useRemove = useMemo(() => Boolean(onRemove), [onRemove]);
  const useClick = useMemo(() => Boolean(onClick), [onClick]);

  const handleRemove = async (e) => {
    e.stopPropagation();
    if(!onRemove) return;
    
    setRemovalState(true);
    await onRemove(id || name);
    setRemovalState(false);
  };

  const handleClick = (e) => {
    onClick && onClick(e, id || name);
  };

  return (
    <div
      className={classnames(
        classes.root,
        useRemove && classes.removableShown,
        classes[`size-${size}`],
        className
      )}
      style={{ ...style, backgroundColor, color: fontColor }}
      aria-label="label"
      onClick={handleClick}
    >
      <p
        className={classnames(
          classes.title,
          useClick && "cursor-pointer",
          useClick && classes.titleHover,
          ellipsisOnOverflow && classes.overflow,
          useMaxWidth && classes.maxWidth,
        )}
        style={{ maxWidth }}
      >
        {name}
      </p>
      {useRemove && (
        <span
          className={`${classes.removeSection} remove`}
          onClick={handleRemove}
          aria-label="remove tag"
        >
          {
            isRemoving
              ? <CircularProgress size="1em" />
              : <Close />
          }
        </span>
      )}
    </div>
  );
};

Label.propTypes = {
  name: oneOfType([string, node, arrayOf(node)]).isRequired,
  backgroundColor: string,
  fontColor: string,
  className: string,
  style: object,
  onRemove: func,
  onClick: func,
  ellipsisOnOverflow: bool,
  useMaxWidth: bool,
  maxWidth: number,
  size: oneOf(["small", "large"]),
  id: oneOfType([string, number]),
};
