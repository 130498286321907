import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  headerRow: {
    zIndex: "14 !important",
    cursor: "auto",
    backgroundColor: (altBackground) => (
      altBackground
        ? theme.props.backgroundSecondary
        : theme.props.background
    ) + " !important"
  },
}));

export default useStyle;
