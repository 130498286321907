import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { chooseBoard, home } from "@paths";
import { switchWorkspaceQuery, getWorkspacesQuery } from "@query";
import { useStores } from "@hooks";
import {
	ActionButton,
	PageTitle,
	Table,
	TableCell,
	TableRow,
  AvatarList,
  ClearButton
} from "@components";
import { CircularProgress, Grid } from "@material-ui/core";
import { BoardsMenu } from "./components/BoardsMenu";
import { CreateWorkspaceDialog } from "./components/CreateWorkspaceDialog";
import { AddOutlined } from "@material-ui/icons";
import useStyle from "./Boards.style";


export const Boards = observer(() => {
	const { userStore } = useStores();
	const { t } = useTranslation();
	const navigate = useNavigate();
  const location = useLocation();
  
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const classes = useStyle(isMobile);
  
  const {availableWorkspaces, isAdmin} = userStore;
  
	const loadData = async () => {
		setIsLoading(true);
		const workspaces = await getWorkspacesQuery();
    userStore.setAvailableWorkspaces(workspaces);
		setIsLoading(false);
	};

	useEffect(() => {
		loadData();
	}, []);

	const openModal = () => {
		// history.replace(workspaceAdd);
		setOpen(true);
	};

	const closeModal = (boardAdded=false) => {
		// history.replace(workspaces);
		setOpen(false);
		if(boardAdded) loadData();
	};

	const handleSelect = async uuid => {
		try {
			const workspace = await switchWorkspaceQuery(uuid)
			if(workspace) {
        userStore.switchBoard(workspace);
				if (location.pathname !== home)
					location.pathname === chooseBoard ? navigate(home, { replace: true }) : navigate(home);
			}
		} catch(e) {
			return e;
		}
	};

  return <>
    <PageTitle>
      {
        isAdmin &&
        <ActionButton
          icon={<AddOutlined/>}
          onClick={openModal}
        >
          {t("views.board.add")}
        </ActionButton>
      }
    </PageTitle>
    <Grid
      item
      container
      justifyContent="center"
      alignContent="flex-start"
      className="m-0 px-6"
    >
      {
        isLoading ?
          <CircularProgress
            aria-label="progress indicator"
            className={classes.spinner}
          />
          :
          <Table
            id="boardsTable"
            headerOffset={96}
            headers={[
              {label: t("views.board.name"), width: 240, flexible: true},
              {label: t("views.board.members"), width: 320, flexible: true},
              {width: 34}
            ]}
          >
            {
              userStore.availableWorkspaces.map(w => {
                const {name, uuid, users} = w;
                return <TableRow key={uuid}>
                  <TableCell width={240} flexible>
                    <ClearButton onClick={() => handleSelect(uuid)}>
                      {name}
                    </ClearButton>
                  </TableCell>
                  <TableCell width={320} flexible>
                    {
                      users && users.length > 0 && <AvatarList users={users}/>
                    }
                  </TableCell>
                  <TableCell justifyContent="center" width={34}>
                    {
                      isAdmin &&
                      <BoardsMenu
                        uuid={uuid}
                        handleSelect={handleSelect}
                        loadData={loadData}
                        enableDeletion={availableWorkspaces.length > 1}
                        workspaceUserEmails={users ? users.map(x => x.email): []}
                      />
                    }
                  </TableCell>
                </TableRow>;
              })
            }
          </Table>
      }
      <CreateWorkspaceDialog
        open={isOpen}
        onClose={closeModal}
      />
    </Grid>
  </>;
});