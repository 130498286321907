import { useEffect, useRef, useState } from "react";
import { arrayOf, shape, string, number, func, bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { DEFAULT_LABEL_COLORS, DEFAULT_LABEL_FONT_COLORS } from "@utils";
import { AddButtonFull } from "@components";
import { Divider, Grid } from "@material-ui/core";
import { LabelRow } from "./components/LabelRow";
import useStyle from "./LabelEditor.style";
import classnames from "classnames";

// import { createProjectTypeQuery, removeProjectTypeQuery } from "@query/labelList";

export const LabelEditor = ({
  labelList: initialLabelList = [],
  onCreate,
  onRemove,
  onUpdate,
  defaultName,
  noRemoval=false,
  noCreation=false,
  stickyNewLabelRow=false,
  showEditor: defaultShowEditor,
}) => {
  const { t } = useTranslation();
  const classes = useStyle(noRemoval);
  
  const [labelList, setLabelList] = useState(initialLabelList);
  const [editorVisible, showEditor] = useState(defaultShowEditor);
  const [additionalColors, setAdditionalColors] = useState([]);
  const [additionalFontColors, setAdditionalFontColors] = useState([]);
  
  const container = useRef(null);
  
  useEffect(() => {
    setAdditionalColors(
      labelList.reduce((list, type) => {
        if(type.fontColor && !list.includes(type.backgroundColor) && !DEFAULT_LABEL_COLORS.includes(type.backgroundColor))
          list.push(type.backgroundColor);
        return list;
      }, [])
    )
    setAdditionalFontColors(
      labelList.reduce((list, type) => {
        if(type.fontColor && !list.includes(type.fontColor) && !DEFAULT_LABEL_FONT_COLORS.includes(type.fontColor))
          list.push(type.fontColor);
        return list;
      }, [])
    )
  }, []);
  
  const showNewProjectTypeRow = () => {
    showEditor(true)
  }
  
  const newAdditionalColor = (hex) => {
    if(!additionalColors.includes(hex))
      setAdditionalColors([...additionalColors, hex]);
  }
  
  const newAdditionalFontColor = (hex) => {
    if(!additionalFontColors.includes(hex))
      setAdditionalFontColors([...additionalFontColors, hex]);
  }
  
  const handleRemove = (id) => {
    setLabelList((list) => {
      const newList = list.filter(e => e.id !== id);
      if(onRemove)
        onRemove(id, newList);
      return newList;
    });
  }
  
  const handleLabelUpdate = (id, name, backgroundColor, fontColor) => {
    setLabelList((list) => {
      const index = list.findIndex(e => e.id === id);
      const newList = [
        ...list.slice(0,index),
        { id, name, backgroundColor, fontColor },
        ...list.slice(index+1),
      ];
      if(onUpdate)
        onUpdate(id, name, backgroundColor, fontColor, newList);
      return newList;
    });
  }
  
  const handleNewLabel = async (name, backgroundColor, fontColor) => {
    let id = new Date().getTime();
    
    if(onCreate) {
      const newId = await onCreate(name, backgroundColor, fontColor);
      id = newId || id;
    }
    
    setLabelList((list) => [
      ...list,
      { id, name, backgroundColor, fontColor }
    ]);
    
    if(stickyNewLabelRow)
      container.current?.parentNode.scrollTo({
        top: container.current.parentNode.scrollHeight,
        behavior: "smooth",
      });
    
    showEditor(false);
  }
  
  return (
    <Grid
      item
      container
      alignItems="center"
      className="text-md"
      ref={container}
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item container className={classes.labelCell}>
          <span>{t("common.label")}</span>
        </Grid>
        <Grid item container>
          <span>{t("common.title")}</span>
        </Grid>
        <Grid item container className={classes.colorCell}>
          <span>{ t("common.color") }</span>
        </Grid>
      </Grid>
      {
        labelList.map((type) => (
          <LabelRow
            key={type.id}
            additionalColors={additionalColors}
            additionalFontColors={additionalFontColors}
            onAdditionalColorAdd={newAdditionalColor}
            onAdditionalFontColorAdd={newAdditionalFontColor}
            onRemove={handleRemove}
            onUpdate={handleLabelUpdate}
            noRemoval={noRemoval}
            {...type}
          />
        ))
      }
      {
        !noCreation &&
        <Grid
          item container
          className={classnames(stickyNewLabelRow && classes.sticky)}
        >
          <Divider className="w-full my-3" />
          {
            editorVisible
              ? <LabelRow
                isNew
                name={defaultName}
                onCreate={handleNewLabel}
                onCancel={() => showEditor(false)}
                additionalColors={additionalColors}
                additionalFontColors={additionalFontColors}
                onAdditionalColorAdd={newAdditionalColor}
                onAdditionalFontColorAdd={newAdditionalFontColor}
              />
              : <AddButtonFull onClick={showNewProjectTypeRow}>
                {t("views.settings.projects.labels.add_label")}
              </AddButtonFull>
          }
        </Grid>
      }
    </Grid>
  )
}

LabelEditor.propTypes = {
  onCreate: func,
  onRemove: func,
  onUpdate: func,
  labelList: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    backgroundColor: string.isRequired,
    fontColor: string.isRequired,
  })),
  showEditor: bool,
  noRemoval: bool,
  noCreation: bool,
  stickyNewLabelRow: bool,
  defaultName: string,
}

