import { zipStructure } from "project-structure";
import { fetchData, APIMethod, ACCESS_DOMAINS } from "@client";

export async function resetJiraConnectionQuery(userId, companyId) {
  const { project } =  await fetchData(`api/remove-connection
`, APIMethod.DELETE, {
    baseURL: ACCESS_DOMAINS.btApi,
    params: {
      code: zipStructure(JSON.stringify({ user_id: userId, company_id: companyId })),
    }
  });
  return project;
}