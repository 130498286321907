import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { Totals } from "project-structure";
import { useEstimateStructure, useEstimateEditorSettings } from "@tools";
import { TotalCost, TotalHours, TotalTime } from "../totalsTypes";

export const TotalsFactory = observer(({
  name,
  showDivider,
}) => {
  const structure = useEstimateStructure();
  const { isProposal } = useEstimateEditorSettings();
  const {
    apply,
    hideTotalHours,
    hideTotalCost,
    hideTotalTime,
  } = structure.visibility;
  
  switch (name) {
    case Totals.COST:
      return isProposal && apply && hideTotalCost
        ? <></>
      : <TotalCost showDivider={showDivider} />;
      
    case Totals.HOURS:
      return isProposal && apply && hideTotalHours
        ? <></>
        : <TotalHours showDivider={showDivider} />;
      
    case Totals.TIME:
      return isProposal && apply && hideTotalTime
        ? <></>
        : <TotalTime showDivider={showDivider} />;
  }
});

TotalsFactory.propTypes = {
  name: string.isRequired,
  showDivider: bool,
}