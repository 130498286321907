import { useMemo } from "react";
import { func, string } from "prop-types";
import { Button } from "@components";
import { makeStyles } from "@material-ui/core/styles";
import { rgb2hsl } from "@utils/colorParsers";

export const ColorSquare = ({
  hex,
  onSelect
}) => {
  const isWhiteish = useMemo(() => (
    rgb2hsl(hex)?.l >= 0.95
  ), [hex]);
  
  const classes = useStyle(isWhiteish);
  
  const handleSelect = () => {
    onSelect(hex);
  }
  
  return <Button
    color="secondary"
    className={classes.block}
    style={{ backgroundColor: hex }}
    onClick={handleSelect}
  />
}

ColorSquare.propTypes = {
  hex: string.isRequired,
  onSelect: func.isRequired,
}

const useStyle = makeStyles((theme) => ({
  block: {
    height: 24,
    width: 24,
    minWidth: 24,
    margin: 4,
    padding: 0,
    borderRadius: 5,
    border: (isWhiteish) => isWhiteish && `1px solid ${theme.props.border}`,
  },
}));