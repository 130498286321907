import { createContext, useContext } from "react";
import { bool, node, number, arrayOf, shape, string } from "prop-types";

const EstimateTableSettings = createContext(null);

export const useEstimateTableSettings = () => {
  const context = useContext(EstimateTableSettings);
  if (context === null) {
    throw new Error("EstimateTableSettings cannot be null, please add a context provider");
  }
  return context;
};

export const EstimateTableSettingsProvider = ({ children, ...value }) => (
  <EstimateTableSettings.Provider value={value}>
    {children}
  </EstimateTableSettings.Provider>
)

EstimateTableSettingsProvider.propTypes = {
  children: node.isRequired,
  useExpandCell: bool,
  columns: arrayOf(shape({
    name: string.isRequired,
    visible: bool,
  })),
  timelineLength: number,
  pricesVisible: bool,
  hasAnyDescription: bool,
  descriptionVisible: bool,
  visibleWorkTypes: arrayOf(shape({
    id: number,
    invisible: bool,
  })),
  maxTitleCellWidth: number,
  sectionContainerWidth: number,
  priceClassName: string,
  descriptionClassName: string,
  showCombinedHours: bool,
}