import { APIMethod, fetchData } from "@client";

export async function moveTemplateVersionComment(
  templateId,
  user,
  version,
  path,
  newPath
) {
  try {
    return await fetchData(
      "/v1/ws/template/comments/move",
      APIMethod.PUT,
      {
        mapRequestKeysToSnakeCase: false,
        data: {
          templateId,
          user,
          version,
          path,
          newPath
        },
      }
    );
  } catch(e) {
    return null;
  }
}