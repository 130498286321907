import { useState } from "react";
import { bool, string, func, object, oneOf } from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, PopMenu } from "@components";
import { DialogTitle, Grid, IconButton } from "@material-ui/core";
import { ColorPickerInner } from "@components/ColorPicker/components/ColorPickerInner";
import { Close } from "@material-ui/icons";
import useStyle from "@components/ColorPicker/ColorPicker.style";

export const ColorPicker = ({
  show,
  onClose,
  onChange,
  anchor,
  title,
  type,
  requireAccept,
  initialColor,
  ...innerProps
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const [pickedColor, setPickedColor] = useState(initialColor);
  
  const handleChange = (hex) => {
    setPickedColor(hex);
    
    if(!requireAccept)
      onChange?.(hex);
  }
  
  const handleAccept = () => {
    onChange?.(pickedColor);
  }
  
  return (
    <PopMenu
      show={show}
      anchor={anchor}
      onClickAway={onClose}
      style={{ width: 376 }}
      placement="right-start"
    >
      {
        (title || !requireAccept) &&
        <DialogTitle disableTypography className="w-full p-4">
          {
            title &&
            <h2 className="text-xl font-bold">
              {
                type
                  ? `${ title } - ${ t(`color_picker_types.${ type }`) }`
                  : title
              }
            </h2>
          }
          {
            !requireAccept &&
            <IconButton
              className="p-0"
              color="primary"
              aria-label="close modal"
              onClick={ onClose }
            >
              <Close />
            </IconButton>
          }
        </DialogTitle>
      }
      <ColorPickerInner
        onChange={handleChange}
        initialColor={pickedColor}
        {...innerProps}
      />
      {
        requireAccept &&
        <Grid
          item container
          justifyContent="flex-end"
          className={classes.acceptButtonContainer}
        >
          <Button
            variant="contained"
            color="secondary"
            className="mx-2"
            onClick={onClose}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            className="mx-2"
            onClick={handleAccept}
          >
            {t("common.add")}
          </Button>
        </Grid>
      }
    </PopMenu>
  );
};

ColorPicker.propTypes = {
  onClose: func.isRequired,
  show: bool,
  anchor: object,
  initialColor: string,
  title: string,
  onChange: func,
  onFocus: func,
  onBlur: func,
  type: oneOf(["font","background","border"]),
  requireAccept: bool,
};
