import { useMemo } from "react";
import { observer } from "mobx-react";
import { getProfitColor } from "@utils";
import { useEstimateStructure, ValueCell } from "@tools";
import { useTheme } from "@material-ui/core/styles";

export const CompanyProfitabilityCell = observer(() => {
  const theme = useTheme();
  const structure = useEstimateStructure();
  const { companyFixedBreakdownProfitability, settings } = structure;
  const { useMinMax } = settings;
  
  const notApplicable = useMemo(() => (
    companyFixedBreakdownProfitability === null
  ), [companyFixedBreakdownProfitability]);
  
  const color = useMemo(() => (
    getProfitColor(companyFixedBreakdownProfitability, theme)
  ), [companyFixedBreakdownProfitability, theme]);
  
  return <ValueCell
    wider
    displayContent
    widest={useMinMax}
    emptyValue={notApplicable}
    value={companyFixedBreakdownProfitability}
    symbolEnd="%"
    max={99.99}
    style={{color}}
  />
})