import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFeatureGuardian } from "@hooks";
import { getCompanySettingsQuery, setCompanySettingsQuery } from "@query";
import { LOCKED_FEATURES } from "@utils";
import { SettingsRow } from "@components";
import { Switch } from "@material-ui/core";
import { NewDomain, ActiveDomain, DomainInfoDialog } from "./compnents";
import { COMPANY_SETTINGS} from "@client";

export const Domain = () => {
  const { t } = useTranslation();

  const [isLoading, setLoading] = useState(true);
  const [oldDomain, setOldDomain] = useState("");
  const [useDomain, setUseDomain] = useState(false);
  const [domain, setDomain] = useState("");
  const [domainSaved, setDomainSaved] = useState(false);
  const [domainFocused, setDomainFocus] = useState(false);
  const [cnameName, setCnameName] = useState("");
  const [cnameValue, setCnameValue] = useState("");
  const [domainDialogVisible, openDomainDialog] = useState(false);

  const { checkAccess, actionBlocked, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.DOMAIN
  );

  useEffect(() => {
    (async () => {
      await checkAccess(true);
      let d = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN);
      let u = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_USE);
      let cN = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_CNAME);
      let cV = await getCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_VALUE);
      setDomain(d || "");
      setOldDomain(d || "");
      setUseDomain(u === 1);
      if (d) setDomainSaved(true);
      setCnameName(cN || "");
      setCnameValue(cV || "");
      setLoading(false);
    })();
  }, []);

  const handleDomainChange = (v) => setDomain(v);
  const handleDomainFocus = () => setDomainFocus(!domainFocused);

  const handleUseDomain = async () => {
    await setCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN_USE, useDomain ? 0 : 1);
    setUseDomain(!useDomain);
  };

  const saveDomain = async () => {
    setDomainFocus(false);
    if (domain !== oldDomain) {
      if (isLoading) return;
      setLoading(true);
      const allowed = await checkAccess();
      if (!allowed) return;

      await setCompanySettingsQuery(COMPANY_SETTINGS.DOMAIN, domain);

      setOldDomain(domain);
      setDomainSaved(!!domain);
      domain && openDomainDialog(true);
      setLoading(false);
    }
  };

  return (
    <>
      {
        domainSaved
          ? <ActiveDomain
            domain={domain}
            cnameName={cnameName}
            cnameValue={cnameValue}
            domainFocused={domainFocused}
            isLoading={isLoading}
            useDomain={useDomain}
            actionBlocked={actionBlocked}
            saveDomain={saveDomain}
            handleDomainChange={handleDomainChange}
            handleDomainFocus={handleDomainFocus}
          />
          : <NewDomain
            domain={domain}
            domainFocused={domainFocused}
            isLoading={isLoading}
            saveDomain={saveDomain}
            handleDomainChange={handleDomainChange}
            handleDomainFocus={handleDomainFocus}
          />
      }
      {
        domainSaved && !actionBlocked &&
        <SettingsRow bordered disabled={!useDomain}>
          <span className="font-bold text-md">
            {t("views.settings.projects.use_domain")}
          </span>
          <Switch
            color="primary"
            checked={useDomain}
            onChange={handleUseDomain}
            disabled={!cnameName || !cnameValue}
          />
        </SettingsRow>
      }
      {domainDialogVisible && (
        <DomainInfoDialog
          domainName={domain}
          onClose={() => openDomainDialog(false)}
        />
      )}
      <FeatureAlert />
    </>
  );
};
