import { useMemo, useRef } from "react";
import { bool, number, string, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { intersectionBy } from "lodash";
import { getSectionWtClass } from "@utils";
import {
  useEstimateEditorSettings,
  useBreakdownValueAlert,
  useEstimateEditorStore,
  useRiskBufferAlert,
  useEstimateStructure,
  useWorkTypePermitChecker,
  DifferentValueAlert,
  ValueCell,
  ResetValueButton,
} from "@tools";
import { Alert } from "@components";
import {TaskWorkTypeOverheadTooltip} from "./components/TaskWorkTypeOverheadTooltip"
import classnames from "classnames";

export const TaskWorkType = observer(({
  element,
  workType,
  emptyValue,
  actualDepthLevel,
  tableDepth,
  opened,
  className,
  ...valueCellProps
}) => {
  
  const {
    allowEdition,
    isLibrary,
  } = useEstimateEditorSettings();
  const { editionGrant } = useWorkTypePermitChecker(workType.id);
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const { t } = useTranslation();
  
  const { estimateValueLevel, settings, usesTwoValues, usesAvgValue, usesMaxValue, usesAnyValue } = structure;
  const {
    riskBuffer,
    roundLevel,
    usedRoundDecimals,
    modifier,
    showBreakdownParentValues,
    showDecimals,
  } = settings;
  const { hasChildren } = element;
  
  const changeValueData = useRef(null);
  
  const {
    riskAlertVisible,
    handleRiskAlertClose,
    handleRiskAlertOpen,
  } = useRiskBufferAlert();
  
  const {
    valueAlertVisible,
    handleValueAlertClose: onValueAlertClose,
    handleValueAlertOpen,
  } = useBreakdownValueAlert();
  
  const editable = allowEdition && (!isLibrary || typeof elementId === "number");
  const visible = !structure.isWorkTypeOff(workType.id);
  const holdsHours = element.holdsViewedHours[workType.id];
  const canEdit = editable && editionGrant;
  const valueVisible = visible && editionGrant;
  
  const isDifferentPercent = element.isValueOverridden?.(workType.id);
  const value = element.getDisplayHoursForWorkType(workType.id);
  const valueMax = element.getDisplayHoursMaxForWorkType(workType.id);
  const cleanValue = element.getUnmodifiedHoursForWorkType(workType.id);
  const cleanValueMax = element.getUnmodifiedHoursMaxForWorkType(workType.id);
  
  const showHours = !emptyValue && usesAnyValue
    && Boolean(!element.parentHoldsHours[workType.id] || (
      element.parentHoldsNonOverriddenPercentHours[workType.id]
      && (
        workType.hasPercent
          ? intersectionBy(workType.of || [], Object.keys(element.holdsViewedHours), (v) => v.toString())?.length
          : Object.entries(element.holdsViewedHours).length
      )
    ))
  
  const displayCurrentCellContent = !opened || !hasChildren || holdsHours || tableDepth + 1 > estimateValueLevel;
  
  const displayCellContent = actualDepthLevel <= estimateValueLevel
    && (showBreakdownParentValues || displayCurrentCellContent);
  
  const blurDisplayedValue = displayCellContent && !displayCurrentCellContent;
  
  const showMaxValue = usesTwoValues && (editable || (value !== valueMax && usesMaxValue));
  
  const displayMinMaxSeparator = !emptyValue && (showHours || canEdit);
  
  const showTaskDecimals = useMemo(() => (
    showDecimals || (
        typeof roundLevel === "number"
        && typeof element.lvl === "number"
        && element.lvl > roundLevel
      )
  ), [element.children?.length, showDecimals, roundLevel, element.lvl]);
  
  const usedWorkTypeOverheads = element.usedOverheads.filter(ov => ov.workTypes.includes(Number(workType.id)));
  
  const displayUsedOverheads = usedWorkTypeOverheads?.length > 0 && displayCellContent && editable;
  
  const sectionWtClassName = useMemo(() => (
    "."+getSectionWtClass(workType.parent.id, workType.id)
  ), [workType.id, workType.parent.id])
  
  const workTypeName = useMemo(() => (
    structure.getWorkTypeById(workType.id)?.name
  ), [workType.id]);
  
  const handleValueChange = (value, isMax = false) => {
    const key = isMax ? "max" : usesAvgValue ? "avg": "min";
    const v = parseFloat((value * modifier).toPrecision(12));
    const oldValue = element.getTotalHoursPerWorkType(workType.id)?.[key];
    const oldUnmodifiedValue = element.values?.get(workType.id.toString())?.[key];
    
    if(!element.holdsViewedHours[workType.id] && oldValue && element.hasChildren) {
      changeValueData.current = { key, value: v };
      handleValueAlertOpen();
      return true;
    }
    else if(oldUnmodifiedValue === v && !emptyValue)
      return true;
    else {
      element.changeValue(workType.id, { [key]: v }, false, element.hasChildren, element.hasChildren, true);
    }
    
    handleRiskAlertOpen();
  };
  
  const handleValueAlertClose = (clearChildren) => {
    onValueAlertClose();
    if(typeof clearChildren === "undefined") return;
    if(changeValueData.current) {
      const {key, value} = changeValueData.current;
      element.changeValue(workType.id, { [key]: value }, false, clearChildren, clearChildren, true);
    }
  }
  
  const recoverAutoCountData = () => {
    element.resetPercentValues(workType.id, true, false, true);
  };
  
  const handleMouseEnter = () => {
    document.querySelector(sectionWtClassName)?.classList.add("opaque");
  }
  
  const handleMouseLeave = () => {
    document.querySelector(sectionWtClassName)?.classList.remove("opaque");
  }
  
  return (
    <ValueCell
      {...valueCellProps}
      displayContent={displayCellContent}
      displayMinMaxSeparator={displayMinMaxSeparator}
      emptyInputs={!showHours}
      value={value}
      valueMax={valueMax}
      displayMax={showMaxValue}
      wider={usesTwoValues}
      onValueChange={handleValueChange}
      warning={isDifferentPercent}
      showDecimals={showTaskDecimals}
      placeholderMin={cleanValue}
      placeholderMax={cleanValueMax}
      editable={canEdit}
      visible={valueVisible}
      decimals={usedRoundDecimals}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classnames(
        className,
        visibilityMode && workType.isHidden && "transparent-2"
      )}
      inputClassName={classnames(
        `input-${workTypeName.toLowerCase().replace(/\//g, "-")}`,
        blurDisplayedValue && "transparent-2",
      )}
      inputMinTooltipMessage={ displayUsedOverheads &&
        <TaskWorkTypeOverheadTooltip
          element={element}
          workTypeId={workType.id}
          usedWorkTypeOverheads={usedWorkTypeOverheads}
        />}
      inputMaxTooltipMessage={displayUsedOverheads &&
        <TaskWorkTypeOverheadTooltip
          element={element}
          workTypeId={workType.id}
          usedWorkTypeOverheads={usedWorkTypeOverheads}
          isMax
        />}
    >
      {canEdit && displayCellContent && isDifferentPercent && showHours && (
        <ResetValueButton action={recoverAutoCountData}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
      {canEdit && riskAlertVisible && (
        <Alert
          isOpen={riskAlertVisible}
          title={t("views.editor.dialogs.settings.risk_buffer")}
          acceptText={t("common.close")}
          onAccept={handleRiskAlertClose}
          useLockCheckbox
        >
          {t("views.editor.risk_alert", {percent: riskBuffer})}
        </Alert>
      )}
      {canEdit && valueAlertVisible && (
        <DifferentValueAlert
          moduleLevel={actualDepthLevel}
          handleValueAlertClose={handleValueAlertClose}
        />
      )}
    </ValueCell>
  );
});

TaskWorkType.propTypes = {
  element: object.isRequired,
  textPresetClass: string,
  tableDepth: number,
  workType: object.isRequired,
  emptyValue: bool,
  opened: bool,
  actualDepthLevel: number,
};
