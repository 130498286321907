import { useEffect, useState } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStores } from "@hooks";
import { home } from "@paths";
import { Alert } from "@components";

export const EstimateLoadingAlert = ({ structureError, isTemplate }) => {
  const { stateStore } = useStores();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [feedbackOpened, openFeedback] = useState(false);

  useEffect(() => {
    if (feedbackOpened && stateStore.openFeedback) handleAlertClose();
  }, [feedbackOpened, stateStore.openFeedback]);

  const handleAlertClose = () => {
    navigate(home);
  };

  const handleFeedbackOpen = () => {
    openFeedback(true);
    stateStore.setOpenFeedback(true);
  };

  return (
    <Alert
      title={t(`errors.editor.${isTemplate ? "template_": ""}structure`)}
      isOpen
      onAccept={handleAlertClose}
      onCancel={handleFeedbackOpen}
      acceptText={t("common.close")}
      cancelText={t("forms.contact.title")}
    >
      {structureError && t(`errors.editor.${isTemplate ? "template_": ""}structure_full`)}
    </Alert>
  );
};

EstimateLoadingAlert.propTypes = {
  structureError: bool,
  isTemplate: bool,
};
