import { number, func, bool, object, string, oneOfType } from "prop-types";

export const InputCellProps = {
  value: oneOfType([number, string]),
  valueMax: oneOfType([number, string]),
  onValueChange: func,
  displayContent: bool,
  displayMinMaxSeparator: bool,
  displayMax: bool,
  editable: bool,
  wider: bool,
  widest: bool,
  symbolStart: string,
  symbolEnd: string,
  textPresetClass: string,
  className: string,
  style: object,
};
