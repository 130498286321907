import { useEffect, useMemo } from "react";
import { string } from "prop-types";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import ResizeObserver from "react-resize-observer";
import { fetchProjectVersionCommentsQuery } from "@query";
import { ShareLinkModifiers } from "@utils";
import { SessionRecorder } from "@workers";
import { useEditorWebsocket, useSharedProjectKey } from "@hooks";
import {
  useEstimatePresetStore,
  useEstimateEditorStore,
  useFonts,
  EditorRoot,
  ProposalAppBar, EstimateStructureProvider,
} from "@tools";
import { PolicyDialog } from "@dialogs";
import { AppContainer, PdfUploadProgress } from "@components";
import { CONTENT_WIDTH } from "@styles/themes";
import { usePresetStyles } from "@styles";

export const ProposalView = observer(({ projectHash }) => {
  const shareKey = useSharedProjectKey();
  const editorStore = useEstimateEditorStore();
  const presetStore = useEstimatePresetStore();
  const socket = useEditorWebsocket();
  const [searchParams] = useSearchParams();
  const { loadFonts, unloadFonts } = useFonts();

  const { currentEstimateStructure, pdfDocument, noTurningOff } = editorStore;
  const { selectedPresetFont } = presetStore;

  const clientEmail = useMemo(() => (
    searchParams.get(ShareLinkModifiers.CLIENT_EMAIL) || null
  ), []);

  useEffect(() => {
    socket?.initSocket();
    socket?.joinProposal(projectHash);
  }, []);
  
  useEffect(() => {
    loadVersionComments();
    
    if(selectedPresetFont)
      loadFonts([selectedPresetFont])
    
    return () => {
      unloadFonts();
    }
  }, [editorStore.currentVersionKey]);

  const sessionRecorder = useMemo(
    () =>
      new SessionRecorder({
        shareKey,
        clientEmail,
        type: "proposal", //"pdf",
        useCompression: true,
      }),
    []
  );

  const loadVersionComments = async () => {
    if( !editorStore?.currentVersionKey || !editorStore?.commentsVisible || editorStore.currentEstimateStructure.commentsFetched)
      return;
    
    const c = await fetchProjectVersionCommentsQuery(
      projectHash,
      editorStore.currentVersionKey
    );
    editorStore.currentEstimateStructure.historyManager.withoutUndo(() => {
      editorStore.currentEstimateStructure.setComments(c);
    })
  }
  
  const handleViewPortChange = () => {
    document.querySelector(
      'meta[name="viewport"]'
    ).content = `width=device-width, initial-scale=${Math.min(
      window.screen.width / CONTENT_WIDTH,
      1
    )}`;
  };

  const initSession = () => {
    sessionRecorder?.init();
  };
  
  usePresetStyles(presetStore?.selectedPresetData, presetStore?.selectedPresetFont);

  return (
    <EstimateStructureProvider value={currentEstimateStructure}>
      {isMobile && <ResizeObserver onResize={handleViewPortChange} />}
      <ProposalAppBar proposalShareKey={shareKey} />
      <AppContainer>
        <EditorRoot
          // readOnly
          isProposal
          allowInteractions
          showPdfCover={!!pdfDocument}
          noTurningOff={noTurningOff}
        />
        <PolicyDialog onDisclaimerClose={initSession} />
        <PdfUploadProgress />
      </AppContainer>
    </EstimateStructureProvider>
  );
});

ProposalView.propTypes = {
  projectHash: string
}