import { useContext } from "react";
import { ArchivedProjectStoreContext } from "@stores";

export const useArchivedProjectStore = () => {
  const context = useContext(ArchivedProjectStoreContext);
  if (context === null) {
    throw new Error("ArchivedProjectStoreContext cannot be null, please add a context provider");
  }
  return context;
};

