import { APIMethod, fetchData } from "@client";
import { zipStructure } from "project-structure";

export async function getProjectEditionDataQuery(uuid, companySettings) {
  const res = await fetchData(
    `/v2/permit/project/access/${uuid}`,
    APIMethod.GET,
    {
      params: {
        settings: companySettings?.length ? zipStructure(JSON.stringify(companySettings)) : null
      },
    }
  );
  
  if(res?.workTypes)
    res.workTypes = res.workTypes.filter(r => r.name?.length > 0);
  
  return res;
}
