import { useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { intersection } from "lodash";
import { useEstimateEditorStore, useEstimateStructure, ValueCell } from "@tools";
import { CombinedTaskWorkTypeOverheadTooltip } from "./components/CombinedTaskWorkTypeOverheadTooltip"
import classnames from "classnames";

export const CombinedTaskWorkType = observer(({
  element,
  emptyValue,
  actualDepthLevel,
  tableDepth,
  opened,
  className,
  blockFocusAction,
  fullIdPath,
  ...valueCellProps
}) => {
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  
  const { estimateValueLevel, settings, usesTwoValues, usesAnyValue } = structure;
  const {
    roundType,
    roundHours,
    roundLevel,
    usedRoundDecimals,
    showBreakdownParentValues,
  } = settings;
  const {
    hasChildren,
    combinedDisplayHours,
    combinedDisplayHoursMax,
    showMaxCombinedHours,
    holdsAllHours,
    parentHoldsAllHours,
    areAllWorkTypesOff,
    areAllWorkTypesHidden,
  } = element;
  
  const showHours = !emptyValue && usesAnyValue && !parentHoldsAllHours;
  
  const displayCurrentCellContent = !opened || !hasChildren || holdsAllHours || tableDepth + 1 > estimateValueLevel;
  
  const displayCellContent = actualDepthLevel <= estimateValueLevel
    && (showBreakdownParentValues || displayCurrentCellContent);
  
  const displayMinMaxSeparator = !emptyValue && showHours;
  
  const showDecimals = useMemo(() => (
    (roundType !== 2 || !roundHours)
      ? true
      : (
        typeof roundLevel === "number"
        && typeof element.lvl === "number"
        && element.lvl > roundLevel
      )
  ), [element.children?.length, roundType, roundHours, roundLevel, element.lvl]);
  
  const usedWorkTypeOverheads = element.usedOverheads.filter(ov => intersection(ov.workTypes, element.usedWorkTypes).length);
  
  const displayUsedOverheads = usedWorkTypeOverheads?.length > 0 && displayCellContent;
  
  const handleFocus = () => {
    if(blockFocusAction) return;
    structure.sections.find(s => s.id === fullIdPath[0])?.setShowCombinedHours(false);
  }
  
  return (
    <ValueCell
      {...valueCellProps}
      wider
      widest={usesTwoValues}
      displayContent={displayCellContent}
      displayMinMaxSeparator={displayMinMaxSeparator}
      emptyInputs={!showHours}
      value={combinedDisplayHours}
      valueMax={combinedDisplayHoursMax}
      displayMax={showMaxCombinedHours}
      showDecimals={showDecimals}
      visible={!areAllWorkTypesOff}
      decimals={usedRoundDecimals}
      className={classnames(
        className,
        visibilityMode && areAllWorkTypesHidden && "transparent-2"
      )}
      focusAction={handleFocus}
      // messageTitle={t("views.editor.estimate.alert.title")}
      // messageContent={t("views.editor.estimate.alert.content")}
      inputClassName="input-totalHours"
      inputMinTooltipMessage={ displayUsedOverheads &&
        <CombinedTaskWorkTypeOverheadTooltip
          element={element}
          usedWorkTypeOverheads={usedWorkTypeOverheads}
        />}
      inputMaxTooltipMessage={displayUsedOverheads &&
        <CombinedTaskWorkTypeOverheadTooltip
          element={element}
          usedWorkTypeOverheads={usedWorkTypeOverheads}
          isMax
        />}
    />
  );
});

CombinedTaskWorkType.propTypes = {
  element: object.isRequired,
  tableDepth: number,
  emptyValue: bool,
  editable: bool,
  opened: bool,
  actualDepthLevel: number,
};
