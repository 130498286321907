import { useEffect, useState } from "react";
import { useStores } from "@hooks";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { Outlet, useNavigate } from "react-router-dom";
import { deleteAccountQuery } from "@query";
import { Grid, Paper, Divider, CircularProgress, Container } from "@material-ui/core";
import { Dialog, Button, TextField, Alert } from "@components";
import { LibraryIcon, User, Trash, DollarSign } from "@assets";
import { LockOutlined, Business, Dns, DateRange, TrendingUp, LabelOutlined } from "@material-ui/icons";
import {
  settings,
  settingsPassword,
  settingsCompany,
  login,
  settingsCurrency,
  settingsDomain,
  settingsTimeEquiv,
  settingsRates, settingsProjectTypes,
} from "@paths";
import useStyle from "./Settings.style";
import { SettingsButton } from "@views/Utility/Settings/components/SettingsButton";
import { SettingsButtonGroup } from "@views/Utility/Settings/components/SettingsButtonGroup";

export const Settings = observer(() => {
  const { userStore } = useStores();
  const classes = useStyle(isMobile);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [openPopup, setOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [deletionAlert, showDeletionAlert] = useState(false);
  const [password, setPassword] = useState("");

  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.settings")}`;
  }, [])


  const deleteAccount = async () => {
    setDeleting(true);
    try {
      await deleteAccountQuery(password);
      await userStore.clearUserData();
      navigate(login);
    } catch (e) {
      showDeletionAlert(true);
    }
    setDeleting(false);
  };

  const { isAdmin } = userStore;

  return (
    <Container>
      <Grid
        item
        container
        spacing={3}
        justifyContent="center"
        className={classes.root}
      >
        <Grid item container sm={12} md={isMobile ? 12 : 4}>
          <Paper className={classes.paper} elevation={0}>
            <h2 className="mb-4">{t("routes.settings")}</h2>
            <SettingsButton
              icon={<User />}
              path={settings}
              titleKey="profile"
            />
            {isAdmin && (
              <SettingsButton
                icon={<Business />}
                path={settingsCompany}
                titleKey="company"
              />
            )}
            <SettingsButton
              icon={<LockOutlined />}
              path={settingsPassword}
              titleKey="passwd"
            />
            {isAdmin && (
              <SettingsButtonGroup
                icon={<LibraryIcon />}
                titleKey="project"
              >
                <SettingsButton
                  icon={<DollarSign />}
                  path={settingsCurrency}
                  titleKey="currency"
                />
                <SettingsButton
                  icon={<Dns />}
                  path={settingsDomain}
                  titleKey="domain"
                />
                <SettingsButton
                  icon={<DateRange />}
                  path={settingsTimeEquiv}
                  titleKey="fte"
                />
                <SettingsButton
                  icon={<LabelOutlined />}
                  path={settingsProjectTypes}
                  titleKey="project_types"
                />
                <SettingsButton
                  icon={<TrendingUp />}
                  path={settingsRates}
                  titleKey="rates"
                />
                {/*<SettingsButton*/}
                {/*  icon={<Bookmarks />}*/}
                {/*  path={settingsProjectTypes}*/}
                {/*  titleKey="project_types"*/}
                {/*/>*/}
              </SettingsButtonGroup>
            )}
            <Divider className="my-1 -mx-6" />
            <SettingsButton
              icon={<Trash color="error" />}
              onClick={() => setOpen(!openPopup)}
              titleKey="delete"
            />
          </Paper>
        </Grid>
        <Grid item container sm={12} md={isMobile ? 12 : 8}>
          <Paper className={classes.paper} elevation={0}>
            <Outlet />
          </Paper>
        </Grid>
        
        <Dialog
          open={openPopup}
          onClose={() => setOpen(false)}
          title={t("views.settings.popup.title")}
          width={510}
          actions={
            <>
              <Button
                variant="outlined"
                className={classes.button}
                onClick={() => setOpen(false)}
              >
                {t("common.cancel")}
              </Button>
              <Button
                variant="contained"
                icon={
                  isDeleting ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null
                }
                className={classes.button}
                onClick={deleteAccount}
                disabled={!password}
              >
                {t("views.settings.popup.delete")}
              </Button>
            </>
          }
        >
          <p>{t("views.settings.popup.text")}</p>
          <TextField
            id="deletePassword"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label={t("forms.password.current")}
            type="password"
            className="my-6"
          />
        </Dialog>
        <Alert
          isOpen={deletionAlert}
          title={t("errors.password.not_valid")}
          onAccept={() => showDeletionAlert(false)}
          acceptText={t("common.close")}
        />
      </Grid>
    </Container>
  );
});
