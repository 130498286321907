import { bool, func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useTemplates } from "@hooks";
import { Dialog, TemplatesFilter, TemplatesList } from "@components";
import { scrollReachedBottom } from "@utils";
import { useRef } from "react";
import { Grid } from "@material-ui/core";

export const TemplatesDialog = observer(({
  open,
  onClose,
  defaultTemplateId
}) => {
  const { t } = useTranslation();

  const { getTemplates } = useTemplates(10);
  
  const reachedBottom = useRef(false);
  const handleScroll = (e) => {
    const onBottom = scrollReachedBottom(e.currentTarget);
    if(onBottom && !reachedBottom.current)
      getTemplates();
    reachedBottom.current = onBottom;
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t("views.new_project.change_template")}
      containerClass="p-0"
      width={1080}
    >
      <Grid
        item container
        className="overflow-auto p-6"
        onScroll={handleScroll}
      >
        <TemplatesFilter />
        <TemplatesList
          defaultTemplateId={defaultTemplateId}
          onSelect={onClose}
          showXls
          showBlank
        />
      </Grid>
    </Dialog>
  );
});

TemplatesDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
};
