import { CircularProgress, Grid } from "@material-ui/core";
import { observer } from "mobx-react";
import { useProjectCreatorStore, useFeatureGuardian, useTemplates } from "@hooks";
import { DEFAULT_WORK_TYPES, LOCKED_FEATURES, TEMPLATE_TYPE } from "@utils";
import { TemplateCard } from "@components";
import { bool, func, number } from "prop-types";
import { BlankTemplateCard } from "@components/Templates/StaticTemplateCards/BlankTemplateCard";
import { XlsTemplateCard } from "@components/Templates/StaticTemplateCards/XlsTemplateCard";
import useStyle from "./TemplatesList.style";
import { checkTemplateCommentsQuery, getWorkTypesQuery } from "@query";

export const TemplatesList = observer(({
  defaultTemplateId,
  onSelect,
  showBlank = false,
  showXls = false,
  companyTemplates = false,
  ...templateCardProps
}) => {
  const creator = useProjectCreatorStore();
  const classes = useStyle();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ESTIMATION
  );
  
  const {
    templates,
    isLoading,
  } = useTemplates();
  
  const setWorkTypes = async (templateWorkTypes) => {
    
    let workTypes = creator.workTypes;
    if (!workTypes.length) {
      workTypes = await getWorkTypesQuery();
      creator.setWorkTypes(workTypes);
    }
    creator.setProjectWorkTypes((templateWorkTypes || DEFAULT_WORK_TYPES)
      .filter(wId => workTypes.find(wT => wT.id === wId)))
  }

  const selectTemplate = async (templateId, templateWorkTypes) => {
    const allowed = await checkAccess();
    if (!allowed) return;
    
    let template;
    if(templateId) {
      template = templates.find((x) => x.id === templateId);
      template.hasComments = checkTemplateCommentsQuery(templateId);
    }
    
    creator.setTemplate(template, companyTemplates);
    await setWorkTypes(templateWorkTypes);
    
    onSelect(templateId);
  };

  const selectBlank = async () => {
    creator.setTemplate(undefined);
    await setWorkTypes();
    onSelect(TEMPLATE_TYPE.BLANK);
  };

  const selectXls = () => {
    creator.setTemplate(undefined);
    onSelect(TEMPLATE_TYPE.XLS);
  };

  return (
    <Grid
      item
      container
      spacing={3}
      alignContent="flex-start"
      className="py-6"
    >
      <FeatureAlert />
      {showBlank && <BlankTemplateCard onSelect={selectBlank} />}
      {showXls && <XlsTemplateCard onSelect={selectXls} />}
      {templates.map(({ id, ...other }) => (
        <TemplateCard
          key={id}
          id={id}
          isDefault={defaultTemplateId === id}
          onSelect={selectTemplate}
          {...other}
          {...templateCardProps}
        />
      ))}
      {isLoading && (
        <CircularProgress
          aria-label="progress indicator"
          className={classes.progress}
        />
      )}
    </Grid>
  );
});

TemplatesList.propTypes = {
  onSelect: func.isRequired,
  defaultTemplateId: number,
  showBlank: bool,
  showXls: bool,
  allowEdition: bool,
  allowRemoval: bool,
  
  // onSetDefault: func,
  // onRemove: func,
  // onEdit: func,x
  // companyTemplates: bool,
  // projectTypes: arrayOf(shape({
  //   id: number.isRequired,
  //   name: string.isRequired,
  //   color: string.isRequired,
  //   font: string.isRequired,
  // })),
};
