import { string } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateEditorSettings,
  useEstimateEditorStore,
  useEstimateStructure, useEstimateTableSettings,
  useVisibilityModeCellClassName,
  VisibilityButton
} from "@tools";
import { Collapse, Grid } from "@material-ui/core";
import { TimelineSectionsRow, TimelineSectionTitleRow } from "../components";

export const TimelineSections = observer(({ tableContainerIdentifier }) => {
  
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const {
    isProposal,
    blockExpansions,
  } = useEstimateEditorSettings();
  const { timelineLength } = useEstimateTableSettings();
  
  const { settings, visibility, sections } = structure;
  const { displayTimelineSections } = settings;
  const { apply, hideTimelineSections } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimelineSections);
  
  const handleTimelineSectionsVisibility = () => {
    if(blockExpansions) return;
    settings.setDisplayTimelineSections(!displayTimelineSections);
  }
  
  return (
    <Grid item container className="vCon">
      <TimelineSectionTitleRow
        isProposal={isProposal}
        code="sections"
        expandable
        expanded={displayTimelineSections}
        onExpand={handleTimelineSectionsVisibility}
        className={className}
        timelineLength={timelineLength}
        action={visibilityMode &&
          <VisibilityButton hidden={hideTimelineSections} onChange={visibility.setHideTimelineSections} />
        }
      />
      <Collapse
        in={displayTimelineSections}
        timeout="auto"
        mountOnEnter
        unmountOnExit
        className={className}
      >
        <Grid
          container
          justifyContent="center"
          wrap="nowrap"
          direction="column"
        >
          {
            sections
              .filter(s => !s.isTurnedOff && (!apply || visibilityMode || !s.hideOnTimeline))
              .map((section, i) => (
                <TimelineSectionsRow
                  key={section.id}
                  section={section}
                  index={i}
                  tableContainerIdentifier={tableContainerIdentifier}
                />
              ))
          }
        </Grid>
      </Collapse>
    </Grid>
  );
});

TimelineSections.propTypes = {
  tableContainerIdentifier: string,
};