import { Fragment } from "react";
import { bool, func, number, object, oneOfType, string } from "prop-types";
import { useEstimateStructure } from "@tools";
import { Grid } from "@material-ui/core";
import { TimelineSettingsTitleRow } from "./TimelineSettingsTitleRow";
import { TimelineSettingsRow } from "./TimelineSettingsRow/TimelineSettingsRow";
import { observer } from "mobx-react";

export const TimelineSettingsContainer = observer(({
  selectedWorkType,
  selectedResource,
  section,
  filterWorkTypes,
  noTeamTogetherSwitch=true,
  ...rowProps
}) => {
  const { settings, workTypes, workTypesHaveResources } = useEstimateStructure();
  const { hasTeamMembersCol, timeModifier, useMinMax } = settings;
  
  const minStartMonth = section ? (section.timelineStartMonth || 0) + 1 : 1;
  
  return (
    <Grid item container spacing={1}>
      <TimelineSettingsTitleRow
        useTeamTogetherSwitch={!noTeamTogetherSwitch && workTypesHaveResources}
        {...rowProps}
      />
      {workTypes
        .filter((workType) => (
          (!filterWorkTypes
            || filterWorkTypes(workType)
          ) &&
          (!section
          || (
            section.workTypes.find(wT => wT.id === workType.id)
            && !workType.areHoursOverwritten
          ))
        ))
        .map((workType) => {
          const sectionElement = section?.getWorkTypeById(workType.id);
          // const disabled =  workType.hasOnlyCost || (
          //   sectionElement ? sectionElement.hasPercent : workType.usesTimelinePercent
          // )
          const disabled = workType.usesTimelinePercent || workType.hasOnlyCost;
          return <Fragment key={`wT${workType.id}`}>
            <TimelineSettingsRow
              element={workType}
              sectionElement={sectionElement}
              hasChildren={hasTeamMembersCol && workType.hasResources}
              timeModifier={timeModifier}
              useMinMax={useMinMax}
              workTypeSettings
              autoFocus={selectedWorkType === workType.id}
              disabled={disabled}
              useTeamTogetherSwitch={!noTeamTogetherSwitch && workTypesHaveResources}
              minStartMonth={minStartMonth}
              {...rowProps}
            />
            {hasTeamMembersCol &&
              workType.resources?.map(
                (resource) => (
                  <TimelineSettingsRow
                    key={`res${resource.id}`}
                    element={resource}
                    sectionElement={sectionElement?.getResourceById(resource.id)}
                    timeModifier={timeModifier}
                    useMinMax={useMinMax}
                    indent
                    autoFocus={selectedResource === resource.id}
                    disabled={disabled}
                    useTeamTogetherSwitch={!noTeamTogetherSwitch}
                    minStartMonth={minStartMonth}
                    {...rowProps}
                  />
                )
              )}
          </Fragment>
        })
      }
    </Grid>
  )
})

TimelineSettingsContainer.propTypes = {
  fullSettings: bool,
  noTeamTogetherSwitch: bool,
  selectedWorkType: number,
  selectedResource: oneOfType([string, number]),
  section: object,
  filterWorkTypes: func
}