import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { Columns } from "project-structure";
import { useEstimateEditorSettings, useEstimateStructure } from "@tools";
import {
  CostCell,
  HoursCell,
  RateCell,
  TeamCell,
  TimeCell,
  ProfitabilityCell,
  ProfitCell,
  UnitCostCell,
  CompanyCostCell,
} from "./cells";

export const HeaderColumnFactory = observer(({
  columnName,
  extendPadding
}) => {
  const structure = useEstimateStructure();
  const {
    isProposal,
    isSellerOrClient,
  } = useEstimateEditorSettings();
  const { settings, visibility } = structure;
  const { currency, companyCurrency, hasTeamMembersCol, hasTimeCol } = settings;
  const {
    apply,
    hideSummaryTime,
    hideSummaryTeam,
    hideSummaryHours,
    hideSummaryRate,
    hideSummaryCost,
  } = visibility;

  switch (columnName) {
    case Columns.TEAM:
      return (isProposal && apply && hideSummaryTeam) || !hasTeamMembersCol
        ? <></>
        : <TeamCell extendPadding={extendPadding} />
    
    case Columns.TIME:
      return (isProposal && apply && hideSummaryTime) || !hasTimeCol
        ? <></>
        : <TimeCell extendPadding={extendPadding} />
    
    case Columns.RATE:
      return (isProposal && apply && hideSummaryRate) || !isSellerOrClient
        ? <></>
        : <RateCell extendPadding={extendPadding} />
    
    case Columns.HOURS:
      return isProposal && apply && hideSummaryHours
        ? <></>
        : <HoursCell extendPadding={extendPadding} />
    case Columns.COST:
      return (isProposal && apply && hideSummaryCost) || !isSellerOrClient
        ? <></>
        : <CostCell extendPadding={extendPadding} />
      
    case Columns.C_COST:
      return isSellerOrClient
        && <CompanyCostCell extendPadding={extendPadding} fieldName="pt-profit" />
    
    case Columns.C_UNIT_COST_INNER:
      return <UnitCostCell extendPadding={extendPadding} company />
      
    case Columns.C_UNIT_COST:
      return currency === companyCurrency
        ? <></>
        : <UnitCostCell extendPadding={extendPadding} />
    
    case Columns.C_PROFIT:
      return <ProfitCell extendPadding={extendPadding} />
    
    case Columns.C_PROFITABILITY:
      return <ProfitabilityCell extendPadding={extendPadding} />
  }
});

HeaderColumnFactory.propTypes = {
  columnName: string,
  extendPadding: bool,
};
