import { useMemo } from "react";
import { Navigate, Route, Routes, BrowserRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { HomeLayout, LandingLayout } from "@layouts";
import {
  Login,
  SSOLink,
  Register,
  Activate,
  CreateWorkspace,
  PasswordRetrieve,
  PasswordReset,
  ActiveProjects,
  Templates,
  TemplateEditor,
  ArchivedProjects,
  Settings,
  Help,
  Team,
  Notifications,
  Subscription,
  Legal,
  SharedHelp,
  ActiveProjectDetails,
  ProjectEditor,
  PdfEditor,
  Pricing,
  Library,
  JiraIntegration,
  ProjectSessions,
  SessionReplayer,
  JiraAuth,
  Proposal,
  PdfProposal,
  BudgetTracking,
} from "@views";
import {
  archived,
  help,
  home,
  login,
  notifications,
  register,
  settings,
  subscription,
  resetPassword,
  retrievePassword,
  editor,
  templateEditor,
  createBoard,
  inviteRegister,
  activate,
  sharedProposal,
  terms,
  policy,
  sharedHelp,
  templates,
  sessions,
  sessionReplay,
  team,
  pdfEditor,
  sharedPdfProposal,
  settingsCompany,
  settingsPassword,
  settingsProject,
  settingsCurrency,
  settingsDomain,
  settingsTimeEquiv,
  settingsRates,
  settingsProjectTypes,
  sharedProposalOld,
  sharedPdfProposalOld,
  pricing,
  library,
  jiraIntegration,
  jiraAuth,
  projectEdit,
  ssoActivation,
  budgetTracking,
} from "@paths";
import {
  Company,
  Password,
  Profile,
  Project,
  Currency,
  Domain,
  Rates,
  TimeEquiv,
  ProjectTypes,
} from "@views/Utility/Settings/subviews";
import { LOCKED_FEATURES } from "@utils";
import { Paper } from "@material-ui/core";
import { ApropoRedirector, RouteProtector } from "@components";
import useStyle from "./Router.style";

export const Router = () => {
  const classes = useStyle();

  const notApropo = useMemo(
    () => window.location.origin !== process.env.REACT_APP_APP_ORIGIN_DOMAIN,
    []
  );

  if (notApropo) {
    return (
      <Paper elevation={0} className={classes.root}>
        <BrowserRouter>
          <Routes>
            <Route path={sharedProposal()} element={<Proposal />} />
            <Route path={sharedProposalOld()} element={<Proposal />} />
            <Route path={sharedPdfProposal()} element={<PdfProposal />} />
            <Route path={sharedPdfProposalOld()} element={<PdfProposal />} />
            <Route path="*" element={<ApropoRedirector />} />
          </Routes>
        </BrowserRouter>
      </Paper>
    );
  }

  return (
    <Paper elevation={0} className={classes.root}>
      <BrowserRouter>
        <Routes>
          <Route path={sharedProposal()} element={<Proposal />} />
          <Route path={sharedProposalOld()} element={<Proposal />} />
          <Route path={sharedPdfProposal()} element={<PdfProposal />} />
          <Route path={sharedPdfProposalOld()} element={<PdfProposal />} />
          <Route path={terms} element={<Legal />} />
          <Route path={policy} element={<Legal />} />
          <Route path={sharedHelp} element={<SharedHelp />} />

          <Route
            path={home}
            element={<HomeLayout />}
            errorElement={<Navigate to={home} />}
          >
            <Route path={notifications} element={<Notifications />} />
            <Route path={archived} element={<ArchivedProjects />} />
            {/*<Route path={boards} element={<Boards />} />*/}
            <Route path={editor()} element={<ProjectEditor />} />
            <Route path={pdfEditor()} element={<PdfEditor />} />
            <Route path={budgetTracking()} element={<BudgetTracking />} />
            <Route
              path={subscription}
              element={
                <RouteProtector adminOnly>
                  <Subscription />
                </RouteProtector>
              }
            />
            <Route
              path={pricing}
              element={
                <RouteProtector adminOnly>
                  <Pricing />
                </RouteProtector>
              }
            />
            <Route path={team} element={<Team />} />
            <Route path={templates} element={<Templates />} />
            <Route
              path={templateEditor()}
              element={
                <RouteProtector moderatorOnly>
                  <TemplateEditor />
                </RouteProtector>
              }
            />
            <Route path={library} element={<Library />} />
            {/*<Route path={contact} element={<Contact />} />*/}
            <Route path={help} element={<Help />} />
            <Route path={jiraIntegration} element={<JiraIntegration />} />

            {isMobile && (
              <Route path={projectEdit()} element={<ActiveProjectDetails />} />
            )}

            <Route path={settings} element={<Settings />}>
              <Route
                path={settingsCompany}
                element={
                  <RouteProtector adminOnly>
                    <Company />
                  </RouteProtector>
                }
              />
              <Route path={settingsPassword} element={<Password />} />
              <Route path={settings} element={<Profile />} />
              <Route
                path={settingsProject}
                element={
                  <RouteProtector adminOnly>
                    <Project />
                  </RouteProtector>
                }
              >
                <Route path={settingsCurrency} element={<Currency />} />
                <Route path={settingsDomain} element={<Domain />} />
                <Route path={settingsTimeEquiv} element={<TimeEquiv />} />
                <Route path={settingsRates} element={<Rates />} />
                <Route path={settingsProjectTypes} element={<ProjectTypes />} />
              </Route>
              <Route path="*" element={<Navigate to={settings} />} />
            </Route>

            {!isMobile && (
              <>
                <Route
                  path={sessions()}
                  element={
                    <RouteProtector
                      accessedFeature={LOCKED_FEATURES.RECORDINGS}
                    >
                      <ProjectSessions />
                    </RouteProtector>
                  }
                />
                <Route
                  path={sessionReplay()}
                  element={
                    <RouteProtector
                      accessedFeature={LOCKED_FEATURES.RECORDINGS}
                    >
                      <SessionReplayer />
                    </RouteProtector>
                  }
                />
              </>
            )}

            <Route path={home} element={<ActiveProjects />} />
            <Route path="*" element={<Navigate to={home} />} />
          </Route>

          <Route path="/" element={<LandingLayout />}>
            <Route path={jiraAuth} element={<JiraAuth />} />
            <Route path={ssoActivation} element={<SSOLink />} />
            <Route path={login} element={<Login />} />
            <Route path={register} element={<Register />} />
            <Route path={inviteRegister()} element={<Register />} />
            <Route path={activate} element={<Activate />} />
            <Route path={retrievePassword} element={<PasswordRetrieve />} />
            <Route path={resetPassword} element={<PasswordReset />} />
            <Route path={createBoard} element={<CreateWorkspace />} />
            <Route path="*" element={<Navigate to={login} />} />
          </Route>
          <Route path="*" element={<Navigate to={home} />} />
        </Routes>
      </BrowserRouter>
    </Paper>
  );
};
