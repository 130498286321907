import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateStructure,
  useEstimateEditorSettings,
  CurrencyEditor,
  ValueCell,
} from "@tools";

export const UnitCostCell = observer(({
  extendPadding,
  company,
}) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const structure = useEstimateStructure();
  const { currency, companyCurrency } = structure.settings;
  
  return (
    <ValueCell
      widest
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
      extendPadding={extendPadding}
    >
      <span>
        {`${getFieldName("pt-hourlyCost")} (${company ? companyCurrency : currency})`}
      </span>
      {
        company && allowEdition &&
        <CurrencyEditor useCompanyCurrency={company} />
      }
    </ValueCell>
  )
})

UnitCostCell.propTypes = {
  extendPadding: bool,
  company: bool,
}