import { Children, cloneElement, useEffect, useMemo, useState } from "react";
import { node, string } from "prop-types";
import { Collapse } from "@material-ui/core";
import { SettingsButton } from "./SettingsButton";
import { ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import classnames from "classnames";

export const SettingsButtonGroup = ({
  titleKey,
  children,
}) => {
  
  const [opened, setOpen] = useState(false);
  
  const path = useMemo(() => (
    Children.toArray(children)[0].props.path
  ), [children]);
  
  const active = useMemo(() => Boolean(
    Children
      .toArray(children)
      .find(child => (
        child.props.path && location.pathname === child.props.path
      ))
  ), [children, location.pathname]);
  
  useEffect(() => {
    if(active)
      setOpen(true);
  }, [active]);
  
  const handleClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(true);
  }
  
  return <>
    <SettingsButton
      icon={<ExpandMore
        onClick={ handleClick }
        className={classNames(
          "expand transition ",
          opened && "-rotate-180"
        )}
      />}
      path={path}
      forceActive={active}
      titleKey={titleKey}
    />
    <Collapse
      in={opened}
      timeout="auto"
      mountOnEnter
      unmountOnExit
    >
      {
        Children.map(children, (child) => (
          cloneElement(child, {
            ...child.props,
            className: classnames(child.props.className, "ml-4")
          })
        ))
      }
    </Collapse>
  </>
}

SettingsButtonGroup.propTypes = {
  titleKey: string.isRequired,
  icon: node.isRequired,
  children: node.isRequired,
}