import { observer } from "mobx-react";
import { useStores } from "@hooks";
import { Router } from "./Router";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SnackbarProvider } from "notistack";
import { ThemeProvider, CssBaseline } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { StyledSnackbarComponent, themeLoader } from "@styles";

const App = observer(() => {
  const { stateStore } = useStores();

  const { theme } = stateStore;

  return (
    <ThemeProvider theme={themeLoader(theme)}>
      <CssBaseline />
      <SnackbarProvider
        variant="info"
        autoHideDuration={4000}
        // Components={{
        //   success: StyledSnackbarComponent,
        //   error: StyledSnackbarComponent,
        // }}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router />
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
});

export default App;
