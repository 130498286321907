import { Children, cloneElement, forwardRef, isValidElement, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { arrayOf, bool, func, node, object, oneOf, string } from "prop-types";
import { isEqual } from "lodash";
import Quill from "quill"
import { Mention, MentionBlot } from "quill-mention";
import MagicUrl from "quill-magic-url";
import {
  MAX_WYSIWYG_LENGTH,
  suggestMentionPeople,
  WYSIWYG_FORMATS,
  WYSIWYG_FORMATS_WITH_MENTIONS
} from "@utils";
import { PopMenu, SearchableListContent, SearchableListItem } from "@components";
import { ClickAwayListener, Grid, InputAdornment } from "@material-ui/core";
import { QuillToolbar } from "../QuillToolbar/QuillToolbar";
import classnames from "classnames";
import useStyle from "./QuillEditor.style";
import "quill/dist/quill.core.css";

export const QuillEditor = forwardRef(({
  children,
  focused,
  value: defaultValue,
  displayValue,
  showDisplayValue,
  
  onFocus,
  onBlur,
  onTextChange,
  toolbarVariant,
  editorMentionUsers,
  editorMentionSearchKeys,
  popupAnchor,
  toolbarPlacement,
  placeholder,
  useBorder,
  useBorderUnfocused,
  blurOnEnter,
  endAdornment,
  className,
  placeholderOnFocus,
}, ref ) => {
  const classes = useStyle();
  
  const containerRef = useRef(null);
  const toolbarRef = useRef(null);
  const onTextChangeRef = useRef(onTextChange);
  const defaultValueRef = useRef(defaultValue);
  const selectionRef = useRef(0);
  const onFocusRef = useRef(onFocus);
  const onBlurRef = useRef(onBlur);
  
  const [ userListVisible, showUsers ] = useState(false);
  const [ filteredUsers, setFilteredUsers ] = useState([]);
  const [ loaded, setLoaded ] = useState(toolbarVariant !== "popup");
  
  const filterMentionUsers = useCallback((searchTerm, renderList) => {
    if (searchTerm.length === 0) {
      const matchedPeople = suggestMentionPeople(editorMentionUsers);
      setFilteredUsers(matchedPeople);
      renderList(matchedPeople, searchTerm);
      return;
    }
    const matchedPeople = suggestMentionPeople(editorMentionUsers, editorMentionSearchKeys, searchTerm);
    setFilteredUsers(matchedPeople);
    renderList(matchedPeople, searchTerm);
  }, []);
  
  const handleBlur = (reason) => {
    if ( !ref.current || !focused )
      return;
    onBlur && onBlur(reason);
  }
  
  const handleToolbarRef = useCallback((r) => {
    toolbarRef.current = r;
    setLoaded(Boolean(r));
  }, []);
  
  const mentionModule = useMemo(() => ({
    source: filterMentionUsers,
    dataAttributes: ["value"],
    onOpen: () => showUsers(true),
    onClose: () => showUsers(false),
  }), []);
  
  const addMention = (item) => async () => {
    ref.current?.getModule('mention')?.insertItem({
      denotationChar: "@",
      ...item
    }, false);
  }
  
  useLayoutEffect(() => {
    onTextChangeRef.current = onTextChange;
    defaultValueRef.current = defaultValue;
    onFocusRef.current = onFocus;
    onBlurRef.current = onBlur;
  });
  
  useEffect(() => {
    if(!ref.current) return;
    if(!isEqual(defaultValue, ref.current.root.innerHTML)) {
      const selection = ref.current.getSelection();
      const delta = ref.current.clipboard.convert({ html: defaultValue });
      ref.current.setContents(delta, 'silent');
      ref.current.setSelection(selection, "silent");
    }
  }, [defaultValue]);
  
  useEffect(() => {
    if(!displayValue || !ref.current) return;
    if(showDisplayValue) {
      const delta = ref.current.clipboard.convert({ html: displayValue });
      ref.current.setContents(delta, "silent");
    } else {
      const delta = ref.current.clipboard.convert({ html: defaultValue });
      ref.current.setContents(delta, "silent");
    }
  }, [ref, showDisplayValue]);
  
  
  useEffect(() => {
    const container = containerRef.current;
    const editorContainer = container.appendChild(
      container.ownerDocument.createElement('div'),
    );
    
    const quill = new Quill(editorContainer, {
      theme: null,
      placeholder,
      formats: editorMentionUsers?.length ? WYSIWYG_FORMATS_WITH_MENTIONS : WYSIWYG_FORMATS,
      modules: {
        magicUrl: true,
        mention: editorMentionUsers?.length ? mentionModule : undefined,
        clipboard: { matchVisual: false },
        history: {
          userOnly: true
        },
        toolbar: toolbarVariant === "none"
          ? false
          : toolbarRef.current,
      }
    });
    
    if(blurOnEnter) {
      quill.keyboard.bindings['Enter'] = null;
      quill.keyboard.addBinding(
        13,
        () => {
          onBlurRef.current?.("enter");
        }
      );
      quill.keyboard.addBinding(
        13,
        { shiftKey: true },
        (range) => {
          quill.insertText(range.index, "\n");
        }
      );
    }
    
    ref.current = quill;
    
    if (defaultValueRef.current) {
      const delta = quill.clipboard.convert({html: defaultValueRef.current})
      quill.setContents(delta, 'silent')
    }
    
    if(selectionRef.current) {
      ref.current.setSelection(selectionRef.current, "silent");
      selectionRef.current = null;
    }
    
    quill.on(Quill.events.TEXT_CHANGE, () => {
      const contentLength = quill.getLength();
      if(contentLength > MAX_WYSIWYG_LENGTH) {
        quill.deleteText(MAX_WYSIWYG_LENGTH-1, contentLength);
        return;
      }
      const deltaOps = quill.getContents()?.ops;
      const mentions = deltaOps
        ?.filter(ops => ops?.insert?.mention?.id)
        .map(ops => ({uuid: ops.insert.mention.id})) || [];
      
      onTextChangeRef.current?.(quill.root.innerHTML, mentions, contentLength, deltaOps);
    });
    
    quill.on(Quill.events.EDITOR_CHANGE, (eventName, rangeOrDelta, oldRangeOrDelta) => {
      if (isEqual(rangeOrDelta, oldRangeOrDelta)) return;
      if (!oldRangeOrDelta && rangeOrDelta) {
        selectionRef.current = rangeOrDelta;
        onFocusRef.current?.();
      }
    });
    
    return () => {
      ref.current = null;
      container.innerHTML = '';
    };
  }, [ref, loaded]);
  
  const childrenWithProps = Children.map(children, (child) => {
    if ( isValidElement(child) )
      return cloneElement(child, {
        ...child.props,
        onEditorBlur: () => handleBlur("clickAway")
      });
    return child;
  });
  
  return <ClickAwayListener mouseEvent="onMouseDown" onClickAway={() => handleBlur("clickAway")}>
    <Grid
      item container
      translate="no"
      className={classnames(
        useBorder && classes.bordered,
        useBorder && useBorderUnfocused && classes.borderedUnfocused,
        useBorder && focused && classes.borderedFocused,
        "ql-root w-full", showDisplayValue && "ql-ellipsis",
        className,
      )}
    >
      <div
        ref={containerRef}
        className={classnames(
          classes.editor,
          placeholderOnFocus && classes.placeholderOnFocus,
          focused && "focused"
        )}
      />
      {
        endAdornment &&
        <InputAdornment position="end">
          { endAdornment }
        </InputAdornment>
      }
      {
        toolbarVariant === "popup" &&
        <PopMenu anchor={popupAnchor} show={focused} placement={toolbarPlacement}>
          <QuillToolbar
            ref={handleToolbarRef}
            id={name}
            float
            visible
          >
            { childrenWithProps }
          </QuillToolbar>
        </PopMenu>
      }
      {
        toolbarVariant !== "popup" &&
        <QuillToolbar
          ref={toolbarRef}
          id={name}
          fullWidth
          visible
          hide={!focused}
        >
          { childrenWithProps }
        </QuillToolbar>
      }
      {
        Boolean(editorMentionUsers) &&
        <SearchableListContent
          show={userListVisible}
          anchor={popupAnchor}
          placement="bottom"
          className={classes.mentionList}
          id="libraryElementSelector"
          onClickAway={() => showUsers(false)}
        >
          {
            filteredUsers.map(m => (
              <SearchableListItem
                key={m.id}
                onClick={addMention(m)}
              >
                {m.value}
              </SearchableListItem>
            ))
          }
        </SearchableListContent>
      }
    </Grid>
  </ClickAwayListener>;
});

QuillEditor.displayName = 'QuillEditor';
QuillEditor.propTypes = {
  disabled: bool,
  popupAnchor: object,
  value: string,
  displayValue: string,
  showDisplayValue: bool,
  focused: bool,
  onFocus: func,
  onBlur: func,
  onTextChange: func.isRequired,
  toolbarVariant: oneOf(["none", "inline", "popup"]),
  children: node,
  endAdornment: node,
  useBorder: bool,
  useBorderUnfocused: bool,
  placeholderOnFocus: bool,
  blurOnEnter: bool,
  placeholder: string,
  editorMentionUsers: arrayOf(object),
  editorMentionSearchKeys: arrayOf(string),
  toolbarPlacement: oneOf(["top", "bottom"]),
  className: string,
}

Quill.register({ "modules/magicUrl": MagicUrl }, true);
Quill.register({ "blots/mention": MentionBlot, "modules/mention": Mention });
const icons = Quill.import("ui/icons");
icons.bold = null;
icons.italic = null;
icons.underline = null;
icons.code = null;
icons["code-block"] = null;
icons.blockquote = null;
icons.strike = null;
icons.underline = null;
icons.link = null;
icons.header = {
  1: null,
  2: null,
};
icons.list = {
  ordered: null,
  bullet: null,
};