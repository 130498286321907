import { useEstimatePresetStore } from "@tools";

export const useFonts = () => {
  const presetStore = useEstimatePresetStore();
  
  const { fonts } = presetStore;
  
  // const loadFontFace = async ({fontStyle}) => {
  //   // if(!fontFace || fontFace.status !== "unloaded") return;
  //   // try {
  //   //   const loadedFont = await fontFace.load();
  //   //   document.fonts.add(loadedFont);
  //   // } catch(e) {
  //   //   console.log(e);
  //   // }
  //   if(!fontStyle || document.getElementById(fontStyle.id))
  //     return;
  //
  //   document.head.appendChild(fontStyle);
  // }
  
  // const unloadFontFace = async ({ fontStyle }) => {
  //   if(!fontStyle) return;
  //   // document.fonts.delete(fontFace);
  //   const node = document.getElementById(fontStyle.id);
  //   console.log(fontStyle.id, node);
  //   if(node)
  //     document.head.removeChild(node);
  // }
  
  const loadFonts = (fonts) => {
    presetStore.setFonts(fonts.map(font => {
      if(font.target) {
        const fontStyle = document.createElement('style');
        fontStyle.id = `font-${font.name.replace(/\s/g, "").toLowerCase()}`;
        fontStyle.className = "font-style"
        fontStyle.appendChild(document.createTextNode(`@font-face{font-family: "${font.name}"; src: url(${font.target});}`));
        if(!document.getElementById(fontStyle.id))
          document.head.appendChild(fontStyle);
        return {
          ...font,
          fontStyle,
          // fontFace: new FontFace(font.name, `url(${font.target})`, {
          //   style: "normal",
          //   weight: 400,
          //   display: "swap",
          // }),
        }
      }
      return { ...font };
    }));
  }
  
  const unloadFonts = (skippedFont) => {
    [...document.head.getElementsByClassName("font-style")].reverse().forEach((node) => {
      if(!skippedFont || !skippedFont.name || node.id !== `font-${skippedFont.name.replace(/\s/g, "").toLowerCase()}`)
        document.head.removeChild(node)
    });
  }
  
  const addFont = (id, name, target, custom=true) => {
    const fontStyle = document.createElement('style');
    fontStyle.id = `font-${name.replace(/\s/g, "").toLowerCase()}`;
    fontStyle.className = "font-style"
    fontStyle.appendChild(document.createTextNode(`@font-face{font-family: "${name}"; src: url(${target});}`));
    presetStore.addFont({
      id,
      name,
      target,
      custom,
      fontStyle,
      // fontFace: new FontFace(name, `url(${target})`, {
      //   style: "normal",
      //   weight: 400,
      //   display: "swap",
      // })
    })
    document.head.appendChild(fontStyle);
    // setFontFaces((s) => {
    //   s.push(
    //     new FontFace(name, `url(${target})`, {
    //       style: "normal",
    //       weight: 400,
    //       display: "swap",
    //     })
    //   );
    //   return s;
    // })
  }
  
  const removeFont = (id) => {
    presetStore.setFonts(s => (
      s.filter(f => f.id !== id)
    ))
  }
  
  return {
    fonts,
    loadFonts,
    unloadFonts,
    addFont,
    removeFont,
  };
}