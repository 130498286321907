import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { SUMMARY_TABLE_DEPTHS, Tables } from "project-structure";
import { ELEMENT_TYPES, CELL_WIDTHS } from "@utils";
import { useTableExpandHandler } from "@hooks";
import { TitleCell, Row, RowGroup } from "@tools";
import { HoursCell } from "./cells/HoursCell";
import { RateCell } from "./cells/RateCell";
import { CostCell } from "./cells/CostCell";
import classnames from "classnames";

export const BudgetTrackingSummaryRowGroup = observer(({ workType }) => {
  const { t } = useTranslation();

  const { id, name, turnOff } = workType;

  const { handleOpen } = useTableExpandHandler(
    true,
    workType.isOpened,
    workType.setOpenState,
  );

  const handleTurnOffState = () => {
    workType.setTurnOffState(!turnOff);
  };

  const tableDepth = SUMMARY_TABLE_DEPTHS.WORK_TYPE;

  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      tableDepth={tableDepth}
      presetClass="preset-accentSummary"
      isStatic
      elementId={id}
      elementType={ELEMENT_TYPES.WORK}
    >
      {
        <Row
          onExpand={handleOpen}
          expandOnClick
          visible={!turnOff}
          onVisibilityToggle={handleTurnOffState}
          isFirstGroupRow
          isLastGroupRow
          tableDepth={tableDepth}
          noArrow
        >
          <TitleCell
            minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
            pinnedLeft={0}
            allowOverflowDisplay
          >
            <span
              className={classnames(
                "name m-3",
                !name?.length && "semi-transparent"
              )}
            >
              {name || t("common.unnamed")}
            </span>
          </TitleCell>
          <RateCell workType={workType} />
          <HoursCell workType={workType} />
          <CostCell workType={workType} />
        </Row>
      }
    </RowGroup>
  );
});

BudgetTrackingSummaryRowGroup.propTypes = {
  workType: object.isRequired,
};
