import { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Grid, IconButton } from "@material-ui/core";
import { useEstimateStructure } from "@tools";
import { Dialog } from "@components";
import { TimelineContentSwitch } from "./components/TimelineContentSwitch";
import { Settings } from "@assets";

export const TimelineContentSettings = observer(() => {
  
  const {t} = useTranslation();
  const structure = useEstimateStructure();
  
  const { settings } = structure;
  const {
    useTimelineSections,
    useTimelineAllocation,
    useTimelineBilling,
  } = settings;
  
  const [dialogVisible, showDialog] = useState(false);
  
  const handleDialogVisibility = () => {
    showDialog(!dialogVisible);
  }
  
  const handleShowTimelineSections = () => {
    structure.historyManager.startGroup();
    settings.setUseTimelineSections(!useTimelineSections);
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  }
  
  const handleShowTimelineAllocation = () => {
    settings.setUseTimelineAllocation(!useTimelineAllocation);
  }
  
  const handleShowTimelineBilling = () => {
    settings.setUseTimelineBilling(!useTimelineBilling);
  }
  
  return (
    <>
      <IconButton
        tabIndex={-1}
        size="small"
        color="primary"
        onClick={handleDialogVisibility}
      >
        <Settings />
      </IconButton>
      <Dialog
        open={dialogVisible}
        onClose={handleDialogVisibility}
        width={460}
        title={t("views.editor.dialogs.timeline_visibility")}
      >
        <Grid item container direction="column">
          <TimelineContentSwitch
            title="proposal_parts.timeline_dropdowns.sections"
            onChange={handleShowTimelineSections}
            value={useTimelineSections}
          />
          <TimelineContentSwitch
            title="proposal_parts.timeline_dropdowns.team"
            onChange={handleShowTimelineAllocation}
            value={useTimelineAllocation}
          />
          <TimelineContentSwitch
            title="proposal_parts.timeline_dropdowns.billing"
            onChange={handleShowTimelineBilling}
            value={useTimelineBilling}
            isLast
          />
        </Grid>
      </Dialog>
    </>
  );
});