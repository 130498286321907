export const login = "/";
export const register = "/sign-up";
export const inviteRegister = (code = ":code") => `${register}/${code}`;
export const activate = "/activate";
export const retrievePassword = "/retrieve";
export const resetPassword = "/reset";
export const createBoard = "/board/create";
export const chooseBoard = "/board/create";
export const sharedHelp = "/help";
export const terms = "/terms";
export const policy = "/policy";

export const ssoActivation = "/sso-activation/:ssoLink"

export const home = "/home";
export const projectEdit = (id = ":projectUuid") => `${home}/${id}`;
export const archived = `${home}/archived`;
export const newProject = `${home}/new`;
export const editor = (id = ":projectUuid") => `${home}/editor/project/${id}`;
export const editorNew = (id = ":projectUuid") => `${home}/editor/project/${id}?new=true`;
export const editorNewTemplate = (id = ":projectUuid") => `${home}/editor/project/${id}?new=true&template=true`;
export const templateEditor = (id = ":templateId") => `${home}/editor/template/${id}`;
export const pdfEditor = (id = ":projectUuid") => `${home}/editor/pdf/${id}`;
export const pdfEditorNew = (id = ":projectUuid") => `${home}/editor/pdf/${id}?new=true`;
// export const archivedDetails = (id=":id") => `${home}/archived/${id}`;
export const subscription = `${home}/subscription`;
export const pricing = `${subscription}/pricing`;
export const settings = `${home}/settings`;
// export const settingsPayment = `${settings}/payment`;
export const settingsPaymentHistory = `${settings}/payments`;
export const settingsPassword = `${settings}/password`;
export const settingsUpgrade = `${settings}/upgrade`;
export const settingsCompany = `${settings}/company`;
export const settingsProject = `${settings}/project`;
export const settingsCurrency = `${settings}/project/currency`;
export const settingsDomain = `${settings}/project/domain`;
export const settingsTimeEquiv = `${settings}/project/fte`;
export const settingsRates = `${settings}/project/rates`;
export const settingsProjectTypes = `${settings}/project/project_types`;
export const settingsLabels = `${settings}/project/labels`;
export const settingsIntegrations = `${settings}/integrations`;
export const boards = `${home}/boards`;
export const boardAdd = `${home}/boards/add`;
export const contact = `${home}/contact`;
export const templates = `${home}/templates`;
export const library = `${home}/library`;
export const help = `${home}/help`;
export const team = `${home}/team`;
export const notifications = `${home}/notifications`;
export const jiraIntegration = `${home}/jira`;
export const budgetTracking = (id = ":projectUuid") => `${home}/budgetTracking/${id}`;

export const sessions = (id = ":projectUuid") => `${home}/sessions/${id}`;
export const sessionReplay = (id = ":projectUuid", sessionId = ":sessionId") =>
  `${sessions(id)}/${sessionId}`;
// export const sessionHeatmap = (id = ":projectUuid", sessionId = ":sessionId") =>
//   `${sessionReplay(id, sessionId)}/heatmap`;
// export const sessionPdfHeatmap = (id = ":projectUuid", sessionId = ":sessionId") =>
//   `${sessionReplay(id, sessionId)}/pdfheatmap`;

export const sharedProposalOld = (hash = ":projectHash") => `/proposal/${hash}`;
export const sharedProposal = (
  company = ":company",
  project = ":project",
  hash = ":projectHash",
) => `/proposal/${company}/${project}/${hash}`;
export const sharedPdfProposalOld = (hash = ":projectHash") => `/proposalpdf/${hash}`;
export const sharedPdfProposal = (
  company = ":company",
  project = ":project",
  hash = ":projectHash",
) => `/proposalpdf/${company}/${project}/${hash}`;

//mail routes:
export const loginWorkspace = "/:workspaceUuid";
export const loginWorkspaceProject = "/:workspaceUuid/:projectId";
export const loginWorkspaceOpenedProject = "/:workspaceUuid/:projectId/estimate";
export const jiraAuth = "/auth/jira";

export const fullWidthViews = [
  editor(),
  editorNew(),
  editorNewTemplate(),
  templateEditor(),
  // library,
  pdfEditor(),
];

export const staticPageView = [
  sessionReplay(),
  pdfEditor(),
  sharedPdfProposal(),
  sharedPdfProposalOld(),
  // sessionPdfHeatmap(),
];

export const scrollablePageView = [
  // editor(),
  // pdfEditor(),
  // archived,
  // sessionReplay(),
  // sharedProposal(),
  // sharedPdfProposal(),
  // sessionHeatmap(),
  // sessionPdfHeatmap(),
  home,
  // workspaces,
  notifications,
  team,
];