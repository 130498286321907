import { memo, useEffect, useMemo, useState } from "react";
import { bool, string, arrayOf, oneOfType, object, shape, number } from "prop-types";
import { StructureModel, structureParser, unzipStructure } from "project-structure";
import { PROJECT_TYPE } from "@utils";
import {
  EstimateEditorStoreProvider,
  EstimateStructureProvider,
  EstimateEditorStore,
  EditorContainer,
  TableBreakdown, EstimateEditorSettingsProvider,
} from "@tools";
import { PageCircularProgress } from "@components";
import { Grid } from "@material-ui/core";

export const TemplatePreview = memo(({
  versionData,
  versionStructure,
  workTypes,
  commentList,
  useNewWorkTypes,
}) => {
  
  const [isLoading, setLoading] = useState(true);
  const [structure, setStructure] = useState(null);
  
  const store = useMemo(() => new EstimateEditorStore({
    estimateType: PROJECT_TYPE.STANDARD,
    workTypes,
    commentsVisible: commentList?.length > 0,
    defaultEstimateVersionsForPreview: [versionData],
    defaultComments: commentList,
  }), []);

  useEffect(() => {
    (async () => {
      let structureData;
      if(typeof versionStructure === "string" && versionStructure[0] !== "{") {// may be blank
        try {
          structureData = JSON.parse(unzipStructure(versionStructure));
        } catch(e) {
          try {
            structureData = JSON.parse(unzipStructure(unzipStructure(versionStructure)));
          } catch(e) {
            console.log("TEMPLATE PARSE FAIL:", JSON.stringify(e));
          }
        }
      } else if(versionStructure)
        structureData = versionStructure;

      if(structureData) {
        const parsedStructure = await structureParser({
          structureData,
          workTypes,
          createNewTags: useNewWorkTypes
        });
        setStructure(StructureModel.create(parsedStructure));
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading || !structure) return <PageCircularProgress />;

  return (
    <EstimateEditorStoreProvider value={store}>
      <EstimateStructureProvider value={structure}>
        <EstimateEditorSettingsProvider isProposal showChatThread previewMode >
          <EditorContainer>
            <Grid
              item
              container
              direction="column"
              alignItems="center"
            >
              <TableBreakdown />
            </Grid>
          </EditorContainer>
        </EstimateEditorSettingsProvider>
      </EstimateStructureProvider>
    </EstimateEditorStoreProvider>
  );
});

TemplatePreview.propTypes = {
  versionStructure: oneOfType([string, object]).isRequired,
  workTypes: arrayOf(object),
  useNewWorkTypes: bool,
  commentList: arrayOf(object),
  versionData: shape({
    key: number,
    order: number,
    name: string,
  }),
};
