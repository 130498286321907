import { makeStyles } from "@material-ui/core/styles";
import { DEFAULT_LABEL_COLOR, DEFAULT_LABEL_FONT_COLOR } from "@utils";

const useStyle = makeStyles(() => ({
  root: {
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    width: "max-content",
    transition: "width .5s ease-out",
    backgroundColor: DEFAULT_LABEL_COLOR,
    color: DEFAULT_LABEL_FONT_COLOR,
  },
  "size-small": {
    height: 24,
    padding: "0 4px",
  },
  "size-large": {
    height: 28,
    padding: "0 8px",
  },
  title: {
    fontSize: 12,
    letterSpacing: 0.22,
  },
  titleHover: {
    "&:hover": {
      textDecoration: "underline"
    },
  },
  removableShown: {
    "& .remove": {
      width: "max-content",
      marginLeft: 6,
      opacity: 1,
      transition: "opacity .3s ease-out, width .5s ease-out",
    },
  },
  removeSection: {
    cursor: "pointer",
    width: 0,
    opacity: 0,
    margin: 0,
    height: "1em",
    boxSizing: "content-box",
  },
  overflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  maxWidth: {
    maxWidth: 312,
  }
}));

export default useStyle;
