import { useState, useMemo, useEffect } from "react";
import { bool, string, func } from "prop-types";
import { MAX_MODULE_NAME } from "@utils";
import { Grid, InputBase } from "@material-ui/core";
import useStyle from "./SectionName.style";
import classnames from "classnames";

export const SectionName = ({
  defaultName,
  editable,
  onChange,
  colored,
}) => {
  const classes = useStyle();

  const [localName, setLocalName] = useState(defaultName || "");
  const [focused, setFocus] = useState(undefined);

  useEffect(() => {
    if (defaultName !== localName) setLocalName(defaultName);
  }, [defaultName]);

  const width = useMemo(
    () => calculateInputWidth(localName, "16px", 400, 0.15, 36),
    [localName]
  );

  const handleFocus = () => {
    setFocus(true);
  };

  const handleBlur = () => {
    setFocus(false);
    onChange(localName.trim());
    setLocalName(localName.trim());
  };

  const handleChange = (e) => {
    setLocalName(
      e.target.value/*.replace(/[\\^{}`]/g, "")*/.slice(0, MAX_MODULE_NAME)
    );
  };

  const checkKey = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
      handleBlur(e);
    }
  };

  return editable ? (
    <InputBase
      onChange={handleChange}
      value={localName}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={checkKey}
      style={{ width }}
      className={classnames("noDrag sectionTitle", {
        [classes.input]: true,
        [classes.inputFocused]: focused,
        [classes.colored]: colored,
      })}
      color="primary"
      type="text"
    />
  ) : (
    <Grid
      item
      container
      className={classnames("sectionTitle", classes.text, colored && classes.colored)}
      style={{ width }}
    >
      {localName}
    </Grid>
  );
};

SectionName.propTypes = {
  id: string,
  defaultName: string.isRequired,
  editable: bool.isRequired,
  onChange: func.isRequired,
  focusedNameColor: string,
  colored: bool,
};

const calculateInputWidth = (text, size, weight, spacing, padding) => {
  const el = document.createElement("span");
  el.innerText = text;
  el.style.fontSize = size;
  el.style.fontWeight = weight;
  el.style.letterSpacing = spacing;
  window.document.body.appendChild(el);
  const v = el.getBoundingClientRect().width;
  window.document.body.removeChild(el);
  return v + padding + "px";
};
