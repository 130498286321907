import { useMemo } from "react";
import { arrayOf, bool, func, number, object, oneOfType, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { removeWorkTypeQuery } from "@query";
import { MAX_VALUES } from "@utils";
import { ClearInput, Tag, SettingsRow } from "@components";
import { IconButton, Grid } from "@material-ui/core";
import { RateElementColorEditor } from "./RateElementColorEditor";
import { Trash } from "@assets";
import { VisibilityOffOutlined, VisibilityOutlined } from "@material-ui/icons";

export const RateElement = ({
  id,
  name,
  backgroundColor,
  rate,
  removable,
  currency,
  hiddenWorkTypes,
  onRemove,
  onHide,
  onColorChange,
  onRateChange
}) => {
  
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  
  const isHidden = useMemo(() => (
    hiddenWorkTypes.includes(id)
  ), [hiddenWorkTypes]);
  
  const handleWorkTypeRateChange = (value) => {
    onRateChange(id, value);
  }
  const handleColorChange = (value) => {
    onColorChange(id, value);
  }
  
  const removeWorkType = async () => {
    try {
      const status = await removeWorkTypeQuery(id);
      if (!status)
        throw "no status";
      onRemove(id);
    } catch (e) {
      enqueueSnackbar(t("views.settings.projects.remove_error"), {
        variant: "error",
      });
    }
  };
  
  const handleWorkTypeHide = async () => {
    onHide(id, !isHidden);
  }
  
  return (
    <SettingsRow bordered>
      <Grid item container>
        <Tag text={name} color={backgroundColor} />
      </Grid>
      {
        removable &&
        <RateElementColorEditor
          elementName={name}
          color={backgroundColor}
          onColorChange={handleColorChange}
        />
      }
      <ClearInput
        value={rate}
        onChange={handleWorkTypeRateChange}
        alignContent="center"
        max={MAX_VALUES.RATE_HOUR}
        confirmOnEnter={false}
        className="max-w-64"
        prefix={currency?.symbolStart || ""}
        suffix={currency?.symbolEnd ? " "+currency.symbolEnd : ""}
      />
      {
        removable
          ? <IconButton onClick={removeWorkType} size="small" className="ml-2">
            <Trash color="error" />
          </IconButton>
          : <IconButton onClick={handleWorkTypeHide} size="small" className="ml-2">
            {isHidden ? (
              <VisibilityOffOutlined color="primary" />
            ) : (
              <VisibilityOutlined color="primary" />
            )}
          </IconButton>
      }
    </SettingsRow>
  )
}

RateElement.propTypes = {
  id: number.isRequired,
  name: string.isRequired,
  hiddenWorkTypes: arrayOf(number).isRequired,
  onHide: func.isRequired,
  onRemove: func.isRequired,
  onColorChange: func.isRequired,
  onRateChange: func.isRequired,
  backgroundColor: string,
  rate: oneOfType([number, string]),
  removable: bool,
  currency: object,
}