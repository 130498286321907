import { createContext, useContext, useMemo, useState } from "react";
import { bool, node } from "prop-types";
import { Tables } from "project-structure";
import { observer } from "mobx-react";
import { useEstimateStructure, useEstimateEditorStore } from "@tools";

const EstimateEditorSettingsContext = createContext({});

export const useEstimateEditorSettings = () => {
  const context = useContext(EstimateEditorSettingsContext);
  if (context === null) {
    throw new Error("EstimateEditorSettingsContext cannot be null, please add a context provider");
  }
  return context;
};

export const EstimateEditorSettingsProvider = observer(({
  children,
  allowInteractions,
  hasEditorPrivileges,
  hasSellerPrivileges,
  readOnly,
  isProposal,
  isLibrary,
  noTurningOff,
  ...toPass
}) => {
  const structure = useEstimateStructure();
  const editorStore = useEstimateEditorStore();
  // const commentStore = useEstimateCommentStore();
  
  const [sectionsReordered, setSectionsReordered] = useState(false);
  
  
  const { settings } = structure;
  const { visibleTables } = settings;
  const {
    profitabilityMode,
    visibilityMode,
    currentVersionLocked,
    isArchived,
  } = editorStore;
  
  const onSectionReorder = () => {
    setSectionsReordered((state) => !state);
  };
  
  const isLocked = useMemo(() => (
    currentVersionLocked && !isProposal
  ), [currentVersionLocked, isProposal]);
  
  const breakdownTableIndex = useMemo(() => (
    visibleTables?.findIndex(t => t.name === Tables.BREAKDOWN)
  ), [visibleTables])
  
  const useProfitability = useMemo(() => (
    profitabilityMode && !readOnly && hasEditorPrivileges && hasSellerPrivileges && !isProposal
  ), [profitabilityMode, readOnly, hasEditorPrivileges, hasSellerPrivileges, isProposal]);
  
  const isSellerOrClient = useMemo(() => (
    isProposal || hasSellerPrivileges
  ), [ isProposal, hasSellerPrivileges ]);
  
  const isEditorOrClient = useMemo(() => (
    isProposal || hasEditorPrivileges
  ), [ isProposal, hasEditorPrivileges ]);
  
  const readOnlyMode = useMemo(() => (
    readOnly || isLocked
  ), [readOnly, isLocked]);
  
  const allowEdition = useMemo(() => (
    !readOnlyMode && hasEditorPrivileges && !visibilityMode
  ), [readOnlyMode, hasEditorPrivileges, visibilityMode]);
  
  const blockExpansions = useMemo(() => (
    readOnlyMode && !allowInteractions && !isLocked
  ), [readOnlyMode, allowInteractions, isLocked]);
  
  const allowCommenting = useMemo(() => (
    isEditorOrClient && !readOnly && (isProposal || !isLocked)
  ), [isEditorOrClient, readOnly, isProposal, isLocked]);
  
  const showChatThread = useMemo(() => (
    isEditorOrClient && allowInteractions && !visibilityMode && (isProposal || hasEditorPrivileges)
  ), [isEditorOrClient, allowInteractions, visibilityMode, isProposal, hasEditorPrivileges]);
  
  const value = {
    readOnly: readOnlyMode,
    isProposal,
    isLibrary,
    isLocked,
    hasEditorPrivileges,
    hasSellerPrivileges,
    noTurningOff,
    ...toPass,
    
    breakdownTableIndex,
    useProfitability,
    isSellerOrClient,
    isEditorOrClient,
    allowEdition,
    blockExpansions,
    allowInteractions,
    useVisibilityCell: allowInteractions && !noTurningOff && !readOnlyMode,
    forceAllowVersionSelect: isArchived,
    
    allowCommenting,
    showChatThread,
    useInternalComments: !isProposal,
    useExternalComments: isSellerOrClient,
    
    sectionsReordered,
    onSectionReorder,
  }
  
  return <EstimateEditorSettingsContext.Provider value={value}>
    {children}
  </EstimateEditorSettingsContext.Provider>
})

EstimateEditorSettingsProvider.propTypes = {
  children: node.isRequired,
  allowInteractions: bool,
  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  readOnly: bool,
  isProposal: bool,
  isLibrary: bool,
  showPdfCover: bool,
  noTurningOff: bool,
  displayLibraryElementStatus: bool,
  allowLibraryElementStatusChange: bool,
  previewMode: bool,
}