import { observer } from "mobx-react";
import { Tables, hashCode } from "project-structure";
import { useTableExpandHandler } from "@hooks"
import {
  useEstimateStructure,
  usePresetFieldNames,
  useEstimateEditorStore,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  Section,
  ProposalSettings,
  VisibilityBreakdownSettings,
  NewSectionButton,
  TableTitle,
  MinMaxSwitch,
  BreakdownPricesSwitch,
  VisibilityButton,
} from "@tools";
import { SortableList } from "@components";
import { Grid, Collapse } from "@material-ui/core";
import useStyle from "@tools/EstimateEditor/styles/CommonTableStyles.style";
import classnames from "classnames";

export const TableBreakdown = observer(() => {
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const {
    isProposal,
    onSectionReorder,
    blockExpansions,
    showPdfCover,
    allowEdition,
    hasSellerPrivileges,
    previewMode,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const classes = useStyle();

  const { sections, settings, visibility } = structure;
  const { showBreakdown } = settings;
  const { hideBreakdown } = visibility;
  
  const className = useVisibilityModeCellClassName(hideBreakdown);

  const handleSectionReorder = (list) => {
    structure.reorderSections(list);
  }
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    !blockExpansions,
    showBreakdown,
    settings.setShowBreakdown
  );

  return (
    <Grid
      item container
      className={classnames(
      "vCon",
        showPdfCover && "pdf-mode",
        previewMode && "preview-mode",
        classes.root
      )}
    >
      <TableTitle
        disabled={visibilityMode && hideBreakdown}
        expandable={!blockExpansions}
        presetClass="breakdown"
        buttonPresetClass="preset-tableBreakdownExpandButton"
        id={hashCode(Tables.BREAKDOWN)}
        title={getFieldName("pt-breakdown")}
        onContentAction={handleOpen}
        showContent={showBreakdown}
        className="preset-page preset-noedit"
        action={(
          visibilityMode &&
          <>
            <VisibilityButton
              hidden={hideBreakdown}
              onChange={visibility.setHideBreakdown}
            />
            <VisibilityBreakdownSettings />
          </>
        )}
      >
        {allowEdition && (
          <Grid
            item
            container
            alignItems="center"
            className="w-max my-1"
          >
            <ProposalSettings noMinMax />
            { !settings.isSummaryTableUsed && <MinMaxSwitch /> }
            { hasSellerPrivileges && <BreakdownPricesSwitch /> }
          </Grid>
        )}
      </TableTitle>
      <Collapse
        in={showBreakdown}
        timeout="auto"
        className={classnames(
          showPdfCover && "pdf-mode",
          previewMode && "preview-mode",
          classes.collapse,
          className
        )}
        mountOnEnter
        unmountOnExit
        data-id={hashCode("cont")}
        data-t="breakdown"
        data-p={hashCode(Tables.BREAKDOWN)}
        { ...collapseProps }
      >
        <SortableList
          list={sections}
          group="sections"
          onListReorderStart={onSectionReorder}
          onListReorder={handleSectionReorder}
          disabled={!allowEdition}
          className={classes.tableContainerWrapper}
        >
          {sections.map((section, index) => {
            return (
              <Section
                key={section.id}
                section={section}
                index={index}
              />
            );
          })}
        </SortableList>
        {allowEdition && <NewSectionButton />}
      </Collapse>
    </Grid>
  );
});