import { useMemo, useState } from "react";
import { object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  useWorkTypePermitChecker,
  useEstimateEditorSettings,
  Estimate,
  ValueCell,
  VisibilityButton,
} from "@tools";
import { IconButton, Tooltip, useTheme } from "@material-ui/core";
import { HeaderWorkTypeActions } from "./HeaderWorkTypeActions";
import { Percent } from "@assets";

export const HeaderWorkType = observer(({
  workType,
  section,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLibrary, allowEdition } = useEstimateEditorSettings();
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { usesTwoValues } = structure;
  const { isHidden } = workType;

  const { editionGrant, isPending, hasPermit, userSeesOnlyPermittedWorkTypes } = useWorkTypePermitChecker(
    workType.id
  );
  
  const [estimateModalVisible, openEstimateModal] = useState(false);

  const editable = allowEdition;
  const visible = !structure.isWorkTypeOff(workType.id);
  
  const className = useVisibilityModeCellClassName(isHidden);
  
  const structureWorkType = useMemo(() => (
    structure.getWorkTypeById(workType.id)
  ), [workType.id]);

  return (
    <ValueCell
      key={workType.id}
      isHeader
      size="xSmall"
      wider={usesTwoValues}
      style={{
        cursor: editable && "grab",
        color:
          editable &&
          hasPermit &&
          (isPending ? theme.props.warning : theme.props.success),
      }}
      visible={visible && editionGrant}
      className={className}
    >
      <Tooltip
        title={t(`views.editor.status_${isPending ? "pending" : "done"}`)}
        disableHoverListener={!editable || !hasPermit}
      >
        <span className="preset-tableBreakdownHeader">
          {structureWorkType?.name}
        </span>
      </Tooltip>
      {
        workType.hasPercent && allowEdition &&
        <IconButton
          size="small"
          color="primary"
          className="text-sm absolute"
          onClick={() => openEstimateModal(!estimateModalVisible)}
          style={{ top: -16 }}
        >
          <Percent />
        </IconButton>
      }
      {editable && Boolean(structureWorkType) && (
        <HeaderWorkTypeActions
          workType={structureWorkType}
          sectionWorkType={workType}
          section={section}
          hasPermit={hasPermit && editionGrant}
          isPending={isPending}
          userSeesOnlyPermittedWorkTypes={userSeesOnlyPermittedWorkTypes}
          openWorkTypeEstimateDialog={openEstimateModal}
        />
      )}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={2}
          hidden={isHidden}
          onChange={workType.setHideWorkType}
        />
      }
      {estimateModalVisible && !isLibrary && (
        <Estimate
          open={true}
          workType={structureWorkType}
          section={section}
          sectionWorkType={workType}
          onClose={() => openEstimateModal(false)}
        />
      )}
    </ValueCell>
  );
});

HeaderWorkType.propTypes = {
  workType: object.isRequired,
  section: object.isRequired,
};
