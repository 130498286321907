import { useContext } from "react";
import { ActiveProjectsSocketContext } from "@client";

export const useActiveProjectsWebsocket = () => {
  const context = useContext(ActiveProjectsSocketContext);
  if (context === null) {
    throw new Error("ActiveProjectsSocketContext cannot be null, please add a context provider");
  }
  return context;
};
