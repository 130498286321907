import { APIMethod, fetchData } from "@client";

export async function removeTemplateVersionComment(
  commentId,
  templateId,
  user, // @note: uuid!!
  version
) {
  await fetchData(
    `v1/ws/template/comments/${commentId}`,
    APIMethod.DELETE,
    {
      data: {
        templateId,
        version,
        user
      }
    }
  );
}