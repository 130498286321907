import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DEFAULT_RATES } from "project-structure";
import { getProjectEditionDataQuery, checkStructureVersionQuery} from "@query";
import { COMPANY_SETTINGS, EditorSocketProvider, PROJECT_COMPANY_SETTINGS, USER_COMPANY_SETTINGS } from "@client";
import { useStores } from "@hooks";
import { parseCompanySettings, PROJECT_TYPE } from "@utils";
import { home } from "@paths";
import {
  EstimatePresetStore,
  EstimateEditorStore,
  EstimatePresetStoreProvider,
  EstimateEditorStoreProvider,
  EstimateLoadingAlert,
  EstimateEditorInactivityMonitor,
  EstimateEditorConnectionErrorAlert,
} from "@tools";
import { NewEditorVersionAlert } from "@dialogs";
import { PageCircularProgressWithLabel } from "@components";
import { ProjectEditorHandlers } from "./ProjectEditorHandlers";

export const ProjectEditor = () => {
  const {t} = useTranslation();
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const [store, setStore] = useState(null);
  const [projectHash, setProjectHash] = useState(null);
  const [presetStore, setPresetStore] = useState(null);
  const [hasError, setError] = useState(0);
  const [hasNewEditorVersion, setHasNewEditorVersion] = useState(false);
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.editor")}`;
  }, [])

  useEffect(() => {
    setupEditorStore();
    return () => {
      stateStore.resetFilesUploadProgress();
    }
  }, []);

  const loadProjectData = async () => {
    try {
      await checkStructureVersionQuery();
    } catch(e) {
      console.log("RELOAD:", e.reload);
      if(e.reload)
        setHasNewEditorVersion(true);
      else
        setError(2);
      throw e;
    }
    
    try {
      const {
        hash,
        companyLogo,
        companySettings,
        projectData,
        projectPreset,
        teamMembers,
        workTypes,
        isCreated,
      } = await getProjectEditionDataQuery(projectUuid, [
        USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid),
        USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid),
        PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid),
        PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(projectUuid),
        COMPANY_SETTINGS.RATES,
        COMPANY_SETTINGS.PRESET,
      ]);
      
      return {
        hash,
        isCreated,
        companyLogo,
        companySettings: {
          ...companySettings,
          // defaultPresetId: parseCompanySettings(companySettings.defaultPresetId),
          defaultCurrency: parseCompanySettings(companySettings.defaultCurrency),
          defaultCompanyCurrency: parseCompanySettings(companySettings.companyCurrency),
          defaultRates: companySettings.rates ? parseCompanySettings(companySettings.rates) : { ...DEFAULT_RATES },
          timeEquivalents: parseCompanySettings(companySettings.timeEquiv),
        },
        workTypes,
        estimateData: projectData,
        teamMembers,
        projectPreset,
      }
    } catch(e) {
      if(e.status === 403)
        navigate(home, { replace: true });
      else throw e;
    }
  };
  
  const reloadProjectData = async () => {
    const { hash, estimateData, isCreated, projectPreset, teamMembers, companySettings } = await loadProjectData();
    setProjectHash(hash);
    if(projectPreset)
      presetStore.setProjectPreset(projectPreset, false);
    store.setTeamMembers(teamMembers);
    store.setCompanySettings(companySettings);
    store.setCreatedStatus(isCreated);
    store.setEstimateData(estimateData, false, false);
    return { estimateData, projectPreset, teamMembers };
  };
  
  const setupEditorStore = async () => {
    try {
      const { hash, projectPreset, ...data } = await loadProjectData();
      
      setProjectHash(hash);
      setPresetStore(new EstimatePresetStore(projectPreset, data.companySettings.defaultPresetId));
      setStore(new EstimateEditorStore({
        useHistoryManager: true,
        estimateType: PROJECT_TYPE.STANDARD,
        commenterData: {
          uuid: userStore.data.uuid,
          fullname: userStore.data.fullname,
          color: userStore.data.avatarColor
        },
        ...data
      }));
    } catch (e) {
      // @todo: check if needed
      // send structure to s3 and add feedback
      // if (e.json) {
      //   if (process.env.REACT_APP_NODE_ENV === "production")
      //     await sendBrokenStructureFeedbackFileQuery(e.json);
      //   setError(1);
      //   return;
      // }

      setError(2);
    }
  };
  
  if (hasError)
    return <EstimateLoadingAlert structureError={hasError === 1} />;

  return <>
    {
      !store ? (
        <PageCircularProgressWithLabel value={0} />
      ) : (
        <EstimateEditorStoreProvider value={store}>
          <EstimatePresetStoreProvider value={presetStore}>
            <EditorSocketProvider reloadProjectData={reloadProjectData}>
              <ProjectEditorHandlers projectHash={projectHash} />
              <EstimateEditorInactivityMonitor reloadEditorData={reloadProjectData} />
              <EstimateEditorConnectionErrorAlert />
            </EditorSocketProvider>
          </EstimatePresetStoreProvider>
        </EstimateEditorStoreProvider>
      )
    }
    {
      hasNewEditorVersion &&
      <NewEditorVersionAlert />
    }
  </>
};
