import { APIMethod, fetchData } from "@client";

export async function removeProjectVersionComment(
  commentId,
  uuid,
  user, // @note: uuid!!
  version
) {
  await fetchData(
    `v1/ws/project/comments/${commentId}`,
    APIMethod.DELETE,
    {
      data: {
        uuid,
        version,
        user
      }
    }
  );
}