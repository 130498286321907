import { useMemo } from "react";
import { observer } from "mobx-react";
import { getProfitColor } from "@utils";
import { useEstimateStructure, ValueCell } from "@tools";
import { useTheme } from "@material-ui/core/styles";

export const CompanyProfitCell = observer(() => {
  const theme = useTheme();
  const structure = useEstimateStructure();
  
  const {
    displayCompanyFixedBreakdownProfit,
    displayCompanyFixedBreakdownProfitMax,
    showMaxTotalCompanyFixedBreakdownProfit,
    companyFixedBreakdownProfitability,
  } = structure;
  const { useMinMax, currencyObj, roundPrice } = structure.settings;
  
  const color = useMemo(() => (
    getProfitColor(companyFixedBreakdownProfitability, theme)
  ), [companyFixedBreakdownProfitability, theme]);
  
  return (
    <ValueCell
      wider
      widest={useMinMax}
      displayContent
      value={displayCompanyFixedBreakdownProfit}
      valueMax={displayCompanyFixedBreakdownProfitMax}
      displayMax={showMaxTotalCompanyFixedBreakdownProfit}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      showDecimals={!roundPrice}
      allowNegative
      max={10000000}
      style={{color}}
    />
  );
});