import { useCallback, useState } from "react";
import { bool, func, number, oneOfType, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Grid, IconButton } from "@material-ui/core";
import { TimelineSettingsContainer } from "../TimelineSettingsContainer";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import useStyle from "./SectionContainer.style";
import classnames from "classnames";

export const NonSectionWTContainer = (containerProps) => {
  const {t} = useTranslation();
  const classes = useStyle();
  
  const [expanded, expand] = useState(false);
  
  const handleExpand = () => expand(!expanded);
  
  const filterWorkTypes = useCallback((workType) => (
    workType.areHoursOverwritten
  ), []);
  
  return (
    <Grid item container className={ classes.container }>
      <Accordion
        square
        expanded={expanded}
        onChange={handleExpand}
        className={classnames(classes.accordion, expanded && "expanded")}
      >
        <AccordionSummary
          className={classnames(classes.title, expanded && "expanded")}
        >
          <Grid item container>
            <IconButton size="small" className="mr-2" onClick={handleExpand}>
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <h3>{t("Ungrouped work types")}</h3>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className={classnames("h-max", classes.content, expanded && "expanded")}
        >
          <TimelineSettingsContainer
            filterWorkTypes={filterWorkTypes}
            {...containerProps}
          />
        </AccordionDetails>
      </Accordion>
    </Grid>
  )
}

NonSectionWTContainer.propTypes = {
  fullSettings: bool,
  selectedWorkType: number,
  selectedResource: oneOfType([string, number]),
  setExpanded: func,
}