import { bool, number, string, func, node } from "prop-types";
import { useTranslation } from "react-i18next";
import { BREAKDOWN_TABLE_DEPTHS } from "project-structure";
import { useEstimateEditorSettings, DescriptionVariantSelector, useEstimateTableSettings } from "@tools";
import { WysiwygEditor } from "@components";
import { Collapse, Grid } from "@material-ui/core";
import useStyle from "./DescriptionRow.style";
import classnames from "classnames";

export const DescriptionRow = ({
  show,
  value,
  onValueChange,
  isLastGroupRow,
  tableDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  staticDescription,
  isSectionRow,
  className,
  showBorder,
  children,
}) => {
  const { t } = useTranslation();
  const { allowEdition, isLibrary } = useEstimateEditorSettings();
  const { sectionContainerWidth } = useEstimateTableSettings();
  const classes = useStyle(sectionContainerWidth);

  return (
    <Collapse
      in={show}
      mountOnEnter
      unmountOnExit
      classes={{
        root: "noDrag w-full",
        wrapperInner: classnames(
          "cell",
          `d${tableDepth}`,
          isLastGroupRow && "bottom",
        )
      }}
    >
      <Grid
        item
        container
        role="row"
        className={classnames(
          "cell",
          `d${tableDepth}`,
          isLastGroupRow && "bottom",
          classes.descriptionEditorContainer,
          className,
        )}
      >
        <WysiwygEditor
          readOnly={!allowEdition}
          autoFocus={!staticDescription || !value?.length}
          noEmptyHtmlStrings
          changeOnClickAway
          placeholder={t("views.editor.desc_change")}
          onChange={onValueChange}
          name="description-row"
          value={value}
          actions={children}
          direction={children ? "row" : undefined}
          alignItems={children ? "flex-start" : undefined}
          className={classnames(
            "cell",
            `d${tableDepth}`,
            classes.descriptionEditor,
            staticDescription && !isSectionRow && classes.descriptionEditorStatic,
            showBorder && classes.border,
            isSectionRow && "preset-sectionDesc",
            !isSectionRow && "preset-rowDesc"
          )}
        >
          {
            !isLibrary &&
            <DescriptionVariantSelector/>
          }
        </WysiwygEditor>
      </Grid>
    </Collapse>
  );
};

DescriptionRow.propTypes = {
  onValueChange: func.isRequired,
  show: bool,
  value: string,
  isLastGroupRow: bool,
  tableDepth: number,
  staticDescription: bool,
  isSectionRow: bool,
  showBorder: bool,
  className: string,
  children: node,
};
