import { APIMethod, fetchData } from "@client";

export async function fetchTemplateCommentsQuery(
  templateId,
  versionKey,
  userUuid=undefined
) {
  const res = await fetchData(
    `/v1/ws/template/comments/${templateId}/${versionKey}`,
    APIMethod.GET,
    {
      params: {
        user: userUuid
      }
    }
  );
  return res.results;
}
