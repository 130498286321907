import { useMemo } from "react";
import { arrayOf, number, object, oneOfType, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Tables, hashCode, SUMMARY_TABLE_DEPTHS } from "project-structure";
import { ELEMENT_TYPES, CELL_WIDTHS } from "@utils";
import { useTableExpandHandler } from "@hooks";
import {
  useEstimateStructure,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  WorkTypeSelect,
  TitleCell,
  Row,
  RowGroup,
  useEstimateTableSettings,
} from "@tools";
import { Collapse } from "@material-ui/core";
import { SummaryRowActions } from "./components/SummaryRowActions";
import { SummaryRowGroupList } from "../SummaryRowGroupList/SummaryRowGroupList";
import { ColumnFactory } from "./ColumnFactory";
import { SummaryNewChildRow } from "./SummaryNewChildRow";
import classnames from "classnames";

export const SummaryRowGroup = observer(({
  workType,
  parentIds,
}) => {
  const { t } = useTranslation();
  const structure = useEstimateStructure();
  
  const {
    isSellerOrClient,
    allowEdition,
    useProfitability,
    useVisibilityCell,
    blockExpansions,
  } = useEstimateEditorSettings();
  const {
    columns,
    useExpandCell,
  } = useEstimateTableSettings();

  const { hasTeamMembersCol } = structure.settings;

  const {
    id,
    name,
    resources,
    hasResources,
    isOpened,
    turnOff,
    hasOnlyCost,
    isHidden,
  } = workType;
  
  const className = useVisibilityModeCellClassName(isHidden);
  
  const expandable = useMemo(
    () =>
      hasTeamMembersCol &&
      ((allowEdition && !useProfitability) || hasResources),
    [hasTeamMembersCol, allowEdition, hasResources, useProfitability]
  );
  
  const opened = useMemo(
    () => hasTeamMembersCol && expandable && (isOpened || blockExpansions),
    [hasTeamMembersCol, expandable, isOpened, blockExpansions]
  );

  const isActive = useMemo(
    () => opened && hasTeamMembersCol,
    [opened, hasTeamMembersCol]
  );

  const displayCellContent = useMemo(
    () => !(opened && hasResources),
    [opened, hasResources]
  );

  const displayClientCellContent = useMemo(
    () => !hasOnlyCost && isSellerOrClient && displayCellContent,
    [hasOnlyCost, isSellerOrClient, displayCellContent]
  );

  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    expandable,
    workType.isOpened,
    workType.setOpenState
  );

  const handleTurnOffState = () => {
    workType.setTurnOffState(!turnOff);
  };

  const handleResourceReorder = (list) => workType.reorderResources(list);

  const tableDepth = SUMMARY_TABLE_DEPTHS.WORK_TYPE;

  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      tableDepth={tableDepth}
      isActive={isActive}
      presetClass="preset-accentSummary"
      isStatic={!allowEdition}
      elementId={id}
      elementType={ELEMENT_TYPES.WORK}
      className={className}
    >
      <Row
        presetClass={isActive ? "preset-activeSummary" : "preset-rowSummary"}
        useExpandCell={useExpandCell}
        expandable={expandable}
        expanded={isActive}
        onExpand={handleOpen}
        expandOnClick
        useVisibilityCell={useVisibilityCell}
        visible={!turnOff}
        onVisibilityToggle={handleTurnOffState}
        isFirstGroupRow
        isLastGroupRow={!isActive}
        tableDepth={tableDepth}
        noArrow
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          pinnedLeft={useExpandCell ? 1 : 0}
          allowOverflowDisplay
          actions={
            !useProfitability &&
            <SummaryRowActions workType={workType} opened={isOpened}/>
          }
        >
          {!allowEdition ? (
            <span
              className={classnames(
                "name",
                "preset-summaryTitleText",
                !name?.length && "semi-transparent",
                !useExpandCell && "ml-3"
              )}
            >
              {name || t("common.unnamed")}
            </span>
          ) : (
            <WorkTypeSelect
              workType={workType}
              useExpandCell={useExpandCell}
            />
          )}
        </TitleCell>
        {
          columns.map(({ name }, index) => (
            <ColumnFactory
              key={name}
              name={name}
              element={workType}
              displayCellContent={displayCellContent}
              displayClientCellContent={displayClientCellContent}
              extendPadding={!useVisibilityCell && index === columns.length-1}
            />
          ))
        }
      </Row>
      {hasTeamMembersCol && (
        <Collapse
          in={opened}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${id}_cont`)}
          data-p={[Tables.SUMMARY, id].map((id) => hashCode(id)).join("/")}
          data-t={ELEMENT_TYPES.RESOURCE}
          { ...collapseProps }
        >
          <SummaryRowGroupList
            listElements={resources}
            isLastGroupRow={!allowEdition || useProfitability}
            onReorder={handleResourceReorder}
            tableDepth={tableDepth}
            parentIds={[...parentIds, id]}
            turnOff={turnOff}
            disabled={!allowEdition}
          />
          {
            allowEdition && !useProfitability &&
            <SummaryNewChildRow workType={workType} columns={columns} />
          }
        </Collapse>
      )}
    </RowGroup>
  );
});

SummaryRowGroup.propTypes = {
  workType: object.isRequired,
  parentIds: arrayOf(oneOfType([number, string])),
};
