import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { getProfitColor } from "@utils";
import { useEstimateEditorSettings, ValueCell } from "@tools";
import { useTheme } from "@material-ui/core/styles";

export const ProfitabilityCell = observer(({
  element,
  displayCellContent,
  extendPadding,
}) => {
  const theme = useTheme();
  const { allowEdition } = useEstimateEditorSettings();
  const { companyProfitability } = element;
  
  const notApplicable = companyProfitability === null;
  
  const color = useMemo(() => (
    getProfitColor(companyProfitability, theme)
  ), [companyProfitability, theme]);
  
  const handleCompanyProfitabilityChange = (profitability) => (
    element.setCompanyProfitability(profitability)
  );

  return (
    <ValueCell
      wider
      displayContent={displayCellContent}
      value={notApplicable ? "" : companyProfitability}
      onValueChange={handleCompanyProfitabilityChange}
      editable={allowEdition}
      symbolEnd={notApplicable ? "" : "%"}
      showDecimals
      allowNegative
      max={99.99}
      style={{color}}
      inputClassName="input-profitability"
      extendPadding={extendPadding}
    />
  );
});

ProfitabilityCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  extendPadding: bool,
};
