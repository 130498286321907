import { createContext, useContext } from "react";

const EstimateStructureContext = createContext(null);
export const EstimateStructureProvider = EstimateStructureContext.Provider;

export const useEstimateStructure = () => {
  const context = useContext(EstimateStructureContext);
  if (context === null) {
    throw new Error("EstimateStructureContext cannot be null, please add a context provider");
  }
  return context;
};