import { useEffect, useMemo, useState } from "react";
import { string } from "prop-types";
import { observer } from "mobx-react";
import { useParams } from "react-router-dom";
import { onPatch } from "mobx-state-tree";
import { fetchTemplateCommentsQuery } from "@query";
import { getStructureMetadata } from "@utils";
import { useEditorWebsocket, useStores } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { PageCircularProgressWithLabel } from "@components";
import { TemplateEditorView } from "./TemplateEditorView";

export const TemplateEditorHandlers = observer(({
  commentHash
}) => {
  const { userStore, stateStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  const { templateId } = useParams();
  
  const [isLoading, setLoading] = useState(false);
  
  const { estimateLoaded, currentEstimateStructure } = editorStore;
  
  const actionListener = useMemo(
    () =>
      currentEstimateStructure &&
      onPatch(
        currentEstimateStructure,
        ({ path, value }) => {
          if(path.match(/\/historyManager\/history\/\d+/) && value?.patches)
            socket.requestStructurePatch(
              value.patches,
              getStructureMetadata(currentEstimateStructure),
            )
        }
      ),
    [currentEstimateStructure]
  );
  
  useEffect(() => () => {
    actionListener && actionListener();
  }, [currentEstimateStructure]);
  
  const onLoad = async () => {
    setLoading(true);
    
    editorStore.setCommentsVisible(true);
    
    socket?.joinTemplate(
      templateId,
      commentHash,
      editorStore.sortedVersions[ 0 ].key
    );
    
    setLoading(false);
  };
  
  useEffect(() => {
    fetchTemplateComments();
  }, [editorStore.currentEstimateStructure])
  
  useEffect(() => {
    if (estimateLoaded) onLoad();
  }, [estimateLoaded]);
  
  useEffect(() => () => {
    socket?.closeTemplate();
  }, []);
  
  const fetchTemplateComments = async () => {
    if(!editorStore.currentVersionKey || !editorStore.currentEstimateStructure || !editorStore?.commentsVisible || editorStore.currentEstimateStructure.commentsFetched)
      return;
    
    const c = await fetchTemplateCommentsQuery(templateId, editorStore.currentVersionKey, userStore.userUuid);
    editorStore.currentEstimateStructure.historyManager.withoutUndo(() => {
      editorStore.currentEstimateStructure.setComments(c);
    })
  }
  
  if (isLoading || !currentEstimateStructure)
    return <PageCircularProgressWithLabel
      value={stateStore.projectCreatorProgress}
      error={stateStore.projectCreatorProgressError}
      label={stateStore.projectCreatorProgressErrorMessage || stateStore.projectCreatorProgressStepName}
    />;
  
  return <TemplateEditorView />
});

TemplateEditorHandlers.propTypes = {
  commentHash: string.isRequired,
}
