import createTheme from "@material-ui/core/styles/createTheme";
import t from "./themes";
import { DEFAULT_FONT_NAME } from "@utils";

/*
 * theme loader
 * will use constants & specific theme colors and/or settings
 */
export const themeLoader = (n) => {
  const props = n !== "def" ? { ...t.def, ...t[n] } : t.def;

  const linkStyling = {
    textDecoration: "none !important",
    color: `${props.primary.main} !important`,
    "&:hover": {
      color: `${props.primary.lighter} !important`,
    },
  };

  return createTheme({
    palette: {
      primary: props.primary,
      secondary: {
        main: props.secondary,
      },
      background: {
        default: props.background,
      },
      success: {
        main: props.success,
      },
      warning: {
        main: props.warning,
      },
      error: props.error,
      text: props.text,
    },
    typography: {
      fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif`,
    },
    shape: {
      borderRadius: 0,
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          body: {
            margin: 0,
            color: props.text.primary,
            lineHeight: "normal",
            fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif`,
          },
          ".DraftEditor-root a": linkStyling,
          ".coloredLinks a": linkStyling,
          ".bg-primary": { backgroundColor: props.background },
          ".bg-secondary": { backgroundColor: props.backgroundSecondary },
          ".bg-primary-lighter": { backgroundColor: `${props.primary.lighter} !important` },
          ".color-primary": { color: `${props.primary.main} !important` },
          ".color-primary-lighter": {
            color: `${props.primary.lighter} !important`,
          },
          ".color-error": { color: `${props.error.main} !important` },
          ".color-error-lighter": { color: `${props.error.light} !important` },
          ".color-warning": { color: `${props.warning} !important` },
          ".text-primary": { color: `${props.text.primary} !important` },
          ".text-secondary": { color: `${props.text.secondary} !important` },
          ".text-alt": { color: `${props.text.alt} !important` },
          ".text-lighter": { color: `${props.text.lighter} !important` },
          ".border-primary": {
            borderColor: `${props.primary.main} !important`,
          },
          ".border-error": { borderColor: `${props.warning} !important` },
          ".border-light": { borderColor: `${props.border} !important` },
          ".shadow-none": { boxShadow: "none !important" },
        },
      },
      MuiBackdrop: {
        root: {
          backgroundColor: props.backdrop,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          color: props.text.alt,
        },
        colorSecondary: {
          color: props.text.primary,
          backgroundColor: props.backgroundSecondary,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.15) !important",
        },
      },
      MuiContainer: {
        root: {
          padding: 0,
          display: "flex",
          flexDirection: "column",
          flexWrap: "nowrap",
          justifyContent: "flex-start",
          // alignItems: "center",
          width: "100%",
        }
      },
      MuiButton: {
        root: {
          color: "inherit",
          width: "max-content",
          boxShadow: "none !important",
          borderRadius: 5,
          justifyContent: "center",
          padding: "0 10px",
          boxSizing: "border-box",
          height: 36,
          minWidth: 36,
          fontSize: 16,
          fontWeight: 600,
          lineHeight: "normal",
        },
        label: {
          display: "flex",
          alignItems: "center",
          height: "100%",
          justifyContent: "inherit",
          textTransform: "none",
        },
        sizeSmall: {
          height: 32,
          minWidth: 32,
          fontSize: 14,
          fontWeight: "normal",
        },
        sizeLarge: {
          height: 45,
          minWidth: 45,
          fontSize: 16,
          fontWeight: 600,
        },
        outlinedPrimary: {
          padding: "0 10px",
          backgroundColor: props.backgroundSecondary,
          borderColor: props.border,
          color: props.primary.main,
          "&hover": {
            backgroundColor: props.backgroundSecondary,
          },
        },
        outlinedSecondary: {
          padding: "0 10px",
          borderColor: props.border,
          "&hover": {
            borderColor: props.border,
          },
        },
        containedPrimary: {
          padding: "0 10px",
          color: props.text.alt,
        },
        containedSecondary: {
          padding: "0 10px",
          backgroundColor: props.background,
          color: props.text.primary,
          "&:hover": {
            backgroundColor: props.buttonSecondaryHoverColor,
          },
          "& $label": {
            opacity: 0.75,
          },
        },
        containedSizeLarge: {
          padding: "0 10px",
        },
        startIcon: { marginLeft: 0 },
        endIcon: { marginRight: 0 },
        iconSizeLarge: { "& *:first-child": { fontSize: 24 } },
      },
      MuiLabel: {
        root: {
          color: props.text,
        },
      },
      MuiIconButton: {
        root: {
          color: "inherit",
          padding: 5,
        },
      },
      MuiPopover: {
        paper: {
          backgroundColor: props.backgroundSecondary,
        },
      },
      MuiStepper: {
        root: {
          width: "100%",
          maxWidth: 920,
          padding: 0,
          backgroundColor: "inherit",
        },
      },
      MuiStep: {
        horizontal: { maxWidth: 216 },
      },
      MuiStepButton: {
        root: {
          margin: "-8px -16px",
          padding: "8px 16px",
        },
      },
      MuiStepLabel: {
        root: {
          "&:hover $label": {
            textDecoration: "underline",
            color: props.primary.main,
          },
          "&:hover .MuiStepIcon-root": {
            borderColor: props.primary.main,
          },
          "&:hover .MuiStepIcon-text": {
            fill: props.primary.main,
          },
        },
        label: {
          color: props.text.lighter,
          transition: "color .3s ease-out, text-decoration .3s ease-out",
          "&$active": {
            color: props.primary.main,
            fontWeight: 600,
          },
        },
      },
      MuiStepIcon: {
        root: {
          color: "transparent",
          border: `1px solid ${props.text.lighter}`,
          borderRadius: "50%",
          fontSize: 32,
          boxSizing: "border-box",
          transition: "border-color .3s ease-out",
        },
        active: {
          border: "none",
          "& $text": {
            fill: props.backgroundSecondary + " !important",
          },
        },
        completed: {
          border: "none",
          fontSize: 32,
          "& $text": {
            fill: props.backgroundSecondary + " !important",
          },
        },
        text: {
          fontWeight: "bold",
          fill: props.text.lighter,
          transition: "fill .3s ease-out",
        },
      },
      MuiSwitch: {
        root: {
          width: 52,
          height: 30,
          padding: 0,
          borderRadius: 20,
        },
        sizeSmall: {
          padding: 0,
          height: 16,
          width: 32,
          "& $thumb": {
            width: 12,
            height: 12,
          },
          "& $switchBase": {
            padding: 0,
          },
        },
        switchBase: {
          top: 2,
          left: 2,
          color: "#fafafa !important",
          padding: 0,
          "&$checked": {
            transform: "translateX(22px)",
            "& + $track": {
              borderRadius: 20,
              backgroundColor: props.primary.main,
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 26,
          height: 26,
          boxShadow:
            "0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1)",
        },
        track: {
          borderRadius: 26 / 2,
          backgroundColor: props.text.primary,
          opacity: 0.15,
        },
        colorPrimary: {
          "&$disabled + $track": {
            backgroundColor: props.text.primary,
            opacity: 0.15,
          }
        }
        //.MuiSwitch-colorPrimary.Mui-disabled + .MuiSwitch-track
      },
      // MuiTypography: {
      //   root: {
      //     lineHeight: "normal",
      //     "&$h1": {
      //       fontSize: 26,
      //       fontWeight: 600
      //     },
      //     "&$h2": {
      //       fontSize: 24,
      //       fontWeight: 600
      //     },
      //     "&$h3": {
      //       fontSize: 18,
      //       fontWeight: 600,
      //       lineHeight: "24px !important"
      //     },
      //     "&$h4": {
      //       fontSize: 16,
      //       fontWeight: "bold"
      //     },
      //   }
      // },
      MuiSelect: {
        root: {
          padding: "14px 16px",
        },
        icon: {
          width: "1em",
          height: "1em",
          fontSize: 24,
        },
      },
      MuiBadge: {
        badge: {
          padding: 0,
          fontSize: 10,
          width: 16,
          height: 16,
          minWidth: 16,
        },
        colorSecondary: {
          backgroundColor: props.warning,
        },
      },
      MuiAvatar: {
        root: {
          margin: 0,
          fontFamily: `'${DEFAULT_FONT_NAME}', sans-serif !important`,
          boxSizing: "border-box",
          position: "relative",
          "&:hover": {
            zIndex: 2,
          },
          overflow: "hidden",
          border: `1px solid ${props.backgroundSecondary}`,
        },
        colorDefault: {
          backgroundColor: props.avatarColor,
          color: props.text.alt,
        },
        square: {
          borderRadius: 5,
        },
      },
      MuiDialog: {
        paper: {
          backgroundColor: props.backgroundSecondary,
          borderRadius: 10,
          overflow: "hidden",
          boxShadow: props.paperShadow,
          border: "1px solid " + props.divider,
          padding: 0,
          boxSizing: "border-box",
          maxWidth: "90vw",
        },
      },
      MuiDialogTitle: {
        root: {
          display: "flex",
          flexWrap: "nowrap",
          width: "initial",
          justifyContent: "space-between",
          alignItems: "flex-start",
          boxSizing: "border-box",
          padding: "24px 24px 16px",
          borderWidth: 0,
          borderBottomWidth: 1,
          borderBottomStyle: "solid",
          borderBottomColor: props.border,
        },
      },
      MuiDialogActions: {
        root: {
          display: "flex",
          flexWrap: "nowrap",
          padding: 24,
          justifyContent: "flex-end",
          boxSizing: "border-box",
        },
      },
      MuiDialogContent: {
        root: {
          width: "100%",
          height: "100%",
          padding: 24,
          display: "flex",
          flexDirection: "column",
          boxSizing: "border-box",
          borderBottom: `1px solid ${props.text.primary}26`,
        },
      },
      MuiCheckbox: {
        root: {
          padding: 0,
          backgroundColor: props.backgroundSecondary,
          borderRadius: 5,
          minWidth: 24,
          fontSize: 22,
          minHeight: 24,
          overflow: "hidden",
          "& .MuiTouchRipple-root": {
            width: 33,
            height: 32,
            top: -5,
            left: -5,
          },
          "&$colorPrimary": {
            border: `1px solid ${props.primary.main}`,
          },
          "&$disabled": {
            border: `1px solid ${props.text.lighter}`,
          },
          "&$colorPrimary$checked, &$colorPrimary$indeterminate": {
            color: props.text.alt,
            backgroundColor: props.primary.main,
          },
          "&:hover, &$colorPrimary$checked:hover, &$colorPrimary$indeterminate:hover":
            {
              color: props.primary.main,
              backgroundColor: props.backgroundSecondary,
            },
        },
      },
      MuiFormControlLabel: {
        label: {
          "&$disabled": {
            color: props.text.lighter,
          },
        }
      },
      MuiTextField: {
        root: {
          marginBottom: 16,
        },
      },
      MuiInputBase: {
        input: {
          padding: "12px 16px",
          fontSize: 16,
          lineHeight: "1.5 !important",
          height: "max-content",
        },
        inputMultiline: {
          minHeight: "max-content"
        },
      },
      MuiOutlinedInput: {
        root: {
          borderRadius: 5,
        },
        notchedOutline: {
          borderColor: props.text.primary,
        },
        input: {
          padding: "10.5px 10px",
        },
        inputMarginDense: {
          paddingTop: "6px",
          paddingBottom: "6px",
        },
        adornedEnd: {
          paddingRight: 39,
        }
      },
      MuiInputLabel: {
        root: {
          fontSize: 16,
          lineHeight: "1.5 !important",
          transform: "translate(16px, 12px) scale(1)",
        },
        outlined: {
          transform: "translate(12px, 10px) scale(1)",
          "&$shrink": {
            transform: "translate(14px, -8px) scale(.75)",
            lineHeight: "1.33 !important",
          },
          "&$marginDense": {
            transform: "translate(12px, 6px) scale(1)",
          },
        },
      },
      MuiInputAdornment: {
        positionEnd: {
          position: "absolute",
          right: 9,
        }
      },
      MuiSvgIcon: {
        root: {
          fontSize: "inherit",
        },
      },
      MuiPickersBasePicker: {
        pickerViewLandscape: {
          width: 380,
          minWidth: 380,
          maxWidth: 380,
          minHeight: "max-content",
          marginBottom: 16,
        },
      },
      MuiPickersCalendarHeader: {
        transitionContainer: {
          "& p": {
            textAlign: "left",
            marginLeft: 16,
          },
        },
        dayLabel: {
          margin: "0 8px",
        },
      },
      MuiPickersDay: {
        day: {
          margin: "0 8px",
        },
        hidden: {
          opacity: 0.3,
        },
        daySelected: {
          color: props.text.alt,
        },
      },
      MuiFab: {
        primary: {
          color: props.text.alt,
        },
      },
      MuiToolbar: {
        root: {
          "-webkit-touch-callout": "none",
          "-webkit-user-select": "none",
          "-khtml-user-select": "none",
          "-moz-user-select": "none",
          "-ms-user-select": "none",
          userSelect: "none",
        },
      },
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
          borderRadius: 5,
          backgroundColor: props.text.primary,
          color: props.text.alt,
          // color: props.text.primary,
          // borderColor: props.border,
          // borderWidth: 1,
          // borderStyle: "solid",
          // backgroundColor: props.text.alt,
          boxShadow: "0px 4px 8px rgb(0 0 0 / 15%)",
          "& p": { margin: 0 },
          "& a": linkStyling,
        },
        arrow: {
          color: props.text.primary,
        },
        tooltipPlacementTop: {
          margin: "4px 0 !important",
        },
        tooltipPlacementBottom: {
          margin: "4px 0 !important",
        },
        tooltipPlacementLeft: {
          margin: "0 4px !important",
        },
        tooltipPlacementRight: {
          margin: "0 4px !important",
        },
      },
      MuiLinearProgress: {
        root: {
          height: 8,
          width: "100%",
          borderRadius: 5,
        },
      },
      MuiAlert: {
        root: {
          borderRadius: 5,
        },
      },
      MuiAutocomplete: {
        root: {
          marginBottom: 0,
          height: "max-content",
        },
        inputRoot: {
          '&[class*="MuiOutlinedInput-root"]': {
            padding: 6,
            "& $input": {
              padding: "4.5px 4px",
            }
          },
          '&[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"]': {
            padding: 4,
            "& $input": {
              padding: "2px 4px",
            }
          },
        },
        input: {
          padding: "4.5px 4px",
        }
      },
      MuiBottomNavigation: {
        root: {
          width: "100%",
          height: "max-content",
        },
      },
      MuiBottomNavigationAction: {
        root: {
          padding: "0 !important",
          borderBottom: "1px solid",
          textTransform: "uppercase",
          fontWeight: 600,
          fontSize: 16,
          letterSpacing: 0.53,
          "&$selected": { borderBottom: "2px solid" },
        },
        // selected: { borderBottom: '2px solid' },
        wrapper: { padding: "6px 0", pointerEvents: "none" },
        label: {
          fontSize: 16,
          "&$selected": { fontSize: 16 },
        },
      },
      MuiAccordion: {
        root: {
          width: "100%",
          overflow: "hidden",
          boxShadow: "none",
          // borderRadius: 10,
          borderWidth: 0,
          borderStyle: "solid",
          borderColor: props.border,
          "&:not(:first-child)": {
            marginTop: 2,
          },
          "&:not(:last-child)": {
            marginBottom: 2,
          },
          "&$expanded": {
            borderColor: props.primary.main,
          },
        },
        rounded: {
          borderRadius: 10,
          "&:first-child": {
            borderTopLeftRadius: undefined,
            borderTopRightRadius: undefined,
          },
          "&:last-child": {
            borderBottomLeftRadius: undefined,
            borderBottomRightRadius: undefined,
          }
        },
      },
      MuiAccordionSummary: {
        root: {
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: props.border,
          borderRadius: 10,
          "&$expanded": {
            minHeight: undefined,
            borderColor: props.primary.main,
          },
          "&$focused, &$focusVisible": {
            backgroundColor: undefined
          },
        },
        content: {
          margin: 0,
          "&$expanded": {
            margin: undefined,
          }
        }
      },
      MuiAccordionDetails: {
        root: {
          fontSize: 16,
          flexDirection: "column",
          padding: "8px",
          // backgroundColor: props.accordion.expandedContent,
        }
      },
      MuiChip: {
        root: {
          borderRadius: 5,
        }
      }
    },
    props,
  });
};
