import { useMemo, useState } from "react";
import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { uniq } from "lodash";
import { CELL_WIDTHS } from "@utils";
import {
  useEstimateStructure,
  useEstimateTableSettings,
  HeaderRow,
  TitleCell,
  ValueCell,
} from "@tools";
import { Tag } from "@components";
import { Grid } from "@material-ui/core";
import { BillingChartCell } from "./components";
import useStyle from "./BillingRow.style";
import classnames from "classnames";

export const BillingRow = observer(() => {
  const classes = useStyle();
  const structure = useEstimateStructure();
  const { timelineLength } = useEstimateTableSettings();

  const { timelineBillingScale, settings, workTypes } = structure;
  const { currencyObj } = settings;
  
  const [billingChartWorkTypes, setBillingChartWorkTypes] = useState([]);
  
  const scales = useMemo(() => (
    [...new Array(5).keys()].map(s => (
      timelineBillingScale.topValue / 4 * (4 - s)
    ))
  ), [timelineBillingScale.topValue]);
  
  const handleOpenedCharts = (open, workTypes) => {
    setBillingChartWorkTypes((state) => {
      if(open) state.push(...workTypes);
      else workTypes.forEach(wT => {
        if(state.indexOf(wT) >= 0)
          state.splice(state.indexOf(wT), 1);
      })
      return [...state];
    })
  }
  
  const uniqueBillingChartWorkTypes = useMemo(() => (
    uniq(billingChartWorkTypes)
  ), [billingChartWorkTypes])
  
  const hasOverflowingWorkTypes = useMemo(() => (
    uniqueBillingChartWorkTypes.length > 8
  ), [uniqueBillingChartWorkTypes]);
  
  return (
    <HeaderRow
      useExpandCell
      altBackground
      size="medium"
      className={"preset-paperTimeline"}
      expandCellPresetClass="preset-timelineSubHeader"
    >
      <TitleCell
        pinnedLeft={1}
        maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
        minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
        size="medium"
        className="-mt-4 pt-4"
      >
        <Grid
          item container
          alignItems="flex-end"
          direction={hasOverflowingWorkTypes ? "row" : "column" }
          alignContent={hasOverflowingWorkTypes ? "flex-start" : "flex-end"}
          justifyContent={hasOverflowingWorkTypes ? "flex-end" : "flex-start"}
          className="h-full p-2 text-transform-none"
          style={{maxWidth: 146}}
        >
          {
            Boolean(uniqueBillingChartWorkTypes?.length) &&
            workTypes
              .filter(wT => uniqueBillingChartWorkTypes.includes(wT.id))
              .map(wT => (
              <Tag
                key={wT.id}
                text={wT.name}
                color={wT.color}
                className="m-0-5"
                ellipsisOnOverflow
              />
            ))
          }
        </Grid>
        <Grid
          item container
          direction="column"
          wrap="nowrap"
          className={classnames("w-max", classes.labelContainer)}
        >
          {
            scales.map((s, i) => (
              <ValueCell
                key={`scale${i}`}
                isHeader
                className={classes.labelCell}
              >
                <Grid item container justifyContent="flex-end" className="preset-tableTimelineBilling">
                  {currencyObj.symbolStart}
                  {parsePrice(s)}
                  {currencyObj.symbolEnd}
                </Grid>
              </ValueCell>
            ))
          }
        </Grid>
      </TitleCell>
      {
        [...Array(timelineLength).keys()]
          .map(( { month, weeks }, i) => (
            <BillingChartCell
              key={`month${i}`}
              month={month}
              weeks={weeks}
              monthIndex={i}
              onOpen={handleOpenedCharts}
            />
          ))
      }
    </HeaderRow>
  );
});