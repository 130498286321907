import { useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MAX_MONTH_DELAY } from "project-structure";
import { ClearNumberInput } from "@components";
import Grid from "@material-ui/core/Grid";
import classnames from "classnames";


export const StartMonthSelector = observer(({
  element,
  disabled,
  hidden,
  gridSize,
  minStartMonth,
  // isSectionElement,
}) => {
  const { t } = useTranslation();
  // const structure = useEstimateStructure();
  
  const { timelineStartMonth, timelineStartWeek } = element;
  
  const handleStartMonthChange = (value) => {
    element.setTimelineStartMonth(value-1);
  }
  const handleStartWeekChange = (value) => {
    element.setTimelineStartWeek(timelineStartMonth*4 + value-1);
  }
  
  const valueMonth = useMemo(() => (
    // (timelineStartMonth || 0) + 1
    (timelineStartMonth || 0) + 1
  ), [timelineStartMonth])
  const valueWeek = useMemo(() => (
    // (timelineStartMonth || 0) + 1
    (timelineStartWeek || 0)%4 + 1
  ), [timelineStartMonth])
  
  return (
    <Grid
      item container
      wrap="nowrap"
      alignItems="center"
      className={ classnames("pl-4", hidden && "invisible") }
      xs={ gridSize }
    >
      <ClearNumberInput
        min={ minStartMonth }
        max={ MAX_MONTH_DELAY }
        value={ valueMonth }
        addInitialBorder
        fullWidth
        onChange={ handleStartMonthChange }
        disabled={ disabled }
      />
      <span className={ classnames("mx-2", disabled && "semi-transparent") }>
        { t("time.month").toLowerCase() }
      </span>
      <ClearNumberInput
        min={ 1 }
        max={ 4 }
        value={ valueWeek }
        addInitialBorder
        fullWidth
        onChange={ handleStartWeekChange }
        disabled={ disabled }
      />
      <span className={ classnames("ml-2", disabled && "semi-transparent") }>
        { t("time.week").toLowerCase() }
      </span>
    </Grid>
  )
})

StartMonthSelector.propTypes = {
  element: object.isRequired,
  minStartMonth: number.isRequired,
  disabled: bool,
  gridSize: number,
  isSectionElement: bool,
}