import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateEditorSettings,
  usePresetFieldNames,
  useEstimateStructure,
  ValueCell,
} from "@tools";

export const ProfitCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const structure = useEstimateStructure();
  const { useMinMax } = structure.settings;
  
  return (
    <ValueCell
      wider
      widest={useMinMax}
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
      extendPadding={extendPadding}
    >
      <span>
        {getFieldName("pt-profit")}
      </span>
    </ValueCell>
  )
})

ProfitCell.propTypes = {
  extendPadding: bool,
}