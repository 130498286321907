import { memo } from "react";
import { bool, number, object, string } from "prop-types";
import { Grid, Tooltip } from "@material-ui/core";
import classnames from "classnames";
import useStyle from "./BillingChartBar.style";
import { BillingChartBarInnerTooltipContent } from "./BillingChartBarInnerTooltipContent";

export const BillingChartBarInner = memo(({
  name,
  value,
  hours,
  height,
  color,
  currencyObj,
  roundPrice,
  timeModifier,
}) => {
  
  const classes = useStyle();
  return (
    <Tooltip
      placement="top"
      title={(
        <BillingChartBarInnerTooltipContent
          name={name}
          value={value}
          hours={hours}
          currencyObj={currencyObj}
          roundPrice={roundPrice}
          timeModifier={timeModifier}
        />
      )}
    >
      <Grid
        item container
        direction="column"
        alignItems="center"
        justifyContent="center"
        wrap="nowrap"
        className={classnames("w-full text-xs", value && "pt-0-5", classes.blockPart)}
        style={{ backgroundColor: color, height: height }}
      >
        {
          height >= 19 &&
          <span className={classes.name}>{name}</span>
        }
        {/*{*/}
        {/*  height > 19 &&*/}
        {/*  <span>*/}
        {/*    {currencyObj.symbolStart || ""}*/}
        {/*    {parsePrice(roundFloat(value, roundPrice ? 3 : 0, !roundPrice))}*/}
        {/*    {currencyObj.symbolEnd || ""}*/}
        {/*  </span>*/}
        {/*}*/}
      </Grid>
    </Tooltip>
  );
});

BillingChartBarInner.propTypes = {
  name: string.isRequired,
  value: number.isRequired,
  hours: number.isRequired,
  height: number.isRequired,
  color: string,
  currencyObj: object,
  roundPrice: bool,
  timeModifier: number,
};