import { useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEstimateStructure } from "@tools";
import { ClearButton, Dialog } from "@components";
import { Grid } from "@material-ui/core";
import { LevelCheckbox } from "./components/LevelCheckbox";
import { Settings } from "@assets";

export const VisibilityBreakdownSettings = observer(() => {
  
  const {t} = useTranslation();
  const { visibility, settings } = useEstimateStructure();
  
  const [visible, show] = useState(false);
  
  const { viewLevel, valueLevel } = visibility;
  const { viewLevel: maxViewLevel, valueLevel: maxValueLevel } = settings;
  
  const handlePopup = () => show(!visible);
  
  const handleVisibilityLevel = (level) => {
    visibility.setValueLevel(level === valueLevel && level === 0 ? -1 : level);
  }
  
  return (
    <>
      <ClearButton
        icon={<Settings />}
        onClick={handlePopup}
        color="primary"
      >
        {t("views.editor.visibility.breakdown_settings")}
      </ClearButton>
      <Dialog
        open={visible}
        title={t("views.editor.visibility.breakdown_settings")}
        onClose={handlePopup}
      >
        <Grid item container spacing={2}>
          <Grid item container xs={6}>
            <span className="mb-3 text-lg font-bold">
              {t("views.editor.dialogs.settings.level")}
            </span>
            {
              [0,1,2,3].map((level) => (
                <LevelCheckbox
                  key={`level${level}`}
                  onCheck={visibility.setViewLevel}
                  level={level}
                  checked={viewLevel >= level}
                  disabled={level > maxViewLevel}
                />
              ))
            }
          </Grid>
          <Grid item container xs={6}>
            <span className="mb-3 text-lg font-bold">
              {t("views.editor.dialogs.settings.values")}
            </span>
            {
              [0,1,2,3].map((level) => (
                <LevelCheckbox
                  key={`level${level}`}
                  onCheck={handleVisibilityLevel}
                  level={level}
                  checked={valueLevel >= level}
                  disabled={level > maxValueLevel || level > viewLevel}
                />
              ))
            }
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
});