import { bool, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useEstimateStructure, ValueCell } from "@tools";
import { Grid, IconButton } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import classNames from "classnames";
import useStyle from "./BudgetTrackingHeaderWorkType.style";

export const BudgetTrackingHeaderWorkType = observer(({ workType }) => {

  const structure = useEstimateStructure();
  const { useMinMax } = structure.settings;
  const structureWorkType = structure.getWorkTypeById(workType.id);
  const classes = useStyle();

  const visible = !workType.turnOff;
  const { t } = useTranslation();

  return (
    <ValueCell
      key={workType.id}
      isHeader
      wider={useMinMax}
      className={classNames(
        "p-0",
        classes.cellContainer,
        structureWorkType.btOpened && classes.cellExpanded
      )}
      visible={visible}
    >
      <Grid item container direction="column">
        <span className="preset-timelineHeader">{structureWorkType.name}</span>
        {structureWorkType.btOpened && (
          <Grid
            item
            container
            wrap="nowrap"
            justifyContent="center"
            alignItems="center"
          >
            {["views.bt.estimate", "views.bt.spent", "views.bt.diffh", "views.bt.diff"].map((week) => (
              <span
                key={week}
                className={classNames(
                  "preset-timelineHeader my-1",
                  classes.weekCell
                )}
              >
                {t(week)}
              </span>
            ))}
          </Grid>
        )}
      </Grid>
      <IconButton
        size="small"
        onClick={structureWorkType.setBtOpen}
        className={classNames(
          "noDrag opaque absolute left",
          classes.button,
          !structureWorkType.btOpened && "cellHoverIcon",
          structureWorkType.btOpened && classes.buttonOpened
        )}
        color="primary"
      >
        <ExpandMore
          className={classNames(
            "expand transition -rotate-90",
            structureWorkType.btOpened && "rotate-90"
          )}
        />
      </IconButton>
    </ValueCell>
  );
});

BudgetTrackingHeaderWorkType.propTypes = {
  workType: object.isRequired,
  section: object.isRequired,
  allowEdition: bool,
  isLibrary: bool,
  isSellerOrClient: bool,
};
