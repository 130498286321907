import { bool, func } from "prop-types";
import { observer } from "mobx-react";
import { APP_BAR_HEIGHTS } from "@styles/themes";
import {
  EditorContainer,
  EstimateEditorSettingsProvider,
  PdfEditorContainer,
  ProposalStepper,
} from "@tools";
import { EditorContent, PdfEstimateContent, ProposalCustomizationToolbar } from "./index";

export const EditorRoot = observer(({
  hasEditorPrivileges,
  hasSellerPrivileges,
  readOnly,
  forceAllowVersionSelect,
  isProposal,
  allowInteractions,
  noTurningOff,
  showStepper,
  showPdfCover,
  showPresetToolbar,
  showUserMovement,
  onCommentMove,
}) => (
  <EstimateEditorSettingsProvider
    hasEditorPrivileges={ hasEditorPrivileges }
    hasSellerPrivileges={ hasSellerPrivileges }
    readOnly={ readOnly }
    showPdfCover={ showPdfCover }
    isProposal={ isProposal }
    allowInteractions={ allowInteractions }
    noTurningOff={ noTurningOff }
    forceAllowVersionSelect={ forceAllowVersionSelect }
  >
    { showStepper && <ProposalStepper/> }
    { showPresetToolbar && <ProposalCustomizationToolbar/> }
    {
      showPdfCover
        ? (
          <PdfEditorContainer
            offsetTop={ hasSellerPrivileges ? APP_BAR_HEIGHTS.LARGE : 0 }
            noShareActions={ !hasSellerPrivileges }
            showPageImages={ !hasSellerPrivileges }
            noThumbnailListRescale
            proposalSharing
            noThumbButton
            noFileAppends
          >
            <PdfEstimateContent/>
          </PdfEditorContainer>
        )
        : (
          <EditorContainer showUserMovement={ showUserMovement }>
            <EditorContent onCommentMove={onCommentMove} />
          </EditorContainer>
        )
    }
  </EstimateEditorSettingsProvider>
));

EditorRoot.propTypes = {

  hasEditorPrivileges: bool,
  hasSellerPrivileges: bool,
  readOnly: bool,
  isProposal: bool,
  allowInteractions: bool,
  useRecorderFriendlyPdf: bool,
  forceAllowVersionSelect: bool,
  noTurningOff: bool,
  
  showStepper: bool,
  showPdfCover: bool,
  showPresetToolbar: bool,
  showUserMovement: bool,
  
  onCommentMove: func,
};
