import { useEffect, useState } from "react";
import { bool, func, number } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { zipStructure } from "project-structure";
import {
  createNewProjectQuery,
  getWorkTypesQuery,
  getCompanySettingsQuery,
  getTemplateQuery, checkTemplateCommentsQuery,
} from "@query";
import { COMPANY_SETTINGS } from "@client";
import { TemplatesDataProvider, useActiveProjectStore, useProjectCreatorStore, useStores } from "@hooks";
import { editor } from "@paths";
import { DEFAULT_WORK_TYPES, TEMPLATE_TYPE } from "@utils";
import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  ControlledTextField,
  InfoLabel, Alert, ControlledCheckbox,
} from "@components";
import { TemplatesDialog } from "@dialogs";
import { XlsImporter } from "@tools";
import { TemplateSelector } from "./components";
import { CircularProgress, Divider, Grid, useTheme } from "@material-ui/core";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { pick } from "lodash";

const newProjectFormSchema = (t, isStandard) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t("errors.project.name.required"))
      .min(4, t("errors.project.name.min"))
      .max(64, t("errors.project.name.max")),
    description: yup.string(),
    workTypes: isStandard
      ? yup
          .array()
          .of(yup.mixed())
          .min(1, t("errors.project.workType.required"))
          .required(t("errors.project.workType.required"))
          .default([])
      : yup.array().of(yup.number()).default([]),
    copyComments: yup.boolean(),
  });

export const NewProjectDialog = observer(({
  open,
  onClose,
  useTemplatesDialog,
  defaultTemplateId: remoteDefaultTemplateId
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userStore } = useStores();
  const creator = useProjectCreatorStore();
  const activeProjects = useActiveProjectStore(true);
  const theme = useTheme();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    resolver: yupResolver(
      newProjectFormSchema(t, true) //, projectType === "standard")
    ),
    defaultValues: {
      name: "",
      description: "",
      workTypes: DEFAULT_WORK_TYPES,
      copyComments: false,
    },
  });

  const [defaultTemplateId, setDefaultTemplateId] = useState(remoteDefaultTemplateId);
  const [workTypesLoading, setWorkTypesLoading] = useState(false);
  const [templatesDialogOpened, openTemplatesDialog] = useState(false);
  const [importDialogOpened, openImportDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  
  const [importTemplate, setImportTemplate] = useState(false);

  const { isMiniUser, workspaceUuid, userUuid, companyId } = userStore;
  const {
    hasXlsFile,
    status,
    workTypes: availableWorkTypes,
    xlsProjectStructure,
    template,
    projectWorkTypes,
  } = creator;

  useEffect(() => {
    setWorkTypesLoading(true);
    (async () => {
      const defaultTemplateId = useTemplatesDialog
        ? await getCompanySettingsQuery(COMPANY_SETTINGS.TEMPLATE)
        : remoteDefaultTemplateId;
      
      let workTypes = creator.workTypes;
      if (!workTypes.length) {
        workTypes = activeProjects?.workTypes || await getWorkTypesQuery();
        creator.setWorkTypes(workTypes);
      }
      
      let wTSet = Boolean(projectWorkTypes?.length);
      if(defaultTemplateId && !creator.template) {
        const hasComments = checkTemplateCommentsQuery(defaultTemplateId);
        
        setDefaultTemplateId(defaultTemplateId);
        const template = await getTemplateQuery(defaultTemplateId);
        if(template)
          creator.setTemplate({
            id: defaultTemplateId,
            hasComments,
            ...template
          }, template.company);
        
        if(template.metadata) {
          const wT = JSON.parse(template.metadata)?.workTypes
            ?.filter(mWT => workTypes.find(wT => wT.id === mWT.id))
            ?.map(mWT => mWT.id);
          if(wT?.length) {
            creator.setProjectWorkTypes(wT);
            wTSet = true;
          }
        }
      }
      
      if(!wTSet)
        creator.setProjectWorkTypes(DEFAULT_WORK_TYPES.filter(wId => workTypes.find(wT => wT.id === wId)))
      
      setWorkTypesLoading(false);
    })();

    return () => {
      creator.clearProjectData();
    };
  }, []);
  
  useEffect(() => {
    setValue("workTypes", projectWorkTypes);
  }, [projectWorkTypes])

  const handleWorkTypesChange = (formChange) => (workTypes, newWorkType, removedWorkType) => {
    formChange(workTypes);
    creator.setProjectWorkTypes(workTypes);
    if(removedWorkType && typeof removedWorkType === "string") {
      creator.removeWorkType(removedWorkType);
    }
  };

  const addCustomWorkType = (name) => {
    const newWT = {
      id: new Date().getTime().toString(),
      name,
      custom: true,
      backgroundColor: theme.palette.primary.main,
    };
    creator.addWorkType(newWT);
    return newWT;
  };

  const saveProject = async ({ name, description, workTypes, copyComments }) => {
    try {
      const {
        uuid,
      } = await createNewProjectQuery(
        userUuid,
        workspaceUuid,
        companyId,
        availableWorkTypes
          .filter(wT => workTypes.includes(wT.id))
          .map(wT => pick(wT, ["id", "name", "backgroundColor"])),
        name,
        description,
        status,
        template?.template?.MLP?.id
          || template?.template?.MVP?.id
          || template?.template?.MMP?.id
          || template?.id
          || undefined,
        xlsProjectStructure ? zipStructure(JSON.stringify(xlsProjectStructure)) : undefined,
        importTemplate,
        copyComments,
      );
      
      navigate(editor(uuid))
      creator.clearProjectData();
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error)
        setErrorMessage(err.response.data.error);
      else if (err.message) setErrorMessage(err.message);
    }
  };

  const handleButtonClick = () => {
    useTemplatesDialog ? handleTemplatesDialogOpen() : onClose();
  };


  const handleTemplatesDialogOpen = () => {
    openTemplatesDialog(!templatesDialogOpened);
  };

  const handleTemplatesClose = (type) => {
    if (type === TEMPLATE_TYPE.XLS) openImportDialog(true);
    openTemplatesDialog(false);
  };

  return (
    <Dialog
      id="newProject"
      open={open}
      onClose={onClose}
      actionsClass="newProjectForm"
      containerId="newProjectForm"
      containerClass="newProjectForm py-3"
      actionsAlignment={hasXlsFile ? "spaceBetween" : "end"}
      width={600}
      title={t("views.new_project.title")}
      actions={
        <>
          {hasXlsFile && (
            <Checkbox
              name="importTemplate"
              checked={importTemplate}
              onChange={setImportTemplate}
              label={t("views.new_project.import_template")}
            />
          )}
          <Button
            variant="contained"
            onClick={handleSubmit(saveProject)}
            endIcon={
              isSubmitting ? (
                <CircularProgress
                  aria-label="progress indicator"
                  size={24}
                  color="inherit"
                />
              ) : undefined
            }
          >
            {t("views.new_project.title_short")}
          </Button>
        </>
      }
    >
      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
      >
        <InfoLabel spacing={8} label="views.new_project.name" />
        <ControlledTextField
          control={control}
          name="name"
          placeholder={t("views.new_project.enter_name")}
        />

        <InfoLabel spacing={8} label="views.new_project.desc" />
        <ControlledTextField
          control={control}
          name="description"
          multiline
          placeholder={t("views.new_project.enter_desc")}
          minRows={2}
        />

        <InfoLabel spacing={8} label="common.template" />
        <TemplateSelector onClick={handleButtonClick} />
  
        <InfoLabel
          label="common.work_type"
          tooltipText="views.new_project.work_type_desc"
        />
        <Controller
          name="workTypes"
          control={control}
          render={({
            field: { onChange, name },
            fieldState: { error },
          }) => (
            <Autocomplete
              id="workTypeSelect"
              value={projectWorkTypes}
              options={availableWorkTypes}
              fullWidth
              multiple
              idKey="id"
              labelKey="name"
              onChange={handleWorkTypesChange(onChange)}
              onOptionCreate={addCustomWorkType}
              name={name}
              dataLoading={workTypesLoading}
              error={!!error}
              helperText={error?.message}
              inputProps={{ className: "mb-0" }}
              inputPlaceholder={t("views.new_project.enter_system")}
              getOptionDisabled={() => projectWorkTypes.length >= 10}
            />
          )}
        />
        {
          template?.hasComments &&
          <>
            <Divider className="my-0 -mx-6" />
            <Grid item container xs={12} className="my-4">
              <InfoLabel label="forms.template.comments" />
              
              <ControlledCheckbox
                name="copyComments"
                control={control}
                label={t("forms.template.comments_yes")}
              />
            </Grid>
          </>
        }
      </Grid>
      <Alert
        isOpen={Boolean(errorMessage)}
        title={errorMessage}
        acceptText={t("common.accept")}
        onAccept={() => setErrorMessage()}
      />

      {useTemplatesDialog && !isMiniUser && (
        <TemplatesDataProvider limit={10}>
          <TemplatesDialog
            open={templatesDialogOpened}
            onClose={handleTemplatesClose}
            defaultTemplateId={defaultTemplateId}
          />
        </TemplatesDataProvider>
      )}
      {importDialogOpened && (
        <XlsImporter
          modalOpened={importDialogOpened}
          onClose={() => openImportDialog(false)}
        />
      )}
    </Dialog>
  );
});

NewProjectDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  useTemplatesDialog: bool,
  defaultTemplateId: number,
};
