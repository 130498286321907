import { APIMethod, fetchData } from "@client";

export async function createProjectTypeQuery(
  name,
  color,
  fontColor,
) {
  const res = await fetchData(
    "/v1/labels",
    APIMethod.POST,
    {
      data: {
        name,
        color,
        font: fontColor
      }
    }
  );
  return res.results?.id;
}