import { bool } from "prop-types";
import { useMemo } from "react";
import { observer } from "mobx-react";
import { getProfitColor } from "@utils";
import { useEstimateStructure } from "@tools";
import { TotalContainer } from "../components";
import { useTheme } from "@material-ui/core/styles";

export const TotalProfit = observer(({ showDivider }) => {
  const theme = useTheme();
  const structure = useEstimateStructure();
  
  const {
    settings,
    usesTwoValues,
    displayTotalCompanyProfit,
    displayTotalCompanyProfitMax,
    totalCompanyProfitability,
  } = structure;
  
  const { currencyObj, useMinMax, roundHours } = settings;
  
  const showMaxValue = usesTwoValues && displayTotalCompanyProfit !== displayTotalCompanyProfitMax;
  
  const color = useMemo(() => (
    getProfitColor(totalCompanyProfitability, theme)
  ), [totalCompanyProfitability, theme]);
  
  const handleProfitChange = (profit, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    structure.setTotalCompanyProfit(profit, key);
  };
  
  return (
    <TotalContainer
      totalName="Profit"
      min={displayTotalCompanyProfit}
      max={displayTotalCompanyProfitMax}
      showMaxValue={showMaxValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      style={{color}}
      editable
      onValueChange={handleProfitChange}
      maxValue={10000000}
      showDecimals={!roundHours}
      allowNegative
      showDivider={showDivider}
    />
  );
})

TotalProfit.propTypes = {
  showDivider: bool
}