import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Outlet, useLocation } from "react-router-dom";
import {  TooltipIcon } from "@components";
import Grid from "@material-ui/core/Grid";

export const Project = observer(() => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  
  const current = useMemo(() => (
    location.pathname
      .split("/")
      .filter((x) => x)
      .slice(-1)
  ), [location.pathname]);
  
  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <Grid
        item container
        alignItems="center"
        className="mb-6"
      >
        <h2>{ t(`views.settings.menu_full.${ current }`) }</h2>
        {
          i18n.exists(`views.settings.info.${ current }`) &&
          <TooltipIcon
            color="primary"
            className="ml-2"
            placement="right"
          >
            { t(`views.settings.info.${ current }`) }
          </TooltipIcon>
        }
      </Grid>
      
      <Outlet/>
    </Grid>
  );
});
