import { useCallback, useMemo, useState } from "react";
import { bool, string, func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { changeProjectStatusQuery } from "@query";
import { isProjectObserver } from "@utils";
import { useActiveProjectStore, useActiveProjectsWebsocket, useStores } from "@hooks";
import { Alert, Button, SortableList, StatusBullet, TextClearInput } from "@components";
import { Grid, CircularProgress } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { Card } from "../ProjectCard/Card";
import useStyle from "./Column.style";

export const Column = observer(({
  code,
  name,
  color,
  isLoading,
  onNewProjectButtonClick,
  onNameChange
}) => {
  const { t } = useTranslation();
  const activeProjects = useActiveProjectStore();
  const socket = useActiveProjectsWebsocket();
  const { userStore } = useStores();
  const classes = useStyle(isMobile);

  const [titleEditorFocused, focusTitleEditor] = useState(false);
  const [moveAlertVisible, showMoveAlert] = useState(false);
  const [editedTitle, setEditedTitle] = useState(
    name || t(`views.active.columns.${code}`)
  );

  const { projects } = activeProjects;
  
  const { userId, isAdmin } = userStore;

  const columnProjects = useMemo(
    () =>
      projects
        .filter((p) => p.status === code)
        .sort((p1, p2) => (p1.order > p2.order ? 1 : -1)),
    [projects]
  );
  
  const handleClick = () => onNewProjectButtonClick(code);
  
  const handleDragEnd = () => {
    activeProjects.isDragging = true;
    setTimeout(() => {
      activeProjects.isDragging = false;
    }, 100)
  }
  
  const handleReorder = (list, projectUuid, isRemoved) => {
    if(isRemoved) return;
    const pr = columnProjects.find(({uuid}) => uuid === projectUuid);
    if(!pr) return;
    
    const newOrder = list.findIndex(({id}) => id === projectUuid)+1;
    if(newOrder === pr.order)
      return;
    
    onMoveOrReorder(projectUuid, code, newOrder, code, pr.order);
  }
  const handleMove = (e) => {
    if(!e.item.dataset.id) return;
    onMoveOrReorder(e.item.dataset.id, e.to.id, e.newIndex+1, e.from.id, e.oldIndex+1);
  }
  
  const onMoveOrReorder = async (projectUuid, status, order, sourceStatus, sourceOrder) => {
    
    if(status === sourceStatus && order === sourceOrder) return;
    
    const project = projects.find(({uuid}) => uuid === projectUuid);
    const isObserver = project.users ? isProjectObserver(userId, project.users, isAdmin) : isAdmin;
    if(!isObserver) {
      showMoveAlert(true);
      return;
    }
    
    activeProjects.editLocalProjectData(projectUuid, "status", {
      status,
      order,
      sourceStatus,
      sourceOrder
    });
    try {
      await changeProjectStatusQuery(projectUuid, status, order);
      socket?.requestProjectStatusChange(projectUuid, status, order);
    } catch (err) {
      activeProjects.editLocalProjectData(projectUuid, "status", {
        status: sourceStatus,
        order: sourceOrder,
        sourceStatus: status,
        sourceOrder: order
      });
      // setAlertText(err.response.data.error);
    }
  }
  
  const handleTitleChange = useCallback(() => {
    focusTitleEditor(false);
    setEditedTitle(editedTitle.trim());
    onNameChange(code, editedTitle.trim());
  }, [code, editedTitle]);

  return (
    <Grid
      xs={4}
      item
      container
      aria-label="project-column"
      className={classes.root}
      direction="column"
      wrap="nowrap"
      alignItems="center"
      alignContent="center"
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        justifyContent="space-between"
        className={classes.title}
      >
        <Grid
          item container
          alignItems="center"
          wrap="nowrap"
        >
          <StatusBullet
            size="sm"
            style={{ backgroundColor: color }}
          />
          <TextClearInput
            onFocus={() => focusTitleEditor(true)}
            onAccept={handleTitleChange}
            onChange={setEditedTitle}
            value={editedTitle}
            confirmOnEnter
            acceptOnClickAway
            focused={titleEditorFocused}
            className={classes.input}
            maxLength={36}
          />
        </Grid>
      </Grid>
      {isLoading ? (
        <CircularProgress
          aria-label="progress indicator"
          className={classes.spinner}
        />
      ) : (
        <>
          <SortableList
            list={columnProjects}
            group="projects"
            path={code}
            itemIdKey="uuid"
            className={classes.droppable}
            onListReorder={handleReorder}
            onListReorderEnd={handleDragEnd}
            onListAdd={handleMove}
            displayAsGrid
            forceFallback
          >
            {columnProjects &&
              columnProjects.map(({ uuid, ...projectData }, i) => (
                <Card
                  key={uuid}
                  projectUuid={uuid}
                  projectData={projectData}
                  index={i}
                />
              ))}
          </SortableList>
          <Alert
            isOpen={moveAlertVisible}
            title={t("errors.project.status.title")}
            acceptText={t("common.close")}
            onAccept={() => showMoveAlert(false)}
          >
            {t("errors.project.status.text")}
          </Alert>
          <Grid
            item container
            className={classes.buttonContainer}
          >
            <Button
              size="small"
              color="secondary"
              onClick={handleClick}
              className="transparent-2"
              icon={<Add className="text-md" />}
            >
              {t("common.new")}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
  );
});

Column.propTypes = {
  code: string.isRequired,
  color: string.isRequired,
  name: string,
  isLoading: bool,
  onNewProjectButtonClick: func.isRequired,
  onNameChange: func.isRequired,
};
