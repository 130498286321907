import { useContext } from "react";
import { AppStoreContext } from "@stores";

export const useStores = () => {
  const context = useContext(AppStoreContext);
  if (context === null) {
    throw new Error("AppStoreContext cannot be null, please add a context provider");
  }
  return context;
};
