import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";
import { CURRENT_STRUCTURE_VERSION, CURRENT_STRUCTURE_BUILD } from "project-structure";

export async function checkStructureVersionQuery() {
  const res = await fetchData("/api/project/version", APIMethod.GET, {
    noAuth: true,
    baseURL: ACCESS_DOMAINS.editor,
    mapRequestKeysToSnakeCase: false,
  });
  
  if(res.version !== CURRENT_STRUCTURE_VERSION || res.build !== CURRENT_STRUCTURE_BUILD)
    throw {
      error: "Invalid editor version",
      reload: true,
    }
}
