import { string } from "prop-types";
import { observer } from "mobx-react";
import { CELL_WIDTHS, TABLE_DRAGGABLE_CONTAINER } from "@utils";
import {
  usePresetFieldNames,
  useEstimateStructure,
  useEstimateEditorSettings,
  useEstimateTableSettings,
  HeaderRow,
  TitleCell,
} from "@tools";
import { SortableList } from "@components";
import Grid from "@material-ui/core/Grid";
import { HeaderColumnFactory } from "./HeaderColumnFactory";

export const TableHeader = observer(({ tableIdentifier }) => {
  const {
    allowEdition,
    isProposal,
    useProfitability,
    useVisibilityCell,
  } = useEstimateEditorSettings();
  const { columns, useExpandCell } = useEstimateTableSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  
  const structure = useEstimateStructure();
  const { settings, allWorkTypesExpanded } = structure;
  
  const handleOpenAction = () => {
    structure.expandAllWorkTypes();
  };

  const handleColumnReorder = (list) => {
    settings.reorderSummaryColumns(list);
  };

  return (
    <HeaderRow
      expandable
      useExpandCell={useExpandCell}
      hideVisibilitySwitch
      useVisibilityCell={useVisibilityCell}
      expanded={allWorkTypesExpanded}
      onExpand={handleOpenAction}
      id={tableIdentifier}
      className="preset-paper"
      altBackground
      expandCellPresetClass="preset-tableSummaryHeader"
    >
      <TitleCell
        minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
        pinnedLeft={useExpandCell ? 1 : 0}
      >
        <span className="preset-tableSummaryHeader pt-work">
          {getFieldName("pt-work")}
        </span>
      </TitleCell>
      <Grid
        className={TABLE_DRAGGABLE_CONTAINER}
        item
        container
        wrap="nowrap"
        alignItems="center"
        style={{ width: "initial" }}
      >
        <SortableList
          list={columns}
          group="summary-columns"
          onListReorder={handleColumnReorder}
          displayAsFlexbox
          disabled={!allowEdition || useProfitability}
          itemIdKey="name"
        >
          {columns.map(({ name }, index) => (
            <HeaderColumnFactory
              key={name}
              columnName={name}
              extendPadding={!useVisibilityCell && index === columns.length-1}
            />
          ))}
        </SortableList>
      </Grid>
    </HeaderRow>
  );
});

TableHeader.propTypes = {
  tableIdentifier: string,
};
