import { useRef, useState } from "react";
import { object } from "prop-types";
import { useEstimateEditorSettings, useEstimateTableSettings, WorkTypeManager } from "@tools";
import { AddButton } from "@components";

export const HeaderWorkTypeEditor = ({
  section,
}) => {
  const { isLibrary } = useEstimateEditorSettings();
  const { visibleWorkTypes } = useEstimateTableSettings();
  
  const [managerVisible, showManager] = useState(false);
  
  const anchor = useRef(null);
  
  return <>
    <AddButton
      onClick={() => showManager(!managerVisible)}
      ref={anchor}
      tabIndex={-1}
      name="Manage breakdown work types"
      className="noDrag mr-3"
    />
    {managerVisible && (
      <WorkTypeManager
        open={managerVisible}
        onClose={() => showManager(false)}
        anchorEl={anchor?.current}
        section={section}
        useLibraryManagementStyle={isLibrary}
        visibleWorkTypes={visibleWorkTypes}
      />
    )}
  </>
}

HeaderWorkTypeEditor.propTypes = {
  section: object,
}