import { APIMethod, fetchData } from "@client";

export async function updateProjectTypeQuery(
  id,
  name,
  color,
  fontColor,
) {
  const res = await fetchData(
    `/v1/labels/${id}`,
    APIMethod.PUT,
    {
      data: {
        name,
        color,
        font: fontColor
      }
    }
  );
  return res.results;
}