import { useMemo } from "react";
import { useStores } from "@hooks";
import { isProjectObserver, isProjectOwner } from "@utils";

export const useCheckProjectAccess = ( projectUsers = undefined ) => {
  const { userStore } = useStores();
  const {
    userId,
    userUuid,
    isAdmin,
    isModerator,
    isBusinessUser,
    isMiniUser
  } = userStore;

  const isOwner = useMemo(
    () => isProjectOwner(userId, projectUsers, isAdmin),
    [isAdmin, userId, projectUsers]
  );

  const isObserver = useMemo(
    () => isProjectObserver(userId, projectUsers, isAdmin),
    [isAdmin, userId, projectUsers]
  );
  
  return {
    userUuid,
    userId,
    isOwner,
    isObserver,

    isAdmin,
    isModerator,
    isMiniUser,
    isBusinessUser,
  };
};
