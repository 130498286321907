import { observer } from "mobx-react";
import { useEstimateStructure } from "@tools";
import { SortableList } from "@components";
import { TotalsFactory } from "./components";
import { useEstimateEditorSettings } from "@tools";
import {
  TotalCompanyCost,
  TotalCost,
  TotalProfit,
  TotalProfitability
} from "./totalsTypes";
import { Grid } from "@material-ui/core";

export const Totals = observer(() => {
  const { settings }  = useEstimateStructure();
  
  const {
    allowEdition,
    isSellerOrClient,
    useProfitability,
  } = useEstimateEditorSettings()
  
  const { visibleTotals } = settings;
  
  const handleTotalsReorder = (list) => {
    settings.reorderTotals(list);
  };
  
  if(useProfitability)
    return (
      <Grid
        container
        className="w-max mt-4"
        justifyContent="flex-end"
        spacing={5}
      >
        <TotalCompanyCost showDivider />
        <TotalCost revenue showDivider />
        <TotalProfit showDivider />
        <TotalProfitability />
      </Grid>
    )
  
  if(!visibleTotals.length)
    return <></>;
  
  return (
    <SortableList
      list={visibleTotals}
      path="totals"
      onListReorder={handleTotalsReorder}
      itemIdKey="name"
      className="w-max mt-4"
      displayAsFlexbox
      disabled={!allowEdition}
    >
      {
        visibleTotals
          .filter(({noDev}) => !noDev || isSellerOrClient)
          .map(({ name }, index) => (
            <TotalsFactory
              key={name}
              name={name}
              showDivider={index+1 < visibleTotals.length}
            />
          ))
      }
    </SortableList>
  );
});
