import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { MAX_VALUES } from "@utils";
import {
  useEstimateStructure,
  useSummaryUnitCostInputTooltipAlert,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  ValueCell,
} from "@tools";

export const RateCell = observer(({
  element,
  displayClientCellContent,
  displayCellContent,
  extendPadding,
}) => {
  const structure = useEstimateStructure();
  const { allowEdition, useProfitability } = useEstimateEditorSettings();
  const tooltip = useSummaryUnitCostInputTooltipAlert(useProfitability, element.hasOnlyCost);
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryRate);
  
  const { usesAnyValue } = structure;
  const { currencyObj, modifier, timeModifier } = structure.settings;
  const { displayRate, hasResources, hasOnlyCost } = element;
  
  const handleRateChange = (rate) => {
    element.setRate(rate / modifier);//, allowInteractions && isProposal);
  };

  return (
    <ValueCell
      widest
      showDecimals
      emptyInputs={hasOnlyCost || !usesAnyValue}
      value={displayRate}
      displayContent={useProfitability ? displayCellContent : displayClientCellContent}
      onValueChange={handleRateChange}
      editable={!hasResources && allowEdition}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      useSeparator={useProfitability}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-rate"
      extendPadding={extendPadding}
      { ...tooltip }
    />
  );
});

RateCell.propTypes = {
  element: object.isRequired,
  displayClientCellContent: bool,
  displayCellContent: bool,
  extendPadding: bool,
};
