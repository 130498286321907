import { useEffect, useState } from "react";
import { func, number } from "prop-types";
import { observer } from "mobx-react";
import { useStores, useTemplates } from "@hooks";
import { NewProjectDialog } from "@dialogs";
import { PageTitle, TemplatesFilter, TemplatesList } from "@components";
import { Container } from "@material-ui/core";

export const TemplatesView = observer(({
  defaultTemplateId,
  setDefaultTemplateId,
}) => {
  const { stateStore } = useStores();
  const { userStore } = useStores();

  const [showNewProjectModal, openNewProjectModal] = useState(false);

  const { isModerator } = userStore;
  
  const {
    templates,
    isLoading,
    getTemplates,
  } = useTemplates();

  useEffect(() => {
    stateStore.setScrollEndHandler(onScrollEnd);
    return () => {
      stateStore.resetScrollEndHandler();
    };
  }, [isLoading, templates, templates?.length]);

  const onScrollEnd = async () => {
    await getTemplates();
  }
  
  return (
    <>
      <PageTitle pinned flexHeight>
        <TemplatesFilter />
      </PageTitle>
      <Container>
        <TemplatesList
          defaultTemplateId={defaultTemplateId}
          onSelect={() => openNewProjectModal(true)}
          onSetDefault={setDefaultTemplateId}
          allowEdition={isModerator}
          allowRemoval={isModerator}
        />
        {
          showNewProjectModal &&
          <NewProjectDialog
            open={showNewProjectModal}
            defaultTemplateId={defaultTemplateId}
            onClose={() => openNewProjectModal(false)}
          />
        }
      </Container>
    </>
  );
});

TemplatesView.propTypes = {
  defaultTemplateId: number,
  setDefaultTemplateId: func.isRequired,
}