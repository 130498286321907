import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { PROJECT_COMPANY_SETTINGS } from "@client";
import { setCompanySettingsQuery } from "@query";
import { useEstimateEditorStore, useEstimateStructure } from "@tools";
import { Dialog } from "@components";
import { IconButton } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";

export const DifferentTotalCostAlert = observer(() => {
  
  const { t } = useTranslation();
  const structure = useEstimateStructure();
  const { projectUuid } = useParams();
  const editorStore = useEstimateEditorStore();
  
  const { hasDifferentBreakdownTotal, settings } = structure;
  
  const [dialogVisible, showDialog] = useState();
  
  const handleDialogOpen = () => showDialog(!dialogVisible)
  
  useEffect(() => {
    if(hasDifferentBreakdownTotal && !editorStore.hadDifferentValues) {
      editorStore.hadDifferentValues = true;
      setCompanySettingsQuery(PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(projectUuid), true)//@todo: projectUuid should be declared in editor root and renamed to sth more neutral
    }
  }, [hasDifferentBreakdownTotal]);
  
  if(!hasDifferentBreakdownTotal)
    return <></>;
  
  return <>
    <IconButton
      size="small"
      onClick={handleDialogOpen}
    >
      <ReportProblemOutlined className="color-warning" />
    </IconButton>
    <Dialog
      open={dialogVisible}
      onClose={handleDialogOpen}
      title={t("common.warning")}
    >
      <p className="mb-4 text-sm">
        {t(
          "views.editor.different_price_dialog.text1",
          { timely: t(`timely.${ settings?.timeModifier === 1 ? "hour" : "day" }`) }
        )}
      </p>
      <p className="mb-4 text-sm">
        {t("views.editor.different_price_dialog.text2")}
      </p>
      <ul className="my-1 pl-6">
        <li>
          {t("views.editor.different_price_dialog.point1")}
        </li>
        <li>
          {t("views.editor.different_price_dialog.point2")}
        </li>
        <li>
          {t("views.editor.different_price_dialog.point3")}
        </li>
      </ul>
    </Dialog>
  </>
})