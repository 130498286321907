import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { number, string, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { BREAKDOWN_TABLE_DEPTHS, generateEntryId } from "project-structure";
import { searchLibraryComponentsQuery } from "@query";
import { LIBRARY_LEVELS, MAX_MODULE_NAME } from "@utils";
import { useEstimateStructure } from "@tools";
import { TextClearInput, Link, SearchableListContent, SearchableListItem } from "@components";
import { CircularProgress } from "@material-ui/core";
import useStyle from "./TaskSelect.style";

let queryTimeout;

export const TaskSelect = observer(({
  element,
  tableDepth,
  parentName
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  let usedHeader = null;
  const { id, name, autoFocus } = element;
  
  const structure = useEstimateStructure();
  
  const [searchValue, setSearchValue] = useState("");
  const [taskNameAlreadyUsed, setUsedValue] = useState(false);
  const [unmatchableText, setNoDataValue] = useState("");
  const [currentPage, setPage] = useState(false);
  const [hasMorePages, setMorePages] = useState(false);
  const [elements, setElements] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [focused, setFocused] = useState(undefined);
  
  const anchorEl = useRef(null);
  
  const noMatchesFound = useMemo(
    () =>
      unmatchableText &&
      searchValue?.length > unmatchableText.length &&
      searchValue.slice(0, unmatchableText.length) === unmatchableText,
    [unmatchableText, searchValue]
  );
  
  useEffect(() => {
    if (autoFocus) {
      setFocused(true);
      element.removeAutofocus();
    }
  }, [autoFocus]);
  
  useEffect(() => {
    if (focused && (searchValue?.length >= 3 || !searchValue?.length)) {
      if (!noMatchesFound) {
        setUsedValue(false);
        if (queryTimeout) clearTimeout(queryTimeout);
        queryTimeout = setTimeout(() => {
          loadElements(true);
        }, 500);
      }
    }
    
    if (searchValue?.length && searchValue !== name)
      setUsedValue(element.hasSibling(searchValue));
  }, [searchValue]);
  
  useEffect(() => {
    setSearchValue(name?.replace(/<(.|\n)*?>/g, ""));
  }, [name]);
  
  useEffect(() => {
    if (focused) loadElements(true, true);
    // if(focused !== undefined)
    // 	setFocusSyncMessage(focused);
    // setSearchValue(name);
  }, [focused]);
  
  // const setFocusSyncMessage = (state) => {
  // 	websocket?.sendSyncMessage("task_name_focus", state, fullIdPath);
  // };
  
  const loadElements = async (resetList = false, noFilter = false) => {
    if (isLoading || tableDepth === BREAKDOWN_TABLE_DEPTHS.SECTION) return;
    
    setLoading(true);
    const {
      elements: newElements,
      page,
      pages,
    } = await searchLibraryComponentsQuery(
      LIBRARY_LEVELS[tableDepth - 1],
      resetList ? 1 : currentPage + 1,
      element.parentComponentId,
      noFilter ? "" : searchValue
    );
    setElements(resetList ? newElements : [...elements, ...newElements]);
    setPage(page);
    setMorePages(pages > page);
    setNoDataValue(newElements.length === 0 ? searchValue : "");
    setLoading(false);
  };
  
  /**
   * Finds best match for provided "searchValue"; triggers on input blur
   */
  const handleModuleSelect = async () => {
    if (focused) {
      setFocused(false);
      if (taskNameAlreadyUsed) {
        setUsedValue(false);
        setSearchValue(name);
      } else if (!id || searchValue !== name) {
        // const matchedModule = elements.find(({name}) => simplifyTextString(name) === simplifyTextString(searchValue));
        // if(matchedModule)
        // 	await handleNameChange(matchedModule);
        // else
        await handleNameChange({ name: searchValue }, true);
      }
    }
  };
  
  const handleInputChange = (v) => {
    setSearchValue(v.slice(0, MAX_MODULE_NAME));
  };
  
  const handleFocus = () => {
    setFocused(true);
  };
  
  const handleScrollEnd = () => {
    if (hasMorePages) loadElements();
  };
  
  const handleNameChange = async (
    newElement,
    isCustomTask = false /*, category*/
  ) => {
    const { name, content, id, componentId, children, values } = newElement;
    structure.historyManager.startGroup();
    
    setFocused(false);
    // setFocusSyncMessage(false);
    element.setName(
      name.trim(),
      isCustomTask || !id ? element.id || generateEntryId() : id,
      componentId
    );
    
    if (tableDepth === BREAKDOWN_TABLE_DEPTHS.SECTION) {
      structure.historyManager.stopGroup();
      return;
    }
    
    if (componentId) {
      if (!element.content && content) element.setContent(content, false);
      if (values) element.setValues(values, true);
      if (children?.length)
        element.addChildren(children.map(populateWithEntryIds));
    }
    structure.historyManager.stopGroup();
  };
  
  const populateWithEntryIds = (child, i) => {
    if (child.children) child.children = child.children.map(populateWithEntryIds);
    
    child.id = generateEntryId(i);
    
    return child;
  };
  
  
  return (
    <>
      <TextClearInput
        multiline
        onChange={handleInputChange}
        value={searchValue}
        focused={focused}
        error={taskNameAlreadyUsed}
        onFocus={handleFocus}
        onAccept={handleModuleSelect}
        acceptOnClickAway
        lockSpecialCharacters={false}
        placeholder={
          focused && tableDepth > BREAKDOWN_TABLE_DEPTHS.SECTION
            ? t(`views.editor.dialogs.features.search`)
            : t("views.editor.dialogs.features.new_name_" + tableDepth)
        }
        ref={anchorEl}
        confirmOnEnter
        containerClass="name"
        className="-m-0-5"
        preventClickAwayOnElement="#libraryElementSelector"
        inputClassName="input-name"
      />
      {tableDepth > BREAKDOWN_TABLE_DEPTHS.SECTION && (
        <SearchableListContent
          scrollEndHandler={handleScrollEnd}
          show={focused}
          anchor={anchorEl.current}
          showEmptyMessage={!isLoading && !elements?.length}
          id="libraryElementSelector"
        >
          {elements.map((childElement) => {
            const {
              id: listedId,
              componentId,
              name: listedName,
              // category,
              parent,
            } = childElement;

            const isUsed = element.hasSibling(
              listedName,
              listedId,
              componentId
            );

            const parentId = element.parentComponentId;
            let header;
            //
            if (!header && parentId && parentId === parent) {
              header = "parent";
            } else header = "other";
            //
            if (header === usedHeader) {
              header = undefined;
            } else {
              usedHeader = header;
            }

            return (
              <Fragment key={`${listedName}_${listedId || componentId}`}>
                {header && (
                  <span className={classes.recommended}>
                    {header === "parent" ? (
                      t("views.editor.dialogs.features.headers.parent", {
                        item: parentName,
                      })
                    ) : (
                      <>
                        {t("views.editor.dialogs.features.headers.library")}
                        <Link to="/home/library">
                          {t(
                            "views.editor.dialogs.features.headers.library_link"
                          )}
                        </Link>
                      </>
                    )}
                  </span>
                )}
                <SearchableListItem
                  onClick={() => handleNameChange(childElement)}
                  disabled={isUsed}
                >
                  {listedName?.replace(/<(.|\n)*?>/g, "")}
                </SearchableListItem>
              </Fragment>
            );
          })}
          {isLoading && (
            <CircularProgress
              aria-label="progress indicator"
              className={classes.progress}
            />
          )}
        </SearchableListContent>
      )}
    </>
  );
});

TaskSelect.propTypes = {
  element: object.isRequired,
  tableDepth: number,
  parentName: string,
};
