import { createContext, useContext, useEffect, useState } from "react";
import { func, node, number } from "prop-types";
import { getTemplatesQuery, getAllProjectTypesQuery, SORT_BY_TYPES, LOCAL_SORT_TYPES } from "@query";

export const TemplatesContext = createContext(null);

export const useTemplates = () => {
  const context = useContext(TemplatesContext);
  if (context === null) {
    throw new Error("TemplatesContext cannot be null, please add a context provider");
  }
  return context;
};

export const TemplatesDataProvider = ({
  children,
  limit,
  onLoad,
}) => {
  const [templates, setTemplates] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filtersLoading, setFiltersLoading] = useState(true);

  const [companyTemplates, setCompanyTemplates] = useState(true);
  const [nameFilter, setNameFilter] = useState("");
  const [typesFilter, setTypesFilter] = useState([]);
  const [sortBy, setSortBy] = useState(`${SORT_BY_TYPES.CREATED}_${LOCAL_SORT_TYPES.DESC}`); // name_asc, name_desc, updated_asc, updated_desc, created_asc, created_desc
  
  useEffect(() => {
    initFilters();
  }, []);
  
  useEffect(() => {
    if(filtersLoading)
      return;
    
    setNameFilter("");
    setTypesFilter([]);
  }, [companyTemplates]);
  
  useEffect(() => {
    if(filtersLoading)
      return;
    
    getTemplates(true);
  }, [nameFilter, typesFilter, sortBy]);

  const clearFilters = () => {
    setNameFilter("");
    setTypesFilter([]);
    getTemplates(true);
  };
  
  const initFilters = async () => {
    const res = await getAllProjectTypesQuery(true);
    setProjectTypes(res);
    await getTemplates(true);
    
    if (onLoad) await onLoad();
    setLoading(false);
    setFiltersLoading(false);
  }

  const getTemplates = async (reset = false) => {
    if (reset || (!loading && hasMore)) {
      setLoading(true);
      const { paginate, results } = await getTemplatesQuery(
        reset ? 1 : page + 1,
        limit,
        companyTemplates,
        nameFilter,
        typesFilter,
        sortBy,
      );
      
      setTemplates(reset ? results : [...templates, ...results]);
      
      setPage(paginate.page);
      setHasMore(paginate.page < paginate.pages);
      setLoading(false);
    }
  }

  const updateTemplate = ({ description, name, types, id }) => {
    const t = [...templates];
    const tpl = t.find((t) => t.id === id);
    tpl.name = name;
    tpl.content = description;
    tpl.types = types;
    setTemplates(t);
  };

  const removeTemplate = (uuid) => {
    const newList = templates.filter((t) => t.id !== uuid);
    if(!newList.length)
      getTemplates(true);
    else
    setTemplates(newList)
  };

  const switchCompanyTemplatesState = async () => setCompanyTemplates(!companyTemplates);

  const addNewProjectType = (newProjectType) => {
    setProjectTypes((v) => [
      ...v,
      newProjectType
    ])
  }
  
  const value = {
    templates,
    projectTypes,
    
    isLoading: loading,
    filtersLoading,

    companyTemplates,
    nameFilter,
    setNameFilter,
    typesFilter,
    setTypesFilter,
    sortBy,
    setSortBy,
    clearFilters,
    getTemplates,
    updateTemplate,
    removeTemplate,
    switchCompanyTemplatesState,
    addNewProjectType,
  };
  
  
  return (
    <TemplatesContext.Provider value={value}>
      {children}
    </TemplatesContext.Provider>
  );
};

TemplatesDataProvider.propTypes = {
  children: node.isRequired,
  limit: number.isRequired,
  onLoad: func,
}