import { useEffect, useMemo } from "react";
import { number, object } from "prop-types";
import { observer } from "mobx-react";
import { isMobile } from "react-device-detect";
import { BREAKDOWN_TABLE_DEPTHS, SECTION_FORMATS, DESCRIPTION_MODES } from "project-structure";
import ResizeObserver from "react-resize-observer";
import {
  useTableOverflowChecker,
  useEstimateStructure,
  getUserVisibleWorkTypes,
  useVisibilityModeCellClassName,
  ScrollButtonContainer,
  useEstimateEditorSettings,
  EstimateTableSettingsProvider,
} from "@tools";
import { Grid, TableContainer } from "@material-ui/core";
import { TableHeader } from "./TableHeader/TableHeader";
import { SectionRow } from "./SectionRow/SectionRow";
import { SectionSummaryRow } from "./SectionSummaryRow/SectionSummaryRow";
import { BreakdownRowGroup } from "./BreakdownRowGroup/BreakdownRowGroup";
import { BreakdownRowGroupList } from "./BreakdownRowGroupList";
import useEditorStyle from "@tools/EstimateEditor/styles/CommonTableStyles.style";
import classnames from "classnames";

export const Section = observer(({
  section,
  index,
}) => {
  const {
    isSellerOrClient,
    allowEdition,
    isProposal,
    isLibrary,
  } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const commonClasses = useEditorStyle();
  
  const { settings, visibility } = structure;
  const {
    useMinMax,
    titleCellWidth,
    descriptionCellWidth,
    showPrices,
    showBreakdownColumns,
    descriptionMode,
  } = settings;
  const { apply } = visibility;
  
  const {
    taskWorkTypes,
    sectionModulesHaveChildren,
    childrenWithContent,
    showCombinedHours,
    hidePrice,
    hideDescription,
    content,
  } = section;

  const visibleWorkTypes = getUserVisibleWorkTypes(taskWorkTypes, isProposal);
  const priceClassName = useVisibilityModeCellClassName(hidePrice);
  const descriptionClassName = useVisibilityModeCellClassName(hideDescription);
  
  const inTableSection = useMemo(
    () => structure.settings.sectionFormat === SECTION_FORMATS.ROW,
    [structure.settings.sectionFormat]
  );

  const showSectionSummary = useMemo(
    () => structure.settings.sectionFormat === SECTION_FORMATS.SUBTOTAL,
    [structure.settings.sectionFormat]
  );

  const tableContainerIdentifier = useMemo(() => `breakdownContainer${index}`, [index]);
  const tableIdentifier = useMemo(() => `tableHeader${index}`, [index]);

  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    containerWidth,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  
  const pricesVisible = useMemo(
    () => showBreakdownColumns && showPrices && isSellerOrClient && (!isProposal || !apply || !hidePrice),
    [showPrices, isSellerOrClient, showBreakdownColumns, isProposal, apply, hidePrice]
  );
  
  const useExpandCell = useMemo(() => (
    !isProposal || sectionModulesHaveChildren
  ), [isProposal, sectionModulesHaveChildren]);
  
  const hasAnyDescription = useMemo(() => (
    Boolean(content) || childrenWithContent > 0
  ), [content, childrenWithContent]);
  
  const descriptionVisible = useMemo(() => (
    (!isProposal || hasAnyDescription) && (!isProposal || !apply || !hideDescription)
  ), [isProposal, hasAnyDescription, apply, hideDescription]);
  
  const maxTitleCellWidth = useMemo(() => (
    descriptionMode === DESCRIPTION_MODES.COLUMN && descriptionVisible
      ? titleCellWidth
      : undefined
  ), [descriptionMode, descriptionVisible, titleCellWidth])
  
  useEffect(() => {
    onResize()
  }, [
    pricesVisible, useMinMax, titleCellWidth, descriptionCellWidth, showCombinedHours, useExpandCell,
    hasAnyDescription, descriptionVisible, maxTitleCellWidth
  ]);
  
  return (
    <EstimateTableSettingsProvider
      useExpandCell={useExpandCell}
      pricesVisible={pricesVisible}
      hasAnyDescription={hasAnyDescription}
      descriptionVisible={descriptionVisible}
      visibleWorkTypes={visibleWorkTypes}
      maxTitleCellWidth={maxTitleCellWidth}
      priceClassName={priceClassName}
      descriptionClassName={descriptionClassName}
      showCombinedHours={showCombinedHours}
      sectionContainerWidth={containerWidth}
    >
      <Grid item container>
        {
          isTableContentOverflowing && !isMobile &&
          <ScrollButtonContainer {...scrollButtonProps} />
        }
        { !inTableSection && (
          <SectionRow index={ index } section={ section }/>
        ) }
        <TableContainer
          ref={ ref }
          id={ tableContainerIdentifier }
          onScroll={ onScroll }
          className={ classnames(
            "pt-3 mb-4 h-max w-full min-w-full relative overflow-x-auto overflow-y-hidden",
            isTableContentOverflowing && commonClasses.overflows
          ) }
        >
          <Grid container role="rowgroup" direction="column">
            <ResizeObserver onResize={ onResize }/>
            <TableHeader
              section={ section }
              tableIdentifier={ tableIdentifier }
            />
            { inTableSection ? (
              <BreakdownRowGroup
                sectionIndex={ index }
                element={ section }
                isLastGroupRow
                tableDepth={ BREAKDOWN_TABLE_DEPTHS.SECTION }
                isFirst={ index === 0 }
              />
            ) : (
              <BreakdownRowGroupList
                initialDepth={ isLibrary ? section.startingLevel - 1 : 0 }
                element={ section }
                sectionIndex={ index }
                isLastGroupRow
                parentIds={ [ section.id ] }
                disabled={!allowEdition}
              />
            ) }
            { !inTableSection && (showSectionSummary || allowEdition) && (
              <SectionSummaryRow
                section={ section }
                isFirst={ index === 0 }
              />
            ) }
          </Grid>
        </TableContainer>
      </Grid>
    </EstimateTableSettingsProvider>
  );
});

Section.props = {
  section: object.isRequired,
  index: number.isRequired,
};
