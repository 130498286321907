import { useEffect } from "react";
import { object } from "prop-types";
import { observer } from "mobx-react";
import { useFonts, useEstimateEditorStore, useEstimatePresetStore } from "@tools";
import { StaticImg } from "@components";
import { AppBar, Toolbar } from "@material-ui/core";
import { PresetToolbar } from "../index";
import { ApropoLogo } from "@assets";
import { APP_BAR_HEIGHTS } from "@styles/themes";

export const ProposalCustomizationToolbar = observer(() => {
  const presetStore = useEstimatePresetStore();
  const editorStore = useEstimateEditorStore();
  const { loadFonts, unloadFonts } = useFonts();

  const { proposalStep, companyLogo, currentVersion } = editorStore;
  const { selectedPresetData, selectedPresetFont } = presetStore;
  
  useEffect(() => {
    if(selectedPresetFont && proposalStep === 2) {
      unloadFonts()
      loadFonts([selectedPresetFont])
      console.log("load fonts")
    }
  }, [currentVersion, proposalStep, selectedPresetFont]);
  
  return (
    <>
      <PresetToolbar />
      <AppBar
        position="static"
        role="banner"
        className="preset-bar"
        style={{ zIndex: 900, boxShadow: "none" }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div
            className="preset-logo h-max w-max"
            style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
          >
            {selectedPresetData && selectedPresetData.logo ? (
              <StaticImg
                src={selectedPresetData.logo}
                className="h-full"
                style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
              />
            ) : companyLogo ? (
              <StaticImg
                src={companyLogo}
                className="h-full"
                style={{ maxHeight: APP_BAR_HEIGHTS.SMALL }}
              />
            ) : (
              <ApropoLogo />
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
});

ProposalCustomizationToolbar.propTypes = {
  tableRef: object,
};
