import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { useEstimatePresetStore, useEstimateEditorStore } from "@tools";
import { AppBar, Toolbar } from "@material-ui/core";
import { StaticImg } from "@components";
import { ApropoLogo } from "@assets";
import classnames from "classnames";
import { APP_BAR_HEIGHTS } from "@styles/themes";

export const ProposalAppBar = observer(({
  // proposalShareKey,
  // isPdfProject,
  className
}) => {
  const { companyLogo } = useEstimateEditorStore();
  const presetStore = useEstimatePresetStore();

  return (
    <AppBar
      position="static"
      role="banner"
      className={classnames("preset-bar", "shadow-none", className)}
    >
      <Toolbar style={{ justifyContent: "space-between" }}>
        {presetStore?.selectedPresetData?.logo ? (
          <StaticImg
            // disableCache
            src={presetStore.selectedPresetData.logo}
            style={{ height: APP_BAR_HEIGHTS.SMALL }}
          />
        ) : companyLogo ? (
          <StaticImg
            // disableCache
            src={companyLogo}
            style={{ height: APP_BAR_HEIGHTS.SMALL }}
          />
        ) : (
          <ApropoLogo />
        )}
        {/*{proposalShareKey && (*/}
        {/*  <PdfExport*/}
        {/*    useSharedStyle*/}
        {/*    shareKey={proposalShareKey}*/}
        {/*    attachCover*/}
        {/*    simpleExport={isPdfProject}*/}
        {/*  />*/}
        {/*)}*/}
      </Toolbar>
    </AppBar>
  );
});

ProposalAppBar.propTypes = {
  proposalShareKey: string,
  className: string,
  isPdfProject: bool,
};
