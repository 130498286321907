import { object } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  ValueCell,
  VisibilityButton,
} from "@tools";

export const CombinedHeaderWorkType = observer(({
  section,
}) => {
  const { isProposal } = useEstimateEditorSettings();
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { usesTwoValues } = structure;
  const { areAllWorkTypesOff, areAllWorkTypesHidden } = section;
  
  const className = useVisibilityModeCellClassName(areAllWorkTypesHidden);

  return (
    <ValueCell
      isHeader
      wider
      size="xSmall"
      widest={usesTwoValues}
      visible={!areAllWorkTypesOff}
      className={className}
    >
      <span className="preset-tableBreakdownHeader pt-combinedEstimate">
        { getFieldName("pt-combinedEstimate") }
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={usesTwoValues ? 2 : -12}
          hidden={areAllWorkTypesHidden}
          onChange={section.hideAllWorkTypes}
        />
      }
    </ValueCell>
  );
});

CombinedHeaderWorkType.propTypes = {
  section: object.isRequired,
};
