import { useEffect, useMemo, useState } from "react";
import { number, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { MAX_WORKTYPE_LENGTH } from "project-structure";
import { useProjectCreatorStore } from "@hooks";
import { Autocomplete } from "@components";
import useStyle from "./StepsFactory/steps/Steps.style";
import classnames from "classnames";

export const WorkTypeSelector = ({ index, id, name }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const creator = useProjectCreatorStore();

  const [localValue, setValue] = useState(name || "");

  const { workTypes, importWorkTypes } = creator;

  const filtered = useMemo(
    () =>
      workTypes.filter(
        (s) => s.id === id || !importWorkTypes.find((i) => i.id === s.id)
      ),
    [workTypes, importWorkTypes]
  );

  useEffect(() => {
    const f = filtered.find((x) => x.name.toLowerCase() === name.toLowerCase());
    if (f && !id)
      creator.updateImportWorkType(index, { name: f.name, id: f.id });

    setValue(name.slice(0, MAX_WORKTYPE_LENGTH));
  }, [name, id]);

  const handleChange = (e, n) => {
    creator.updateImportWorkType(index, { name: n.name, id: n.id });
  };

  const handleInputChange = (v) => {
    setValue(v.slice(0, MAX_WORKTYPE_LENGTH));
  };

  const handleChangeCommit = () => {
    creator.updateImportWorkType(index, {
      name: localValue,
      id: localValue !== "name" ? null : id,
    });
  };

  return (
    <Autocomplete
      id="workTypeSelect"
      value={id}
      options={filtered}
      inputPlaceholder={t("views.import.select_system")}
      inputValue={localValue}
      fullWidth
      idKey="id"
      labelKey="name"
      size="small"
      disableClearable
      onBlur={handleChangeCommit}
      onChange={handleChange}
      onInputChange={handleInputChange}
      fieldClassName="mb-0"
      InputProps={{
        className: classnames(classes.workTypeAutocompleteTextField, "p-0 mb-0"),
      }}
      inputProps={{
        className: "py-1 pr-6"
      }}
      classes={{
        endAdornment: classes.endAdornment
      }}
    />
  );
};

WorkTypeSelector.propTypes = {
  index: number.isRequired,
  name: string.isRequired,
  id: number,
};
