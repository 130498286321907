import { useMemo } from "react";
import { bool, object, number, arrayOf, oneOfType, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tables, hashCode } from "project-structure";
import { chunk } from "lodash";
import { CELL_WIDTHS, ELEMENT_TYPES, getTimelineChartSegments, LOCKED_FEATURES } from "@utils";
import { useFeatureGuardian, useTableExpandHandler } from "@hooks";
import {
  useEstimateStructure,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  useEstimateTableSettings,
  TitleCell, Row, TimelineValueCell, RowGroup, SummaryRow
} from "@tools";
import { AddButtonFull } from "@components";
import { Collapse, Grid } from "@material-ui/core";
import { TimelineRowGroupList } from "../TimelineRowGroupList/TimelineRowGroupList";
import { TimelineRowActions } from "./components/TimelineRowActions";
import classnames from "classnames";

export const TimelineRowGroup = observer(({
  workType,
  tableDepth,
  parentIds,
}) => {
  const { t } = useTranslation();
  const structure = useEstimateStructure();
  const {
    allowEdition,
    blockExpansions,
    showChatThread,
    allowCommenting,
  } = useEstimateEditorSettings();
  const { timelineLength, useExpandCell } = useEstimateTableSettings();
  
  const { hasTeamMembersCol, modifier, timelineHoursKey } = structure.settings;

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ALLOCATION
  );

  const {
    id,
    name,
    color,
    isTimelineOpened,
    presentedTimeline,
    hasResources,
    resources,
    turnOff,
    usesTimelinePercent,
    maxTimelineHours,
    timelineStartMonth,
    parsedMta,
    isHidden,
    hideOnTimeline,
  } = workType;
  
  const className = useVisibilityModeCellClassName(isHidden || hideOnTimeline);
  
  const chunkedTimeline = chunk(presentedTimeline?.weeks || [], 4).slice(0, workType.totalDevelopmentTime);
  
  const fillerMonths = Math.max(timelineLength - workType.totalDevelopmentTime, 0);
  const timelineChartSegments = getTimelineChartSegments(
    presentedTimeline,
    parsedMta[timelineHoursKey],
    modifier,
    usesTimelinePercent
  );
  
  const handleNewResource = async () => {
    const allowed = await checkAccess();
    if (!allowed) return;
    workType.addResource(true, `r${new Date().getTime()}`, true);
  };

  const expandable = useMemo(
    () => hasTeamMembersCol && (allowEdition || hasResources),
    [allowEdition, hasResources]
  );
  const isActive = useMemo(
    () => expandable && (isTimelineOpened || blockExpansions),
    [isTimelineOpened, blockExpansions, expandable]
  );
  const displayCellContent = useMemo(
    () => !isActive || !hasResources,
    [isActive, hasResources]
  );
  
  const maxHours = useMemo(() => (
    maxTimelineHours / modifier
  ), [maxTimelineHours, modifier]);

  const handleResourceReorder = (list) =>
    workType.reorderResources(list);
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    expandable,
    isTimelineOpened,
    workType.setTimelineOpenState
  );
  
  return (
    <RowGroup
      originTableId={Tables.TIMELINE}
      tableDepth={tableDepth}
      isActive={isActive}
      presetClass="preset-accentTimeline"
      elementId={`T${id}`}
      elementType={ELEMENT_TYPES.TIMELINE_WORK}
      className={classnames("timeline", className)}
    >
      <Row
        useExpandCell={useExpandCell}
        expandOnClick
        onExpand={handleOpen}
        expandable={expandable}
        expanded={isActive}
        isFirstGroupRow
        isLastGroupRow={!isActive}
        tableDepth={tableDepth}
        // useFiller
        visible={!turnOff}
        noArrow
        presetClass={
          isActive ? "preset-activeTimeline" : "preset-rowTimeline"
        }
      >
        <TitleCell
          pinnedLeft={useExpandCell ? tableDepth : 0}
          maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
          minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
          allowOverflowDisplay
          actions={
            <TimelineRowActions
              allowEdition={allowEdition}
              workType={workType}
              opened={isActive}
              showChatThread={showChatThread}
              allowCommenting={allowCommenting}
            />
          }
        >
          <Grid
            item
            container
            alignItems="center"
            className={classnames(
              "name",
              "preset-timelineTitleText",
              !useExpandCell && "ml-3",
              !name?.length && "semi-transparent"
            )}
          >
            {name || t("common.unnamed")}
          </Grid>
        </TitleCell>
        {
          chunkedTimeline.map((month, i) => (
            <TimelineValueCell
              key={`month${i}`}
              monthIndex={i}
              month={month}
              maxHours={maxHours}
              timelineStarted={!timelineStartMonth || i >= timelineStartMonth }
              readOnly={hasResources || usesTimelinePercent }
              displayCellContent={displayCellContent}
              element={workType}
              visible={!turnOff}
              color={color}
              timelineChartSegments={timelineChartSegments}
            />
          ))
        }
        {
          [...new Array(fillerMonths).keys()]
            .map((_, i) => (
              <TimelineValueCell
                key={`month${i}`}
                monthIndex={workType.totalDevelopmentTime + i}
                visible={!turnOff}
              />
            ))
        }
      </Row>
      {
        hasTeamMembersCol &&
        <Collapse
          in={isActive}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${id}_Tcont`)}
          data-p={[Tables.TIMELINE, `T${id}`].map((id) => hashCode(id)).join("/")}
          data-t={ELEMENT_TYPES.TIMELINE_RESOURCE}
          { ...collapseProps }
        >
          <TimelineRowGroupList
            listElements={resources}
            onReorder={handleResourceReorder}
            isLastGroupRow={!allowEdition}
            tableDepth={tableDepth}
            parentIds={[...parentIds, id]}
            turnOff={turnOff}
            parentColor={color}
            timelineLength={timelineLength}
          />
          {allowEdition && (
            <SummaryRow
              useExpandCell
              isLastGroupRow
              tableDepth={tableDepth}
            >
              <FeatureAlert />
              <TitleCell
                allowOverflowDisplay
                pinnedLeft={tableDepth}
                maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
                minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
              >
                <AddButtonFull
                  id="newTeamMemberButton-timeline"
                  onClick={handleNewResource}
                  name="Add new resource to work type"
                >
                  {t("views.editor.summary_table.work_resource_add")}
                </AddButtonFull>
              </TitleCell>
              {[...new Array(timelineLength).keys()].map((i) => (
                <TimelineValueCell
                  key={`month${i}`}
                  monthIndex={i}
                  visible={!turnOff}
                />
              ))}
            </SummaryRow>
          )}
        </Collapse>
      }
    </RowGroup>
  );
});

TimelineRowGroup.propTypes = {
  workType: object.isRequired,
  tableDepth: number.isRequired,
  isLastGroupRow: bool,
  parentIds: arrayOf(oneOfType([number, string])),
};