import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateStructure,
  useEstimateEditorSettings,
  ValueCell,
} from "@tools";

export const CompanyCostCell = observer(({ extentPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { usesTwoValues } = useEstimateStructure();
  
  return (
    <ValueCell
      wider
      widest={usesTwoValues}
      isHeader
      size="xSmall"
      style={{ cursor: allowEdition && "grab" }}
      extentPadding={extentPadding}
    >
      <span>
        {getFieldName("pt-cost")}
      </span>
    </ValueCell>
  )
})

CompanyCostCell.propTypes = {
  extendPadding: bool,
}