import { useMemo, useRef, useState } from "react";
import { func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectCreatorStore } from "@hooks";
import { HtmlParser } from "@utils";
import { Grid, Link } from "@material-ui/core";
import { Button, PopMenu } from "@components";
import { FileAlt, TemplatePlaceholder, XlsFile } from "@assets";
import { useBlankBorderStyle, useXlsBorderStyle } from "@styles";
import useStyle from "./TemplateSelector.style";
import classnames from "classnames";

export const TemplateSelector = observer(({ onClick }) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { blankBorder } = useBlankBorderStyle();
  const { xlsBorder } = useXlsBorderStyle();
  const creator = useProjectCreatorStore();

  const [detailsVisible, showDetails] = useState(false);

  const ref = useRef();

  const { template, hasXlsFile, xlsFile } = creator;

  const desc = useMemo(() => {
    if (template?.template) {
      const { MVP, MMP, MLP } = template.template;
      const sortedComplexities = [MVP, MMP, MLP].filter((x) => x);
      return sortedComplexities.map((x) => x.content).join("<br/><br/>");
    }

    return template?.content || "";
  }, [template]);

  return (
    <Grid item container className={classes.frame} onClick={onClick}>
      <Grid
        item
        container
        className={classnames(
          classes.templateContainer,
          !template && "border-dashed-2",
          !template && (hasXlsFile ? xlsBorder : blankBorder)
        )}
      >
        {template ? (
          template.thumb ? (
            <img src={template.thumb} alt={`${template.title} graphic`} />
          ) : (
            <TemplatePlaceholder />
          )
        ) : hasXlsFile ? (
          <XlsFile style={{ fontSize: 60 }} />
        ) : (
          <FileAlt style={{ fontSize: 60 }} />
        )}
      </Grid>

      <Grid
        item
        container
        direction="column"
        wrap="nowrap"
        className={classes.templateDescription}
      >
        {template ? (
          <>
            <p className={classes.templateTitle}>{template.name}</p>
            <p className={classnames(classes.templateSubtitle, "mb-4")}>
              {HtmlParser(
                desc
                  .replace(/<[^>]*>/g, "")
                  .slice(0, 130)
                  .replace(/ \w+,?$/, "")
              )}
              {desc.length > 130 && (
                <Link ref={ref} onClick={() => showDetails(!detailsVisible)}>
                  {" ("}
                  {t("views.new_project.read_more")}
                  {"...)"}
                </Link>
              )}
            </p>
            <PopMenu
              show={detailsVisible}
              anchor={ref.current}
              onClickAway={() => showDetails(false)}
              className="p-6"
              style={{ width: 560 }}
            >
              <p>{HtmlParser(desc)}</p>
            </PopMenu>
          </>
        ) : (
          <>
            <p className={classes.templateTitle}>
              {hasXlsFile ? xlsFile : t("views.templates.blank.title")}
            </p>
            {!hasXlsFile && (
              <p className={classes.templateSubtitle}>
                {t("views.templates.blank.desc_alt")}
              </p>
            )}
          </>
        )}
        <Button
          variant="outlined"
          color="primary"
          className="mt-4"
          size="small"
          onClick={onClick}
        >
          {t("views.new_project.change_template")}
        </Button>
      </Grid>
    </Grid>
  );
});

TemplateSelector.propTypes = {
  onClick: func,
};
