import { useMemo, useState } from "react";
import { bool, number, shape, object, oneOfType, string } from "prop-types";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { getPdfThumbSrc, PROPOSAL_THUMB_ID } from "@utils";
import { useEditorWebsocket } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { CircularProgress, IconButton } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DocumentRemoval } from "../DocumentRemoval";
import { PROPOSAL_THUMB_SRC, Trash } from "@assets";
import useStyle from "./PdfPageThumbnail.style";
import classNames from "classnames";

export const PdfPageThumbnail = observer(({
  page,
  pageIndex,
  isRemovable,
  showPageImages
}) => {
  
  const { projectUuid } = useParams();
  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  const { t } = useTranslation();
  const classes = useStyle();

  const [removeAlertVisible, showRemoveAlert] = useState(false);
  
  const { pdfDocument, currentPage, pdfPagesLoaded } = editorStore;
  
  const isCurrentPage = useMemo(() => (
    currentPage === pageIndex
  ), [currentPage]);
  
  const isProposalThumb = useMemo(() => (
    page.id === PROPOSAL_THUMB_ID
  ), [page.id]);
  
  const thumbImgSrc = useMemo(() => (
    isProposalThumb
      ? PROPOSAL_THUMB_SRC
      : getPdfThumbSrc(page.el)
  ), [isProposalThumb, page.el]);
  
  
  const jumpToPage = () => {
    editorStore.jumpToPage(pageIndex);
  }

  const handlePageRemove = () => {
    if(!pdfPagesLoaded) return;
    if (editorStore.usedPdfPages.length <= 1){
      showRemoveAlert(true);
      return;
    }
    const { usedPages, tablePos } = editorStore.removePage(page.id);
    socket?.updateCoverPages(pdfDocument.id, usedPages, tablePos);
  };
  
  return (
    <div
      className={classNames(
        classes.root,
        isCurrentPage && classes.highlight,
        "h-max"
      )}
      onClick={jumpToPage}
      id={`page${pageIndex}`}
    >
      {isRemovable && pdfPagesLoaded && (
        <IconButton
          onClick={handlePageRemove}
          size="small"
          className={classes.removeButton}
        >
          <Trash color="error" />
        </IconButton>
      )}
      {
        page.el || isProposalThumb
          ? <>
            <img
              src={thumbImgSrc}
              draggable={false}
              className={classNames(classes.canvasThumb, !isProposalThumb && "react-pdf__Page__Thumb")}
              alt=""
            />
            {
              !isProposalThumb && showPageImages &&
              <img
                src={ editorStore.getPageImageUrl(page.id) }
                className={classes.imageThumb}
                draggable={false}
                alt=""
              />
            }
          </>
          : <Grid
            item container
            alignItems="center"
            justifyContent="center"
            className={classes.loadingPlaceholder}
          >
            <CircularProgress size={16} />
          </Grid>
      }
      {isProposalThumb && (
        <div
          className={classNames(classes.proposal, {
            [classes.proposalHighlight]: isCurrentPage,
          })}
        >
          {t("views.pdf_viewer.summary_table")}
        </div>
      )}
      {
        isRemovable &&
        <DocumentRemoval
          projectUuid={projectUuid}
          visible={removeAlertVisible}
          onClose={() => showRemoveAlert(false)}
        />
      }
    </div>
  );
});

PdfPageThumbnail.propTypes = {
  pageIndex: number.isRequired,
  page: shape({
    id: oneOfType([number, string]).isRequired,
    el: object,
  }).isRequired,
  isRemovable: bool,
};
