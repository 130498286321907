import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEstimateStructure } from "@tools";
import { Button, Dialog } from "@components";
import { Risk } from "../settings/Risk";

export const RiskDisplay = () => {
  
  const structure = useEstimateStructure();
  const { t } = useTranslation();
  
  const [visible, show] = useState(false);
  
  const { riskBuffer } = structure.settings;
  
  const handleClose = () => {
    show(false);
  }
  
  return <>
    <Button
      onClick={() => show(!visible)}
      className="font-normal mr-2"
    >
      {t("views.editor.dialogs.settings.risk", { value: riskBuffer })}
    </Button>
    <Dialog
      open={visible}
      onClose={handleClose}
      width={460}
      title={t("views.editor.dialogs.settings.risk_buffer")}
    >
      <Risk noLabel />
    </Dialog>
  </>
}