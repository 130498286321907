import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { getWorkTypesQuery } from "@query";
import { PROJECT_TYPE } from "@utils";
import { EstimateEditorStore, EstimateEditorStoreProvider } from "@tools";
import { LibraryStore, LibraryStoreProvider } from "@views/Projects/Library/providers";
import { PageCircularProgress } from "@components";
import { Container } from "@material-ui/core";
import { LibraryView } from "./LibraryView";

export const Library = () => {
  
  const {t} = useTranslation();
  
  const [store, setStore] = useState(null);
  const libraryStore = useMemo(() => new LibraryStore(), []);
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.library")}`;
  }, [])

  useEffect(() => {
    onLoad();
  }, []);

  const onLoad = async () => {
    const workTypes = await getWorkTypesQuery();
    setStore(new EstimateEditorStore({
      estimateType: PROJECT_TYPE.LIBRARY,
      workTypes
    }));
  };

  
  // @todo: check if  "container" is needed
  return !store ? (
    <PageCircularProgress />
  ) : (
    <EstimateEditorStoreProvider value={store}>
      <LibraryStoreProvider value={libraryStore}>
        <Container>
          <LibraryView />
        </Container>
      </LibraryStoreProvider>
    </EstimateEditorStoreProvider>
  );
};
