import { useRef, useState } from "react";
import { bool, func, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { setEstimationStatusQuery } from "@query";
import { getSectionWtClass } from "@utils";
import { useCheckEstimateEditorAccess, useEditorWebsocket } from "@hooks";
import {
  useEstimateEditorStore,
  useEstimateStructure,
  useEstimateTableSettings, 
  useEstimateEditorSettings,
  WorkTypeManager,
  UserPermits, 
} from "@tools";
import { Alert, MenuButton, PopMenu } from "@components";
import { IconButton } from "@material-ui/core";
import { Settings, Trash, Percent, Users } from "@assets";
import { Clear, Check } from "@material-ui/icons";
import classnames from "classnames";

export const HeaderWorkTypeActions = observer(({
  workType,
  section,
  sectionWorkType,
  hasPermit,
  isPending,
  userSeesOnlyPermittedWorkTypes,
  openWorkTypeEstimateDialog,
}) => {
  const { isLibrary } = useEstimateEditorSettings();
  const { visibleWorkTypes } = useEstimateTableSettings();
  const structure = useEstimateStructure();
  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  const { projectUuid } = useParams();
  const { t } = useTranslation();

  const [visible, show] = useState(false);
  const [alertVisible, showAlert] = useState(false);
  const [workTypeModal, openWorkTypeModal] = useState(false);
  const [userPermitsVisible, openUserPermits] = useState(false);

  const anchor = useRef(null);

  const { id, name, inBreakdown } = workType || {};

  const { isOwner } = useCheckEstimateEditorAccess(editorStore.estimateUsers);

  const handleWorkTypeRemove = () => {
    if (isLibrary) {
      workType.setBreakdownVisibility(!inBreakdown);
      return;
    }
    showAlert(true);
  };

  const removeWorkType = async () => {
    structure.historyManager.startGroup();
    const path = workType.treePath.join("/");
    section.removeWorkType(id);
    const removed = structure.checkAndRemoveSectionWorkType(id);
    if(removed && !isLibrary) {
      socket?.requestCommentPathRemove(path);
      socket?.requestCommentPathRemove("t"+path);
    }
    structure.historyManager.stopGroup();
  };

  const handleWorkTypePermits = () => {
    openUserPermits(true);
    handleMenuClose();
  };

  const handleStatusChange = async () => {
    const status = editorStore.updateLocalPermit(workType.id)
    socket?.setPermitStatus(workType.id, status)
    await setEstimationStatusQuery(projectUuid, workType.id);
  };

  const handleWorkTypeEstimate = () => {
    openWorkTypeEstimateDialog(true);
    handleMenuClose();
  };

  const handleWorkTypeChange = () => {
    openWorkTypeModal(true);
    handleMenuClose();
  };

  const handleMenuClose = () => {
    show(false);
  };

  return (
    <>
      <IconButton
        style={{ right: -4 }}
        size="small"
        color="primary"
        onClick={() => show(!visible)}
        ref={anchor}
        tabIndex={-1}
        aria-label={`"${name}" work type settings`}
        className={classnames("noDrag cellHoverIcon absolute text-sm", getSectionWtClass(section.id, workType.id))}
      >
        <Settings />
      </IconButton>

      <PopMenu
        show={visible}
        anchor={anchor?.current}
        onClickAway={() => show(false)}
        style={{ minHeight: 0, width: 208 }}
        showArrow
      >
        {hasPermit && (
          <MenuButton
            icon={isPending ? <Check /> : <Clear />}
            onClick={handleStatusChange}
          >
            {t("views.editor.status_change")}
          </MenuButton>
        )}
        {!isLibrary && (
          <MenuButton icon={<Percent />} onClick={handleWorkTypeEstimate}>
            {t("views.editor.automation")}
          </MenuButton>
        )}
        {isOwner && (
          <MenuButton icon={<Users />} onClick={handleWorkTypePermits}>
            {t("views.editor.workType_permits")}
          </MenuButton>
        )}
        {
          !userSeesOnlyPermittedWorkTypes &&
          <MenuButton icon={<Settings />} onClick={handleWorkTypeChange}>
            {t("views.editor.change_workType")}
          </MenuButton>
        }
        <MenuButton
          icon={<Trash color="error" />}
          onClick={handleWorkTypeRemove}
        >
          {t(
            `views.editor.${
              isLibrary ? "hide" : "remove"
            }_workType`
          )}
        </MenuButton>
      </PopMenu>
      <Alert
        isOpen={alertVisible}
        title={t(
          `alerts.editor.${
            structure.sectionsWithWorkType(id)
              ? "tag_remove_section"
              : "tag_remove"
          }`
        )}
        onAccept={removeWorkType}
        onCancel={() => showAlert(false)}
        acceptText={t("common.remove")}
        cancelText={t("common.cancel")}
      />
      {workTypeModal && (
        <WorkTypeManager
          open={workTypeModal}
          section={section}
          sectionWorkTypeId={sectionWorkType.id}
          onClose={() => openWorkTypeModal(false)}
          anchorEl={anchor?.current}
          isLibrary={isLibrary}
          visibleWorkTypes={visibleWorkTypes}
        />
      )}
      {isOwner && userPermitsVisible && (
        <UserPermits
          isOpen={true}
          onClose={() => openUserPermits(false)}
          workTypeId={id}
          workTypeName={name}
        />
      )}
    </>
  );
});

HeaderWorkTypeActions.propTypes = {
  workType: object.isRequired,
  section: object.isRequired,
  sectionWorkType: object.isRequired,
  hasPermit: bool,
  isPending: bool,
  userSeesOnlyPermittedWorkTypes: bool,
  openWorkTypeEstimateDialog: func,
};
