import { func } from "prop-types";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { home } from "@paths";
import { useEditorWebsocket } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { Alert } from "@components";
import { CircularProgress } from "@material-ui/core";

export const EstimateEditorInactivityMonitor = ({ reloadEditorData }) => {
  
  const {t} = useTranslation();
  const socket = useEditorWebsocket();
  const editorStore = useEstimateEditorStore();
  const navigate = useNavigate();
  const [visible, showAlert] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  
  const onIdle = () => {
    console.log("idle");
    showAlert(true);
    socket?.closeProject();
  }
  
  const timer = useIdleTimer({
    onIdle,
    timeout: 20*60*1000,
    throttle: 10*1000,//check every 10sec
    stopOnIdle: true,
  })
  
  useEffect(() => {
    return () => {
      timer.pause();
    }
  }, []);
  
  const handleAccept = async () => {
    if(isReloading)
      return;
    setIsReloading(true);
    const { hash, estimateData } = await reloadEditorData();
    
    socket?.joinProject(estimateData.uuid, hash, editorStore.isCreated, editorStore.sortedVersions[ 0 ].key);
    setIsReloading(false);
    showAlert(false);
    timer.start();
  }
  
  const handleCancel = () => {
    if(isReloading)
      return;
    navigate(home);
  }
  
  return <Alert
    isOpen={visible}
    title={t("alerts.editor.idle.title")}
    acceptText={(
      isReloading
      ? <>
        <CircularProgress size={ 18 } className="mr-2 text-alt" />
        {t("alerts.editor.idle.loading")}
      </>
      : t("alerts.editor.idle.accept")
    )}
    
    cancelText={t("alerts.editor.idle.cancel")}
    onAccept={handleAccept}
    onCancel={isReloading ? undefined : handleCancel}
  >
    {t("alerts.editor.idle.text")}
  </Alert>
}

EstimateEditorInactivityMonitor.propTypes = {
  reloadEditorData: func.isRequired
}