import { useEffect } from "react";
import { getStructureMetadata } from "@utils";
import { useEditorWebsocket } from "@hooks";
import { useEstimateEditorStore, useEstimateStructure } from "@tools";

export const useHistoryManagerKeybinds = (onCommentMove) => {
  
  const socket = useEditorWebsocket();
  const editorStore = useEstimateEditorStore();
  const structure = useEstimateStructure();
  
  const handleStructureActionHistory = (e) => {
    
    if(!editorStore.useHistoryManager || !(e.ctrlKey || e.metaKey) || e.target.className.includes("ql-editor"))
      return;
    
    const isZKey = e.key.toUpperCase() === "Z";
    const isYKey = e.key.toUpperCase() === "Y";
    let patches = [], prevPatches = [];
    
    if(isYKey || (isZKey && e.shiftKey)) {
      e.preventDefault();
      if(!structure.historyManager?.redoLevels)
        return;
      patches = structure.historyManager?.history[structure.historyManager?.undoIdx].patches;
      prevPatches = structure.historyManager?.history[structure.historyManager?.undoIdx].inversePatches;
      structure.historyManager?.redo();
      // socket?.requestStructurePatch(patches, getStructureMetadata(structure));
    }
    else if(isZKey) {
      e.preventDefault();
      if(!structure.historyManager?.undoLevels)
        return;
      patches = structure.historyManager?.history[structure.historyManager?.undoIdx - 1].inversePatches.slice().reverse();
      prevPatches = structure.historyManager?.history[structure.historyManager?.undoIdx - 1].patches.slice().reverse();
      structure.historyManager?.undo();
      // socket?.requestStructurePatch(patches, getStructureMetadata(structure));
    }
    
    if(patches.length > 0) {
      const commentMovePatchIndex = patches.findIndex(p => p.path.match(/^\/comments\/\d+\/place$/));
      if(commentMovePatchIndex >= 0) {
        onCommentMove?.(editorStore.currentVersionKey, prevPatches[commentMovePatchIndex].value, patches[commentMovePatchIndex].value)
      }
      socket?.requestStructurePatch(patches, getStructureMetadata(structure));
    }
  }
  
  useEffect(() => {
    if(editorStore.useHistoryManager) {
      window.document.addEventListener("keydown", handleStructureActionHistory);
    }
    return () => {
      window.document.removeEventListener("keydown", handleStructureActionHistory);
    }
  }, [structure]);
}