import { useState } from "react";
import { observer } from "mobx-react";
import { useStores } from "@hooks";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { Grid, Avatar, IconButton, CircularProgress } from "@material-ui/core";
import { Alert, Button } from "@components";
import { Add } from "@material-ui/icons";
import { Trash } from "@assets";
import { UserInfoForm } from "@forms";
import { toBase64 } from "@utils";
import imageCompression from "browser-image-compression";
import useStyle from "./SettingsViews.style";
import classnames from "classnames";
import { updateUserInfoQuery } from "@query";

export const Profile = observer(() => {
  const { userStore } = useStores();
  const { t } = useTranslation();
  const classes = useStyle();

  const [isLoading, setLoading] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState();
  // const [calendlyLink, setCalendlyLink] = useState(null);

  const { fullname, email, avatar } = userStore.data;

  const onDropAccepted = async (files) => {
    if (!isLoading && files.length) {
      setLoading(true);
      const compressedFile = await imageCompression(files[0], {
        maxSizeMB: 0.75,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
        fileType: "image/jpeg",
      });
      const a = await toBase64(compressedFile);
      await updateUserInfoQuery({ avatar: a });
      userStore.updateUserData({ avatar: a });
      setLoading(false);
    }
  };

  const onDropRejected = (fileRejections) => {
    if (fileRejections?.[0]?.errors?.[0])
      setFileErrorMessage(fileRejections[0].errors[0].message);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    // getFilesFromEvent: fileGetter,
    // maxSize: 800*1024,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg"],
    },
    onDropAccepted,
    onDropRejected,
  });

  const removeAvatar = async () => {
    setLoading(true);
    await updateUserInfoQuery({ avatar: null });
    userStore.updateUserData({ avatar: null });
    setLoading(false);
  };

  const saveChanges = async (data) => {
    try {
      const d = {
        ...data,
        firstname: data.fullname.split(" ")[0],
        lastname: data.fullname.split(" ").slice(1).join(" ")
      }
      await updateUserInfoQuery(data);
      userStore.updateUserData(d);
    } catch (e) {
      return { reset: true };
    }
  };

  // useEffect(() => {
  // 	(async () => {
  // 		const d = await dataStore.getCalendlyLink();
  // 		setCalendlyLink(d);
  // 	})();
  // }, []);

  // const saveCalendlyChanges = () => dataStore.setCalendlyLink(calendlyLink);

  // const removeCalendly = async () => {
  // 	if(savedCalendlyLink) {
  // 		await dataStore.setCalendlyLink();
  // 		setCalendlyLink('');
  // 	}
  // };

  // const calendlyMatched = calendlyLink?.match(/^https:\/\/calendly.com\/\w+(-?\w+)*(\/\w+(-?\w+)*)?$/);

  return (
    <Grid
      item
      container
      direction="column"
      alignItems="flex-start"
      justifyContent="center"
    >
      <h2 className="mb-4">{t("views.settings.menu_full.profile")}</h2>
      <Grid
        item
        container
        alignItems="center"
        justifyContent="flex-start"
        {...getRootProps({ className: "dropzone relative mb-8" })}
      >
        {avatar ? (
          <>
            <Avatar
              alt={fullname}
              src={avatar}
              variant="circular"
              className={classnames(classes.avatar, isLoading && "transparent-2")}
            />
            {isLoading && (
              <CircularProgress
                color="primary"
                size={24}
                className={classes.avatarProgress}
              />
            )}
          </>
        ) : (
          <IconButton className={classes.addAvatar} onClick={open}>
            {isLoading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <Add color="primary" />
            )}
          </IconButton>
        )}
        <Button
          onClick={open}
          variant="contained"
          color="secondary"
          icon={<Add color="primary" />}
          className={classes.actionButton}
        >
          {t("views.settings.photo_upload")}
        </Button>
        <Button
          onClick={removeAvatar}
          variant="contained"
          color="secondary"
          icon={<Trash color="error" />}
          className={classes.actionButton}
          disabled={!avatar}
        >
          {t("common.delete")}
        </Button>
        <input {...getInputProps()} />
        <Alert
          isOpen={!!fileErrorMessage}
          title={fileErrorMessage}
          acceptText={t("common.close")}
          onAccept={() => setFileErrorMessage()}
        />
      </Grid>
      <UserInfoForm
        onSubmit={saveChanges}
        defaultValues={{ fullname, email }}
      />
      {/*<Divider className={classes.formDivider} />*/}
      {/*<InfoLabel*/}
      {/*	label="integrations.calendly.label"*/}
      {/*	tooltipText="integrations.calendly.desc"*/}
      {/*/>*/}
      {/*<Grid item container wrap="nowrap" alignItems="center">*/}
      {/*	<TextField*/}
      {/*		autoComplete="current-password"*/}
      {/*		id="currentPassword"*/}
      {/*		value={calendlyLink}*/}
      {/*		size="small"*/}
      {/*		onChange={e => setCalendlyLink(e.target.value)}*/}
      {/*		placeholder="https://calendly.com/john-doe"*/}
      {/*		style={{flexShrink: 1, margin: 0}}*/}
      {/*		error={calendlyLink?.length && !calendlyMatched}*/}
      {/*		helperText={calendlyLink?.length && !calendlyMatched ? t('integrations.calendly.error') : ''}*/}
      {/*		InputProps={savedCalendlyLink ? {*/}
      {/*			endAdornment: (*/}
      {/*				<IconButton*/}
      {/*					size="small"*/}
      {/*					color="secondary"*/}
      {/*					onClick={removeCalendly}*/}
      {/*				>*/}
      {/*					<Trash color="error" />*/}
      {/*				</IconButton>*/}
      {/*			)*/}
      {/*		} : undefined}*/}
      {/*	/>*/}

      {/*</Grid>*/}
      {/*{*/}
      {/*	calendlyLink && savedCalendlyLink !== calendlyLink ?*/}
      {/*		<Grid item container justifyContent="flex-end">*/}
      {/*			<Button*/}
      {/*				onClick={() => setCalendlyLink(savedCalendlyLink)} variant="contained"*/}
      {/*				color="secondary"*/}
      {/*				disabled={!calendlyMatched}*/}
      {/*				style={{marginRight: 8}}*/}
      {/*			>*/}
      {/*				{t('common.cancel')}*/}
      {/*			</Button>*/}
      {/*			<Button*/}
      {/*				onClick={saveCalendlyChanges} variant="contained"*/}
      {/*				color="primary"*/}
      {/*				disabled={!calendlyMatched}*/}
      {/*			>*/}
      {/*				{t('common.save')}*/}
      {/*			</Button>*/}
      {/*		</Grid> : undefined*/}
      {/*}*/}
    </Grid>
  );
});
