import { useMemo } from "react";
import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Columns } from "project-structure";
import { LOCKED_FEATURES, CELL_WIDTHS } from "@utils";
import { useFeatureGuardian } from "@hooks";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  PriceCell,
  TitleCell,
  ValueCell,
  SummaryRow,
} from "@tools";
import { AddButtonFull } from "@components";

export const SummaryNewChildRow = observer(({
  workType,
}) => {
  const { t } = useTranslation();
  const { settings, workTypesHaveResources, usesTwoValues } = useEstimateStructure();
  
  const {
    isSellerOrClient,
    useVisibilityCell,
    isProposal,
  } = useEstimateEditorSettings();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.ALLOCATION
  );

  const {
    visibleSummaryColumns,
    hasTeamMembersCol,
    hasTimeCol,
  } = settings;
  
  const hasHoursCell = useMemo(
    () =>
      Boolean(
        visibleSummaryColumns.find((col) => col.name === Columns.HOURS)
      ),
    [visibleSummaryColumns]
  );
  
  const hasRateCell = useMemo(
    () =>
      Boolean(visibleSummaryColumns.find((col) => col.name === Columns.RATE)),
    [visibleSummaryColumns]
  );
  
  const hasCostCell = useMemo(
    () =>
      Boolean(visibleSummaryColumns.find((col) => col.name === Columns.COST)),
    [visibleSummaryColumns]
  );

  const handleNewResource = async () => {
    const allowed = await checkAccess();
    if (!allowed) return;
    workType.addResource(true, `r${new Date().getTime()}`, false);
  };

  const tableDepth = 1;

  return (
    <SummaryRow
      useExpandCell
      isLastGroupRow
      displayMinMax={usesTwoValues}
      useVisibilityCell={useVisibilityCell}
      tableDepth={tableDepth}
    >
      <TitleCell
        allowOverflowDisplay
        minWidth={CELL_WIDTHS.TITLE_SUMMARY}
        pinnedLeft={!isProposal || workTypesHaveResources ? 1 : 0}
      >
        <AddButtonFull
          id="newTeamMemberButton-summary"
          onClick={handleNewResource}
          name="Add new resource to work type"
        >
          {t("views.editor.summary_table.work_resource_add")}
        </AddButtonFull>
      </TitleCell>
      { hasTeamMembersCol && <ValueCell wider /> }
      { hasTimeCol && <ValueCell widest /> }
      { hasRateCell && <ValueCell widest /> }
      { isSellerOrClient && hasHoursCell && <ValueCell widest /> }
      { isSellerOrClient && hasCostCell && <PriceCell widest={usesTwoValues} extendPadding={!useVisibilityCell} /> }
      <FeatureAlert />
    </SummaryRow>
  );
});

SummaryNewChildRow.propTypes = {
  workType: object.isRequired,
};
