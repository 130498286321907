import { useContext } from "react";
import { ActiveProjectStoreContext } from "@stores";

export const useActiveProjectStore = (optional=false) => {
  const context = useContext(ActiveProjectStoreContext);
  if (context === null && !optional) {
    throw new Error("ActiveProjectStoreContext cannot be null, please add a context provider");
  }
  return context;
};
