import { object, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { useEstimateEditorSettings } from "@tools";
import { IconButton, Tooltip } from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import classnames from "classnames";

export const WorkTypeColumnExpand = ({
  section,
  className,
}) => {
  const {t} = useTranslation();
  const { blockExpansions, visibilityMode, previewMode } = useEstimateEditorSettings();

  const { showCombinedHours } = section;
  
  const noAction = blockExpansions || visibilityMode || previewMode;

  const handleWorkTypeColumnExpand = () => {
    if(noAction) return;
    section.setShowCombinedHours(!showCombinedHours);
  }

  return (
    <Tooltip
      title={t(`views.editor.estimate.${showCombinedHours ? "show" : "hide"}`)}
      disableHoverListener={noAction}
      disableFocusListener={noAction}
      disableTouchListener={noAction}
    >
      <IconButton
        tabIndex={-1}
        size="small"
        color="primary"
        name="Expand/collapse work type columns"
        className={classnames(
          "noDrag text-xl p-0",
          showCombinedHours ? "rotate-90" : "-rotate-90",
          className
        )}
        onClick={handleWorkTypeColumnExpand}
      >
        <ExpandMore />
      </IconButton>
    </Tooltip>
  )
}

WorkTypeColumnExpand.propTypes = {
  section: object.isRequired,
  className: string,
}