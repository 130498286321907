import { useMemo, useState } from "react";
import {
  bool,
  number,
  string,
  func,
  oneOf,
  node,
  arrayOf,
  shape,
} from "prop-types";
import { CircularProgress, Divider, Grid, Link, Paper } from "@material-ui/core";
import { Button } from "@components";
import { useTranslation } from "react-i18next";
// import { parsePrice } from "project-structure";
import { CircleCheck, PriceTag } from "@assets";
import useStyle from "./SubscriptionPlan.style";
import classnames from "classnames";

export const SubscriptionPlan = ({
  code,
  name,
  children,
  prices,
  userAmount,
  estimateAmount,
  duration,
  onSelect,
  onRequestPrice,
  selectButtonText,
  highlight,
  isUsed,
  hashLink,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const [isLoading, setLoading] = useState(false);

  const hasSelect = useMemo(() => Boolean(onSelect), []);
  const usePriceRequests = useMemo(() => Boolean(onRequestPrice), []);
  const showPrice = useMemo(() => prices !== undefined, [prices]);

  const finalPrice = useMemo(() => {
    if (!showPrice) return 0;
    const o = prices[duration]
      .find((p) => p.estimates === estimateAmount)
      ?.tiers?.find(
        (t) =>
          (t.from < userAmount && t.to >= userAmount) ||
          (!t.to && t.from <= userAmount)
      );

    return o ? o.price + o.priceUnit * userAmount : 0;
  }, [estimateAmount, userAmount, duration]);
  
  {/** @note: leave this; might be back someday */}
  // const displayPrice = useMemo(
  //   () => Math.round(finalPrice / (duration === "month" ? 1 : 12)),
  //   [duration, finalPrice]
  // );

  const handleSubSelect = () => {
    const stripePriceId = prices?.[duration]?.find(
      (p) => p.estimates === estimateAmount
    )?.id;
    onSelect && onSelect(code, name, stripePriceId, finalPrice);
  };
  const handleRequestPrice = async() => {
    if(!onRequestPrice) return;
    
    const stripePriceId = prices?.[duration]?.find(
      (p) => p.estimates === estimateAmount
    )?.id;
    setLoading(true);
    await onRequestPrice(code, name, stripePriceId, finalPrice);
    setLoading(false);
  };

  const hashLinkClick = (e) => {
    e.preventDefault();
    document.getElementById(hashLink).scrollIntoView();
  };

  return (
    <Grid item container alignItems="flex-end" xs={12} sm={6} md key={code}>
      <Paper
        elevation={0}
        className={classnames(
          classes.wrapper,
          finalPrice && classes.wrapperAlt1,
          highlight && classes.wrapperAlt2
        )}
      >
        <Grid item container direction="column" className={classes.container}>
          {isUsed ? (
            <Grid item container className={classes.topper}>
              <PriceTag className={classes.topperTag} />
              <span>{t("plan.default")}</span>
            </Grid>
          ) : (
            highlight && (
              <Grid item container className={classes.topper}>
                <PriceTag className={`${classes.topperTag} ${classes.best}`} />
                <span>{t("views.subscription.plan.popular")}</span>
              </Grid>
            )
          )}
          <p
            className={classnames(
              classes.name,
              highlight && classes.nameHighlight
            )}
          >
            {`${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`}
          </p>
          {/** @note: leave this; might be back someday */}
          {/*{showPrice && (*/}
          {/*  <p>*/}
          {/*    <span*/}
          {/*      className={classnames(*/}
          {/*        classes.price,*/}
          {/*        highlight && classes.priceHighlight*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      {"$"}*/}
          {/*      {parsePrice(displayPrice)}*/}
          {/*    </span>*/}
          {/*    <span*/}
          {/*      className={classnames(*/}
          {/*        classes.duration,*/}
          {/*        highlight && classes.durationHighlight*/}
          {/*      )}*/}
          {/*    >*/}
          {/*      {"/"}*/}
          {/*      {t("time.month")}*/}
          {/*    </span>*/}
          {/*  </p>*/}
          {/*)}*/}
          <Divider className={classes.priceDivider} />
          {!!estimateAmount && (
            <Grid item container alignItems="center" className={classes.amount}>
              <CircleCheck className={classes.checkmark} />
              <strong>{estimateAmount}</strong>
              <span>{t("views.subscription.plan.active_projects")}</span>
            </Grid>
          )}
          {!!userAmount && (
            <Grid item container alignItems="center" className={classes.amount}>
              <CircleCheck className={classes.checkmark} />
              <strong>{userAmount}</strong>
              <span>{t("common.users")}</span>
            </Grid>
          )}
          {children}
          {!children && <span className={classes.buttonDivider} />}
          {hasSelect && (
            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                size={highlight ? "large" : "medium"}
                color={finalPrice && !highlight ? "secondary" : "primary"}
                className={classnames(
                  classes.button,
                  !finalPrice && classes.wrapperAlt2,
                  highlight && classes.wrapperAlt1
                )}
                onClick={handleSubSelect}
                // disabled={currentSub === code  && currentDuration === selectedDuration[code]}
              >
                {selectButtonText || t("views.subscription.plan.get")}
              </Button>
            </Grid>
          )}
          {usePriceRequests && (
            <Grid item container justifyContent="center">
              <Button
                variant="contained"
                size={highlight ? "large" : "medium"}
                color={finalPrice && !highlight ? "secondary" : "primary"}
                className={classnames(
                  classes.button,
                  !finalPrice && classes.wrapperAlt2,
                  highlight && classes.wrapperAlt1
                )}
                onClick={handleRequestPrice}
              >
                {
                  isLoading
                    ? <CircularProgress size={24} color="inherit" />
                    : t("views.subscription.plan.request")
                }
              </Button>
            </Grid>
          )}
          {hashLink && (
            <Link className={classes.featuresLink} onClick={hashLinkClick}>
              {t("views.subscription.plan.see_features")}
            </Link>
          )}
        </Grid>
      </Paper>
    </Grid>
  );
};

const pricePropType = arrayOf(
  shape({
    id: string.isRequired,
    estimates: number.isRequired,
    currency: string.isRequired,
    tiers: arrayOf(
      shape({
        price: number.isRequired,
        priceUnit: number.isRequired,
        from: number.isRequired,
        to: number,
      })
    ).isRequired,
  })
);

SubscriptionPlan.propTypes = {
  code: string.isRequired,
  name: string.isRequired,
  children: node.isRequired,
  prices: shape({
    month: pricePropType,
    year: pricePropType,
  }),
  userIndex: number,
  estimateIndex: number,
  userAmount: number,
  estimateAmount: number,
  duration: oneOf(["year", "month"]),
  onSelect: func,
  onRequestPrice: func,
  selectButtonText: string,
  showPrice: bool,
  highlight: bool,
  isUsed: bool,
  hashLink: string,
};
