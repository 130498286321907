import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import {
  usePresetFieldNames,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  ResetValueButton, useEstimateEditorSettings,
} from "@tools";
import { TotalContainer } from "../components";

export const TotalTime = observer(({ showDivider }) => {
  const {t} = useTranslation();
  const structure = useEstimateStructure();
  const { allowEdition, isProposal } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  
  const {
    settings,
    visibility,
    displayTotalTime,
    displayTotalTimeMax,
    showMaxTotalTime,
  } = structure;
  const { useMinMax, useWeekTime, isTotalTimeOverwritten } = settings;
  const { hideTotalTime } = visibility;
  const className = useVisibilityModeCellClassName(hideTotalTime);
  
  const presetText = useWeekTime ? "pt-totalTimeWeeks" : "pt-totalTimeMonths";
  
  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    settings.setCustomTotalTime({ [key]: time });
  };
  
  return (
    <TotalContainer
      commentPath="time"
      totalName="Time"
      min={displayTotalTime}
      max={displayTotalTimeMax}
      showMaxValue={showMaxTotalTime}
      onValueChange={handleTimeChange}
      editable
      showDecimals
      textSmaller
      className={className}
      valueClassName={presetText}
      visibilityHidden={hideTotalTime}
      setVisibility={visibility.setHideTotalTime}
      symbolEnd={<span>{ getFieldName(presetText) }</span>}
      showDivider={showDivider}
    >
      {allowEdition && isTotalTimeOverwritten && (
        <ResetValueButton
          action={() => settings.resetTotalTimeValues()}
          className="text-4xl"
        >
          {t("common.restore_value")}
        </ResetValueButton>
      )}
    </TotalContainer>
  );
})

TotalTime.propTypes = {
  showDivider: bool,
}