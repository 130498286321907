import { createContext, useContext } from "react";
import { instanceOf, node } from "prop-types";
import { action, computed, makeObservable, observable } from "mobx";
import { StructureModel, Tables, unzipStructure } from "project-structure";
import { intersection } from "lodash";
import { getCurrencyExchangeRatesQuery, notifyAboutClientCommentQuery } from "@query";
import {
  WT_STATUS,
  VERTICAL_PADDING,
  HORIZONTAL_PADDING,
  SCALE_PRESETS,
  PROPOSAL_THUMB_ID,
  PAGE_VISIBLE_BOTTOM_OFFSET,
  PROJECT_TYPE,
  TABLE_CONTAINER_ID,
  PROJECT_STATUS,
  PROJECT_COLUMNS,
  ShareLinkModifiers,
  universalDateParser,
  parseUsedPages,
  safeJSONParse,
  getPdfPage,
} from "@utils";

// =================== Context, Provider & Hook ===================
export const EstimateEditorStoreContext = createContext(null);
export const EstimateEditorStoreProvider = ({ children, value }) => (
  <EstimateEditorStoreContext.Provider value={value}>
    {children}
  </EstimateEditorStoreContext.Provider>
);

export const useEstimateEditorStore = () => {
  const context = useContext(EstimateEditorStoreContext);
  if (context === null) {
    throw new Error("EstimateEditorStoreContext cannot be null, please add a context provider");
  }
  return context;
};


// =================== Store ===================
export class EstimateEditorStore {
  constructor({
    estimateType,
    estimateData,
    workTypes,
    logo,
    unzipped,
    isProposal,
    teamMembers,
    useHistoryManager,
    btIssues,
    companySettings,
    isCreated,
    companyLogo,
    commenterData,
    commentsVisible,
    defaultEstimateVersionsForPreview,
    defaultComments,
  }) {
    makeObservable(this);

    this.isStandardEstimate = estimateType === PROJECT_TYPE.STANDARD;
    if(isProposal) this.isProposal = true;
    if(logo) this.setCompanyLogo(logo);
    if(useHistoryManager) this.useHistoryManager = useHistoryManager;
    if(workTypes) this.setWorkTypes(workTypes);
    if(companySettings) this.setCompanySettings(companySettings);
    if(companyLogo) this.setCompanyLogo(companyLogo);
    if(estimateData)
      this.setEstimateData(estimateData, isProposal, unzipped, btIssues);
    if(teamMembers) this.setTeamMembers(teamMembers);
    if(commentsVisible)
      this.commentsVisible = commentsVisible;
    if(commenterData)
      this.commenterData = commenterData;
    this.isCreated = Boolean(isCreated);

    if(defaultEstimateVersionsForPreview) {
      this.estimateVersions = defaultEstimateVersionsForPreview;
      this.currentVersion = defaultEstimateVersionsForPreview[0].key;
      if(defaultComments)
        defaultEstimateVersionsForPreview.historyManager?.withoutUndo(() => {
          defaultEstimateVersionsForPreview.setComments(defaultComments);
        })
    }
  }

  // =============== PROPERTIES ===============
  isProposal;
  blockedAverageAlert;
  blockedRiskAlert;
  blockedValueAlert;
  hadDifferentValues;
  useHistoryManager;

  @observable teamMembers = [];

  @observable estimateLoaded = false;
  @observable estimateName;
  @observable estimateDesc;
  @observable estimateDue;
  @observable estimateCategory;
  isStandardEstimate;
  @observable isCreated;
  @observable isRestored;

  @observable proposalStep = 0;

  @observable estimateStatus;
  @observable estimateUsers;
  @observable estimateId;
  @observable estimateUserWorkTypeVisibility = {};
  @observable companyLogo = null;
  @observable shareLinkSettings = null;

  @observable workTypes = [];
  @observable workTypesWithStatus = [];

  @observable shareModalForced = false;

  @observable estimateVersions = [];
  @observable currentVersion;
  @observable newVersionLoading;

  savedExchangeRates = [];
  defaultRates;

  
  @observable visibilityMode = false;
  @observable allowVisibility = false;
  
  @observable profitabilityMode = false;
  @observable allowProfitability = false;
  
  @observable allowedInternalComments = false;
  @observable addedCommentThisSession = false;
  @observable commentsVisible = false;
  @observable commenterData = null;
  
  @observable hasProjectComments = false;
  
  // @observable currencyExchangeRate = 1;

  // ----------- PDF -----------

  @observable pdfDocument;
  @observable newPdfFile = false;
  @observable currentPage = 0;
  @observable totalPages = 0;
  @observable allPdfPages = [];
  @observable usedPdfPages = [];
  @observable proposalTablePosition;
  basePagesUrl;
  documentChanged;

  containerRef = null;
  pageViewRef = null;
  thumbViewRef = null;

  tmpScale = 1;
  @observable scale = 1;
  @observable scaleValue = "page-fit";

  // =============== COMPUTED-S ===============

  @computed get isArchived() {
    return this.estimateStatus === PROJECT_STATUS.ARCHIVE;
  }
  
  @computed get isRemoved() {
    return this.estimateStatus === PROJECT_STATUS.REMOVED;
  }
  
  @computed get presetEditionMode() {
    return this.proposalStep === 1;
  }

  @computed get currentEstimateStructure() {
    return this.estimateVersions?.slice()?.find((v) => v.key === this.currentVersion)
      ?.structure;
  }
  
  @computed get currentVersionKey() {
    return this.estimateVersions?.slice()?.find((v) => v.key === this.currentVersion)
      ?.key;
  }
  
  @computed get currentVersionName() {
    return this.estimateVersions?.slice()?.find((v) => v.key === this.currentVersion)
      ?.name;
  }

  @computed get currentVersionVisible() {
    return Boolean(
      this.estimateVersions?.slice()?.find((v) => v.key === this.currentVersion)?.visible
    );
  }
  
  @computed get currentVersionLocked() {
    return Boolean(
      this.estimateVersions?.slice()?.find((v) => v.key === this.currentVersion)?.locked
    );
  }
  
  @computed get sortedVersions() {
    return this.estimateVersions
      .slice()
      .sort((a, b) => (a.order > b.order ? 1 : -1));
  }
  
  @computed get visibleEstimateVersions() {
    return this.estimateVersions.filter(
      ({ visible }) => visible
    ).length;
  }

  @computed get pdfPages() {
    return this.usedPdfPages
      .slice()
      .sort((a, b) => (a[1] > b[1] ? 1 : -1))
      .map(([p]) => p);
  }

  pageMapFunc = (p) => this.allPdfPages[p - 1] || { id: p };
  @computed get pdfPageThumbnails() {
    return this.isStandardEstimate
      ? [
          ...this.headerPages.map(this.pageMapFunc),
          { id: PROPOSAL_THUMB_ID },
          ...this.footerPages.map(this.pageMapFunc),
        ]
      : this.pdfPages.map(this.pageMapFunc);
  }

  @computed get numPages() {
    return this.pdfPageThumbnails.length;
  }

  @computed get headerPages() {
    if (this.isStandardEstimate)
      return this.pdfPages.slice(0, this.proposalTablePosition);

    return this.pdfPages;
  }

  @computed get footerPages() {
    if (this.isStandardEstimate)
      return this.pdfPages.slice(this.proposalTablePosition);

    return [];
  }

  @computed get pdfPagesLoaded() {
    return (
      !this.pdfDocument ||
      (this.totalPages > 0 &&
        this.allPdfPages.filter((p) => !!p).length === this.totalPages)
    );
  }

  @computed get widestPageSize() {
    let width = 0,
      height = 0;

    this.allPdfPages.forEach((page) => {
      if (!page) return;
      if (page.originalWidth > width) {
        width = page.originalWidth;
        height = page.originalHeight;
      }
    });

    return { width, height };
  }
  
  @computed get nonOwnerTeamMembers() {
    return this.teamMembers.filter(user => !(
      (this.estimateUsers?.author && user.uuid === this.estimateUsers?.author?.uuid) ||
      (this.estimateUsers?.owner && user.uuid === this.estimateUsers?.owner?.uuid)
    ))
  }
  
  @computed get noTurningOff() {
    return this.shareLinkSettings?.[ShareLinkModifiers.NO_TURN_OFF] || false;
  }
  
  @computed get hasComments() {
    return this.estimateVersions?.find((v) => v.structure?.commentsFetched
      ? v.structure?.comments?.length
      : this.hasProjectComments[v.key]
    )
  }
  
  // =============== GETTERS ===============
  
  hasAssignedWorkTypes(userUuid) {
    return Boolean(this.workTypesWithStatus?.find(w => w.estimatorUuid === userUuid));
  }

  getWorkTypeDefaultRate(workTypeId) {
    return (this.defaultRates?.[workTypeId] || 0) / 60;
  }
  
  getWorkTypeName(workTypeId) {
    return this.workTypes?.find(wT => wT.id === workTypeId)?.name;
  }

  getPageImageUrl(pageNum) {
    return `${this.basePagesUrl}_${pageNum}.png`;
    // const s = decodeURIComponent(this.basePagesUrl).split("/");
    // s[s.length-1] = encodeURIComponent(s[s.length-1]);
    // return `${s.join("/")}_${pageNum}.png`
  }
  
  getEstimateUser(userUuid) {
    return this.estimateUsers.observer.find(u => u.uuid === userUuid);
  }
  
  isEstimateUser(userUuid) {
    return Boolean(this.estimateUsers.observer.find(u => u.uuid === userUuid))
  }

  getEstimateId() {
    return this.estimateData;
  }

  // =============== ACTIONS ===============
  
  setCompanySettings({
    blockedAverageAlert,
    blockedRiskAlert,
    blockedValueAlert,
    hadDifferentValues,
    defaultRates,
    isTimelineAllowed,
  }) {
    if (blockedAverageAlert) this.blockedAverageAlert = blockedAverageAlert;
    if (blockedRiskAlert) this.blockedRiskAlert = blockedRiskAlert;
    if (blockedValueAlert) this.blockedValueAlert = blockedValueAlert;
    if (hadDifferentValues) this.hadDifferentValues = hadDifferentValues;
    if (defaultRates) this.defaultRates = defaultRates;
    if (isTimelineAllowed) this.isTimelineAllowed = isTimelineAllowed;
  }
  
  @action setCommenterData(commenterData) {
    this.commenterData = commenterData;
  }
  
  @action setCommentsVisible(commentsVisible) {
    this.commentsVisible = commentsVisible;
  }
  
  @action setTeamMembers = (teamMembers) => {
    this.teamMembers = teamMembers;
  };

  @action setEstimateName = (name) => (this.estimateName = name);

  @action setEstimateStatus(status) {
    this.isRestored = this.estimateStatus === PROJECT_STATUS.ARCHIVE && Object.values(PROJECT_COLUMNS).includes(status);
    return (this.estimateStatus = status);
  }
  
  @action setRestored() {
    this.isRestored = undefined;
  }

  @action setWorkTypes = (workTypes) => {
    this.workTypes = workTypes;
  };

  @action addCustomEstimateWorkTypes = (workTypes) => {
    const ids = this.workTypes.map(w => w.id);
    this.workTypes = [
      ...this.workTypes,
      ...workTypes
        .filter(wT => !ids.includes(wT.id))
        .sort((a,b) => Math.abs(a.id) > Math.abs(b.id) ? 1 : -1)
    ];
  };

  @action setEstimateUsers = (users) => {
    this.estimateUsers = users;
  };
  
  @action setEstimateUserWorkTypeVisibility = (userId, status) => {
    this.estimateUserWorkTypeVisibility = {
      ...this.estimateUserWorkTypeVisibility,
      [userId]: status ? 1 : 0
    };
  };

  @action setCompanyLogo = (companyLogo) => {
    this.companyLogo = companyLogo;
  };
  
  @action async getExchangeRates(companyCurrency, currency) {
    let rate = this.savedExchangeRates.find(rates => intersection(rates.map(r => r.code), [companyCurrency, currency]).length === rates.length);
    if(!rate) {
      rate = await getCurrencyExchangeRatesQuery(companyCurrency, currency);
      this.savedExchangeRates.push(rate);
    }

    return rate;
  }

  @action async handleCurrentEstimateStructureProfitabilityMode() {
    const hasFreshDailyExchangeRate = this.currentEstimateStructure
      && !this.currentEstimateStructure.settings.hasOutdatedDailyExchangeRate();
    if(!this.currentEstimateStructure || this.currentEstimateStructure.settings.useCustomExchangeRate || hasFreshDailyExchangeRate) {
      if(hasFreshDailyExchangeRate) {
        const savedDailyRate = safeJSONParse(this.currentEstimateStructure.settings.savedDailyRate);
        if(savedDailyRate)
          this.savedExchangeRates.push(savedDailyRate);
      }
      return;
    }

    try {
      const { companyCurrency, currency } = this.currentEstimateStructure.settings;
      const rates = await this.getExchangeRates(companyCurrency, currency);
      this.currentEstimateStructure.settings.setDailyExchangeRate(rates);
      
      if(!this.currentEstimateStructure.usesNewPA)
        this.currentEstimateStructure.oneTimeReplaceCompanyUnitCost();
    } catch(e) {
      if(!this.currentEstimateStructure.settings.dailyExchangeRate)
        this.currentEstimateStructure.settings.setDefaultDailyExchangeRate();
    }
  }

  @action async switchProfitabilityMode() {
    if(!this.profitabilityMode)
      await this.handleCurrentEstimateStructureProfitabilityMode();
    
    this.profitabilityMode = !this.profitabilityMode;
    if (this.visibilityMode) this.visibilityMode = false;
  };
  
  @action setAllowProfitability(allowProfitability) {
    this.allowProfitability = allowProfitability
  }
  
  @action setAllowVisibility(allowVisibility) {
    this.allowVisibility = allowVisibility
  }

  @action setShareLinkSettings(key, value) {
    if(!this.shareLinkSettings)
      this.shareLinkSettings = {};
    
    this.shareLinkSettings[key] = value;
  }
  @action setAllShareLinkSettings(settings) {
    this.shareLinkSettings = settings;
  }
  //
  // @action setCurrencyExchangeRate = (
  //   companyCurrencyRate,
  //   estimateCurrencyRate
  // ) => {
  //   this.currencyExchangeRate = companyCurrencyRate * (1 / estimateCurrencyRate);
  // };

  @action setCreatedStatus(status) {
    this.isCreated = status;
  }

  @action cleanup = () => {
    this.setProposalStep(0);
    this.profitabilityMode = false;
    this.visibilityMode = false;

    this.estimateVersions = [];
    this.currentVersion = undefined;

    this.estimateDesc = undefined;
    this.estimateDue = undefined;
    this.estimateStatus = undefined;
    this.estimateCategory = undefined;
  };

  @action forceShareModal = (state) => {
    this.shareModalForced = state;
  };

  @action setProposalStep = (v, m) => {
    this.proposalStep = v !== null ? v : this.proposalStep + m;
    this.visibilityMode = false;
    this.profitabilityMode = false;
    
    this.currentEstimateStructure.historyManager.withoutUndo(() => {
      this.estimateVersions.forEach(( ver ) => {
        ver.structure?.visibility?.applyVisibility(this.proposalStep > 0);
      });
    })
  };

  @action useVisibilityMode = (use) => {
    this.visibilityMode = use;
    if (this.profitabilityMode) this.profitabilityMode = false;
    
    this.currentEstimateStructure.historyManager.withoutUndo(() => {
      this.estimateVersions.forEach(( ver ) => {
        ver.structure?.visibility?.applyVisibility(use);
      });
    });
  };

  @action
  setEstimateData(
    estimateData,
    isProposal = false,
    unzipped = false,
    btIssues = null
  ) {
    this.estimateLoaded = false;
    const {
      // uuid,
      name,
      content,
      tags,
      versions,
      category,
      logo,
      dueOn,
      status,
      users,
      visible,
      covers,
      timeline,
      shareLinkSettings,
    } = estimateData;
    this.setWorkTypesWithStatus(tags);

    if (name) this.estimateName = name;
    if (logo) this.companyLogo = logo;
    if (shareLinkSettings && typeof shareLinkSettings === "string")
      this.shareLinkSettings = JSON.parse(shareLinkSettings);
    
    if(versions?.length) {
      if(isProposal || btIssues !== null) {
        const parsedVer = versions.map(( v ) => this.unzipVersion(v, !timeline, unzipped))
        const hasVisibleVersion = parsedVer.find(v => v?.visible);
        const smallestOrder = parsedVer.reduce(( min, v ) => Math.min(v.order, min), Infinity);
        
        this.estimateVersions = parsedVer.filter(v => (!hasVisibleVersion && v.order === smallestOrder) || v?.visible);
        
        if ( this.currentVersion === undefined )
          this.currentVersion = this.sortedVersions[ 0 ].key;
        
        if (btIssues !== null) {
          this.currentVersion = btIssues.version;
          const versionIndex = versions.findIndex(
            (item) => item.key === this.currentVersion
          );
          const arrayIdWT = Object.values(btIssues.workTypes);
          
          let path;
          
          if (btIssues.issues[0].workTypes["-200"] >= 0) {
            const usedWorkTypes = this.estimateVersions[
              versionIndex
              ].structure.workTypes.filter((workType) =>
              arrayIdWT.includes(workType.id)
            );
            
            const sumWTRate = usedWorkTypes.reduce((totalRate, workType) => {
              return (
                totalRate +
                workType.rate *
                this.estimateVersions[versionIndex].structure.settings.modifier
              );
            }, 0);
            
            const averageWT =
              sumWTRate /
              usedWorkTypes.length /
              this.estimateVersions[versionIndex].structure.settings.modifier;
            
            const nameWorkTypes = usedWorkTypes
              .map((workType) => {
                return workType.name;
              })
              .join(", ");
            this.estimateVersions[versionIndex].structure.addWorkType(
              false,
              {
                id: -200,
                name: nameWorkTypes,
              },
              averageWT
            );
            this.estimateVersions[versionIndex].structure.settings.setBtWorkTypes(
              arrayIdWT
            );
            this.estimateVersions[versionIndex].structure.sections.forEach(
              (section) => {
                section.addWorkType(-200);
              }
            );
          } else {
            this.estimateVersions[versionIndex].structure.workTypes.forEach(
              (wT) => {
                if (!arrayIdWT.includes(wT.id)) wT.setTurnOffState(true);
              }
            );
          }
          
          this.estimateVersions[versionIndex].structure.sections.forEach((sec) => {
            if (!btIssues.sections.includes(sec.id)) sec.setTurnOffState(true);
          });
          btIssues.issues.forEach((item) => {
            path = `/sections/` + item.index.replace(/\./g, `/children/`);
            const el = path
              .split("/")
              .splice(1)
              .reduce(
                (node, key) => node[key],
                this.estimateVersions[versionIndex].structure
              );
            el.setBTValues(item.workTypes);
          });
          
          btIssues.newIssues.forEach((item) => {
            this.estimateVersions[versionIndex].structure.attachSection(item);
          });
          
          if (typeof btIssues.depth === "number")
            this.estimateVersions[versionIndex].structure.settings.setValueLevel(
              btIssues.depth
            );
        }
        
        if(this.estimateVersions[0]?.structure?.visibility) {
          
          this.currentEstimateStructure.historyManager.withoutUndo(() => {
            this.estimateVersions.forEach(ver => {
              ver.structure?.visibility?.applyVisibility(true);
            })
          })
        }
      } else {
        this.estimateVersions = versions.map(v => ({ ...v, structure: undefined }));
      }
    }
    

    if (covers?.[0]) {
      this.setPdfDocument(covers[0]);
    }

    this.estimateDue = dueOn && universalDateParser(dueOn);
    this.estimateStatus = status;
    this.estimateDesc = content;
    this.estimateCategory = category;
    this.estimateUsers = users;
    this.estimateId = estimateData.id;
    this.estimateUserWorkTypeVisibility = visible || {};
    this.hasProjectComments = versions?.reduce((o,v) => ({ ...o, [v.key]: v.hasComments}), {}) || []
    this.estimateLoaded = true;
  }


  @action setVersions(versions) {
    this.estimateVersions = versions.map(v => ({ ...v, structure: undefined }));
  }

  @action setVersionData(versionData) {
    const index = this.estimateVersions?.findIndex((v) => v.key === versionData.key)
    if(index >= 0) {
      this.estimateVersions[ index ] = this.unzipVersion({ ...versionData }, !this.isProposal && !this.isTimelineAllowed);
      this.setCurrentVersion(versionData.key);
    }
  }
  
  @action unzipVersion(
    { structure, ...versionData },
    noTimeline = false,
    unzipped = false
    // ignoreVisibility = false
  ) {
    if(!structure)
      return versionData;

    const p = unzipped ? structure : JSON.parse(unzipStructure(structure));
    // if(isProposal && !p?.visibility?.version && !ignoreVisibility)
    //   return;
    
    const data = {
      ...versionData,
      structure: StructureModel.create(p),
    };

    if (noTimeline)
      data.structure.settings?.usedTables
        ?.find((t) => t.name === Tables.TIMELINE)
        ?.setVisibility(false);

    if (this.useHistoryManager) data.structure.initHistoryManager();

    return data;
  }

  @action addCustomWorkType(id, name, backgroundColor="#4098F4") {
    this.workTypes.push({
      id,
      name,
      backgroundColor,
      estimator: null,
      estimatorUuid: null,
      status: WT_STATUS.PENDING,
    });
    // this.addWorkTypeWithStatus({
    //   id,
    //   name,
    //   backgroundColor,
    // });
    this.addWorkTypeWithStatus(id);
  }
  
  @action updateWorkTypeData(id, name, backgroundColor) {
    const l = [...this.workTypes];
    const match = l.find(wT => wT.id === id);
    if(!match)
      return;
    
    match.name = name;
    match.backgroundColor = backgroundColor;
    this.workTypes = l;
  }

  // ----------- VERSIONS -----------

  @action setNewVersionLoading() {
    this.newVersionLoading = true;
  }

  @action addNewVersion(versionData, focus = false) {
    const version = this.unzipVersion(versionData);
    this.estimateVersions.push(version);
    this.newVersionLoading = false;
    if (focus) this.setCurrentVersion(version.key);
  }

  @action async setCurrentVersion(key) {
    this.currentVersion = key;
    if(this.profitabilityMode)
      await this.handleCurrentEstimateStructureProfitabilityMode();
  }

  @action editVersionName(versionKey, name) {
    const version = this.estimateVersions.find((v) => v.key === versionKey);
    version.name = name;
    // version.structure.setVersionName(name);
  }

  @action setVersionHidden(versionKey, visible) {
    const version = this.estimateVersions.find((v) => v.key === versionKey);
    version.visible = visible;
  }
  
  @action setEditionLock(versionKey, locked) {
    const version = this.estimateVersions.find((v) => v.key === versionKey);
    version.locked = locked;
  }

  @action removeSelectedVersion(versionKey) {
    const index = this.estimateVersions.findIndex((v) => v.key === versionKey);

    this.estimateVersions.splice(index, 1);
    // if (this.currentVersion === versionKey) {
    //   this.setCurrentVersion(
    //     this.estimateVersions[index === 0 ? index : index - 1].key
    //   );
    // }
  }

  @action updateVersionOrder(versionOrder) {
    this.estimateVersions.forEach((v) => {
      const o = versionOrder.find((vO) => vO.key === v.key);
      if(o) v.order = o.order;
    });
  }

  @action reorderVersions(list) {
    this.estimateVersions.forEach((v) => {
      v.order = list.indexOf(v.key);
    });
  }

  // ----------- ASSIGNMENTS -----------

  @action setWorkTypesWithStatus = (workTypes) => {
    this.workTypesWithStatus = workTypes || [];
  };
  @action addWorkTypeWithStatus = (workTypeId) => {
    const wT = this.workTypes.find((t) => t.id === workTypeId);
    if (!wT) return;

    this.workTypesWithStatus?.push({
      ...wT,
      estimator: null,
      estimatorUuid: null,
      status: WT_STATUS.PENDING,
    });
  };

  @action addLocalPermit(workTypeId, userUuid) {
    let workTypes = this.workTypesWithStatus.slice(0);
    let wT = workTypes.find((wT) => wT.id === workTypeId);
    if(!wT) {
      this.addWorkTypeWithStatus(workTypeId);
      workTypes = this.workTypesWithStatus.slice(0);
      wT = workTypes.find((wT) => wT.id === workTypeId);
    }
    wT.estimatorUuid = userUuid;
    this.workTypesWithStatus = workTypes;
  }
  @action removeLocalPermit(workTypeId) {
    const workTypes = this.workTypesWithStatus.slice(0);
    const wT = workTypes.find((wT) => wT.id === workTypeId);
    wT.estimatorUuid = undefined;
    this.workTypesWithStatus = workTypes;
  }

  @action updateLocalPermit(workTypeId, permit) {
    const workTypes = this.workTypesWithStatus.slice(0);
    const wT = workTypes.find((wT) => wT.id === workTypeId);
    wT.status =
      permit ||
      (wT.status === WT_STATUS.PENDING
        ? WT_STATUS.ESTIMATED
        : WT_STATUS.PENDING);
    this.workTypesWithStatus = workTypes;
    return wT.status;
  }

  // ----------- PDF -----------

  setContainerRef = (r) => (this.containerRef = r);
  setPageViewRef = (r) => (this.pageViewRef = r);
  setThumbViewRef = (r) => (this.thumbViewRef = r);

  @action setPdfDocument = (document, newPdfFile = false) => {
    this.removeDocument();
    this.newPdfFile = newPdfFile;
    this.pdfDocument = document;
    this.usedPdfPages = parseUsedPages(document.usedPages);
    this.proposalTablePosition = Number(document.tablePos);
    this.scale = Number(document.scale || 1);
    if (document.scale) this.scaleValue = SCALE_PRESETS.CUSTOM;

    this.basePagesUrl = decodeURIComponent(document.file.path).match(
      /(https?:\/\/.*.s3.*\.com\/.*\/.*)\.pdf/
    )[1];
  };

  @action async setupDocument(data) {
    this.pdfDocument.fileData = await data.getData();
    this.totalPages = data.numPages;
    if (this.newPdfFile || !this.usedPdfPages.length) {
      const usedPdfPages = parseUsedPages("[]", data.numPages);
      this.usedPdfPages = usedPdfPages;
      this.newPdfFile = false;
      this.scaleValue = SCALE_PRESETS.FIT;
      this.setScale(undefined, SCALE_PRESETS.FIT);
      return { usedPdfPages, scale: this.scale };
    }
  }

  @action handlePageLoaded(pageNum, originalWidth, originalHeight) {
    this.allPdfPages[pageNum - 1] = {
      id: pageNum,
      originalWidth,
      originalHeight,
      el: getPdfPage(pageNum),
    };
  }

  @action setScale(value, scaleValue = undefined, noPageUpdate = false) {
    if (!this.pageViewRef || !this.pdfDocument) return;
    if (!value && this.scaleValue === SCALE_PRESETS.CUSTOM) return;
    if (value) this.documentChanged = true;

    if (!value) value = this.scaleValue;

    let scale = parseFloat(value);

    if (scale > 0) {
      if (scale < 0.1) scale = 0.1;
      if (scale > 10) scale = 10;
    } else {
      const { width, height } = this.widestPageSize;

      if (width === 0) return;

      const containerWidth =
        this.pageViewRef.clientWidth - 2 * HORIZONTAL_PADDING;
      const pageWidthScale = containerWidth / width;

      const pageHeightScale =
        (this.pageViewRef.clientHeight - 2 * VERTICAL_PADDING) / height;

      switch (value) {
        case SCALE_PRESETS.WIDTH:
          scale = pageWidthScale;
          break;
        case SCALE_PRESETS.FIT:
          scale = Math.min(pageWidthScale, pageHeightScale);
          break;
        case SCALE_PRESETS.AUTO:
          scale = Math.min(1.25, Math.min(pageHeightScale, pageWidthScale));
          break;
        default:
          console.error(`_setScale: "${value}" is an unknown zoom value.`);
          return this.pdfDocument;
      }
    }

    if (!noPageUpdate) this.applyScale(scale, scaleValue || value);

    this.pdfDocument.scale = parseFloat(value);
    this.findCurrentPage();
    return {
      scale,
      scaleValue: scaleValue || value,
    };
  }

  @action applyScale(scale, scaleValue) {
    if (scale) this.scale = scale;
    if (scaleValue) this.scaleValue = scaleValue;
  }

  @action removeDocument() {
    this.pdfDocument = undefined;
    this.allPdfPages = [];
    this.usedPdfPages = [];
    this.currentPage = 0;
    this.totalPages = 0;
    this.scale = 1;
    this.scaleValue = 1;
    this.proposalTablePosition = -1;
  }

  @action removePage(pageId) {
    const index = this.usedPdfPages.find(([p]) => p === pageId)?.[1];
    this.usedPdfPages = this.usedPdfPages.filter((p) => {
      if (p[1] > index) p[1] = p[1] - 1;
      return p[0] !== pageId;
    });

    if (index < this.proposalTablePosition) {
      this.proposalTablePosition = this.proposalTablePosition - 1;
    }

    return {
      usedPages: this.usedPdfPages,
      tablePos: this.proposalTablePosition,
    };
  }

  @action jumpToPage(pageIndex) {
    const element =
      pageIndex === this.proposalTablePosition
        ? document.getElementById(TABLE_CONTAINER_ID)
        : this.pdfPageThumbnails[pageIndex]?.el;
    if (!element) return;
    this.pageViewRef.scrollTop = element.offsetTop - VERTICAL_PADDING;
  }

  @action reorderPages(list) {
    const proposalIndex = list.findIndex((p) => p.id === PROPOSAL_THUMB_ID);

    if (proposalIndex >= 0) {
      this.proposalTablePosition = proposalIndex;
      list.splice(proposalIndex, 1);
    }

    this.usedPdfPages = list.map(({ id }, i) => [id, i]);

    return {
      usedPages: this.usedPdfPages,
      tablePos: this.proposalTablePosition,
    };
  }

  @action findCurrentPage() {
    if (!this.pdfPagesLoaded) return;

    const tableContainer = document.getElementById(TABLE_CONTAINER_ID);
    const containerRect = this.pageViewRef.getBoundingClientRect();
    const pageIndex = this.pdfPageThumbnails.findIndex((p) => {
      const el = p.el || tableContainer;
      if (!el) return;
      const elementRect = el.getBoundingClientRect();
      return elementRect.y + PAGE_VISIBLE_BOTTOM_OFFSET > containerRect.y;
    });

    this.currentPage = pageIndex >= 0 ? pageIndex : this.totalPages;

    if (this.thumbViewRef) {
      const thumbContainerRect = this.thumbViewRef.getBoundingClientRect();
      const thumbEl =
        this.currentPage === this.proposalTablePosition
          ? tableContainer
          : document.getElementById(`page${this.currentPage}`);

      if (!thumbEl) return;
      if (
        thumbEl.getBoundingClientRect()?.y >
          thumbContainerRect.y + thumbContainerRect.bottom ||
        thumbEl.getBoundingClientRect()?.y < thumbContainerRect.y
      )
        this.thumbViewRef.scrollTop = thumbEl.offsetTop - VERTICAL_PADDING;
    }
  }

  @action setUsedPages(usedPdfPages) {
    this.usedPdfPages = usedPdfPages;
  }

  @action setTablePos(tablePos) {
    this.proposalTablePosition = tablePos;
  }
  
  @action async notifyUserAboutComment(shareLink) {
    this.addedCommentThisSession = true;
    await notifyAboutClientCommentQuery(shareLink);
  }
}

EstimateEditorStoreProvider.propTypes = {
  children: node.isRequired,
  value: instanceOf(EstimateEditorStore).isRequired,
};
