import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { PROPOSAL_STEPPER_ID } from "@utils";
import { useEstimateEditorStore } from "@tools";
import { ActionButton } from "@components";
import { Grid, Step, StepButton, Stepper, Toolbar } from "@material-ui/core";
import useStyle from "./ProposalStepper.style";
import classnames from "classnames";

export const ProposalStepper = observer(() => {
  const { t } = useTranslation();
  const classes = useStyle();
  const editorStore = useEstimateEditorStore();

  const { proposalStep } = editorStore;

  return (
    <Toolbar
      id={PROPOSAL_STEPPER_ID}
      className={classnames(
        classes.root,
        !proposalStep && classes.backgroundAlt
      )}
    >
      <Grid item className={classes.stepperNaviButtonWrapper}>
        {proposalStep !== 0 && (
          <ActionButton
            color="secondary"
            className="w-full"
            onClick={() => editorStore.setProposalStep(null, -1)}
          >
            {t("common.back")}
          </ActionButton>
        )}
      </Grid>
      <Grid
        item
        container
        wrap="nowrap"
        justifyContent="center"
        className={classes.stepperWrapper}
      >
        <Stepper nonLinear activeStep={proposalStep} style={{ maxWidth: 720 }}>
          {[1, 2, 3].map((s, i) => {
            return (
              <Step key={s}>
                <StepButton
                  completed={s.completed}
                  onClick={() => editorStore.setProposalStep(i)}
                  id={`editorStep${s}`}
                >
                  {t(`views.editor.steps.${s}`)}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
      <Grid item className={classes.stepperNaviButtonWrapper}>
        {proposalStep < 2 && (
          <ActionButton
            color="primary"
            className="w-full"
            onClick={() => editorStore.setProposalStep(null, 1)}
          >
            {t("common.next")}
          </ActionButton>
        )}
      </Grid>
    </Toolbar>
  );
});
