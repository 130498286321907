import { useEstimateEditorSettings, usePresetFieldNames } from "@tools";
import classnames from "classnames";

export const SummaryTitle = () => {
  const { isProposal } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  return (
    <p
      className={classnames(
        "text-sm",
        !isProposal && "transparent-3",
        isProposal && "preset-tableFooter pt-subtotal font-bold"
      )}
    >
      {getFieldName("pt-subtotal")}
    </p>
  );
}