import { makeStyles } from "@material-ui/core/styles";
import { PDF_TOOLBAR_HEIGHT } from "@styles/themes";

const useStyle = makeStyles((theme) => ({
  root: {
    height: PDF_TOOLBAR_HEIGHT,
    width: "100%",
    zIndex: 20,
    paddingLeft: 8,
    paddingRight: 24,
    alignSelf: "flex-end",
    "& > div": {
      position: "relative",
    },
  },
  input: {
    marginBottom: "0 !important",
    minWidth: 42,
    width: 42,
    marginRight: 4,
    borderRadius: 5,
    backgroundColor: theme.props.background,
    "& input": {
      padding: "2px 8px",
      textAlign: "right",
    },
    "& fieldset": {
      top: 0,
      borderColor: "transparent",
      "& > legend": { display: "none" },
    },
    "&:focus fieldset": {
      borderColor: theme.palette.primary.main,
    },
  },
  select: {
    marginBottom: "0 !important",
    minWidth: 80,
    maxWidth: 172,
    "& > div > div": {
      padding: "2px 8px",
    },
    "& fieldset": {
      top: 0,
      "& > legend": { display: "none" },
    },
  },
  buttonContainer: {
    width: "max-content",
    marginLeft: 12,
    marginRight: -2,
    right: 0,
  },
  divider: {
    height: 36,
    backgroundColor: theme.props.border,
    marginLeft: 8,
    marginRight: 8,
  },
  filler: {
    width: 0,
    "@media (min-width: 700px)": {
      width: "10%",
    },
    marginRight: 16,
  },
}));

export default useStyle;
