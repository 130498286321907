import { hashCode } from "project-structure";
import { MAX_PAGE_WIDTH } from "@styles/themes";
import { EDITOR_CONTAINER_ID, PROPOSAL_STEPPER_ID } from "@utils";

export const drawUserMovement = (data) => {
  if(!data?.id)
    return;
  
  const cursor = document.getElementById(hashCode(data.id));
  
  if (cursor) {
    let target =
      data.elementId &&
      document.querySelector(
        `[data-id="${data.elementId}"][data-t="${data.elementType}"]`
      );
    let parent,
      i = 0;
    
    if (data.elementPath && !target) {
      const l = data.elementPath.split("/").reverse();
      do {
        parent = document.querySelector(`[data-id="${l[i]}"]`);
        i++;
      } while (!parent && i < l.length);
    }
    
    if (target || !parent) {
      // const innerHeight = document.getElementById(TABLE_CONTAINER_ID).clientHeight;
      
      const scaleX =
        Math.min(MAX_PAGE_WIDTH, window.innerWidth) / Math.min(MAX_PAGE_WIDTH, data.vW);
      // const scaleY = innerHeight / data.vH;
      const offsetLeft =
        window.innerWidth < MAX_PAGE_WIDTH ? 24 : (window.innerWidth - MAX_PAGE_WIDTH) / 2;
      const x = Math.round(data.x * scaleX) + offsetLeft;
      const y = Math.round(data.y);
      
      cursor.style.top = `${y}px`;
      cursor.style.left = `${x}px`;
    } else if (parent) {
      const con = document.getElementById(EDITOR_CONTAINER_ID);
      if(!con) return;
      const stepperHeight =
        document.getElementById(PROPOSAL_STEPPER_ID)?.getBoundingClientRect()
          .height || 0;
      const par = parent.getBoundingClientRect();
      const l = parent.querySelector(".name")?.getBoundingClientRect()?.left;
      cursor.style.top = `${
        par.top +
        par.height / 2 +
        con.scrollTop -
        stepperHeight -
        con.getBoundingClientRect()?.y
      }px`;
      cursor.style.left = `${(l || par.left) + 24}px`;
    }
  }
};