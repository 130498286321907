import { useMemo, useState } from "react";
import { string, bool, number, func, shape, arrayOf } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Dialog, Alert } from "@components";
import { NewTemplateForm } from "@forms";
import { createTemplateQuery, forceProjectSaveQuery, saveTemplateQuery } from "@query";

export const CreateTemplateDialog = observer(({
  projectUuid,
  open,
  defaultValues,
  projectTypes,
  onClose,
  onChange,
  onNewTypeCreate,
  hasComments=false,
}) => {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  const edition = useMemo(() => !projectUuid, []);

  const saveTemplate = async (data) => {
    if (edition) await saveTemplateQuery(defaultValues.id, data);
    else {
      await forceProjectSaveQuery(projectUuid);
      await createTemplateQuery(projectUuid, data);
    }

    onChange && onChange(data);
    setShowModal(true);
    return { reset: true };
  };

  const closeAlert = () => {
    setShowModal(false);
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      width={600}
      title={t(
        `views.project.project_template.${
          edition ? "title_edit" : "title_full"
        }`
      )}
      containerClass="overflow-x-hidden"
    >
      <NewTemplateForm
        onSubmit={saveTemplate}
        onCancel={onClose}
        defaultValues={defaultValues}
        projectTypes={projectTypes}
        onNewTypeCreate={onNewTypeCreate}
        edition={edition}
        hasComments={hasComments}
      />
      <Alert
        isOpen={showModal}
        title={t(
          `views.project.project_template.${
            edition ? "success_save" : "success"
          }`
        )}
        acceptText={t("common.close")}
        onAccept={closeAlert}
      />
    </Dialog>
  );
});

CreateTemplateDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  onChange: func,
  onNewTypeCreate: func,
  projectUuid: string,
  hasComments: bool,
  defaultValues: shape({
    id: string,
    name: string,
    description: string,
    category: number,
  }),
  projectTypes: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    backgroundColor: string.isRequired,
    fontColor: string.isRequired,
  })),
};
