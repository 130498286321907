import { memo } from "react";
import { string } from "prop-types";
import { CELL_WIDTHS } from "@utils";
import { TitleCell, HeaderRow, useEstimateTableSettings } from "@tools";
import { Grid } from "@material-ui/core";
import { TableHeaderCell } from "./components/TableHeaderCell";

export const TableHeader = memo(({ tableIdentifier }) => {
  const { timelineLength } = useEstimateTableSettings();
  
  return (
    <HeaderRow
      id={tableIdentifier}
      useExpandCell
      altBackground
      className="preset-paperTimeline"
      expandCellPresetClass="preset-tableTimelineHeader"
    >
      <TitleCell
        pinnedLeft={1}
        maxWidth={CELL_WIDTHS.TITLE_SUMMARY}
        minWidth={CELL_WIDTHS.TITLE_SUMMARY_MIN}
        allowOverflowDisplay
      />
      <Grid item container wrap="nowrap" className="month-container w-max">
        {
          [...new Array(timelineLength).keys()]
            .map((i) => (
              <TableHeaderCell
                key={`month${i}`}
                monthIndex={i}
              />
            ))
        }
      </Grid>
    </HeaderRow>
  )
});

TableHeader.propTypes = {
  tableIdentifier: string.isRequired,
};
