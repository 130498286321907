import { bool, number, object } from "prop-types";
import { BREAKDOWN_TABLE_DEPTHS, DESCRIPTION_MODES } from "project-structure";
import { CELL_WIDTHS } from "@utils";
import {
  AddChildTask,
  DescriptionCell,
  SummaryRow,
  TitleCell,
  useEstimateEditorSettings,
  useEstimateStructure, useEstimateTableSettings
} from "@tools";
import classnames from "classnames";

export const BreakdownNewChildRow = ({
  element,
  tableDepth,
  actualDepthLevel,
  showDescriptionCell,
  isFirst,
}) => {
  
  const { useVisibilityCell } = useEstimateEditorSettings();
  const {
    visibleWorkTypes,
    useExpandCell,
    pricesVisible,
    showCombinedHours,
    maxTitleCellWidth,
    priceClassName,
    descriptionClassName,
  } = useEstimateTableSettings();
  const structure = useEstimateStructure();
  
  const { usesAvgValue } = structure;
  const {
    showBreakdownColumns,
    descriptionMode,
    descriptionCellWidth,
    titleCellWidth,
  } = structure.settings;
  
  return (
    <SummaryRow
      displayMinMax={!usesAvgValue}
      useExpandCell={!!actualDepthLevel && useExpandCell}
      useVisibilityCell={useVisibilityCell}
      showEmptyValueCells={showBreakdownColumns && visibleWorkTypes.length > 0}
      emptyValueCells={ visibleWorkTypes?.length}
      showEmptyCombinedValueCell={showCombinedHours}
      emptyPriceCell={pricesVisible}
      tableDepth={tableDepth}
      isLastGroupRow={tableDepth === 1}
      className={classnames(tableDepth === 0 && "bg-transparent")}
      priceClassName={priceClassName}
    >
      <TitleCell
        allowOverflowDisplay={descriptionMode < DESCRIPTION_MODES.COLUMN && !tableDepth}
        minWidth={
          showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE
        }
        maxWidth={maxTitleCellWidth}
        pinnedLeft={useExpandCell ? tableDepth : 0}
      >
        <AddChildTask
          parent={element}
          tableDepth={actualDepthLevel + 1}
          showId={isFirst}
        />
      </TitleCell>
      {showDescriptionCell && (
        <DescriptionCell
          allowOverflowDisplay={!!tableDepth}
          isSectionRow={actualDepthLevel === BREAKDOWN_TABLE_DEPTHS.SECTION}
          minWidth={descriptionCellWidth}
          left={titleCellWidth}
          className={descriptionClassName}
        />
      )}
    </SummaryRow>
  )
}

BreakdownNewChildRow.propTypes = {
  element: object,
  tableDepth: number,
  actualDepthLevel: number,
  showDescriptionCell: bool,
  isFirst: bool,
}