import { useEffect, useMemo, useState } from "react";
import { string } from "prop-types";
import { observer } from "mobx-react";
import { onPatch } from "mobx-state-tree";
import { useParams, useSearchParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { DEFAULT_VERSION_INFO } from "project-structure";
import { fetchProjectVersionCommentsQuery } from "@query";
import { getStructureMetadata } from "@utils";
import { useEditorWebsocket, useCheckEstimateEditorAccess, useStores } from "@hooks";
import { useFonts, useEstimateEditorStore } from "@tools";
import { ProjectEditorView } from "@views/Projects/ProjectEditor/ProjectEditorView";
import { PageCircularProgressWithLabel } from "@components";

export const ProjectEditorHandlers = observer(({ projectHash }) => {
  const { userStore, stateStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const { projectUuid } = useParams();
  const socket = useEditorWebsocket();
  const { closeSnackbar } = useSnackbar();
  const [ searchParams ] = useSearchParams();
  const { unloadFonts } = useFonts();
  
  const [isLoading, setLoading] = useState(false);
  
  const {
    estimateLoaded,
    currentEstimateStructure,
    estimateUsers,
    isCreated,
  } = editorStore;
  
  const { hasEditorPrivileges } = useCheckEstimateEditorAccess(estimateUsers);
  
  const actionListener = useMemo(
    () =>
      currentEstimateStructure &&
      onPatch(
        currentEstimateStructure,
        ({ path, value }) => {
          if(path.match(/\/historyManager\/history\/\d+/) && value?.patches)
            socket.requestStructurePatch(
              value.patches,
              getStructureMetadata(currentEstimateStructure),
            )
        }
      ),
    [currentEstimateStructure]
  );
  
  useEffect(() => () => {
    actionListener && actionListener();
  }, [currentEstimateStructure]);
  
  
  
  const onLoad = async () => {
    setLoading(true);
    
    editorStore.setCommentsVisible(hasEditorPrivileges);
    
    socket?.joinProject(
      projectUuid,
      projectHash,
      isCreated,
      isCreated
        ? editorStore.sortedVersions[ 0 ].key
        : DEFAULT_VERSION_INFO.key,
    );
    
    if (searchParams.get("share")) editorStore.forceShareModal(true);
    
    setLoading(false);
  };
  
  useEffect(() => {
    fetchProjectComments();
  }, [editorStore.currentEstimateStructure])
  
  useEffect(() => {
    if (estimateLoaded) onLoad();
  }, [estimateLoaded]);
  
  useEffect(() => () => {
    closeSnackbar(stateStore.lockedProjectVersionSnackbar);
    stateStore.lockedProjectVersionSnackbar = undefined;
    socket?.closeProject();
    
    return () => {
      unloadFonts();
      stateStore.resetProjectCreatorProgress();
    }
  }, []);
  
  const fetchProjectComments = async () => {
    if(!editorStore.currentVersionKey || !editorStore.currentEstimateStructure || !editorStore?.commentsVisible || editorStore.currentEstimateStructure.commentsFetched)
      return;
    
    // commentStore.setUseInternalComments(true);
    // commentStore.setUseExternalComments(hasSellerPrivileges);
    const c = await fetchProjectVersionCommentsQuery(projectUuid, editorStore.currentVersionKey, userStore.userUuid);
    editorStore.currentEstimateStructure.historyManager.withoutUndo(() => {
      editorStore.currentEstimateStructure.setComments(c);
    })
  }
  
  if (isLoading || !currentEstimateStructure)
    return <PageCircularProgressWithLabel
      value={stateStore.projectCreatorProgress}
      error={stateStore.projectCreatorProgressError}
      label={stateStore.projectCreatorProgressErrorMessage || stateStore.projectCreatorProgressStepName}
    />;
  
  return <ProjectEditorView />
});

ProjectEditorHandlers.propTypes = {
  projectHash: string.isRequired,
}
