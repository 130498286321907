import { useEffect, useMemo, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Tables } from "project-structure";
import ResizeObserver from "react-resize-observer";
import { EditorTablesFactory, useEstimateEditorStore, useEstimateStructure } from "@tools";
import { ProposalAppBar, Versioning } from "@tools";
import { TABLE_CONTAINER_ID } from "@utils";
import Grid from "@material-ui/core/Grid";
import { CONTENT_WIDTH } from "@styles/themes";
import useStyle from "../../styles/EditorContent.style";
import classnames from "classnames";

export const PdfEstimateContent = observer(() => {
  const classes = useStyle();
  const editorStore = useEstimateEditorStore();
  const structure = useEstimateStructure();
  
  const { settings } = structure;
  const { visibleTables } = settings;

  const { widestPageSize, scale } = editorStore;

  const containerWidth = (widestPageSize.width || 800) * scale;
  const transformScale = containerWidth / CONTENT_WIDTH;

  const [containerHeight, setContainerHeight] = useState(0);
  
  const tableRef = useRef(null);
  
  const breakdownTableIndex = useMemo(() => (
    visibleTables?.findIndex(t => t.name === Tables.BREAKDOWN)
  ), [visibleTables])
  

  useEffect(() => {
    if (tableRef.current?.children?.[0])
      setContainerHeight(
        tableRef.current?.children?.[0]?.offsetHeight * transformScale
      );
  }, [transformScale, tableRef.current?.children]);

  return (
    <Grid
      id={TABLE_CONTAINER_ID}
      item
      container
      direction="column"
      alignItems="center"
      className={classnames(
        "preset-page", 
        classes.scrollableContainer,
        classes.pageFormat,
      )}
      style={{
        width: containerWidth,
        height: containerHeight,
      }}
      ref={tableRef}
    >
      <Grid
        item
        container
        direction="column"
        alignItems="center"
        style={{
          transform: `scale(${transformScale})`,
          transformOrigin: transformScale > 1 ? "top" : "top left",
        }}
        className={classes.scalableContainer}
      >
        <ResizeObserver
          onResize={(rect) => setContainerHeight(rect.height)}
        />
        <ProposalAppBar className={classes.proposalBar} />
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          className={classnames(
            "preset-paper",
            classes.titleContainer,
            breakdownTableIndex === 0 && classes.titleContainerLast
          )}
          id="titleBar"
        >
          <Grid
            item container
            justifyContent="flex-end"
            className={classnames("pdf-mode", classes.titleBarRoot)}
          >
            <Versioning
              noEditing
              allowInteractions
              showPdfCover
            />
          </Grid>
        </Grid>
        {
          visibleTables.map(({ name }, index) => (
            <EditorTablesFactory
              key={name}
              index={index}
              tableName={name}
            />
          ))
        }
      </Grid>
    </Grid>
  );
});