import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEditorWebsocket } from "@hooks";
import {
  useEstimateEditorSettings,
  ChatThread,
  TimelineSettings,
} from "@tools"
import { TooltipIcon } from "@components";
import { Trash } from "@assets";

export const TimelineNestedRowActions = observer(({
  resource,
  parentUsesTimelinePercent,
}) => {
  const socket = useEditorWebsocket();
  const { t } = useTranslation();
  
  const {
    allowEdition,
    showChatThread,
  } = useEstimateEditorSettings();
  
    const path = resource.treePath.join("/");
    const timelinePath = "t"+path;

    const handleRemoval = async () => {
      resource.removeSelf();
      socket?.requestCommentPathRemove(path);
      socket?.requestCommentPathRemove(timelinePath);
    };

    return (
      <>
        {allowEdition && (
          <>
            <TooltipIcon
              icon={<Trash color="error" />}
              onClick={handleRemoval}
              className="button-remove hoverIcon"
            >
              {t("views.editor.remove_resource")}
            </TooltipIcon>
            {
              !parentUsesTimelinePercent &&
              <TimelineSettings
                fullSettings
                className="button-wta-settings hoverIcon opaque"
                selectedResource={resource.id}
              />
            }
          </>
        )}
        {
          showChatThread &&
          <ChatThread
            rowThread
            path={timelinePath}
          />
        }
      </>
    );
  }
);

TimelineNestedRowActions.propTypes = {
  resource: object.isRequired,
  allowEdition: bool,
  showChatThread: bool,
  allowCommenting: bool,
  parentUsesTimelinePercent: bool,
};
