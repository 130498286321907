import { APIMethod, fetchData } from "@client";
import { zipStructure } from "project-structure";

export async function getTemplateEditionDataQuery(id, companySettings) {
  const res = await fetchData(
    `/v2/permit/template/access/${id}`,
    APIMethod.GET,
    {
      params: {
        settings: companySettings?.length ? zipStructure(JSON.stringify(companySettings)) : null
      },
    }
  );

  if(res?.workTypes)
    res.workTypes = res.workTypes.filter(r => r.name?.length > 0);

  return res;
}
