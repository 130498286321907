import { bool, string, number, node, object, func, arrayOf, oneOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { BREAKDOWN_TABLE_DEPTHS } from "project-structure";
import { SortableList } from "@components";
import { TimelineNestedRow } from "../TimelineNestedRow/TimelineNestedRow";
import { TimelineRowGroup } from "../TimelineRowGroup/TimelineRowGroup";

export const TimelineRowGroupList = observer(({
  disabled,
  isLastGroupRow,
  listElements,
  onReorder,
  children,
  parentIds,
  tableDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  ...otherProps
}) => (
  <>
    <SortableList
      list={listElements}
      path={parentIds.join("/")}
      group={`timeline_${parentIds.join("/")}`}
      // group={`timeline_d${tableDepth}`}
      onListReorder={onReorder}
      displayAsColumnFlexbox
      disabled={disabled}
    >
      {listElements?.map((child, i) =>
        tableDepth ? (
          <TimelineNestedRow
            key={`element${child.id}`}
            resource={child}
            isLastGroupRow={isLastGroupRow && i === listElements.length - 1}
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            {...otherProps}
          />
        ) : (
          <TimelineRowGroup
            key={`element${child.id}`}
            workType={child}
            parentIds={parentIds}
            tableDepth={tableDepth + 1}
            {...otherProps}
          />
        )
      )}
    </SortableList>
    {children}
  </>
));

TimelineRowGroupList.propTypes = {
  tableDepth: oneOf([0, 1, 2, 3]),
  listElements: arrayOf(object),
  onReorder: func,
  isLastGroupRow: bool,

  parentIds: arrayOf(oneOfType([number, string])),
  children: node,
  disabled: bool,
};
