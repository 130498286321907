import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  hoverIcons: {
    "& .hoverIcon": {
      "-webkit-transition": "opacity 0.4s ease",
      "-moz-transition": "opacity 0.4s ease",
      transition: "opacity 0.4s ease",
      opacity: 1,
    },
    "&:not(:hover) .hoverIcon": {
      zIndex: -1,
      opacity: "0 !important",
    },
  },
  gfx: {
    height: 130, //187,
    minHeight: 130, //187,
    width: "100%",
    backgroundColor: theme.props.templateCard.standard,
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
    "& > img": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    "& > svg": {
      width: "100%",
      height: "max-content",
    },
    borderBottom: "1px solid " + theme.props.border,
  },
  accent: {
    height: 60,
    minHeight: 60,
    width: "100%",
    backgroundColor: theme.props.templateCard.standard,
    borderBottom: "1px solid " + theme.props.border,
  },
  info: {
    padding: "16px 24px 24px",
    height: 192,
    minHeight: 192,
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
  },
  button: {
    flexGrow: 1,
    justifyContent: "center !important",
    width: "calc(50% - 24px) !important",
    boxSizing: "border-box",
    margin: "0 12px",
  },
  actionButtonContainer: {
    position: "absolute",
    top: 8,
    right: 8,
    width: "max-content",
    borderRadius: 50,
    backgroundColor: theme.props.background,
  },
  dialogPaper: {
    backgroundColor: theme.props.backgroundSecondary,
    borderRadius: 10,
    width: "80vw",
    height: "80vh",
    maxWidth: "80vw",
    overflow: "hidden",
    boxShadow: "0 0 9px 0 #0e1d4833 !important",
    border: `1px solid ${theme.props.border}`,
  },
  setDefaultButton: {
    height: 24,
    maxHeight: 24,
    borderRadius: 50,
    paddingLeft: 8,
    paddingRight: 8,
  }
}));

export default useStyle;
