import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateEditorSettings,
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  TimelineSettings,
  ValueCell,
  VisibilityButton,
} from "@tools";

export const TimeCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { hideSummaryTime } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryTime);
  
  return (
    <ValueCell
      widest
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-timeMta">
        {getFieldName("pt-timeMta")}
      </span>
      {
        allowEdition &&
        <TimelineSettings
          small
          useWeekMonthSwitch
          className="noDrag"
        />}
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn
          right={-2}
          hidden={hideSummaryTime}
          onChange={structure.visibility.setHideSummaryTime}
          extendPadding={extendPadding}
        />
      }
    </ValueCell>
  )
})

TimeCell.propTypes = {
  extendPadding: bool,
}