import { useMemo } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { getPercentWorkTypes } from "@utils";
import {
  useEstimateEditorStore,
  useEstimateEditorSettings,
  useEstimateStructure,
  ChatThread,
  TimelineSettings,
  VisibilityButton,
} from "@tools";
import { TooltipIcon } from "@components";
import { Percent } from "@assets";

export const TimelineRowActions = observer(({
  workType,
  opened,
}) => {
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const {
    allowEdition,
    showChatThread,
  } = useEstimateEditorSettings();
  
  const { id, hasPercent, usesTimelinePercent, hideOnTimeline, percentageWorkTypes } = workType;
  
  const commentPath = "t"+workType.treePath.join("/");

  const percentWorkTypes = useMemo(() => (
    getPercentWorkTypes(percentageWorkTypes)
  ), [structure.percentageWorkTypes, percentageWorkTypes]);

  return (
    <>
      {allowEdition && hasPercent && usesTimelinePercent && (
        <TooltipIcon
          icon={<Percent />}
          color="primary"
          className="noDrag"
        >
          {percentWorkTypes}
        </TooltipIcon>
      )}
      {allowEdition && (
        <TimelineSettings
          fullSettings
          className="button-wta-settings hoverIcon opaque"
          selectedWorkType={id}
        />
      )}
      {
        visibilityMode &&
        <VisibilityButton
          hidden={hideOnTimeline}
          onChange={workType.setHideOnTimeline}
          inRow
        />
      }
      {
        showChatThread &&
        <ChatThread
          rowThread
          path={commentPath}
          hideBadge={opened}
        />
      }
    </>
  );
});

TimelineRowActions.propTypes = {
  workType: object.isRequired,
  opened: bool,
};
