import { useMemo } from "react";
import { useEstimateEditorStore, useEstimateStructure } from "@tools";
import classnames from "classnames";

export const useVisibilityModeCellClassName = (hide=false) => {
  
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  
  const { apply } = structure.visibility;
  
  return useMemo(() => (
    classnames(visibilityMode && apply && hide && "transparent-2")
  ), [visibilityMode, apply, hide])
}