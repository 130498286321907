import { useEffect, useRef, useState } from "react";
import { func, node } from "prop-types";
import { useSnackbar } from "notistack";
import { applyPatch } from "mobx-state-tree";
import { useTranslation } from "react-i18next";
import { CoreCommands, EditorSocketContext, TemplateCommands, TemplateEditorCommands } from "@client";
import {
  createTemplateVersionComment,
  editTemplateVersionComment,
  moveTemplateVersionComment,
  removeTemplateVersionComment,
  removeTemplateVersionCommentByPath,
} from "@query";
import { PROJECT_STATUS, SNACKBAR_WEBSOCKET_RESTORED } from "@utils";
import { useStores, useWorkspaceWebsocket } from "@hooks";
import { useEstimateEditorStore } from "@tools";
import { drawUserMovement } from "./utils/drawUserMovement";

// @todo: abstract some of the similar functions from EditorSocketProvider
export const TemplateSocketProvider = ({
  children,
  reloadTemplateData
}) => {

  const { t } = useTranslation();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { userStore, stateStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const {
    init,
    socket,
    connectionOnline,
    sendAction,
    requestTemplateRename,
  } = useWorkspaceWebsocket();
  
  const [isSetup, setSetupStatus] = useState(false);
  const [users, setUsers] = useState([]);
  const [hasConnectionError, setConnectionError] = useState(false);

  const lastSentAction = useRef(null);
  const templateJoined = useRef(null);
  const connectionOnlineSnackbar = useRef(null);
  const connectionRestoredSnackbar = useRef(null);
  
  // --------- connections ---------
  
  const joinTemplate = (templateId, templateHash, versionKey) => {
   
    console.log("JOINING TEMPLATE", templateId)
    
    setupProjectEvents(
      true,
      true,
      Boolean(templateHash),
    );
    
    socket.current.emit(CoreCommands.JOIN_TEMPLATE, {
      templateId,
      companyId: userStore.companyId,
      versionKey,
      commentHash: templateHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
    });
    templateJoined.current = { templateId, templateHash };
  };
  
  const closeTemplate = () => {
    if(!templateJoined.current)
      return;
    console.log("CLOSING TEMPLATE");
    setupProjectEvents(false);
    socket.current.emit(CoreCommands.CLOSE_TEMPLATE, {
      templateId: templateJoined.current.templateId,
      commentHash: templateJoined.current.templateHash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
    });
    templateJoined.current = null;
    
    lastSentAction.current = "END";
    closeSnackbar(connectionOnlineSnackbar.current);
    closeSnackbar(connectionRestoredSnackbar.current);
    setUsers([]);
  };
  
  const setupProjectEvents = (on, openEditionChannel, openCommentsChannel) => {
    if(!on) {
      socket.current.removeAllListeners();
      return;
    }
    
    socket.current.on(CoreCommands.ACTION_ERROR, onError);
    socket.current.on(TemplateEditorCommands.USER_JOINED, onJoined);
    socket.current.on(TemplateEditorCommands.USER_LEFT, onLeft);
    socket.current.on(CoreCommands.JOIN_TEMPLATE, onTemplateJoinError);
    socket.current.on(TemplateCommands.TEMPLATE_PROGRESS, onTemplateOpenProgress);
    
    if(openEditionChannel || !on) {
      socket.current.on(TemplateEditorCommands.USER_MOVE, drawUserMovement);
      socket.current.on(TemplateEditorCommands.PATCH, onStructurePatch);
      
      socket.current.on(TemplateEditorCommands.VERSION_LIST, onReceiveVersionList);
      socket.current.on(TemplateEditorCommands.VERSION_REQUEST, onReceiveVersion);
      socket.current.on(TemplateEditorCommands.VERSION_NEW, onCreateVersion);
      socket.current.on(TemplateEditorCommands.VERSION_REMOVE, onRemoveVersion);
      socket.current.on(TemplateEditorCommands.VERSION_RENAME, onRenameVersion);
      socket.current.on(TemplateEditorCommands.VERSION_ORDER, onReorderVersion);
      socket.current.on(TemplateEditorCommands.VERSION_LOCK, onVersionLock);
      socket.current.on(TemplateEditorCommands.VERSION_VISIBILITY, onVersionVisibility);
      
      socket.current.on(TemplateCommands.TEMPLATE_RENAME, onTemplateRename);
      socket.current.on(TemplateCommands.TEMPLATE_REMOVE, onTemplateRemove);
    }
    if(openCommentsChannel || !on) {
      socket.current.on(TemplateEditorCommands.COMMENT_ADD, onCommentAdd);
      socket.current.on(TemplateEditorCommands.COMMENT_EDIT, onCommentEdit);
      socket.current.on(TemplateEditorCommands.COMMENT_REMOVE, onCommentRemove);
      socket.current.on(TemplateEditorCommands.COMMENT_REMOVE_PATH, onCommentPathRemove);
      socket.current.on(TemplateEditorCommands.COMMENT_MOVE, onCommentMove);
    }
    
    setSetupStatus(true);
  }

  const resetProjectData = async () => {
    if(!templateJoined.current) return;
    const { hash, estimateData } = await reloadTemplateData();
    socket.current.emit(CoreCommands.JOIN_TEMPLATE, {
      templateId: estimateData.id,
      versionKey: editorStore.currentVersionKey,
      commentHash: hash,
      userId: userStore.data.id,
      userUuid: userStore.data.uuid,
      name: userStore.data.fullname,
      email: userStore.data.email,
      avatar: userStore.data.avatar,
    });
    templateJoined.current = { templateId: estimateData.id, templateHash: hash };
  }
  
  useEffect(() => {
    if(isSetup && connectionOnline) {
      onReconnect();
    }
    
    if(!connectionOnline) {
      onDisconnect();
    }
  }, [connectionOnline]);

  const onReconnect = async () => {
    console.log("EDITOR RECONNECTED");
   
    if(templateJoined.current) {
      await resetProjectData();
      closeSnackbar(connectionOnlineSnackbar.current);
      connectionRestoredSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_restored"),
        {
          key: SNACKBAR_WEBSOCKET_RESTORED,
          variant: "success",
          autoHideDuration: 2000
        }
      );
    }
  };

  const onDisconnect = () => {
    console.log("EDITOR DISCONNECTED");
    if(lastSentAction.current !== "END") {
      closeSnackbar(connectionRestoredSnackbar.current);
      connectionOnlineSnackbar.current = enqueueSnackbar(
        t("alerts.editor.connection_offline"),
        {
          persist: true,
          variant: "warning"
        }
      );
    }
    setUsers([]);
  };

  const madeIdenticalAction = (command, payload, additionalChecks) => {
    if (!lastSentAction.current) return false;
    return (
      lastSentAction.current.command === command &&
      lastSentAction.current.timestamp > payload.timestamp &&
      (!additionalChecks || additionalChecks(lastSentAction.current.payload))
    );
  };

  const saveAndSendAction = (command, payload) => {
    if (!socket.current?.connected) return;
    const timestamp = new Date().getTime();
    payload.timestamp = timestamp;
    payload.templateId = templateJoined.current.templateId;
    lastSentAction.current = { command, payload, timestamp };
    sendAction(command, payload);
  };

  // --------- remote actions ---------

  const onJoined = (userData) => {
    console.log("USER JOINED", userData);
    setUsers((l) => [...l, ...userData])
  };

  const onLeft = (userSocketIds) => {
    console.log("USER LEFT", userSocketIds);
    setUsers((l) => l.filter((u) => !userSocketIds.includes(u.socketId)));
  };
  
  const onTemplateJoinError = ({ status }) => {
    editorStore.setCreatedStatus(status);
  }
  
  const onTemplateOpenProgress = ({ progress, stepName }) => {
    stateStore.setProjectCreatorProgress(progress, stepName);
  }
  
  const onError = (data) => {
    console.error("ERROR => ", data.message);
    setConnectionError(true);
  }
  
  const onStructurePatch = (data) => {
    
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === data.versionKey
    )?.structure;
    
    if (structure) try {
      structure.historyManager.withoutUndo(() => {
        data.patches.forEach(p => {
          if(p.removesElement && p.identifier)
          structure.historyManager.clearByTargetId(p.identifier)
        })
        applyPatch(structure, data.patches);
      })
    } catch(e) {
      console.log("Error: applyPatch", e);
    }
  };

  const onReceiveVersionList = ({ message, versions }) => {
    if(message)
      console.error(message);
    if(versions)
        editorStore.setVersions(versions);
  }
  
  const onReceiveVersion = ({ message, versionData }) => {
    if(message)
      console.error(message);
    if(versionData)
      editorStore.setVersionData(versionData);
    
    stateStore.resetProjectCreatorProgress();
  };

  const onCreateVersion = ({ versionData, userSocketId }) => {
    editorStore.addNewVersion(versionData, userSocketId === socket.current.id);
  };

  const onRemoveVersion = ({ versionKey, newVersionData, versionName, userSocketId }) => {
    if(newVersionData && editorStore.currentVersion === versionKey) {
      editorStore.setVersionData(newVersionData);
      if(socket.current.id !== userSocketId)
        enqueueSnackbar(
          <p>
            {t("common.version")}
            <b>{` ${versionName} `}</b>
            {t("alerts.editor.version_removed")}
          </p>,
          { variant: "warning", autoHideDuration: 2000 }
        );
    }
    editorStore.removeSelectedVersion(versionKey);
  };

  const onRenameVersion = (data) => {
    if (
      madeIdenticalAction(
        TemplateEditorCommands.VERSION_RENAME,
        data,
        (p) => p.versionKey === data.versionKey
      )
    )
      return;
    const { versionKey, newName } = data;
    editorStore.editVersionName(versionKey, newName);
  };

  const onReorderVersion = (data) => {
    if (madeIdenticalAction(TemplateEditorCommands.VERSION_ORDER, data))
      return;
    editorStore.updateVersionOrder(data.newOrder);
  };
  
  const onVersionLock = (data) => {
    editorStore.setEditionLock(data.versionKey, data.locked);
  }
  
  const onVersionVisibility = (data) => {
    editorStore.setVersionHidden(data.versionKey, data.visible);
  }

  const onTemplateRename = (data) => {
    if (madeIdenticalAction(TemplateCommands.PROJECT_RENAME, data)) return;
    editorStore.setEstimateName(data.newName);
  };
  
  const onTemplateRemove = ({ templateId }) => {
    if(templateId !== templateJoined.current.templateId)
      return;
    editorStore.setEstimateStatus(PROJECT_STATUS.REMOVED);
  }
  
  
  const onCommentAdd = ({ versionKey, commentData }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.addComment(commentData);
      })
  };
  
  const onCommentEdit = ({ versionKey, commentId, body, parentId }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.updateComment(commentId, body, parentId);
      })
  };
  
  const onCommentRemove = ({ versionKey, commentId, parentId }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.removeComment(commentId, parentId);
      })
  };
  
  const onCommentPathRemove = ({ versionKey, place }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.removeCommentByPath(place);
      })
  };
  
  const onCommentMove = ({ versionKey, place, oldPlace }) => {
    const structure = editorStore.estimateVersions.find(
      (v) => v.key === versionKey
    )?.structure;
    if(structure)
      structure.historyManager.withoutUndo(() => {
        structure.updateCommentPath(oldPlace, place);
      })
  };

  // --------- USER ACTIONS ---------

  const sendMoveData = (
    clientX,
    clientY,
    elementType,
    elementPath,
    elementId
  ) => {
    sendAction(TemplateEditorCommands.USER_MOVE, {
      id: userStore.data.id,
      vW: window.innerWidth,
      // vH: containerHeight,
      x: clientX,
      y: clientY,
      elementType,
      elementPath,
      elementId,
    });
  };
  
  const requestStructurePatch = (patches, metadata) => {
    saveAndSendAction(TemplateEditorCommands.PATCH, {
      versionKey: editorStore.currentVersionKey,
      timestamp: new Date().getTime(),
      patches,
      metadata,
    });
  };

  const requestExistingVersion = (versionKey) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_REQUEST, {
      templateId: templateJoined.current.templateId,
      userUuid: userStore.userUuid,
      versionKey,
    });
  };
  
  const requestNewVersion = (copyComments) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_NEW, {
      originVersionKey: editorStore.currentVersionKey,
      copyComments,
    });
  };

  const requestVersionRename = (versionKey, newName) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_RENAME, { versionKey, newName });
  };

  const requestVersionRemoval = (versionKey) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_REMOVE, { versionKey });
  };

  const requestVersionVisibility = (versionKey, visible) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_VISIBILITY, { versionKey, visible });
  };

  const requestVersionLock = (versionKey, locked) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_LOCK, { versionKey, locked });
  };

  const requestVersionReorder = (movedVersionKey, newOrder) => {
    saveAndSendAction(TemplateEditorCommands.VERSION_ORDER, { movedVersionKey, newOrder });
  };
  
  const requestCommentAdd = async (commenterUuid, place, body, mentions, internal, parentId) => {
    const versionKey = editorStore.currentVersionKey;
    const commentData = await createTemplateVersionComment(
      templateJoined.current.templateId,
      commenterUuid, versionKey, place, body, mentions, internal, parentId
    );
    saveAndSendAction(TemplateEditorCommands.COMMENT_ADD, { versionKey, commentData });
  };
  
  const requestCommentEdit = async (commentId, commenterUuid, place, body) => {
    const versionKey = editorStore.currentVersionKey;
    await editTemplateVersionComment(commentId, templateJoined.current.templateId, commenterUuid, versionKey, place, body);
    saveAndSendAction(TemplateEditorCommands.COMMENT_EDIT, {
      versionKey,
      commentData :{
        id: commentId,
        userUuid: commenterUuid,
        place,
        body,
      },
    });
  };
  
  const requestCommentRemove = async (commentId, commenterUuid, parentId) => {
    const versionKey = editorStore.currentVersionKey;
    await removeTemplateVersionComment(
      commentId, templateJoined.current.templateId, commenterUuid, versionKey
    );
    saveAndSendAction(TemplateEditorCommands.COMMENT_REMOVE, {
      versionKey, commentId, parentId, commenterUuid,
    });
  };
  
  const requestCommentPathRemove = async (place) => {
    const versionKey = editorStore.currentVersionKey;
   await removeTemplateVersionCommentByPath(
     templateJoined.current.templateId,
     userStore.data.uuid,
     versionKey, place,
   );
   saveAndSendAction(TemplateEditorCommands.COMMENT_REMOVE_PATH, { versionKey, place });
  };
  
  const requestCommentMove = async (oldPlace, place) => {
    const versionKey = editorStore.currentVersionKey;
    await moveTemplateVersionComment(
      templateJoined.current.templateId,
      userStore.data.uuid,
      versionKey, oldPlace, place,
    );
    saveAndSendAction(TemplateEditorCommands.COMMENT_MOVE, { versionKey, oldPlace, place });
  };

  
  const value = {
    users,
    hasConnectionError,
    editionPrivileges: true,
    initSocket: init,
    joinTemplate,
    closeTemplate,
    
    sendMoveData,
    
    requestEstimateRename: requestTemplateRename,
    
    requestStructurePatch,
    
    requestExistingVersion,
    requestNewVersion,
    requestVersionRename,
    requestVersionRemoval,
    requestVersionReorder,
    requestVersionVisibility,
    requestVersionLock,
    
    requestCommentAdd,
    requestCommentEdit,
    requestCommentRemove,
    requestCommentPathRemove,
    requestCommentMove,
  };

  return (
    <EditorSocketContext.Provider value={value}>
      {children}
    </EditorSocketContext.Provider>
  );
};

TemplateSocketProvider.propTypes = {
  children: node.isRequired,
  reloadTemplateData: func,
};
