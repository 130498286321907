import { useState } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tables } from "project-structure";
import { LOCKED_FEATURES } from "@utils";
import { useEstimateStructure } from "@tools";
import { useFeatureGuardian } from "@hooks";
import { ActionButton, Dialog, InfoLabel, SortableList } from "@components";
import { TableCheckbox } from "./components/TableCheckbox";
import Grid from "@material-ui/core/Grid";
import { Settings } from "@assets";
import { makeStyles } from "@material-ui/core/styles";

export const EstimateSettings = observer(() => {
  const structure = useEstimateStructure();
  const { t } = useTranslation();
  const classes = useStyle();

  const { checkAccess, FeatureAlert } = useFeatureGuardian(
    LOCKED_FEATURES.TIMELINE
  );

  const [visible, show] = useState(false);
  const { settings } = structure;
  const { usedTables } = settings;

  const handleVisibility = () => show(!visible);

  const handleTableVisibility = (name, visible, setVisibility) => async () => {
    if (name === Tables.TIMELINE) {
      const allowed = await checkAccess();
      if (!allowed) return;
    }
    setVisibility(!visible);
    if(name === Tables.TIMELINE) {
      structure.historyManager.startGroup();
      structure.splitWorkTypeResources();
      structure.generateTimeline();
      structure.historyManager.stopGroup();
    }
  };

  const handleTablesReorder = (list) => {
    settings.reorderProjectTables(list);
  };

  return (
    <>
      <ActionButton
        square
        id="projectSettings"
        icon={<Settings />}
        color="secondary"
        onClick={handleVisibility}
        name={t("views.editor.dialogs.project_settings.title")}
        tooltip
      />
      <Dialog
        open={visible}
        onClose={handleVisibility}
        width={460}
        title={t("views.editor.dialogs.project_settings.title")}
      >
        <Grid item container direction="column">
          <InfoLabel
            label="views.editor.dialogs.project_settings.tables"
            tooltipText="views.editor.dialogs.project_settings.tables_tooltip"
          />
          <SortableList
            list={usedTables}
            path="projectSettings"
            onListReorder={handleTablesReorder}
            itemIdKey="name"
            className={classes.dragContainer}
            useHandle
          >
            {usedTables.map(({ name, visible, setVisibility }) => (
              <TableCheckbox
                key={name}
                name={name}
                visible={visible}
                setVisibility={handleTableVisibility(
                  name,
                  visible,
                  setVisibility
                )}
              />
            ))}
          </SortableList>
        </Grid>
      </Dialog>
      <FeatureAlert />
    </>
  );
});

EstimateSettings.propTypes = {
  allowEdition: bool,
};

const useStyle = makeStyles(() => ({
  dragContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: -24,
  },
}));