import { memo, useMemo } from "react";
import { arrayOf, number, shape, string, func } from "prop-types";
import { Grid } from "@material-ui/core";
import { BillingChartBar } from "../BillingChartBar/BillingChartBar";
import useStyle from "./BillingChartCell.style";
import useCellStyle from "@tools/EstimateEditor/styles/CommonTableStyles.style";
import classnames from "classnames";

export const BillingChartInnerCell = memo(({
  values,
  weekIndex,
  onOpen,
}) => {
  
  const classes = useStyle();
  const cellClasses = useCellStyle();
  
  const value = useMemo(() => (
    values
      .map(({ weeks, month, weekHours, monthHours, ...passed }) => ({
        ...passed,
        value: typeof weekIndex === "number" ? weeks[weekIndex] : month,
        hours: typeof weekIndex === "number" ? weekHours[weekIndex] : monthHours,
      }))
      .filter(wT => wT.value > 0)
  ), [values, weekIndex]);
  
  return (
    <Grid
      item container
      wrap="nowrap"
      direction="column"
      className={classnames(
        "h-max",
        classes.cell,
        typeof weekIndex === "number" && classes.cellNarrow,
        cellClasses.bolder,
        cellClasses.collapseCellInner,
        cellClasses.fullDashBorderRight,
      )}
    >
      <div className={classes.cellChartBlock}/>
      <div className={classes.cellChartBlock}/>
      <div className={classes.cellChartBlock}/>
      <Grid
        item container
        wrap="nowrap"
        alignItems="flex-end"
        className={classes.cellChartBlock}
      >
        <BillingChartBar
          values={value}
          onOpen={onOpen}
        />
      </Grid>
    </Grid>
  )
});

BillingChartInnerCell.propTypes = {
  weekIndex: number,
  onOpen: func.isRequired,
  values: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    color: string,
    month: number.isRequired,
    monthHours: number.isRequired,
    weeks: arrayOf(number).isRequired,
    weekHours: arrayOf(number).isRequired,
  })),
}