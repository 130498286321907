import { useEffect, useMemo, useState } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import ResizeObserver from "react-resize-observer";
import { Tables, hashCode, MAX_TIMELINE_LENGTH } from "project-structure";
import { isMobile } from "react-device-detect";
import { CELL_WIDTHS, MIN_FILLER_MONTHS } from "@utils";
import { useTableExpandHandler } from "@hooks"
import {
  useEstimateStructure,
  usePresetFieldNames,
  useTableOverflowChecker,
  useEstimateEditorStore,
  useVisibilityModeCellClassName,
  TimelineContentSettings,
  ScrollButtonContainer,
  TableTitle,
  VisibilityButton, useEstimateEditorSettings, EstimateTableSettingsProvider
} from "@tools";
import { ButtonSwitch } from "@components";
import { TableContainer, Grid, Collapse } from "@material-ui/core";
import { TableHeader } from "./components";
import { TimelineBilling, TimelineSections, TimelineTeamAllocation } from "./sections";
import useStyle from "@tools/EstimateEditor/styles/CommonTableStyles.style";
import classnames from "classnames";

export const TableTimeline = observer(({
  tableBreakdownNext,
  tableBreakdownPrev,
}) => {
  
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const {
    allowEdition,
    isSellerOrClient,
    isProposal,
    readOnly,
    blockExpansions,
    showPdfCover,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const classes = useStyle();

  const [additionalFillerMonths, setAdditionalFillerMonths] = useState(0);
  
  const {
    settings,
    visibility,
    usesTwoValues,
    usesAnyValue,
    totalDevelopmentTime,
    usedTimelineMode,
    workTypesHaveResources,
  } = structure;
  
  const {
    showTimeline,
    useMinMax,
    useTimelineSections,
    useTimelineAllocation,
    useTimelineBilling,
    expandedMonthList,
  } = settings;
  
  const {
    apply,
    hideTimeline,
    hideTimelineSections: vHideTimelineSections,
    hideTimelineAllocation: vHideTimelineAllocation,
    hideTimelineBilling: vHideTimelineBilling,
  } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimeline || !usesAnyValue);
  
  const useExpandCell = useMemo(() => (
    !isProposal || workTypesHaveResources
  ), [isProposal, workTypesHaveResources]);
  
  const timelineLength = useMemo(() => Math.min(
    Math.max(totalDevelopmentTime, MIN_FILLER_MONTHS) + additionalFillerMonths,
    MAX_TIMELINE_LENGTH
  ), [totalDevelopmentTime, additionalFillerMonths]);
  
  const timelineModeValues = useMemo(
    () => [
      {
        value: "min",
        label: getFieldName("pt-min"),
        className: classnames(
          "pt-min",
          `preset-timelineMode${usedTimelineMode === "min" ? "Active" : ""}`
        ),
      },
      {
        value: "max",
        label: getFieldName("pt-max"),
        className: classnames(
          "pt-max",
          `preset-timelineMode${usedTimelineMode === "max" ? "Active" : ""}`
        ),
      },
    ],
    [usedTimelineMode, getFieldName("pt-max"), getFieldName("pt-min")]
  );

  const tableContainerIdentifier = "timelineContainer";
  const tableIdentifier = "timelineHeader";
  
  const resizeHandler = (b, containerWidth) => {
    const months = Math.max(totalDevelopmentTime, MIN_FILLER_MONTHS);
    // const minimalWidth = [...b.lastChild.children].slice(0,months).reduce((t,c) => t+c.offsetWidth, 0);
    const minimalWidth = months * CELL_WIDTHS.VALUE.SMALL;
    
    const availableWidth = containerWidth - CELL_WIDTHS.TITLE_SUMMARY - minimalWidth - 2;
    const freeSlots = Math.floor(availableWidth / CELL_WIDTHS.VALUE.SMALL);
    setAdditionalFillerMonths(freeSlots >= 0 ? freeSlots : 0);
  }

  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier, resizeHandler);
  
  useEffect(() => {
    onResize()
  }, [ useMinMax, expandedMonthList, totalDevelopmentTime ]);

  const handleTimelineMode = (mode) => {
    if (blockExpansions) return;
    structure.historyManager.startGroup();
    settings.setTimelineMode(mode);
    structure.generateTimeline();
    structure.historyManager.stopGroup();
  };
  
  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    !blockExpansions,
    showTimeline,
    settings.setShowTimeline
  );
  
  return (
    <EstimateTableSettingsProvider
      useExpandCell={useExpandCell}
      timelineLength={timelineLength}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classnames(
          "preset-paperTimeline",
          classes.whiteContainer,
          tableBreakdownNext && classes.whiteContainerLast,
          tableBreakdownPrev && classes.whiteContainerFirst,
        )}
      >
        <Grid item container justifyContent="flex-end" className={classnames("vCon", showPdfCover && "pdf-mode", classes.root)}>
          <TableTitle
            disabled={visibilityMode && (hideTimeline || !usesAnyValue)}
            expandable={!blockExpansions}
            presetClass="timeline"
            buttonPresetClass="preset-tableTimelineExpandButton"
            id={hashCode(Tables.TIMELINE)}
            title={getFieldName("pt-timeline")}
            showContent={showTimeline}
            onContentAction={handleOpen}
            action={<>
              {!readOnly && <TimelineContentSettings />}
              { visibilityMode &&
                <VisibilityButton hidden={hideTimeline} onChange={visibility.setHideTimeline} />
              }
            </>}
          >
            {
              (allowEdition || useMinMax) &&
              <Grid
                item
                container
                alignItems="center"
                className="w-max my-1"
              >
                {
                  usesTwoValues &&
                  <ButtonSwitch
                    value={usedTimelineMode}
                    setValue={handleTimelineMode}
                    values={timelineModeValues}
                    containerClassName={classes.buttonSwitch}
                    size="small"
                  />
                }
              </Grid>
            }
          </TableTitle>
          <Collapse
            in={showTimeline}
            timeout="auto"
            mountOnEnter
            unmountOnExit
            className={classnames(showPdfCover && "pdf-mode", classes.collapse, className)}
            data-id={hashCode("cont")}
            data-t="timeline"
            data-p={hashCode(Tables.TIMELINE)}
            { ...collapseProps }
          >
            <Grid
              container
              justifyContent="center"
              wrap="nowrap"
              direction="column"
            >
              {
                isTableContentOverflowing && !isMobile &&
                <ScrollButtonContainer {...scrollButtonProps} />
              }
              <TableContainer
                ref={ref}
                id={tableContainerIdentifier}
                onScroll={onScroll}
                className={classnames(
                  "pb-3",
                  classes.tableContainer,
                )}
              >
                <Grid
                  container
                  role="rowgroup"
                  direction="column"
                >
                  <ResizeObserver onResize={onResize} />
                  <TableHeader tableIdentifier={tableIdentifier} />
                  {
                    useTimelineSections && (!apply || visibilityMode || !vHideTimelineSections) &&
                    <TimelineSections tableContainerIdentifier={tableContainerIdentifier} />
                  }
                  {
                    useTimelineAllocation && (!apply || visibilityMode || !vHideTimelineAllocation) &&
                    <TimelineTeamAllocation  />
                  }
                  {
                    isSellerOrClient && useTimelineBilling && (!apply || visibilityMode || !vHideTimelineBilling) &&
                    <TimelineBilling />
                  }
                </Grid>
              </TableContainer>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </EstimateTableSettingsProvider>
  );
});

TableTimeline.propTypes = {
  tableBreakdownNext: bool,
  tableBreakdownPrev: bool,
};
