export const DEFAULT_LABEL_COLOR = "#D7D7E2";
export const DEFAULT_LABEL_FONT_COLOR = "#FFFFFF";

export const DEFAULT_LABEL_COLORS = [
  "#FFCC00",
  "#FF9500",
  "#34C759",
  "#00C7BE",
  "#30B0C7",
  "#32ADE6",
  "#007AFF",
  "#5856D6",
  "#AF52DE",
  "#FF92E7",
  "#A2845E",
]

export const DEFAULT_LABEL_FONT_COLORS = [
  "#2590FF",
  "#FFFFFF",
  "#D7D7E2",
  "#6D6D7D",
  "#3A3C4F",
]