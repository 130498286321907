import { useState } from "react";
import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { USER_COMPANY_SETTINGS } from "@client";
import { setCompanySettingsQuery } from "@query";
import { MAX_VALUES } from "@utils";
import { useStores } from "@hooks";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  useEstimateEditorStore,
  useVisibilityModeCellClassName,
  ValueCell,
  ResetValueButton
} from "@tools";
import { Alert } from "@components";

export const RateCell = observer(({
  element,
  extendPadding,
}) => {
  const { t } = useTranslation();
  const {
    allowEdition,
    useProfitability,
    allowInteractions,
    isProposal,
  } = useEstimateEditorSettings();
  const { userStore } = useStores();
  const editorStore = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryRate);
  
  const { usesAnyValue } = structure;
  const { currencyObj, modifier, timeModifier } = structure.settings;
  const { displayRate, hasClientRate } = element;

  const [ rateAlertVisible, showRateAlert ] = useState(false);

  const handleRateChange = (rate) => {
    const rateDifference = element.setRate(
      rate / modifier,
      allowInteractions && !allowEdition
    );
    setTimeout(
      () =>
        showRateAlert(rateDifference && !editorStore?.blockedAverageAlert),
      100
    );
  };

  const hideRateAlert = async (e, lock) => {
    showRateAlert(false);
    if (lock) {
      editorStore.blockedAverageAlert = true;
      await setCompanySettingsQuery(USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid), true)
    }
  };

  return (
    <ValueCell
      widest
      showDecimals
      displayContent
      emptyInputs={!usesAnyValue}
      value={displayRate}
      onValueChange={handleRateChange}
      editable={allowEdition || (isProposal && allowInteractions)}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      useSeparator={useProfitability}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-rate"
      extendPadding={extendPadding}
    >
      {allowInteractions && hasClientRate && (
        <ResetValueButton action={element.resetClientRate}>
          {t("views.editor.alert")}
        </ResetValueButton>
      )}
      {rateAlertVisible && (
        <Alert
          isOpen={rateAlertVisible}
          title={t("views.editor.rate_diff")}
          acceptText={t("common.close")}
          onAccept={hideRateAlert}
          useLockCheckbox
        >
          {t("views.editor.average_breakdown_rate_info")}
        </Alert>
      )}
    </ValueCell>
  );
});

RateCell.propTypes = {
  element: object.isRequired,
  extendPadding: bool,
};
