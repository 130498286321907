import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  CurrencyEditor,
  ValueCell,
  VisibilityButton,
} from "@tools";

export const RateCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { currency } = structure.settings;
  const { hideSummaryRate } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryRate);
  
  return (
    <ValueCell
      widest 
      isHeader
      size="xSmall"
      className={className}
      style={{ cursor: allowEdition && "grab", }}
    >
      <span className="preset-tableSummaryHeader pt-rate">
        {`${getFieldName("pt-rate")} (${currency})`}
      </span>
      { allowEdition && <CurrencyEditor /> }
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={-4}
          hidden={hideSummaryRate}
          onChange={structure.visibility.setHideSummaryRate}
          extendPadding={extendPadding}
        />
      }
    </ValueCell>
  )
})

RateCell.propTypes = {
  extendPadding: bool,
}