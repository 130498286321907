import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DEFAULT_RATES } from "project-structure";
import { getTemplateEditionDataQuery, checkStructureVersionQuery} from "@query";
import {
  COMPANY_SETTINGS,
  PROJECT_COMPANY_SETTINGS,
  USER_COMPANY_SETTINGS,
  TemplateSocketProvider,
} from "@client";
import { parseCompanySettings, PROJECT_TYPE } from "@utils";
import { templates } from "@paths";
import { useStores } from "@hooks";
import {
  EstimateEditorStore,
  EstimateEditorStoreProvider,
  EstimateLoadingAlert,
  TemplateEditorInactivityMonitor,
  EstimateEditorConnectionErrorAlert,
} from "@tools";
import { NewEditorVersionAlert } from "@dialogs";
import { PageCircularProgressWithLabel } from "@components";
import { TemplateEditorHandlers } from "./TemplateEditorHandlers";

export const TemplateEditor = () => {
  const {t} = useTranslation();
  const { userStore, stateStore } = useStores();
  const navigate = useNavigate();

  const { templateId } = useParams();

  const [store, setStore] = useState(null);
  const [commentHash, setCommentHash] = useState(null);
  const [hasError, setError] = useState(0);
  const [hasNewEditorVersion, setHasNewEditorVersion] = useState(false);
  
  useEffect(() => {
    document.title = `Apropo App | ${t("routes.editor")}`;
  }, [])

  useEffect(() => {
    setupEditorStore();
    return () => {
      stateStore.resetFilesUploadProgress();
    }
  }, []);

  const loadTemplateData = async () => {
    try {
      await checkStructureVersionQuery();
    } catch(e) {
      console.log("RELOAD:", e.reload);
      if(e.reload)
        setHasNewEditorVersion(true);
      else
        setError(2);
      throw e;
    }
    
    try {
      const {
        hash,
        companySettings,
        templateData,
        workTypes,
      } = await getTemplateEditionDataQuery(templateId, /*userStore.userUuid,*/ [
        USER_COMPANY_SETTINGS.AVERAGE_ALERT(userStore.userUuid),
        USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid),
        PROJECT_COMPANY_SETTINGS.VALUE_ALERT(`t${templateId}`),
        PROJECT_COMPANY_SETTINGS.VALUE_PROJECT(`t${templateId}`),
        COMPANY_SETTINGS.RATES,
        // COMPANY_SETTINGS.PRESET,
      ]);
      
      return {
        hash,
        companySettings: {
          ...companySettings,
          // defaultPresetId: parseCompanySettings(companySettings.defaultPresetId),
          // defaultCurrency: parseCompanySettings(companySettings.defaultCurrency),
          defaultCompanyCurrency: parseCompanySettings(companySettings.companyCurrency),
          defaultRates: companySettings.rates ? parseCompanySettings(companySettings.rates) : { ...DEFAULT_RATES },
          timeEquivalents: parseCompanySettings(companySettings.timeEquiv),
        },
        workTypes,
        estimateData: templateData,
      }
    } catch(e) {
      if(e.status === 403)
        navigate(templates, { replace: true });
      else throw e;
    }
  };
  
  const reloadTemplateData = async () => {
    const { hash, estimateData, companySettings } = await loadTemplateData();
    setCommentHash(hash);
    store.setCompanySettings(companySettings);
    store.setEstimateData(estimateData, false, false);
    return { estimateData };
  };
  
  const setupEditorStore = async () => {
    try {
      const { hash, ...data } = await loadTemplateData();
      
      setCommentHash(hash);
      setStore(new EstimateEditorStore({
        useHistoryManager: true,
        estimateType: PROJECT_TYPE.STANDARD,
        commenterData: {
          uuid: userStore.data.uuid,
          fullname: userStore.data.fullname,
          color: userStore.data.avatarColor
        },
        ...data
      }));
    } catch (e) {
      setError(2);
    }
  };
  
  if (hasError)
    return <EstimateLoadingAlert isTemplate structureError={hasError === 1} />;

  return <>
    {
      !store ? (
        <PageCircularProgressWithLabel value={0} />
      ) : (
        <EstimateEditorStoreProvider value={store}>
          <TemplateSocketProvider reloadTemplateData={reloadTemplateData}>
            <TemplateEditorHandlers commentHash={commentHash} />
            <TemplateEditorInactivityMonitor reloadTemplateData={reloadTemplateData} />
            <EstimateEditorConnectionErrorAlert />
          </TemplateSocketProvider>
        </EstimateEditorStoreProvider>
      )
    }
    {
      hasNewEditorVersion &&
      <NewEditorVersionAlert />
    }
  </>
};