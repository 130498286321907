import { useRef, useState } from "react";
import { bool, arrayOf, func, string } from "prop-types";
import { DEFAULT_LABEL_COLOR, DEFAULT_LABEL_COLORS, DEFAULT_LABEL_FONT_COLOR, DEFAULT_LABEL_FONT_COLORS } from "@utils";
import { InfoLabel, PopMenu, Button } from "@components";
import { Divider, Grid } from "@material-ui/core";
import { ColorSquare } from "./ColorSquare";
import { NewColorButton } from "./NewColorButton";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

export const LabelColorPicker = ({
  backgroundColor,
  fontColor,
  additionalColors,
  additionalFontColors,
  onConfirm,
  onColorChange,
  onFontColorChange,
  onAdditionalColorAdd=[],
  onAdditionalFontColorAdd=[],
  className,
  disabled,
}) => {
  const classes = useStyle();
  
  const [pickerVisible, showPicker] = useState(false);
  
  const ref = useRef(null);
  
  const handleConfirmChange = async () => {
    showPicker(false);
    await onConfirm?.();
  }
  
  return (
    <>
      <Button
        ref={ref}
        color="secondary"
        variant="outlined"
        className={classnames(classes.block, className)}
        onClick={() => showPicker(true)}
        disabled={disabled}
      >
        <Grid
          item container
          justifyContent="center"
          alignItems="center"
          className={classes.sample}
          style={{
            color: fontColor || DEFAULT_LABEL_FONT_COLOR,
            backgroundColor: backgroundColor || DEFAULT_LABEL_COLOR,
        }}
        >
          {"Ab"}
        </Grid>
      </Button>
      <PopMenu
        show={pickerVisible}
        onClickAway={handleConfirmChange}
        anchor={ref.current}
        style={{ width: 424 }}
      >
        <Grid
          item container
          className="px-5 pt-6 pb-5"
        >
          <InfoLabel label="views.settings.projects.labels.select_color" spacing={0} />
          <Grid item container>
            {
              [
                ...DEFAULT_LABEL_COLORS,
                ...additionalColors
              ].map((c, i) => (
                <ColorSquare key={c+i} hex={c} onSelect={onColorChange} />
              ))
            }
            <NewColorButton onComplete={onAdditionalColorAdd} />
          </Grid>
          <Divider className="w-full mt-3" />
          <InfoLabel label="views.settings.projects.labels.select_font_color" />
          <Grid item container>
            {
              [
                ...DEFAULT_LABEL_FONT_COLORS,
                ...additionalFontColors
              ].map((c, i) => (
                <ColorSquare key={c+i} hex={c} onSelect={onFontColorChange} />
              ))
            }
            <NewColorButton onComplete={onAdditionalFontColorAdd} />
          </Grid>
        </Grid>
      </PopMenu>
    </>
  )
}

LabelColorPicker.propTypes = {
  backgroundColor: string,
  fontColor: string,
  className: string,
  additionalColors: arrayOf(string),
  additionalFontColors: arrayOf(string),
  onConfirm: func,
  onColorChange: func.isRequired,
  onFontColorChange: func.isRequired,
  onAdditionalColorAdd: func.isRequired,
  onAdditionalFontColorAdd: func.isRequired,
  disabled: bool,
}


const useStyle = makeStyles((theme) => ({
  block: {
    height: 48,
    width: 48,
    minWidth: 48,
    borderRadius: 5,
    padding: 0,
    border: `1px solid ${theme.props.border}`,
  },
  sample: {
    height: 24,
    width: 24,
    borderRadius: 5,
    fontSize: 12
  }
}));