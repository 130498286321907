import { useMemo, useState } from "react";
import { number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useEstimateStructure, SectionTitle, SectionName, useEstimateEditorSettings } from "@tools";
import { Alert, WysiwygEditor } from "@components";
import { isEmptyHtml } from "project-structure";
import { Grid, IconButton } from "@material-ui/core";
import { Trash } from "@assets";
import useStyle from "./SectionRow.style";
import { useEditorWebsocket } from "@hooks";

export const SectionRow = observer(({
  index,
  section,
}) => {
  const { t } = useTranslation();
  const { allowEdition, isLibrary } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const socket = useEditorWebsocket();
  const classes = useStyle();

  const { id, name, content, hasChildren } = section;
  
  const [alertVisible, showAlert] = useState(false);
  
  const editable = (allowEdition && !isLibrary);
  
  const removable = useMemo(() => (
    structure.hasMultipleSections && editable
  ), [
    structure.hasMultipleSections, editable
  ]);
  
  const toggleAlert = () => {
    showAlert((state) => !state);
  };
  
  const handleNameChange = (value) => {
    section.setName(value);
  };
  
  const handleSectionRemoval = (moveModules = false) => async () => {
    const path = section.treePath.join("/");
    const newSection = structure.removeChild(section.id, moveModules);
    if(newSection) {
      structure.updateCommentPath(path, newSection.treePath.join("/"))
      socket?.requestCommentMove(path, newSection.treePath.join("/"));
    } else
      socket?.requestCommentPathRemove(path);
    showAlert(false);
  };
  
  const handleDescriptionChange = (v) => {
    section.setContent(v, false);
  };

  return (
    <>
      <SectionTitle
        id={"sectionTitle" + index}
        useDragHandle={editable}
        actions={
          removable && (
            <IconButton
              onClick={
                hasChildren ? toggleAlert : handleSectionRemoval(false)
              }
              size="small"
              tabIndex={-1}
              className={`removeButton preset-page preset-noedit ${classes.removeButton}`}
            >
              <Trash color="error" />
            </IconButton>
          )
        }
      >
        <SectionName
          id={id}
          defaultName={
            isLibrary ? t(`proposal_parts.section_titles.${name}`) : name
          }
          editable={editable}
          onChange={handleNameChange}
          colored
        />
      </SectionTitle>
      <Alert
        isOpen={alertVisible}
        title={t("views.editor.dialogs.remove_alert.title_level0")}
        onCancel={toggleAlert}
        onIntermediate={handleSectionRemoval(true)}
        onAccept={handleSectionRemoval(false)}
        acceptText={t("common.yes")}
        intermediateText={t("views.editor.dialogs.remove_alert.intermediate")}
        cancelText={t("common.no")}
      />
      {(editable || !isEmptyHtml(content)) && (
        <Grid
          item
          container
          wrap="nowrap"
          alignItems="center"
          className={`preset-sectionDesc ${classes.descContainer}`}
          id={"sectionDesc" + index}
        >
          <WysiwygEditor
            readOnly={!editable}
            noEmptyHtmlStrings
            changeOnClickAway
            placeholder={editable && t("views.editor.desc_change")}
            onChange={handleDescriptionChange}
            name="description-section"
            value={content}
          />
        </Grid>
      )}
    </>
  );
});

SectionRow.propTypes = {
  index: number.isRequired,
  section: object.isRequired,
};
