import { useMemo, useRef, useState } from "react";
import { bool, string } from "prop-types";
import { PopMenu, TooltipIcon } from "@components";
import { CommentAdd, Comment, CommentFilled } from "@assets";
import { useEstimateEditorSettings, useEstimateStructure } from "@tools";
import { observer } from "mobx-react";
import { ChatThreadPopupContent } from "./ChatThreadPopupContent";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import useStyle from "./ChatThread.style";
import { Badge, BottomNavigation, BottomNavigationAction, Grid } from "@material-ui/core";

export const ChatThread = observer(({
  path,
  className,
  rowThread,
  hideBadge,
}) => {
  const { allowCommenting, useInternalComments, useExternalComments } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const { t } = useTranslation();
  const classes = useStyle();
  

  const [threadVisible, showThread] = useState(false);
  const [internal, setInternal] = useState(!useExternalComments);

  const anchor = useRef(null);
  const { comments } = structure;

  const hasComment = useMemo(
    () => structure.hasAnyPathComments(path, useExternalComments, useInternalComments),
    [comments, comments?.length, path, useExternalComments, useInternalComments]
  );
  
  const initialMessage = useMemo(
    () => structure.getPathComments(path, internal),
    [comments, comments?.length, internal]
  );

  const childComments = useMemo(
    () => structure.getChildComments(path, internal),
    [comments, comments?.length, ]
  );

  const noBadge = useMemo(
    () => hideBadge || !childComments,
    [hideBadge, childComments]
  );

  const handleThreadVisibility = () => {
    showThread((open) => !open);
  };
  
  const handleCommentTypeSwitch = async (e, val) => {
    // if(!internal && !commentStore.allowedInternalComments) {
    //   const allowed = await checkAccess();
    //   if(!allowed)
    //     return;
    //   commentStore.setAllowInternalComments(allowed);
    // }
    setInternal(val)
  }
  
  if(!allowCommenting && !hasComment)
    return <></>;

  return (
    <>
      <Badge
        color="secondary"
        invisible={noBadge}
        badgeContent={childComments}
        overlap="circular"
        max={9}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <TooltipIcon
          ref={anchor}
          color="primary"
          className={classnames(
            "button-comment",
            "noDrag",
            !hasComment && !(childComments && !hideBadge) && rowThread && "hoverIcon opaque",
            className
          )}
          onClick={handleThreadVisibility}
          icon={
            Boolean(hasComment) || childComments > 0
              ? (
                !hasComment?.internal
                  ? <CommentFilled />
                  : <Comment />
              )
              : <CommentAdd />
          }
        >
          {t(`views.editor.comments.${allowCommenting ? "tooltip" : "tooltip_readonly"}`)}
        </TooltipIcon>
      </Badge>
      <PopMenu
        id="commentThread"
        placement="right-start"
        anchor={anchor.current}
        className={classes.root}
        show={threadVisible}
        onClickAway={handleThreadVisibility}
      >
        {
          useInternalComments && useExternalComments &&
          <>
            <Grid
              item container
              wrap="nowrap"
              alignItems="center"
              className="pr-2"
            >
              <BottomNavigation
                value={internal}
                aria-label="comment type switch"
                onChange={handleCommentTypeSwitch}
                role="navigation"
                className="p-0"
                showLabels
              >
                <BottomNavigationAction
                  aria-label="external comments"
                  label={t("views.editor.comments.external")}
                  value={false}
                  className={classnames(classes.navi, "max-w-full text-deco-none nav-external")}
                />
                <BottomNavigationAction
                  aria-label="internal comments"
                  label={t("views.editor.comments.internal")}
                  value={true}
                  className={classnames(classes.navi, "max-w-full text-deco-none nav-internal")}
                />
              </BottomNavigation>
              <TooltipIcon
                color="primary"
                className="ml-2"
              >
                {t("views.editor.comments.external_info")}
              </TooltipIcon>
            </Grid>
          </>
        }
        <ChatThreadPopupContent
          internal={internal}
          path={path}
          initialMessage={initialMessage}
          allowCommenting={allowCommenting}
          onClose={handleThreadVisibility}
        />
      </PopMenu>
    </>
  );
});

ChatThread.propTypes = {
  path: string.isRequired,
  rowThread: bool,
  // color: oneOf(["default", "inherit", "primary", "secondary"]),
  className: string,
  hideBadge: bool,
};