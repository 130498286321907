import { useState, useEffect } from "react";
import { bool, string, object, func, arrayOf } from "prop-types";
import { observer } from "mobx-react";
import { Grid, CircularProgress } from "@material-ui/core";
import { ChatMessage } from "./ChatMessage/ChatMessage";
import { universalDateParser } from "@utils";
import { fetchProjectCommentsQuery } from "@query";
import classnames from "classnames";
import useStyle from "./ProjectChat.style";

export const ProjectChat = observer(({
  projectUuid,
  showCommentInput,
  onCommentCommit,
  teamMembers
}) => {

  const classes = useStyle();
  const [comments, setComments] = useState([]);
  const [loadingComments, setLoading] = useState(false);

  const loadComments = async () => {
    setLoading(true);
    const r = await fetchProjectCommentsQuery(projectUuid);
    setComments(r);
    setLoading(false);
  };

  useEffect(() => {
    loadComments();
  }, []);

  const handleCommentCreation = (commentId) => {
    if (commentId) loadComments();
    onCommentCommit && onCommentCommit();
  };

  return (
    <Grid item container direction="column">
      {showCommentInput && (
        <ChatMessage
          creationMode
          projectUuid={projectUuid}
          onChange={handleCommentCreation}
          teamMembers={teamMembers}
        />
      )}
      <Grid
        item
        container
        direction="column"
        role="listbox"
        className={classnames({
          [classes.commentsContainer]: showCommentInput,
        })}
      >
        {loadingComments && !comments.length ? (
          <CircularProgress className={classes.progress} />
        ) : (
          comments.map(({ id, userId, content, createdAt }) => (
            <ChatMessage
              key={id}
              commentId={id}
              projectUuid={projectUuid}
              date={universalDateParser(createdAt)}
              message={content}
              authorId={userId}
              onChange={loadComments}
              teamMembers={teamMembers}
            />
          ))
        )}
      </Grid>
    </Grid>
  );
});

ProjectChat.propTypes = {
  projectUuid: string.isRequired,
  showCommentInput: bool,
  onCommentCommit: func,
  teamMembers: arrayOf(object)
};