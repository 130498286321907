import { useMemo } from "react";
import { observer } from "mobx-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { editor } from "@paths";
import { useStores } from "@hooks";
import { useLibraryStore } from "../providers";
import {
  EstimateEditorSettingsProvider,
  useEstimateStructure ,
  ProposalSettings,
  EditorContainer,
  Section,
} from "@tools";
import { Button } from "@components";
import { Grid, Switch } from "@material-ui/core";
import { ArrowBack } from "@assets";
import { Notifications, NotificationsNone } from "@material-ui/icons";

export const LibraryContainer = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { userStore } = useStores();
  const libraryStore = useLibraryStore();
  const libraryStructure = useEstimateStructure();

  const { pending, hasSuggestions } = libraryStore;
  const { settings, sections } = libraryStructure;
  const { isModerator } = userStore;

  const allowEdition = useMemo(
    () => isModerator && !pending,
    [isModerator, pending]
  );

  const handleHistoryBack = () => navigate(-1);

  const handleShowPrices = () =>
    settings.setShowPrices(!libraryStructure.settings.showPrices);

  const showSuggestedElements = () => {
    libraryStore.setPendingState(!pending);
  };

  const previouslyOpenedProject = useMemo(
    () => state?.from.match(editor(".*")),
    []
  );

  
  return (
    <EstimateEditorSettingsProvider
      hasEditorPrivileges={allowEdition}
      hasSellerPrivileges={allowEdition}
      allowInteractions={allowEdition}
      displayLibraryElementStatus={pending}
      allowLibraryElementStatusChange={isModerator}
      noTurningOff
      isLibrary
    >
      <EditorContainer>
        <Grid item container className="w-full mt-6">
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            name="table_title"
            className="mb-9"
          >
            <Grid item container className="w-max">
              <h3 className="preset-title text-xl">{t("views.library.title")}</h3>
              {previouslyOpenedProject && (
                <Button
                  variant="text"
                  size="small"
                  onClick={handleHistoryBack}
                  className="ml-4 cursor-pointer"
                >
                  {t("views.library.back")}
                </Button>
              )}
              {isModerator && (
                <Button
                  variant="text"
                  size="small"
                  onClick={showSuggestedElements}
                  className="ml-4"
                  icon={
                    !pending ? (
                      hasSuggestions ? (
                        <Notifications />
                      ) : (
                        <NotificationsNone />
                      )
                    ) : (
                      <ArrowBack />
                    )
                  }
                >
                  {t(`views.library.suggestions${pending ? "_back" : ""}`)}
                </Button>
              )}
            </Grid>
            {isModerator && (
              <Grid item container className="w-max" alignItems="center">
                <ProposalSettings noViewLevel noRounding noDescriptionMode noRiskBuffer />
                <p className="mx-2">{t("views.editor.show_prices")}</p>
                <Switch
                  color="primary"
                  checked={settings.showPrices}
                  onChange={handleShowPrices}
                  name={
                    settings.showPrices
                      ? "Hide prices from breakdown"
                      : "Show prices in breakdown"
                  }
                />
              </Grid>
            )}
          </Grid>
          {sections.map((section, index) => (
            <Section
              key={section.id}
              section={section}
              index={index}
            />
          ))}
          {!sections?.length && (
            <p>
              {t("views.library.pending_empty")}
            </p>
          )}
        </Grid>
      </EditorContainer>
    </EstimateEditorSettingsProvider>
  );
});
