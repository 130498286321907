import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  createProjectTypeQuery,
} from "@query/projectTypes";
import { LabelEditor, Dialog, Button } from "@components";
import { useState } from "react";
import { CircularProgress } from "@material-ui/core";

export const NewProjectTypeDialog = ({
  open,
  defaultName,
  onClose,
}) => {
  const {t} = useTranslation();
  
  const [ isLoading, setLoading ] = useState(null);
  const [ newProjectType, setNewProjectType ] = useState({ id: 1, name: defaultName });
  
  const handleUpdate = async (_, name, backgroundColor, fontColor) => {
    setNewProjectType((t) => ({ id: t.id, name, backgroundColor, fontColor }));
  }
  
  const handleConfirm = async () => {
    if(!newProjectType)
      return;
    setLoading(true)
    const id = await createProjectTypeQuery(newProjectType.name, newProjectType.backgroundColor, newProjectType.fontColor);
    onClose({ ...newProjectType, id });
    setLoading(false)
  }
  
  const handleCancel = () => {
    onClose();
  }
  
  return <Dialog
    open={open}
    width={456}
    title={t("views.settings.project_type_add")}
    actions={
      <>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
        >
          {t("common.cancel")}
        </Button>
        <Button
          variant="contained"
          onClick={handleConfirm}
        >
          {
            isLoading
              ? <CircularProgress size={24} />
              : t("common.confirm")
          }
        </Button>
      </>
    }
  >
    <LabelEditor
      labelList={[ newProjectType ]}
      noRemoval
      noCreation
      onUpdate={handleUpdate}
    />
  </Dialog>;
}

NewProjectTypeDialog.propTypes = {
  open: bool.isRequired,
  onClose: func.isRequired,
  defaultName: string,
}