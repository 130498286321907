import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { useTranslation } from "react-i18next";
import {
  createProjectTypeQuery,
  removeProjectTypeQuery,
  updateProjectTypeQuery
} from "@query";
import { LabelEditor, Dialog, Button } from "@components";

export const ProjectTypesDialog = ({
  open,
  projectTypes,
  onChange,
  onClose,
}) => {
  const {t} = useTranslation();
  
  const handleCreate = async (name, color, font) => {
    const id = await createProjectTypeQuery(name, color, font);
    onChange([...projectTypes, { id, name, color, font }]);
    return id;
  }
  
  const handleRemove = async (id, updatedList) => {
    await removeProjectTypeQuery(id);
    onChange(updatedList);
  }
  
  const handleUpdate = async (id, name, color, font, updatedList) => {
    await updateProjectTypeQuery(id, name, color, font);
    onChange(updatedList);
  }
  
  return <Dialog
    open={open}
    width={520}
    title={t("views.settings.menu_full.project_types")}
    onClose={onClose}
    actions={
      <Button
        variant="contained"
        onClick={onClose}
      >
        {t("common.close")}
      </Button>
    }
  >
    <LabelEditor
      labelList={projectTypes}
      onCreate={handleCreate}
      onRemove={handleRemove}
      onUpdate={handleUpdate}
      stickyNewLabelRow
    />
  </Dialog>;
}

ProjectTypesDialog.propTypes = {
  open: bool.isRequired,
  projectTypes: arrayOf(shape({
    id: number.isRequired,
    name: string.isRequired,
    backgroundColor: string.isRequired,
    fontColor: string.isRequired,
  })),
  onChange: func.isRequired,
  onClose: func.isRequired,
}