import { arrayOf, number, shape, string, object, boolean } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { safeRoundHours } from "project-structure";
import { useEstimateStructure } from "@tools";
import { Grid } from "@material-ui/core";

export const CombinedTaskWorkTypeOverheadTooltip = observer(({
  element,
  usedWorkTypeOverheads,
  isMax
}) => {
  const {t} = useTranslation();
  const structure = useEstimateStructure();
  const { modifier, timeModifier, useMinMax } = structure.settings;
  const { combinedUnmodifiedHours } = element;
  
  const getValue = (percent=1) => Number(
    (safeRoundHours(((
      isMax
        ? combinedUnmodifiedHours?.max
        : useMinMax
          ? combinedUnmodifiedHours?.min
          : combinedUnmodifiedHours?.avg
      ) || 0 ) * percent, modifier) / modifier
    ).toPrecision(12)
  );
  
  return <Grid item container direction="column" className="font-bold">
      <span>
        {t("common.base_value")}
        {": "}
        <strong>
          {getValue()}
          {" "}
          {t(`times.${timeModifier === 1 ? "hour" : "day"}`)}
        </strong>
      </span>
    {
      usedWorkTypeOverheads
        .map(({ name, percent }) => (
          <span key={name}>
            {name}{": "}
            <strong>
              {"+"}
              {getValue(percent)}
            </strong>
          </span>
        ))
    }
  </Grid>
});

CombinedTaskWorkTypeOverheadTooltip.propTypes = {
  element: object.isRequired,
  usedWorkTypeOverheads: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    percent: number.isRequired,
  })).isRequired,
  isMax: boolean,
}