import { useTranslation } from "react-i18next";
import { Grid, Switch } from "@material-ui/core";
import { Autocomplete, ClearButton, Label, TextField } from "@components";
import { Search } from "@assets";
import useStyle from "./TemplatesFilter.style";
import { useEffect, useRef, useState } from "react";
import { LOCAL_SORT_TYPES, SORT_BY_TYPES } from "@query";
import { useTemplates } from "@hooks";

export const TemplatesFilter = () => {
  const { t } = useTranslation();
  const classes = useStyle();
  
  const {
    nameFilter,
    setNameFilter,
    typesFilter,
    setTypesFilter,
    sortBy,
    setSortBy,
    projectTypes,
    clearFilters,
    switchCompanyTemplatesState,
    filtersLoading,
    companyTemplates,
  } = useTemplates();
  
  const [localName, setLocalName] = useState(nameFilter);
  
  const inputRef = useRef();
  
  useEffect(() => {
    setLocalName(nameFilter);
  }, [nameFilter])

  const setName = (e) => setLocalName(e.target.value);
  
  const checkKey = e => {
    if(e.keyCode === 13 ) {
      e.stopPropagation();
      handleNameChange();
      inputRef.current.blur();
    }
  };
  
  const handleNameChange = () => {
    if(nameFilter === localName) 
      return;
    setNameFilter(localName);
  }

  return (
    <Grid
      container
      spacing={1}
      className={classes.filter}
      wrap="nowrap"
      alignItems="center"
    >
      <Grid
        item
        container
        wrap="nowrap"
        alignItems="center"
        xs
        style={{ maxWidth: "max-content" }}
      >
        <p style={{ width: 80 }}>{t("views.templates.custom_templates")}</p>
        <Switch
          color="primary"
          checked={companyTemplates}
          onChange={switchCompanyTemplatesState}
          name={t(
            `views.templates.${
              companyTemplates ? "apropo_templates" : "custom_templates"
            }`
          )}
        />
      </Grid>
      <Grid item xs style={{ minWidth: 220 }}>
        <TextField
          size="small"
          id="filterName"
          value={localName}
          InputLabelProps={{
            style: { display: "flex", alignItems: "center" },
          }}
          InputProps={{
            inputProps: {
              ref: inputRef
            }
          }}
          label={
            <>
              <Search
                style={{ fontSize: 18, marginRight: 4, marginTop: 2 }}
              />
              {t("views.templates.search")}
              {"..."}
            </>
          }
          onChange={setName}
          onBlur={handleNameChange}
          onKeyDown={checkKey}
          style={{ margin: 0 }}
        />
      </Grid>
      <Grid item xs style={{ minWidth: 180 }}>
        <Autocomplete
          id="projectTypesSelect"
          value={typesFilter || []}
          options={projectTypes}
          inputPlaceholder={t("forms.template.types_enter")}
          fullWidth
          multiple
          size="small"
          limitTags={3}
          fieldClassName="mb-0"
          renderTagsOnList
          dataLoading={filtersLoading}
          onChange={setTypesFilter}
          noOptionsText={t("views.settings.menu_full.no_project_types")}
          renderOption={({ option, ...tagProps }) => <Label
            {...tagProps}
            {...option}
            size="small"
          />}
        />
      </Grid>
      <Grid item>
        <ClearButton className={classes.clearFilters} onClick={clearFilters}>
          {t("common.clear_filters")}
        </ClearButton>
      </Grid>
      <Grid item xs style={{ minWidth: 180, maxWidth: 212 }}>
        <Autocomplete
          id="sorting"
          value={sortBy}
          size="small"
          fieldClassName="mb-0"
          options={ sortByOptions.map(id => ({ id })) }
          inputPlaceholder={t("forms.template.types_enter")}
          fullWidth
          labelFormat={(l) => t(`sorting.${l}`)}
          onChange={setSortBy}
          disableClearable
          disableSearch
        />
      </Grid>
    </Grid>
  );
};

const sortByOptions = [
  `${SORT_BY_TYPES.NAME}_${LOCAL_SORT_TYPES.ASC}`,
  `${SORT_BY_TYPES.NAME}_${LOCAL_SORT_TYPES.DESC}`,
  `${SORT_BY_TYPES.CREATED}_${LOCAL_SORT_TYPES.ASC}`,
  `${SORT_BY_TYPES.CREATED}_${LOCAL_SORT_TYPES.DESC}`,
  `${SORT_BY_TYPES.UPDATED}_${LOCAL_SORT_TYPES.ASC}`,
  `${SORT_BY_TYPES.UPDATED}_${LOCAL_SORT_TYPES.DESC}`,
];