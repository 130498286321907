import { useEffect, useMemo } from "react";
import { observer } from "mobx-react";
import ResizeObserver from "react-resize-observer";
import { Tables, hashCode } from "project-structure";
import { useTableExpandHandler } from "@hooks";
import {
  useEstimateStructure,
  usePresetFieldNames,
  useTableOverflowChecker,
  useEstimateEditorSettings,
  EstimateTableSettingsProvider,
  TableTitle, ScrollButtonContainer,
} from "@tools";
import { TableContainer, Grid, Collapse } from "@material-ui/core";
import { TableHeader } from "./components/TableHeader/TableHeader";
import { BudgetTrackingSummaryRowGroup } from "./components/BudgetTrackingSummaryRowGroup/BudgetTrackingSummaryRowGroup";
import useStyle from "@tools/EstimateEditor/styles/CommonTableStyles.style";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

export const BudgetTrackingSummary = observer(() => {
  const structure = useEstimateStructure();
  const classes = useStyle();
  const { isProposal, blockExpansions } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);

  const { settings, btWorkTypes } = structure;
  
  const {
    showSummary,
    useMinMax,
    visibleSummaryColumns,
  } = settings;

  const columns = useMemo(() => (
    visibleSummaryColumns.filter((column) => ["cost", "hours", "rate"].includes(column.name))
  ), [visibleSummaryColumns]);

  const tableContainerIdentifier = useMemo(() => "summaryContainer", []);
  const tableIdentifier = useMemo(() => "summaryHeader", []);
  
  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);

  useEffect(() => {
    onResize();
  }, [useMinMax]);

  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    true,
    showSummary,
    settings.setShowSummary
  );

  return (
    <EstimateTableSettingsProvider
      useExpandCell
      columns={columns}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        className={classnames(
          "preset-paper",
          classes.whiteContainer,
        )}
      >
        <Grid item container justifyContent="flex-end">
          <TableTitle
            expandable={!blockExpansions}
            presetClass="summary"
            id={hashCode(Tables.SUMMARY)}
            title={getFieldName("pt-summary")}
            showContent={showSummary}
            onContentAction={handleOpen}
          />
          <Collapse
            in={showSummary}
            timeout="auto"
            mountOnEnter
            unmountOnExit
            className={classnames(classes.collapse)}
            data-id={hashCode("cont")}
            data-t="summary"
            data-p={hashCode(Tables.SUMMARY)}
            {...collapseProps}
          >
            <Grid
              container
              justifyContent="center"
              wrap="nowrap"
              direction="column"
            >
              {
                isTableContentOverflowing && !isMobile &&
                <ScrollButtonContainer {...scrollButtonProps} />
              }
              <TableContainer
                ref={ref}
                id={tableContainerIdentifier}
                onScroll={onScroll}
                className={classnames(
                  "pb-3",
                  classes.tableContainer,
                  isTableContentOverflowing && classes.overflows
                )}
              >
                <Grid container role="rowgroup" direction="column">
                  <ResizeObserver onResize={onResize} />
                  <TableHeader tableIdentifier={tableIdentifier}/>
                  {btWorkTypes.map((workType) => (
                    <BudgetTrackingSummaryRowGroup
                      key={workType.id}
                      workType={workType}
                    />
                  ))}
                </Grid>
              </TableContainer>
            </Grid>
          </Collapse>
        </Grid>
      </Grid>
    </EstimateTableSettingsProvider>
  );
});