import { useState } from "react";
import { bool } from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// import { home } from "@paths";
import { useEditorWebsocket } from "@hooks";
import { CircularProgress } from "@material-ui/core";
import { Alert } from "@components";


export const EstimateEditorConnectionErrorAlert = ({
  isTemplate,
}) => {
  
  const {t} = useTranslation();
  const socket = useEditorWebsocket();
  const navigate = useNavigate();
  
  const [isReloading, setIsReloading] = useState(false);
  
  const handleAccept = async () => {
    if(isReloading)
      return;
    setIsReloading(true);
    navigate(0);
  }
  
  // const handleCancel = () => {
  //   if(isReloading)
  //     return;
  //   navigate(home);
  // }
  
  return <Alert
    isOpen={socket?.hasConnectionError}
    title={t("alerts.editor.connection.title")}
    acceptText={(
      isReloading
      ? <>
        <CircularProgress size={ 18 } className="mr-2 text-alt" />
        {t(`alerts.editor.connection.loading${isTemplate ? "template" : ""}`)}
      </>
      : t("alerts.editor.connection.accept")
    )}
    onAccept={handleAccept}
  >
    <span>{t("alerts.editor.connection.text")}</span>
    <br />
    <span>{t("alerts.editor.connection.text2")}</span>
  </Alert>
}

EstimateEditorConnectionErrorAlert.propTypes = {
  isTemplate: bool,
}