// import { useEffect } from "react";
import { PdfUploadProgress } from "@components";
import { PdfEditorContainer } from "@tools";
// import { useEditorWebsocket } from "@hooks";
// import { useParams } from "react-router-dom";

export const PdfEditorView = () => {
  // @todo: check if we can throw WS connection from pdf projects
  // const { projectUuid } = useParams();
  // const socket = useEditorWebsocket();
  
  // useEffect(() => {
  //   socket?.joinProject(projectUuid, undefined, true);
  //   return () => {
  //     socket?.closeProject();
  //   }
  // }, []);
  
  return (
    <>
      <PdfEditorContainer />
      <PdfUploadProgress />
    </>
  )
}