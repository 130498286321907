import { useMemo, useState } from "react";
import { bool, number, string, object, arrayOf, oneOf, oneOfType } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Tables, hashCode, BREAKDOWN_TABLE_DEPTHS, DESCRIPTION_MODES } from "project-structure";
import { BREAKDOWN_ELEMENT_CODES, CELL_WIDTHS } from "@utils";
import { useTableExpandHandler } from "@hooks";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  useEstimateTableSettings,
  TitleCell,
  Row,
  RowGroup,
  DescriptionCell,
} from "@tools";
import { WysiwygEditor } from "@components";
import { Collapse } from "@material-ui/core";
import { BudgetTrackingTaskWorkType } from "./BudgetTrackingTaskWorkType";
import { BudgetTrackingBreakdownRowGroupList } from "./BudgetTrackingBreakdownRowGroupList";
import classnames from "classnames";

export const BudgetTrackingBreakdownRowGroup = observer(({
  element,
  isLastGroupRow,
  parentIds = [],
  tableDepth = 0,
  initialDepth = 0,
}) => {
  const {
    allowEdition,
    useVisibilityCell,
    blockExpansions,
    displayLibraryElementStatus,
  } = useEstimateEditorSettings();
  const { useExpandCell } = useEstimateTableSettings();
  const structure = useEstimateStructure();
  const { t } = useTranslation();

  const [descEdition] = useState(undefined);

  const { valueLevel, viewLevel, descriptionMode } = structure.settings;

  const {
    id: elementId,
    name,
    content,
    isTurnedOff,
    hasChildren,
    hasFixedPrice,
    isOpened,
    status,
    showMoreContent,
  } = element;

  const actualDepthLevel = useMemo(
    () => tableDepth + initialDepth,
    [tableDepth, initialDepth]
  );

  const fullIdPath = useMemo(
    () => [...parentIds, elementId],
    [parentIds, elementId]
  );

  const openable = useMemo(
    () => actualDepthLevel < viewLevel && (allowEdition || hasChildren),
    [actualDepthLevel, viewLevel, allowEdition, hasChildren]
  );

  const hasEditedDescription = useMemo(
    () =>
      descriptionMode === DESCRIPTION_MODES.ROW ? Boolean(content) || descEdition : descEdition,
    [descEdition, descriptionMode, content]
  );

  const isAcceptedComponent = useMemo(
    () => status === 1 && displayLibraryElementStatus,
    [status, displayLibraryElementStatus]
  );

  const opened = useMemo(
    () => isAcceptedComponent || (openable && (isOpened || blockExpansions)),
    [isAcceptedComponent, openable, isOpened, blockExpansions]
  );

  const isSectionRow = useMemo(
    () => actualDepthLevel === BREAKDOWN_TABLE_DEPTHS.SECTION,
    [actualDepthLevel]
  );

  const displayCellContent = useMemo(
    () =>
      actualDepthLevel <= valueLevel &&
      (!opened || !hasChildren || tableDepth + 1 > valueLevel),
    [actualDepthLevel, valueLevel, opened, hasChildren, tableDepth]
  );

  const hashedPath = useMemo(
    () =>
      [Tables.BREAKDOWN, ...(parentIds || []), elementId]
        .map((id) => hashCode(id))
        .join("/"),
    [parentIds, elementId]
  );

  const handleTurnOff = () => {
    element.setTurnOffState(!isTurnedOff);
  };

  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    openable,
    isOpened,
    element.setOpenState
  );

  return (
    <RowGroup
      originTableId={Tables.BREAKDOWN}
      tableDepth={tableDepth}
      isActive={opened}
      isStatic={!allowEdition}
      elementId={elementId}
      parentIds={parentIds}
      elementType={BREAKDOWN_ELEMENT_CODES[actualDepthLevel]}
    >
      <Row
        useExpandCell={useExpandCell}
        expandable={openable}
        expanded={opened}
        onExpand={handleOpen}
        noArrow={actualDepthLevel === BREAKDOWN_TABLE_DEPTHS.MODULE}
        expandOnClick
        useVisibilityCell={useVisibilityCell}
        visible={!isTurnedOff}
        onVisibilityToggle={handleTurnOff}
        isFirstGroupRow={tableDepth <= 1}
        isLastGroupRow={ isSectionRow || (isLastGroupRow && !opened && !hasEditedDescription) }
        highlightCellText={isAcceptedComponent}
        tableDepth={tableDepth}
        style={!tableDepth ? { marginBottom: 2 } : undefined}
      >
        <TitleCell
          allowOverflowDisplay={descriptionMode < DESCRIPTION_MODES.COLUMN}
          minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
          maxWidth={CELL_WIDTHS.DESCRIPTION}
          pinnedLeft={useExpandCell ? tableDepth || 1 : 0}
        >
          {
            <span
              className={classnames(
                "name wrap-text my-3",
                !isSectionRow && "preset-titleText",
                isSectionRow && "preset-titleTextSection",
                !name?.length && "semi-transparent",
                !useExpandCell && "ml-3"
              )}
            >
              {name?.replace(/<(.|\n)*?>/g, "") || t("common.unnamed")}
            </span>
          }
        </TitleCell>
        <DescriptionCell
          allowOverflowDisplay
          isSectionRow={isSectionRow}
          minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
        >
          <WysiwygEditor
            readOnly
            noEmptyHtmlStrings
            changeOnClickAway
            placeholder={t("views.editor.desc_change")}
            name={`desc${hashCode(elementId)}`}
            value={content}
            toolbarVariant="popup"
            shorten
            useBorder
            showMore={showMoreContent}
            onShowMore={element.showMoreContent}
            moreButtonClassName={classnames(
              !isSectionRow && "preset-moreDesc",
              isSectionRow && "preset-moreDescSection color-primary-lighter"
            )}
            className={allowEdition ? "my-1-5" : "my-3 ml-0-5"}
          />
        </DescriptionCell>
        {
          element.btTaskWorkTypes?.map((workType) => (
          <BudgetTrackingTaskWorkType
            key={workType.id}
            tableDepth={tableDepth}
            element={element}
            workType={workType}
            editable={ allowEdition }
            displayContent={displayCellContent}
            fullIdPath={fullIdPath}
            emptyValue={hasFixedPrice}
          />
        ))
        }
      </Row>
      {openable && (
        <Collapse
          in={opened}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${elementId}_cont`)}
          data-p={hashedPath}
          data-t={BREAKDOWN_ELEMENT_CODES[actualDepthLevel + 1]}
          {...collapseProps}
        >
          <BudgetTrackingBreakdownRowGroupList
            element={element}
            tableDepth={tableDepth}
            initialDepth={initialDepth}
            isLastGroupRow={ tableDepth === 0 || (isLastGroupRow && !allowEdition) }
            parentIds={fullIdPath}
            className="w-full"
          />
        </Collapse>
      )}
    </RowGroup>
  );
});

BudgetTrackingBreakdownRowGroup.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),
  parentIds: arrayOf(oneOfType([number, string])),
  isLastGroupRow: bool,
};
