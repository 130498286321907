import { bool, number, string, object, node, arrayOf, oneOfType, oneOf } from "prop-types";
import { observer } from "mobx-react";
import { BudgetTrackingBreakdownRowGroup } from "./BudgetTrackingBreakdownRowGroup";

export const BudgetTrackingBreakdownRowGroupList = observer(({
  element,
  isLastGroupRow,
  parentIds,
  tableDepth = 0,
  initialDepth = 0,
  ...elementProps
}) => {
  const { children: elementChildren } = element;

  return (
    <>
      {elementChildren?.map((child, i) => (
        <BudgetTrackingBreakdownRowGroup
          key={`element${child.id}`}
          element={child}
          isLastGroupRow={
            isLastGroupRow &&
            (tableDepth === 0 || i === elementChildren.length - 1)
          }
          parentIds={parentIds}
          tableDepth={tableDepth + 1}
          initialDepth={initialDepth}
          {...elementProps}
        />
      ))}
    </>
  );
});

BudgetTrackingBreakdownRowGroupList.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),
  isLastGroupRow: bool,
  parentIds: arrayOf(oneOfType([number, string])),
  children: node,
};
