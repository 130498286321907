import { useMemo, useState } from "react";
import { arrayOf, bool, number, object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Accordion, Alert, Dialog } from "@components";
import { OverheadTitle } from "./components/OverheadTitle";
import { SettingContents } from "../SettingContents/SettingContents";
import classnames from "classnames";

export const OverheadEntry = observer(({
  overhead,
  isGlobal,
  isFirstOnList,
  availableWorkTypes,
}) => {
  const { t } = useTranslation();
  
  const [expanded, setExpanded] = useState(false);
  const [alertVisible, showAlert] = useState(false);
  const [removeAlertVisible, showRemoveAlert] = useState(false);
  
  const {
    id,
    used,
    name,
    percent,
    workTypes
  } = overhead;
  
  const messageContent = useMemo(() => (
    isGlobal ? t("views.editor.dialogs.overheads.global_alert") : undefined
  ), [isGlobal])
  
  const messageTitle = useMemo(() => (
    isGlobal ? t("views.editor.dialogs.overheads.global_full") : undefined
  ), [isGlobal])
  
  const handleExpand = (e) => {
    if(e.target.nodeName === "INPUT")
      return;
    setExpanded(!expanded)
  }
  
  const handleShowAlert = (e) => {
    e?.stopPropagation?.();
    showAlert(true);
  }
  
  const handleSetUsed = (newUsed) => {
    if(isGlobal) {
      showAlert(true);
      return;
    }
    overhead.setUseState(newUsed)
  }
  
  const handleSetName = (newName) => {
    overhead.setName(newName)
  }
  
  const handleSetWorkTypes = (workTypeId) => {
    if(isGlobal) {
      showAlert(true);
      return;
    }
    overhead.setWorkTypes(
      workTypes.includes(workTypeId) ? workTypes.filter((s) => s !== workTypeId) : [...workTypes, workTypeId]
    )
  }
  const handleSetAllOf = () => {
    if(isGlobal) {
      showAlert(true);
      return;
    }
    overhead.setWorkTypes(workTypes.length === availableWorkTypes.length ? [] : [...availableWorkTypes])
  }
  
  const handleSetPercent = (newPercent) => {
    overhead.setPercent(newPercent)
  }
  
  const handleRemove = (e) => {
    e.stopPropagation();
    if(isGlobal)
      showAlert(true);
    else
      showRemoveAlert(true);
  }
  
  const removeOverhead = () => {
    overhead.removeSelf(id);
  }
  
  return (
    <>
      <Accordion
        expanded={expanded}
        onExpand={handleExpand}
        title={<OverheadTitle
          name={name}
          used={used}
          expanded={expanded}
          isGlobal={isGlobal}
          isFirstOnList={isFirstOnList}
          percent={percent}
          setUsed={handleSetUsed}
          setName={handleSetName}
          onNameInputFocus={isGlobal ? handleShowAlert : undefined}
          onRemove={handleRemove}
        />}
        className={classnames("pl-6", isGlobal && "transparent-3")}
        titleClassName="pl-2"
        variant="info"
        bordered={false}
        titleColored
        useDragHandle
      >
        <SettingContents
          percent={percent}
          usedWorkTypes={workTypes.slice()}
          availableWorkTypes={availableWorkTypes}
          onPercentChange={handleSetPercent}
          onWorkTypeSelect={handleSetWorkTypes}
          hideList
          onSelectAll={handleSetAllOf}
          clickDialogMessageTitle={messageTitle}
          clickDialogMessageContent={messageContent}
        />
      </Accordion>
      {
        isGlobal && alertVisible &&
        <Dialog
          open
          onClose={() => showAlert(!alertVisible)}
          title={t("views.editor.dialogs.overheads.global_full")}
        >
          {t("views.editor.dialogs.overheads.global_alert")}
        </Dialog>
      }
      {
        removeAlertVisible &&
        <Alert
          isOpen
          title={t("views.editor.dialogs.overheads.remove")}
          acceptText={t("common.yes")}
          onAccept={removeOverhead}
          onCancel={() => showRemoveAlert(false)}
        />
      }
    </>
  );
});

OverheadEntry.propTypes = {
  overhead: object.isRequired,
  availableWorkTypes: arrayOf(number).isRequired,
  isGlobal: bool,
  isFirstOnList: bool,
};