import { APIMethod, fetchData } from "@client";

export async function fetchProjectVersionCommentsQuery(
  projectUuidOrHash,
  versionKey,
  userUuid=undefined
) {
  const res = await fetchData(
    `/v1/ws/project/comments/${projectUuidOrHash}/${versionKey}`,
    APIMethod.GET,
    {
      params: {
        user: userUuid
      }
    }
  );
  return res.results;
}
