import { bool } from "prop-types";
import { observer } from "mobx-react";
import {
  usePresetFieldNames,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  ValueCell,
  VisibilityButton, useEstimateEditorSettings,
} from "@tools";

export const HoursCell = observer(({ extendPadding }) => {
  const {
    allowEdition,
    isProposal,
  } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { hideSummaryHours } = structure.visibility;
  const className = useVisibilityModeCellClassName(hideSummaryHours);
  
  return (
    <ValueCell
      widest 
      isHeader
      size="xSmall"
      className={className}
      extendPadding={extendPadding}
      style={{ cursor: allowEdition && "grab" }}
    >
      <span className="preset-tableSummaryHeader pt-time">
        {getFieldName("pt-time")}
      </span>
      {
        visibilityMode &&
        <VisibilityButton
          size="tiny"
          inColumn right={32}
          hidden={hideSummaryHours}
          onChange={structure.visibility.setHideSummaryHours}
        />
      }
    </ValueCell>
  )
})

HoursCell.propTypes = {
  extendPadding: bool,
}