import { useMemo } from "react";
import { node, number, string } from "prop-types";
import { observer } from "mobx-react";
import { useEstimateStructure, CollapseValueCell } from "@tools";
import { TimelineSectionDroppable } from "./TimelineSectionDroppable";
import useStyle from "./SectionCell.style";

export const SectionCell = observer(({
  monthIndex,
  startWeek,
  sectionId,
  children,
}) => {
  const structure = useEstimateStructure();
  const classes = useStyle();
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(monthIndex)
  ), [structure.settings.expandedTimelineMonths?.size]);
  
  return (
    <CollapseValueCell
      isOpened={isOpened}
      narrowChildren
      useBorder
      isHeader
      visible
      isFirst={monthIndex === 0}
      cell={
        <TimelineSectionDroppable
          week={monthIndex*4}
          sectionId={sectionId}
        >
          { children }
        </TimelineSectionDroppable>
      }
    >
      {
        isOpened &&
        [...new Array(4).keys()]
          .map((week) => (
            <TimelineSectionDroppable
              key={`week${week}`}
              sectionId={sectionId}
              week={monthIndex*4+week}
              className={classes.weekCell}
            >
              { (startWeek === monthIndex*4 + week) && children}
            </TimelineSectionDroppable>
          ))
      }
    </CollapseValueCell>
  )
})

SectionCell.propTypes = {
  monthIndex: number.isRequired,
  startWeek: number.isRequired,
  sectionId: string.isRequired,
  children: node,
}