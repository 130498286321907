import { observer } from "mobx-react";
import { EstimateStructureProvider, useEstimateEditorStore } from "@tools";
import { BudgetTrackingContainer } from "./components";

export const BudgetTrackingView = observer(() => {
  const editorStore = useEstimateEditorStore();

  const { currentEstimateStructure } = editorStore;

  return (
    <EstimateStructureProvider value={currentEstimateStructure}>
      <BudgetTrackingContainer  />
    </EstimateStructureProvider>
  );
});
