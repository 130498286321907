import { useState } from "react";
import { USER_COMPANY_SETTINGS } from "@client";
import { setCompanySettingsQuery } from "@query";
import { useStores } from "@hooks";
import { useEstimateEditorStore, useEstimateStructure } from "@tools";

export const useRiskBufferAlert = () => {
  
  const structure = useEstimateStructure();
  const editorStore = useEstimateEditorStore();
  const { userStore } = useStores();
  
  const { applyRiskBuffer } = structure.settings;
  
  const [riskAlertVisible, showRiskAlert] = useState(false);
  
  const handleRiskAlertClose = async (e, lock) => {
    showRiskAlert(false);
    if (lock) {
      editorStore.blockedRiskAlert = true;
      await setCompanySettingsQuery(USER_COMPANY_SETTINGS.RISK_ALERT(userStore.userUuid), true)
    }
  };
  
  const handleRiskAlertOpen = () => {
    
    if(applyRiskBuffer && !editorStore?.blockedRiskAlert)
      setTimeout(
        () =>
          showRiskAlert(true),
        100
      );
  }
  
  return {
    riskAlertVisible,
    handleRiskAlertClose,
    handleRiskAlertOpen,
  }
}