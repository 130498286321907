import { APIMethod, fetchData } from "@client";

export async function saveTemplateQuery(
  uuid,
  {
    name,
    description,
    types
  },
) {
  await fetchData(`/template/${uuid}`, APIMethod.POST, {
    data: {
      uuid,
      name,
      description,
      labels: types ? JSON.stringify(types) : undefined,
    },
  });
}
