import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MONTH_WEEKS } from "project-structure";
import {
  useEstimateStructure,
  useSummaryTimeInputTooltipAlert,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  ValueCell,
  ResetValueButton,
} from "@tools";

export const TimeCell = observer(({
  element,
  displayCellContent,
  extendPadding,
}) => {
  const { t } = useTranslation();
  const { allowEdition, useProfitability } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const tooltip = useSummaryTimeInputTooltipAlert(allowEdition);
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryTime);
  
  const { usesTwoValues, usesAnyValue } = structure;
  const { useMinMax, useWeekTime, isTimelineTableUsed } = structure.settings;
  const {
    displayTime,
    displayTimeMax,
    isTimeOverwritten,
    hasOnlyCost,
    hasResources,
  } = element;
  
  const editable = allowEdition && !hasResources && !useProfitability && !isTimelineTableUsed;
  const showMaxValue = usesTwoValues && (editable || displayTime !== displayTimeMax);
  
  const handleTimeChange = (time, isMax) => {
    const key = isMax ? "max" : useMinMax ? "min" : "avg";
    element.setCustomTime({ [key]: time * (useWeekTime ? 1 : MONTH_WEEKS) });
  };
  
  const handleReset = () => {
    element.resetTimeValues();
  };

  return (
    <ValueCell
      widest
      showDecimals
      emptyInputs={hasOnlyCost || !usesAnyValue}
      value={displayTime}
      valueMax={displayTimeMax}
      displayMax={showMaxValue}
      onValueChange={handleTimeChange}
      displayContent={displayCellContent}
      warning={isTimeOverwritten}
      editable={editable}
      className={className}
      textPresetClass="preset-summaryText"
      inputClassName="input-time"
      extendPadding={extendPadding}
      { ...tooltip }
    >
      {displayCellContent && allowEdition && isTimeOverwritten && (
        <ResetValueButton action={handleReset}>
          {t("views.editor.revert_value")}
        </ResetValueButton>
      )}
    </ValueCell>
  );
});

TimeCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  extendPadding: bool,
};
