import { APIMethod, fetchData } from "@client";

export async function createTemplateQuery(
  uuid,
  {
    name,
    description,
    copyComments,
    types
  },
) {
  await fetchData(
    "/template",
    APIMethod.POST,
    {
      removeEmptyKeys: true,
      data: {
        uuid,
        name,
        description,
        labels: types ? JSON.stringify(types) : undefined,
        copyComments: copyComments ? 1 : 0,
      }
    }
  );
}
