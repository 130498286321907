import { useState } from "react";
import { useParams } from "react-router-dom";
import { setCompanySettingsQuery } from "@query";
import { PROJECT_COMPANY_SETTINGS } from "@client";
import { useEstimateEditorStore } from "@tools";

export const useBreakdownValueAlert = () => {
  const editorStore = useEstimateEditorStore();
  const { projectUuid } = useParams();
  
  const [valueAlertVisible, showAlertVisible] = useState(false);
  
  const handleValueAlertClose = async (e, lock) => {
    showAlertVisible(false);
    if (lock) {
      editorStore.blockedValueAlert = true;
      await setCompanySettingsQuery(PROJECT_COMPANY_SETTINGS.VALUE_ALERT(projectUuid), true)//@todo: projectUuid should be declared in editor root and renamed to sth more neutral
    }
  };
  
  const handleValueAlertOpen = () => {
    // if(!editorStore?.blockedValueAlert)
    setTimeout(
      () =>
        showAlertVisible(true),
      100
    );
  }
  
  return {
    valueAlertVisible,
    handleValueAlertClose,
    handleValueAlertOpen,
  }
}