import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BudgetTrackingStoreProvider } from "./providers";
import { EstimateEditorStoreProvider, EstimateEditorStore } from "@tools/EstimateEditor";
import { EstimateLoadingAlert } from "@tools/EstimateEditor";
import { getProjectEditionDataQuery, checkStructureVersionQuery, sendBrokenStructureFeedbackFileQuery } from "@query";
import { PROJECT_TYPE } from "@utils";
import { home } from "@paths";
import { NewEditorVersionAlert } from "@dialogs";
import { PageCircularProgress } from "@components";
import { BudgetTrackingView } from "./BudgetTrackingView";

export const BudgetTracking = () => {
  const navigate = useNavigate();

  const { projectUuid } = useParams();

  const [store, setStore] = useState(null);
  const [hasNewEditorVersion, setHasNewEditorVersion] = useState(null);
  const [hasError, setError] = useState(0);

  useEffect(() => {
    const abortController = new AbortController();
    setupEditorStore();
    return () => abortController.abort();
  }, []);
  
  const setupEditorStore = async () => {
    try {
      await checkStructureVersionQuery();
    } catch(e) {
      console.log("RELOAD:", e.reload);
      if(e.reload)
        setHasNewEditorVersion(true);
      throw e;
    }
    
    try {
      const data = await getProjectEditionDataQuery(projectUuid);
      
      setStore(new EstimateEditorStore({
        useHistoryManager: true,
        estimateType: PROJECT_TYPE.STANDARD,
        ...data
      }));
    } catch(e) {
      if(e.status === 403)
        navigate(home, { replace: true });
      else if (e.json) {
        if (process.env.REACT_APP_NODE_ENV === "production")
          await sendBrokenStructureFeedbackFileQuery(e.json);
        setError(1);
      } else
        setError(2);
    }
  };

  if (hasError) return <EstimateLoadingAlert structureError={hasError === 1} />;

  return (
    <>
      {
        !store ? (
          <PageCircularProgress />
        ) : (
          <BudgetTrackingStoreProvider>
            <EstimateEditorStoreProvider value={store}>
              <BudgetTrackingView />
            </EstimateEditorStoreProvider>
          </BudgetTrackingStoreProvider>
        )
      }
      {
        hasNewEditorVersion &&
        <NewEditorVersionAlert />
      }
    </>
  );
};
