import { observer } from "mobx-react";
import {
  getUserVisibleWorkTypes,
  useEstimateEditorSettings,
  useEstimateEditorStore,
  useEstimateStructure,
  useVisibilityModeCellClassName,
  TimelineSettings,
  VisibilityButton,
} from "@tools";
import { ButtonSwitch } from "@components";
import { Collapse, Grid } from "@material-ui/core";
import { TimelineRowGroupList, TimelineSectionTitleRow } from "../components";
import { BarChartSide, TableChart } from "@assets";

export const TimelineTeamAllocation = observer(() => {
  
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const {
    isProposal,
    allowEdition,
    blockExpansions,
  } = useEstimateEditorSettings();
  
  const { settings, visibility, timelineWorkTypes } = structure;
  const { displayTimelineAllocation, useTimelineChart } = settings;
  const { hideTimelineAllocation } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimelineAllocation);
  
  const visibleWorkTypes = getUserVisibleWorkTypes(timelineWorkTypes, isProposal, true);
  
  const handleTimelineAllocationVisibility = () => {
    if(blockExpansions) return;
    settings.setDisplayTimelineAllocation(!displayTimelineAllocation);
  }
  
  const handleWorkTypeReorder = (list) => {
    structure.reorderWorkTypes(list);
  }
  const handleTimelineChartSelect = (v) => {
    if(blockExpansions) return;
    structure.settings.setUseTimelineChart(v);
  }
  
  return (
    <Grid item container className="vCon">
      <TimelineSectionTitleRow
        code="team"
        expandable
        expanded={displayTimelineAllocation}
        onExpand={handleTimelineAllocationVisibility}
        className={className}
        action={<>
          { allowEdition && <TimelineSettings fullSettings/> }
          {
            visibilityMode &&
              <VisibilityButton hidden={hideTimelineAllocation} onChange={visibility.setHideTimelineAllocation} />
          }
        </>}
      >
        {
          !visibilityMode &&
          <ButtonSwitch
            value={useTimelineChart}
            setValue={handleTimelineChartSelect}
            width={32}
            height={24}
            lighter
            values={[
              { value: true, label: <BarChartSide className="text-lg" /> },
              { value: false, label: <TableChart className="text-lg" /> },
            ]}
            size="small"
          />
        }
      </TimelineSectionTitleRow>
      <Collapse
        in={displayTimelineAllocation}
        timeout="auto"
        mountOnEnter
        unmountOnExit
        className={className}
      >
        <TimelineRowGroupList
          parentIds={["timeline"]}
          listElements={visibleWorkTypes}
          onReorder={handleWorkTypeReorder}
          isLastGroupRow
          disabled={!allowEdition}
        />
      </Collapse>
    </Grid>
  );
});