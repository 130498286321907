import { APIMethod, fetchData } from "@client";

export async function removeProjectVersionCommentByPath(
  uuid,
  user,
  version,
  path
) {
  return await fetchData(
    "/v1/ws/project/comments/path",
    APIMethod.DELETE,
    {
      data: {
        uuid,
        user,
        version,
        path
      },
    }
  );
}