import { useMemo, useState } from "react";
import { number } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { MAX_VERSIONS } from "@utils";
import { useEditorWebsocket } from "@hooks";
import { useEstimateEditorSettings, useEstimateEditorStore, useEstimateStructure } from "@tools";
import { Alert, Button, SortableList } from "@components";
import { CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { VersionButton } from "./components/VersionButton";
import { Add } from "@material-ui/icons";
import classnames from "classnames";

export const Versioning = observer(({ jiraVersion = null }) => {
  const {t} = useTranslation();
  
  const editorStore = useEstimateEditorStore();
  const socket = useEditorWebsocket();
  const structure = useEstimateStructure();
  
  const {
    readOnly,
    allowInteractions,
    hasEditorPrivileges,
    isProposal,
    forceAllowVersionSelect,
  } = useEstimateEditorSettings();

  const [isDragged, setDragged] = useState(false);
  const [commentDialogVisible, showCommentDialog] = useState(false);

  const {
    estimateVersions,
    visibleEstimateVersions,
    newVersionLoading,
    sortedVersions,
    currentVersion,
    visibilityMode,
    currentVersionName,
    isArchived,
  } = editorStore;

  const handleVersionReorder = async (list, key) => {
    setDragged(false);
    socket?.requestVersionReorder(key, list.map(({ id }, index) => ({ key: id, order: index+1 })));
    editorStore.reorderVersions(list.map(({ id }) => id));
  };

  const handleNewVersionAdd = () => {
    if (newVersionLoading)
      return;
    if(structure.comments?.length)
      showCommentDialog(true);
    else
      createNewVersion();
  };
  
  const handleNewVersionAddWithComments = (copyComments) => () => {
    showCommentDialog(false);
    createNewVersion(copyComments);
  };
  
  const createNewVersion = (copyComments) => {
    if (newVersionLoading)
      return;
    editorStore.setNewVersionLoading();
    socket?.requestNewVersion(copyComments);
  }

  const clickable = hasEditorPrivileges || allowInteractions || forceAllowVersionSelect;
  const allowAdding = !readOnly && hasEditorPrivileges && !isArchived && estimateVersions?.length < MAX_VERSIONS;
  const removable = !readOnly && hasEditorPrivileges && !isArchived && estimateVersions?.length > 1;
  const hideable = !readOnly && !isArchived && visibleEstimateVersions > 1;

  const versions = useMemo(
    () =>
      !hasEditorPrivileges && !visibilityMode
        ? sortedVersions.filter(({ visible }) => visible)
        : sortedVersions,
    [hasEditorPrivileges, sortedVersions, visibilityMode]
  );

  return (
    <>
      {hasEditorPrivileges || estimateVersions?.length > 1 ? (
        <Grid item container alignItems="center" className="my-4">
          <SortableList
            list={sortedVersions}
            group="versions"
            onListReorderStart={() => setDragged(true)}
            onListReorder={handleVersionReorder}
            disabled={!hasEditorPrivileges}
            itemIdKey="key"
          >
            {versions.map(({ key, name, locked, visible }, index) => (
              (!jiraVersion || jiraVersion.toString() === key.toString() ) && (
                <VersionButton
                  key={key}
                  identifier={key}
                  name={name}
                  index={index}
                  selected={key === currentVersion}
                  removable={removable}
                  editable={hasEditorPrivileges && !readOnly}
                  hideable={hideable}
                  clickable={clickable}
                  isDragged={isDragged}
                  used={!!visible}
                  locked={!!locked && !isProposal}
                  hasEditorPrivileges={hasEditorPrivileges}
                  editVisibility={editorStore.visibilityMode}
                />
              )
            ))}
            <Tooltip title="Add new version" arrow placement="bottom">
              <Button
                icon={
                  newVersionLoading ? <CircularProgress size={24} /> : <Add />
                }
                onClick={handleNewVersionAdd}
                className={classnames(
                  "noDrag m-1 p-1 border-dashed",
                  !allowAdding && "hidden invisible"
                )}
                variant="outlined"
                size="large"
              />
            </Tooltip>
          </SortableList>
        </Grid>
      ) : undefined}
      <Alert
        isOpen={commentDialogVisible}
        title={t("views.editor.versioning.copy_comments", { name: currentVersionName })}
        onAccept={handleNewVersionAddWithComments(true)}
        onCancel={handleNewVersionAddWithComments(false)}
        acceptText={t("common.yes")}
        cancelText={t("common.no")}
      />
    </>
  );
});

Versioning.propTypes = {
  jiraVersion: number,
};
