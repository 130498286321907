import { useTranslation } from "react-i18next";
import { useProjectCreatorStore } from "@hooks";
import { Grid, Step, StepButton, Stepper } from "@material-ui/core";
import useStyle from "./ImporterStepper.style";

export const ImporterStepper = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const creator = useProjectCreatorStore();

  const { steps, activeStep } = creator;

  return (
    <Grid item container justifyContent="center">
      <Stepper nonLinear className={classes.stepper} activeStep={activeStep}>
        {steps.map(({ locked, completed, id }, i) => (
          <Step key={id}>
            <StepButton
              completed={!locked && completed}
              disabled={locked}
              onClick={() => creator.setActiveStep(i)}
            >
              {t(`views.import.steps.${id}`)}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};
