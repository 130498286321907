import { useMemo } from "react";
import {
  number,
  bool,
  string,
  node,
  arrayOf,
  oneOfType,
  oneOf,
} from "prop-types";
import { BREAKDOWN_TABLE_DEPTHS, hashCode } from "project-structure";
import { ELEMENT_TYPES } from "@utils";
import Grid from "@material-ui/core/Grid";
import useStyle from "./RowGroup.style";
import classnames from "classnames";

export const RowGroup = ({
  children,
  isActive,
  tableDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  className,
  presetClass,
  isStatic,
  elementId,
  parentIds,
  originTableId,
  elementType,
}) => {
  const classes = useStyle();

  const path = useMemo(
    () =>
      [originTableId, ...(parentIds || [])].map((id) => hashCode(id)).join("/"),
    [parentIds]
  );

  return (
    <Grid
      item
      container
      role="rowgroup"
      direction="column"
      alignItems="flex-start"
      className={classnames(
        presetClass,
        `d${tableDepth}`,
        classes.rowGroup,
        {
          "preset-accent": !presetClass && tableDepth === BREAKDOWN_TABLE_DEPTHS.MODULE,
          // "preset-section": !presetClass && tableDepth === BREAKDOWN_TABLE_DEPTHS.SECTION,
          active: isActive && tableDepth > BREAKDOWN_TABLE_DEPTHS.SECTION,
          static: isStatic,
        },
        className
      )}
      aria-selected={isActive}
      data-id={elementId && hashCode(elementId.toString())}
      data-p={path}
      data-t={elementType}
    >
      {children}
    </Grid>
  );
};

RowGroup.propTypes = {
  children: oneOfType([node, arrayOf(node)]).isRequired,
  isActive: bool,
  className: string,
  presetClass: string,
  id: oneOfType([string, number]),
  isStatic: bool,
  //common
  tableDepth: number,
  highlightCellText: bool,
  elementType: oneOf(Object.values(ELEMENT_TYPES)),
  originTableId: string,
  elementId: oneOfType([number, string]),
  parentIds: arrayOf(oneOfType([number, string])),
};
