import { useMemo } from "react";
import { number, func } from "prop-types";
import { observer } from "mobx-react";
import { useEstimateStructure, CollapseValueCell } from "@tools";
import { BillingChartInnerCell } from "./BillingChartInnerCell";

export const BillingChartCell = observer(({
  monthIndex,
  onOpen,
}) => {
  const structure = useEstimateStructure();
  const { workTypes } = structure;
  
  const isOpened = useMemo(() => (
    structure.settings.isTimelineMonthExpanded(monthIndex)
  ), [structure.settings.expandedTimelineMonths?.size])
  
  const values = workTypes.map(wT => {
    const weeks = wT.timelineBilling?.slice(monthIndex*4, (monthIndex+1)*4);
    const weekHours = wT.presentedTimeline?.weeks
      ?.slice(monthIndex*4, (monthIndex+1)*4)
      ?.map(w => w.hours) || [];
    return {
      id: wT.id,
      name: wT.name,
      color: wT.color,
      month: weeks.reduce((t, w) => t + w, 0),
      monthHours: weekHours.reduce((t, w) => t + w, 0),
      weeks,
      weekHours,
    }
  })
  
  return (
    <CollapseValueCell
      isOpened={isOpened}
      narrowChildren
      useBorder
      visible
      isHeader
      isFirst={monthIndex === 0}
      cell={<BillingChartInnerCell
        values={values}
        onOpen={onOpen}
      />}
    >
      {
        [...new Array(4).keys()]
          .map((index) => (
            <BillingChartInnerCell
              key={"week"+index}
              values={values}
              weekIndex={index}
              onOpen={onOpen}
            />
          ))
      }
    </CollapseValueCell>
  )
})

BillingChartCell.propTypes = {
  monthIndex: number.isRequired,
  onOpen: func.isRequired,
}