import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { fetchProjectProposalQuery } from "@query";
import { PROJECT_TYPE, HtmlParser } from "@utils";
import { useSharedProjectKey } from "@hooks";
import { EstimateEditorStore, EstimateEditorStoreProvider } from "@tools";
import { Dialog, PageCircularProgress } from "@components";
import PdfProposalView from "./PdfProposalView";

export const PdfProposal = () => {
  const key = useSharedProjectKey();
  const { t } = useTranslation();
  
  const [store, setStore] = useState(null);
  const [isRemoved, setRemoved] = useState(false);
  const [isArchived, setArchived] = useState(false);
  
  useEffect(() => {
    onLoad();
  }, []);
  
  const onLoad = async () => {
    try {
      const projectData = await fetchProjectProposalQuery(key);
      
      if (projectData.status === "archive") {
        setArchived(true);
        return;
      }
      
      setStore(new EstimateEditorStore({
        estimateType: PROJECT_TYPE.MINI,
        estimateData: projectData,
        isProposal: true,
      }));
    } catch (e) {
      if (e?.response?.data?.status === 400) setArchived(true);
      else if (
        e?.response?.data?.status === 403 ||
        e?.response?.data?.status === 404
      )
        setRemoved(true);
    }
  };
  
  if (isRemoved)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.removed_share"))}</h3>
      </Dialog>
    );
  
  if (isArchived)
    return (
      <Dialog open>
        <h3>{HtmlParser(t("alerts.editor.archive_share"))}</h3>
      </Dialog>
    );
  
  return !store ? (
    <PageCircularProgress />
  ) : (
    <EstimateEditorStoreProvider value={store}>
      <PdfProposalView />
    </EstimateEditorStoreProvider>
  );
};
