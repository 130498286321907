import { useEffect, useRef } from "react";
import { bool, node } from "prop-types";
import { SECTION_FORMATS } from "project-structure"
import { EDITOR_CONTAINER_ID, handleFixedTableHeader } from "@utils";
import { useStores } from "@hooks";
import {
  useEstimateEditorStore,
  useEstimateStructure,
  useEstimateEditorSettings,
  UserMoveBoard,
} from "@tools";
import { Grid } from "@material-ui/core";

export const EditorContainer = ({
  showUserMovement,
  children
}) => {

  const structure = useEstimateStructure();
  const editorStore = useEstimateEditorStore();
  const { stateStore } = useStores();
  const { sectionsReordered } = useEstimateEditorSettings();

  const scrollbarRef = useRef(null);

  useEffect(() => {
    updatePinnedHeaders(sectionsReordered ? 0 : scrollbarRef.current?.scrollTop);
  }, [sectionsReordered]);
  
  useEffect(() => {
    scrollbarRef.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [editorStore.proposalStep])
  
  useEffect(() => {
    stateStore.setScrollHandler(handleScroll);
    return () => {
      stateStore.resetScrollHandler();
    };
  }, []);
  
  const handleScroll = (e) => {
    if (!sectionsReordered) {
      updatePinnedHeaders(e.currentTarget.scrollTop);
    }
  };

  const updatePinnedHeaders = (scrollTop = 0) => {
    structure.sections.forEach((m, i) => {
      let hasSectionTitle =
        structure.settings.sectionFormat > SECTION_FORMATS.ROW &&
        document.getElementById("sectionTitle" + i);
      if (hasSectionTitle)
        handleFixedTableHeader(
          document.getElementById("sectionTitle" + i),
          "breakdownContainer" + i,
          scrollTop,
          38 +
          (document.getElementById("sectionDesc" + i)?.offsetHeight || 0),
          42 + 72,
          true
        );

      if (document.getElementById("tableHeader" + i))
        handleFixedTableHeader(
          document.getElementById("tableHeader" + i),
          "breakdownContainer" + i,
          scrollTop,
          (hasSectionTitle ? 42 : 0) - 16,
          // 42 + 72 + 16
        );
    });
  };

  return (
    <Grid
      item container
      id={EDITOR_CONTAINER_ID}
      ref={scrollbarRef}
      className="h-max"
    >
      {showUserMovement && <UserMoveBoard containerRef={scrollbarRef.current} />}
      { children }
    </Grid>
  )
}

EditorContainer.propTypes = {
  sectionsReordered: bool,
  showUserMovement: bool,
  children: node
}