import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Tables, SUMMARY_TABLE_DEPTHS } from "project-structure";
import { CELL_WIDTHS, ELEMENT_TYPES } from "@utils";
import {
  useEstimateStructure,
  useEstimateEditorSettings,
  Row,
  RowGroup,
  TitleCell,
  WorkTypeResourceSelect, useEstimateTableSettings,
} from "@tools";
import { SummaryNestedRowActions } from "./components/SummaryNestedRowActions";
import { ColumnFactory } from "./ColumnFactory";
import classnames from "classnames";

export const SummaryNestedRow = observer(({
  resource,
  parentIds,
  isLastGroupRow,
  turnOff,
}) => {
  const { t } = useTranslation();
  const { workTypesHaveResources } = useEstimateStructure();
  const { columns } = useEstimateTableSettings();
  
  const {
    allowEdition,
    isProposal,
    useProfitability,
    useVisibilityCell,
  } = useEstimateEditorSettings();
  
  const { id, name } = resource;

  const tableDepth = SUMMARY_TABLE_DEPTHS.TEAM_MEMBER;
  
  return (
    <RowGroup
      originTableId={Tables.SUMMARY}
      parentIds={parentIds}
      tableDepth={tableDepth}
      isStatic={!allowEdition}
      elementId={id}
      elementType={ELEMENT_TYPES.RESOURCE}
    >
      <Row
        presetClass="preset-rowSummary"
        useExpandCell={!isProposal || workTypesHaveResources}
        useVisibilityCell={useVisibilityCell}
        hideVisibilitySwitch
        isLastGroupRow={isLastGroupRow}
        tableDepth={tableDepth}
        elementId={id}
        elementType="r"
        visible={!turnOff}
      >
        <TitleCell
          minWidth={useProfitability ? CELL_WIDTHS.TITLE_SUMMARY_EMPTY : CELL_WIDTHS.TITLE_SUMMARY}
          pinnedLeft={!isProposal || workTypesHaveResources ? 2 : 0}
          allowOverflowDisplay
          actions={
            !useProfitability &&
            <SummaryNestedRowActions resource={resource} />
          }
        >
          {allowEdition ? (
            <WorkTypeResourceSelect resource={resource} />
          ) : (
            <span
              className={classnames(
                "name",
                "preset-summaryTitleText",
                !name?.length && "semi-transparent"
              )}
            >
              {name || t("common.unnamed")}
            </span>
          )}
        </TitleCell>
        {
          columns.map(({ name }, index) => (
            <ColumnFactory
              key={name}
              name={name}
              element={resource}
              extendPadding={!useVisibilityCell && index === columns.length-1}
            />
          ))
        }
      </Row>
    </RowGroup>
  );
});

SummaryNestedRow.propTypes = {
  resource: object.isRequired,
  isLastGroupRow: bool,
  turnOff: bool,
};
