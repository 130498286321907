import { useMemo } from "react";
import { string, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { DESCRIPTION_MODES, SECTION_FORMATS } from "project-structure";
import { TABLE_DRAGGABLE_CONTAINER, CELL_WIDTHS } from "@utils";
import {
  useEstimateStructure,
  usePresetFieldNames,
  useEstimateEditorStore,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  DescriptionCell,
  VisibilityButton,
  HeaderRow,
  PriceCell,
  TitleCell, useEstimateTableSettings,
} from "@tools";
import { TooltipIcon, Link, SortableList } from "@components";
import Grid from "@material-ui/core/Grid";
import { HeaderWorkType } from "./components/HeaderWorkType";
import { CombinedHeaderWorkType } from "./components/CombinedHeaderWorkType";
import { HeaderWorkTypeEditor } from "./components/HeaderWorkTypeEditor";
import { WorkTypeColumnExpand } from "./components/WorkTypeColumnExpand";
import { Warning } from "@assets";
import classnames from "classnames";

export const TableHeader = observer(({
  section,
  tableIdentifier,
}) => {
  const {
    useVisibilityCell,
    isSellerOrClient,
    blockExpansions,
    allowEdition,
    isProposal,
    isLibrary,
  } = useEstimateEditorSettings();
  const {
    useExpandCell,
    maxTitleCellWidth,
    visibleWorkTypes,
    descriptionClassName,
    descriptionVisible,
    pricesVisible,
    priceClassName,
  } = useEstimateTableSettings();
  const { visibilityMode } = useEstimateEditorStore();
  const structure = useEstimateStructure();
  const { visibility, estimateViewLevel, usesTwoValues } = structure;

  const {
    currencyObj,
    showBreakdownColumns,
    sectionFormat,
    descriptionMode,
    descriptionCellWidth,
    titleCellWidth,
  } = structure.settings;

  const {
    allChildrenExpanded,
    startingLevel,
    sectionModulesHaveChildren,
    isOpened,
    hasChildrenHours,
    taskWorkTypes,
    showCombinedHours,
    hidePrice,
    hideDescription,
  } = section;

  const {
    apply,
    hideEstimateCollapse,
  } = visibility;

  const { t } = useTranslation();
  const { getFieldName } = usePresetFieldNames(isProposal);

  const estimateCollapseClassName = useVisibilityModeCellClassName(hideEstimateCollapse);

  const expanded = useMemo(
    () => (sectionFormat !== 1 || isOpened) && allChildrenExpanded,
    [allChildrenExpanded, isOpened, sectionFormat]
  );

  const showDescriptionCell = useMemo(() => (
    descriptionMode === DESCRIPTION_MODES.COLUMN && descriptionVisible
  ), [descriptionMode, descriptionVisible]);

  const showEstimateCollapse = useMemo(() => (
    !isProposal || !apply || !hideEstimateCollapse
  ), [hideEstimateCollapse, apply, isProposal]);

  const expandAllChildren = () => section.expandAllChildren(!expanded);

  const handleWorkTypeReorder = (list) => section.reorderWorkTypes(list);

  const handleTitleCellRescale = (w) => {
    structure.settings.setTitleCellWidth(w);
  }

  const handleDescriptionCellRescale = (w) => {
    structure.settings.setDescriptionCellWidth(w);
  }

  return (
    <>
      <HeaderRow
        id={ tableIdentifier }
        useExpandCell={ useExpandCell }
        hideVisibilitySwitch
        useVisibilityCell={useVisibilityCell}
        expandable={ !blockExpansions && estimateViewLevel > 1 }
        expanded={ expanded }
        onExpand={ expandAllChildren }
        expandCellPresetClass="preset-tableBreakdownHeader"
      >
        <TitleCell
          allowOverflowDisplay={ descriptionMode < DESCRIPTION_MODES.COLUMN }
          minWidth={ showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE }
          maxWidth={ maxTitleCellWidth }
          pinnedLeft={ !isProposal || sectionModulesHaveChildren ? 1 : 0 }
          actions={
            <>
              {
                allowEdition && descriptionMode < DESCRIPTION_MODES.COLUMN && visibleWorkTypes.length === taskWorkTypes.length &&
                <HeaderWorkTypeEditor
                  isLibrary={ isLibrary }
                  section={ section }
                  visibleWorkTypes={ visibleWorkTypes }
                />
              }
              {
                !showDescriptionCell && showEstimateCollapse && visibleWorkTypes.length > 0 &&
                <WorkTypeColumnExpand
                  section={section}
                  blockExpansions={blockExpansions || visibilityMode}
                  className={estimateCollapseClassName}
                />
              }
              {
                visibilityMode && descriptionMode < DESCRIPTION_MODES.COLUMN &&
                <VisibilityButton
                  size="tiny" inRow
                  hidden={hideEstimateCollapse}
                  onChange={visibility.setHideEstimateCollapse}
                  className={estimateCollapseClassName}
                />
              }
            </>
          }
          allowEdition={ showDescriptionCell && allowEdition }
          setWidth={ handleTitleCellRescale }
          setNextCellWidth={ handleDescriptionCellRescale }
          nextCellWidth={ descriptionCellWidth }
        >
          <span className="preset-tableBreakdownHeader pt-module">
            { getFieldName(
              "pt-module",
              isLibrary ? startingLevel : sectionFormat === SECTION_FORMATS.ROW ? 0 : 1
            ) }
          </span>
        </TitleCell>
        {
          showDescriptionCell &&
          <DescriptionCell
            minWidth={ descriptionCellWidth }
            left={ titleCellWidth }
            isHeader
            size="xSmall"
            useRescale={ allowEdition }
            setWidth={ handleDescriptionCellRescale }
            actions={
              <>
                {
                  allowEdition && visibleWorkTypes.length === taskWorkTypes.length &&
                  <HeaderWorkTypeEditor
                    isLibrary={ isLibrary }
                    section={ section }
                  />
                }
                {
                  visibleWorkTypes.length > 0 && showEstimateCollapse &&
                  <WorkTypeColumnExpand
                    section={section}
                    className={estimateCollapseClassName}
                  />
                }
                {
                  visibilityMode &&
                  <VisibilityButton
                    size="tiny" inRow
                    hidden={hideEstimateCollapse}
                    onChange={visibility.setHideEstimateCollapse}
                    className={estimateCollapseClassName}
                  />
                }
              </>
            }
          >
            <Grid
              item container
              alignItems="center"
              className={classnames("w-max", descriptionClassName)}
            >
              <span className="preset-tableBreakdownHeader pt-desc ml-0-5">
                { getFieldName("pt-desc") }
              </span>
              {
                visibilityMode &&
                <VisibilityButton
                  size="tiny"
                  hidden={hideDescription}
                  onChange={section.setHideDescription}
                />
              }
            </Grid>
          </DescriptionCell>
        }
        <Grid
          className={ classnames(TABLE_DRAGGABLE_CONTAINER, "wt-container") }
          item
          container
          wrap="nowrap"
          alignItems="center"
          style={ { width: "initial" } }
        >
          {
            showBreakdownColumns && !showCombinedHours &&
            <SortableList
              list={ visibleWorkTypes }
              group="breakdown-systems"
              onListReorder={ handleWorkTypeReorder }
              displayAsFlexbox
              disabled={ !allowEdition }
            >
              {
                visibleWorkTypes?.map(( workType ) => (
                  <HeaderWorkType
                    key={ workType.id }
                    workType={ workType }
                    section={ section }
                    allowEdition={ allowEdition }
                    isLibrary={ isLibrary }
                    isSellerOrClient={ isSellerOrClient }
                    visibleWorkTypes={ visibleWorkTypes }
                  />
                ))
              }
            </SortableList>
          }
          {
            showBreakdownColumns && showCombinedHours && visibleWorkTypes.length > 0 &&
            <CombinedHeaderWorkType
              section={ section }
              isProposal={isProposal}
            />
          }
        </Grid>
        { pricesVisible && (
          <PriceCell
            wider={ usesTwoValues }
            extendPadding={!useVisibilityCell}
            className={priceClassName}
          >
            <span className="preset-tableBreakdownHeader pt-price">
              { getFieldName("pt-price") +
                (currencyObj?.code ? ` (${ currencyObj.code || "" })` : "") }
            </span>
            {
              visibilityMode &&
              <VisibilityButton
                size="tiny"
                inColumn right={2}
                hidden={hidePrice}
                onChange={section.setHidePrice}
              />
            }
            { hasChildrenHours && (
              <TooltipIcon
                style={ { right: 0 } }
                className="absolute text-sm"
                icon={ <Warning className="color-warning"/> }
              >
                { t(`alerts.editor.fixed_price_alert`) }
              </TooltipIcon>
            ) }
            { isLibrary && (
              <TooltipIcon
                color="primary"
                style={ { right: 0 } }
                className="absolute text-sm"
                interactive
              >
                { t("views.editor.price_library_info.text") }
                <Link to="/home/settings/project">
                  { t("views.editor.price_library_info.link") }
                </Link>
              </TooltipIcon>
            ) }
            {/*{hasAverageRate(usedWorkTypes) && !isProposal && (*/ }
            {/*  <TooltipIcon*/ }
            {/*    style={{ fontSize: ".9rem" }}*/ }
            {/*    interactive*/ }
            {/*    className="color-warning"*/ }
            {/*  >*/ }
            {/*    {t("views.editor.average_breakdown_rate_info_tooltip")}*/ }
            {/*  </TooltipIcon>*/ }
            {/*)}*/ }
          </PriceCell>
        ) }
      </HeaderRow>
    </>
  );
});

TableHeader.propTypes = {
  section: object.isRequired,
  tableIdentifier: string.isRequired,
};
