import { useMemo } from "react";
import { bool, number, string, object, arrayOf, oneOfType } from "prop-types";
import { observer } from "mobx-react";
import { roundFloat } from "project-structure";
import { CELL_WIDTHS } from "@utils";
import { useEstimateStructure, useWorkTypePermitChecker, ValueCell, CollapseValueCell } from "@tools";
import { makeStyles } from "@material-ui/core";

export const BudgetTrackingTaskWorkType = observer(({
  element,
  workType,
  editable,
  displayContent,
  emptyValue,
  ...valueCellProps
}) => {
  
  const classes = useStyle();
  
  const { editionGrant } = useWorkTypePermitChecker(workType.id);
  const structure = useEstimateStructure();
  const {
    useMinMax,
    modifier,
    roundHours,
    usedRounding,
    usedDecimals,
    roundType,
    roundLevel,
  } = structure.settings;

  const structureWorkType = structure.getWorkTypeById(workType.id);
  const visible = !structure.isWorkTypeOff(workType.id);

  const values = element.getTotalHoursPerWorkType(workType.id);
  const btValues = element.getTotalBTHoursPerWorkType(workType.id);
  const btHourDiffs = element.getBTHourDiff(workType.id);
  const btHourDiffPercents = element.getBTHourDiffPercent(workType.id);

  const value = useMemo(
    () =>
      emptyValue
        ? undefined
        : roundFloat(
            (useMinMax ? values.max || 0 : values.avg || 0) / modifier,
            usedRounding,
            false,
            usedDecimals
          ),
    [
      values,
      useMinMax,
      usedRounding,
      usedDecimals,
      roundType,
      roundLevel,
      emptyValue,
    ]
  );
  const btValue = useMemo(
    () => (btValues ? btValues / modifier : 0),
    [btValues, useMinMax, roundHours]
  );
  const btHourDiff = useMemo(
    () =>  emptyValue
    ? undefined
    : roundFloat(
        (useMinMax ? btHourDiffs.max || 0 : btHourDiffs.avg || 0) / modifier,
        usedRounding,
        false,
        usedDecimals
      ),
    [btValues, useMinMax, roundHours, useMinMax]
  );

  const btHourDiffPercent = useMemo(
    () =>  emptyValue
    ? undefined
    : roundFloat(
        (useMinMax ? btHourDiffPercents.max || 0 : btHourDiffPercents.avg || 0),
        usedRounding,
        false,
        usedDecimals
      ),
    [btValues, useMinMax, roundHours, useMinMax]
  );

  const allowEdition = useMemo(
    () => editable && editionGrant,
    [editable, editionGrant]
  );

  const colorText = useMemo(() => (
    btHourDiff > 0 ? classes.rowAccepted : classes.rowDanger
  ), [value, btHourDiff]);

  return (
    <CollapseValueCell
      childrenWidth={CELL_WIDTHS.VALUE.MINI}
      mainCellWidth={CELL_WIDTHS.VALUE.LARGE}
      isOpened={structureWorkType.btOpened}
      visible={visible}
      cell={
        <ValueCell
        {...valueCellProps}
        displayContent={displayContent && (!emptyValue || allowEdition)}
        displayMinMaxSeparator={!emptyValue}
        value={emptyValue ? undefined : btHourDiffPercent || 0}
        wider
        showDecimals={!roundHours}
        textPresetClass={colorText}
        visible
        symbolEnd="%"
      />
      }
    >
      <ValueCell
        {...valueCellProps}
        displayContent={displayContent && (!emptyValue || allowEdition)}
        displayMinMaxSeparator={!emptyValue}
        value={emptyValue ? undefined : value}
        mini
        showDecimals={!roundHours}
        visible
      />
      <ValueCell
        {...valueCellProps}
        displayContent={displayContent && (!emptyValue || allowEdition)}
        displayMinMaxSeparator={!emptyValue}
        value={emptyValue ? undefined : btValue}
        mini
        showDecimals={!roundHours}
        visible
        textPresetClass={colorText}
        valueContainerBackground
      />
      <ValueCell
        {...valueCellProps}
        displayContent={displayContent && (!emptyValue || allowEdition)}
        displayMinMaxSeparator={!emptyValue}
        value={emptyValue ? undefined : btHourDiff}
        mini
        showDecimals={!roundHours}
        visible
        textPresetClass={colorText}
        valueContainerBackground
        showValueSign
      />
      <ValueCell
        {...valueCellProps}
        displayContent={displayContent && (!emptyValue || allowEdition)}
        displayMinMaxSeparator={!emptyValue}
        value={emptyValue ? undefined : btHourDiffPercent || 0}
        mini
        showDecimals={!roundHours}
        visible
        textPresetClass={colorText}
        valueContainerBackground
        symbolEnd="%"
        showValueSign
      />
    </CollapseValueCell>
  );
});

BudgetTrackingTaskWorkType.propTypes = {
  element: object.isRequired,
  textPresetClass: string,
  tableDepth: number,
  workType: object.isRequired,
  emptyValue: bool,
  editable: bool,
  displayContent: bool,
  fullIdPath: arrayOf(oneOfType([number, string])).isRequired,
};

const useStyle = makeStyles((theme) => ({
  cell: {
    zIndex: 2,
  },
  rowDanger: {
    color: theme.props.success,
  },
  rowAccepted: {
    color: theme.props.error.main,
  },
  default: {
    color: "#727482",
  },
}));
