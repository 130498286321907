import { useContext } from "react";
import { EditorSocketContext } from "@client";

export const useEditorWebsocket = () => useContext(EditorSocketContext);
// export const useEditorWebsocket = () => {
//   const context = useContext(EditorSocketContext);
//   if (context === null) {
//     throw new Error("EditorSocketContext cannot be null, please add a context provider");
//   }
//   return context;
// };
