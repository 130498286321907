import { useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import {
  StartMonthSelector,
  WtaInput,
  TimelineSettingsRowTitle,
  // TeamTogetherSwitch
} from "./components";
import Grid from "@material-ui/core/Grid";
import { getGridSizes } from "../getGridSizes";

export const TimelineSettingsRow = observer(({
  element,
  sectionElement,
  indent,
  hasChildren,
  fullSettings,
  // workTypeSettings,
  autoFocus,
  disabled,
  useTeamTogetherSwitch=true,
  minStartMonth,
}) => {
  const gridSizes = useMemo(() => getGridSizes(fullSettings, useTeamTogetherSwitch), [fullSettings, useTeamTogetherSwitch]);
  
  return <Grid
    item
    container
    wrap="nowrap"
    alignItems="center"
    xs={ 12 }
    spacing={ 2 }
    className="h-32 p-0 my-0-5 mx-0"
  >
    <TimelineSettingsRowTitle
      element={ element }
      sectionElement={ sectionElement }
      gridSize={ gridSizes.name }
      indent={ indent }
      transparent={ (indent && (!name || disabled)) }
      fullSettings={fullSettings}
      hasSection={ Boolean(sectionElement) }
    />
    <WtaInput
      element={ sectionElement || element }
      disabled={ disabled || hasChildren }
      hidden={ hasChildren }
      autoFocus={ autoFocus }
      gridSize={ gridSizes.wta }
      isSectionElement={!!sectionElement}
    />
    {
      fullSettings &&
      <StartMonthSelector
        element={ sectionElement || element }
        disabled={ disabled || hasChildren }
        hidden={ hasChildren }
        gridSize={ gridSizes.start }
        minStartMonth={minStartMonth}
        isSectionElement={!!sectionElement}
      />
    }
    {/*{*/}
    {/*  fullSettings && workTypeSettings && !section &&*/}
    {/*  <TeamTogetherSwitch*/}
    {/*    disabled={ disabled }*/}
    {/*    workType={ element }*/}
    {/*    gridSize={ gridSizes.teamTogether }*/}
    {/*  />*/}
    {/*}*/}
  </Grid>
});

TimelineSettingsRow.propTypes = {
  element: object.isRequired,
  sectionElement: object,
  indent: bool,
  hasChildren: bool,
  fullSettings: bool,
  workTypeSettings: bool,
  autoFocus: bool,
  disabled: bool,
  useTeamTogetherSwitch: bool,
  minStartMonth: number,
};
