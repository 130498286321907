import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import {
  useEstimateStructure,
  useSummaryTeamInputTooltipAlert,
  useVisibilityModeCellClassName,
  useEstimateEditorSettings,
  ValueCell
} from "@tools";
import useStyle from "../../TeamMembersCell.style"
import classnames from "classnames";

export const TeamMembersCell = observer(({
  element,
  displayCellContent,
  extendPadding,
}) => {
  const { allowEdition, useProfitability } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const tooltip = useSummaryTeamInputTooltipAlert(allowEdition);
  const className = useVisibilityModeCellClassName(structure.visibility.hideSummaryTeam);
  const classes = useStyle();
  
  const { usesAnyValue } = structure;
  const { displayTeamMembers, hasOnlyCost, hasResources } = element;
  
  const handleTeamMembersChange = (members) => {
    element.setTeamMembers(members);
  };

  return (
    <ValueCell
      wider
      min={1}
      value={displayTeamMembers}
      emptyInputs={!usesAnyValue}
      onValueChange={handleTeamMembersChange}
      displayContent={!hasOnlyCost && displayCellContent}
      editable={allowEdition && !hasResources && !useProfitability}
      className={className}
      textPresetClass={classnames("preset-summaryText", classes.input)}
      inputClassName="input-team-members"
      extendPadding={extendPadding}
      { ...tooltip }
    />
  );
});

TeamMembersCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  extendPadding: bool,
};