import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles(( theme ) => ({
  row: {
    padding: "8px 16px",
    borderRadius: 5,
    marginTop: 2,
    marginBottom: 2,
    minHeight: 52,
    height: "max-content",
  },
  headerRow: {
    padding: "4px 17px",
    marginBottom: 2,
    fontSize: 12,
    fontWeight: "400 !important",
    lineHeight: "normal",
    textTransform: "uppercase",
    color: theme.palette.text.secondary,
  },
  bordered: {
    border: `1px solid ${theme.props.border}`,
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
  },
  disabled: {
    color: theme.props.text.lighter,
  }
}));

export default useStyle;