import { bool, object } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice } from "project-structure";
import { MAX_VALUES } from "@utils";
import {
  useEstimateEditorSettings,
  useEstimateStructure,
  useSummaryUnitCostInputTooltipAlert,
  ValueCell,
} from "@tools";

export const CompanyUnitCostCell = observer(({
  element,
  displayCellContent,
  useCompanyCurrency,
  extendPadding,
}) => {
  const { allowEdition, useProfitability } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const tooltip = useSummaryUnitCostInputTooltipAlert(useProfitability, element.hasOnlyCost);
  
  const { currencyObj, companyCurrencyObj, modifier, timeModifier, exchangeRateModifier } = structure.settings;
  const {
    hasResources,
    hasOnlyCost,
    displayCompanyUnitCost,
    getDisplayCompanyUnitCostWithModifier,
  } = element;
  
  const usedCurrency = useCompanyCurrency
    ? companyCurrencyObj
    : currencyObj;
  
  const displayValue = useCompanyCurrency
    ? displayCompanyUnitCost
    : getDisplayCompanyUnitCostWithModifier;
  
  const handleCompanyUnitCostChange = (unitCost) => {
    element.setCompanyUnitCost(unitCost / modifier / (useCompanyCurrency ? 1 : exchangeRateModifier));
  };
  
  return (
    <ValueCell
      widest
      emptyInputs={hasOnlyCost}
      displayContent={displayCellContent}
      value={displayValue}
      onValueChange={handleCompanyUnitCostChange}
      editable={!hasResources && allowEdition}
      max={timeModifier === 1 ? MAX_VALUES.RATE_HOUR : MAX_VALUES.RATE_DAY}
      symbolStart={usedCurrency.symbolStart}
      symbolEnd={usedCurrency.symbolEnd}
      showDecimals
      parser={parsePrice}
      inputClassName="input-unit-cost"
      extendPadding={extendPadding}
      { ...tooltip }
    />
  );
});

CompanyUnitCostCell.propTypes = {
  element: object.isRequired,
  displayCellContent: bool,
  useCompanyCurrency: bool,
  extendPadding: bool,
};
