import { observer } from "mobx-react";
import {
  useEstimateEditorSettings,
  useEstimateEditorStore,
  useEstimateStructure, useEstimateTableSettings,
  useVisibilityModeCellClassName,
  VisibilityButton,
} from "@tools";
import { Collapse, Grid } from "@material-ui/core";
import { BillingRow, TimelineSectionTitleRow } from "../components";

export const TimelineBilling = observer(() => {
  
  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const {
    isProposal,
    blockExpansions,
  } = useEstimateEditorSettings();
  const { timelineLength } = useEstimateTableSettings();
  
  const { settings, visibility } = structure;
  const { displayTimelineBilling } = settings;
  const { hideTimelineBilling } = visibility;
  
  const className = useVisibilityModeCellClassName(hideTimelineBilling);
  
  const handleTimelineBillingVisibility = () => {
    if(blockExpansions) return;
    settings.setDisplayTimelineBilling(!displayTimelineBilling);
  }
  
  return (
    <Grid item container className="vCon">
      <TimelineSectionTitleRow
        isProposal={isProposal}
        code="billing"
        expandable
        expanded={displayTimelineBilling}
        onExpand={handleTimelineBillingVisibility}
        className={className}
        timelineLength={timelineLength}
        action={visibilityMode &&
          <VisibilityButton
            hidden={hideTimelineBilling}
            onChange={visibility.setHideTimelineBilling}
          />
        }
      />
      <Collapse
        in={displayTimelineBilling}
        timeout="auto"
        mountOnEnter
        unmountOnExit
        className={className}
      >
        <BillingRow />
      </Collapse>
    </Grid>
  );
});