import { useState } from "react";
import { arrayOf, bool, func, number, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { DEFAULT_LABEL_COLOR, DEFAULT_LABEL_FONT_COLOR } from "@utils";
import { Alert, Label, TextField } from "@components";
import { CircularProgress, Grid, IconButton } from "@material-ui/core";
import { LabelColorPicker } from "./LabelColorPicker";
import { Trash } from "@assets";
import { Close } from "@material-ui/icons";
import useStyle from "../LabelEditor.style";

export const LabelRow = ({
  id,
  name: defaultName,
  backgroundColor: defaultBackgroundColor = DEFAULT_LABEL_COLOR,
  fontColor: defaultFontColor = DEFAULT_LABEL_FONT_COLOR,
  isNew,
  onCancel,
  onRemove,
  onUpdate,
  onCreate,
  onAdditionalColorAdd,
  onAdditionalFontColorAdd,
  noRemoval=false,
  ...colorPickerProps
}) => {
  const { t } = useTranslation();
  const classes = useStyle(noRemoval);
  
  const [isLoading, setLoading] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [inputValue, setInputValue] = useState(defaultName);
  const [name, setName] = useState(defaultName);
  const [backgroundColor, setColor] = useState(defaultBackgroundColor);
  const [fontColor, setFontColor] = useState(defaultFontColor);
  
  const confirmNameChange = async () => {
    if(!inputValue) {
      setInputValue(name);
      return;
    }
    
    setLoading(true);
    if(isNew)
      await onCreate?.(inputValue, backgroundColor, fontColor);
    else if(inputValue !== name)
      await onUpdate?.(id, inputValue, backgroundColor, fontColor);
    setName(inputValue);
    setLoading(false);
  }
  
  const handleNameChange = (e) => {
    setInputValue(e.target.value);
  }
  
  const handleColorChange = (hex) => {
    setColor(hex);
  }
  
  const handleFontColorChange = (hex) => {
    setFontColor(hex);
  }
  
  const confirmColorChange = async () => {
    if(backgroundColor === defaultBackgroundColor && fontColor === defaultFontColor)
      return;
    
    setLoading(true);
    await onUpdate?.(id, name, backgroundColor, fontColor);
    setLoading(false);
  }
  
  const handleAdditionalColorAdd = (hex) => {
    setColor(hex);
    onAdditionalColorAdd(hex);
  }
  
  const handleAdditionalFontColorAdd = (hex) => {
    setFontColor(hex);
    onAdditionalFontColorAdd(hex);
  }
  
  const handleRemove = async () => {
    if(isDeleting) return;
    setDeleting(true);
  }
  
  const confirmRemove = async () => {
    await onRemove?.(id);
    setDeleting(false);
  }
  
  return (
    <Grid
      item
      container
      wrap="nowrap"
      alignItems="center"
      justifyContent="space-between"
      className="my-1"
    >
      <Grid
        item container
        className={classes.labelCell}
      >
        <Label
          name={inputValue || t("common.sample")}
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          ellipsisOnOverflow
          maxWidth={104}
        />
      </Grid>
      <Grid item container>
        <TextField
          size
          autoFocus={isNew}
          value={inputValue}
          disabled={isLoading || isDeleting}
          onChange={handleNameChange}
          onBlur={confirmNameChange}
          className="m-0"
          placeholder={t(`views.settings.projects.labels.type_${isNew ? "new_" : ""}label_name`)}
          InputProps={{
            inputProps: {
              className: "py-3"
            }
          }}
        />
      </Grid>
      <Grid
        item container
        className={classes.colorCell}
      >
        <LabelColorPicker
          backgroundColor={backgroundColor}
          fontColor={fontColor}
          onConfirm={confirmColorChange}
          onColorChange={handleColorChange}
          onFontColorChange={handleFontColorChange}
          onAdditionalColorAdd={handleAdditionalColorAdd}
          onAdditionalFontColorAdd={handleAdditionalFontColorAdd}
          disabled={isLoading || isDeleting}
          {...colorPickerProps}
        />
        {
          !noRemoval &&
          <IconButton
            onClick={isNew ? onCancel : handleRemove}
            disabled={isDeleting}
            className="ml-2"
          >
            {isDeleting ? (
              <CircularProgress size={14} />
            ) : (
              isNew
                ? <Close />
                : <Trash color="error" />
            )}
          </IconButton>
        }
      </Grid>
      <Alert
        isOpen={isDeleting}
        title={t("views.settings.projects.labels.remove_alert")}
        onCancel={() => setDeleting(false)}
        onAccept={confirmRemove}
        acceptText={t("common.confirm_remove")}
        cancelText={t("common.cancel_remove")}
      />
    </Grid>
  )
}

LabelRow.propTypes = {
  additionalColors: arrayOf(string).isRequired,
  additionalFontColors: arrayOf(string).isRequired,
  onAdditionalColorAdd: func.isRequired,
  onAdditionalFontColorAdd: func.isRequired,
  id: number,
  name: string,
  backgroundColor: string,
  fontColor: string,
  isNew: bool,
  noRemoval: bool,
  onCancel: func,
  onRemove: func,
  onUpdate: func,
  onCreate: func,
}