import { APIMethod, fetchData } from "@client";

export async function registerCommenterQuery(
  projectUuid,
  fullName
) {
  const res = await fetchData(
    "/v1/ws/project/comments/user",
    APIMethod.POST,
    {
    data: {
        fullname: fullName,
        uuid: projectUuid
      },
    }
  );
  return res.results;
}
