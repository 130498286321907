import { useRef, useState } from "react";
import { func } from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { ColorPicker, Button } from "@components";
import { Add } from "@material-ui/icons";

export const NewColorButton = ({ onComplete }) => {
  
  const theme = useTheme();
  const classes = useStyle();
  
  const [pickerVisible, showPicker] = useState(false);
  
  const ref = useRef(null);
  
  const handleSelect = (hex) => {
    onComplete(hex);
    showPicker(false);
  }
  
  return <>
    <Button
      ref={ref}
      color="primary"
      className={classes.button}
      onClick={() => showPicker(true)}
    >
      <Add />
    </Button>
    <ColorPicker
      anchor={ref.current}
      show={pickerVisible}
      requireAccept
      initialColor={theme.palette.primary.main}
      onClose={() => showPicker(false)}
      onChange={handleSelect}
    />
  </>
}

NewColorButton.propTypes = {
  onComplete: func.isRequired,
}

const useStyle = makeStyles((theme) => ({
  button: {
    height: 24,
    width: 24,
    minWidth: 24,
    margin: 4,
    padding: 0,
    borderRadius: 5,
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));