import { useMemo } from "react";
import { bool, number } from "prop-types";
import { observer } from "mobx-react";
import { parsePrice, roundFloat } from "project-structure";
import { useEstimateStructure } from "@tools";
import { CollapsePriceInnerCell } from "../../../../../cells/CollapseValueCell/components/CollapsePriceInnerCell";

export const BillingInnerCell = observer(({
  value,
  ...cellProps
}) => {
  const structure = useEstimateStructure();
  const { roundPrice, currencyObj} = structure.settings;
  
  const displayValue = useMemo(() => (
    parsePrice(roundFloat(
      value,
      roundPrice ? 3 : 0,
      !roundPrice
    ))
  ), [value, roundPrice, roundPrice])
  
  return (
    <CollapsePriceInnerCell
      displayContent
      value={displayValue}
      symbolStart={currencyObj.symbolStart}
      symbolEnd={currencyObj.symbolEnd}
      {...cellProps}
    />
  )
})

BillingInnerCell.propTypes = {
  value: number,
  narrow: bool,
  useBorder: bool,
  useSeparator: bool,
}