import { useMemo } from "react";
import { func } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useProjectCreatorStore } from "@hooks";
import { Button, ButtonSwitch } from "@components";
import { Grid, Switch } from "@material-ui/core";
import { ArrowBack } from "@assets";

export const ImporterActions = observer(({
  cancelImport,
  finishImporting
}) => {
    const { t } = useTranslation();
    const creator = useProjectCreatorStore();

    const { steps, activeStep, useMinMax, importTimeModifier } = creator;

    const stepsCompleted = useMemo(
      () =>
        steps.reduce((p, c) => {
          return p && c.completed;
        }, true),
      [steps]
    );

    const nextStepDisabled = useMemo(
      () => steps.findIndex((s, i) => i === activeStep + 1 && s.locked) >= 0,
      [steps, activeStep]
    );

    const nextStep = () => {
      const nextIndex = steps.findIndex((s, i) => i > activeStep && !s.locked);
      if (nextIndex >= 0) creator.setActiveStep(nextIndex);
    };

    const prevStep = () => {
      creator.setActiveStep(activeStep - 1);
    };

    return (
      <>
        <Grid item container alignItems="center" className="w-max">
          {activeStep === 3 && (
            <>
              <p className="mr-2">{t("views.import.time")}</p>
              <ButtonSwitch
                value={importTimeModifier}
                setValue={(v) => creator.setImportTimeModifier(v)}
                values={[
                  { value: 1, label: t("times.hour") },
                  { value: 8, label: t("times.day") },
                ]}
              />
              <p className="ml-4 mr-2">{t("views.editor.min_max")}</p>
              <Switch
                color="primary"
                checked={useMinMax}
                onChange={() => creator.setMinMax(!useMinMax)}
                name={
                  useMinMax ? "Revert to fixed values" : "Allow min-max values"
                }
              />
            </>
          )}
        </Grid>
        <Grid item container alignItems="center" className="w-max">
          <Button
            onClick={cancelImport}
            className="mr-4"
            variant="outlined"
          >
            {t("views.import.cancel")}
          </Button>
          {activeStep > 0 && (
            <Button
              variant="outlined"
              icon={<ArrowBack />}
              className="mr-4"
              onClick={prevStep}
              disabled={activeStep === 0}
            >
              {t("views.import.prev_step")}
            </Button>
          )}
          {activeStep + 1 < steps.length ? (
            <Button
              variant="contained"
              endIcon={<ArrowBack className="rotate-180" />}
              onClick={nextStep}
              disabled={nextStepDisabled}
            >
              {t("views.import.next_step")}
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              onClick={finishImporting}
              disabled={!stepsCompleted}
            >
              {t("views.import.confirm")}
            </Button>
          )}
        </Grid>
      </>
    );
  }
);

ImporterActions.propTypes = {
  cancelImport: func,
  finishImporting: func,
};
