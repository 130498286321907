import { useMemo } from "react";
import { bool, number, object } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { ButtonSwitch } from "@components";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

export const TimelinePresentationSelector = observer(({
  workType,
  disabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const { useTimelinePercent } = workType;
  
  const handleChange = (value) => {
    workType.setUseTimelinePercent(value);
  }
  
  const values = useMemo(() => [
    { value: true, label: t( "%") },
    { value: false, label: t("views.editor.timeline_table.custom_amount") },
  ], []);
  
  return (
    <ButtonSwitch
      size="small"
      value={useTimelinePercent || false}
      setValue={handleChange}
      disabled={disabled}
      values={values}
      fullWidth
      className={classnames("text-xs", classes.root)}
    />
  )
});

const useStyle = makeStyles(() => ({
  root: { height: 30 },
}));

TimelinePresentationSelector.propTypes = {
  workType: object.isRequired,
  disabled: bool,
  gridSize: number,
}