import { useMemo, useState } from "react";
import { bool, number, string, object, arrayOf, oneOf, oneOfType } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Tables, hashCode, DESCRIPTION_MODES, BREAKDOWN_TABLE_DEPTHS } from "project-structure";
import { BREAKDOWN_ELEMENT_CODES, CELL_WIDTHS } from "@utils";
import { useTableExpandHandler } from "@hooks";
import {
  useEstimateEditorStore,
  useEstimateStructure,
  useEstimateEditorSettings,
  useEstimateTableSettings,
  DescriptionCell,
  TitleCell,
  TaskSelect,
  LibraryTaskNameEditor,
  DescriptionVariantSelector,
  Row,
  RowGroup,
  VisibilityButton,
} from "@tools";
import { WysiwygEditor } from "@components";
import { Collapse } from "@material-ui/core";
import { TaskWorkType } from "../TaskWorkType/TaskWorkType";
import { DescriptionRow } from "../DescriptionRow/DescriptionRow";
import { CombinedTaskWorkType } from "../CombinedTaskWorkType/CombinedTaskWorkType";
import { TaskPrice } from "../TaskPrice";
import { BreakdownRowGroupList } from "../BreakdownRowGroupList";
import { BreakdownRowActions } from "./components/BreakdownRowActions";
import { BreakdownNewChildRow } from "./BreakdownNewChildRow";
import classnames from "classnames";

export const BreakdownRowGroup = observer(({
  element,
  isLastGroupRow,
  parentName,
  parentIds = [],
  tableDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  initialDepth = BREAKDOWN_TABLE_DEPTHS.SECTION,
  isFirst,
}) => {

  const structure = useEstimateStructure();
  const { visibilityMode } = useEstimateEditorStore();
  const { t } = useTranslation();
  
  const {
    allowEdition,
    useVisibilityCell,
    blockExpansions,
    displayLibraryElementStatus,
    allowLibraryElementStatusChange,
    isLibrary,
  } = useEstimateEditorSettings();
  const {
    visibleWorkTypes,
    useExpandCell,
    descriptionVisible,
    pricesVisible,
    showCombinedHours,
    maxTitleCellWidth,
    priceClassName,
    descriptionClassName,
  } = useEstimateTableSettings();
  
  const [descEdition, setDescEdition] = useState(undefined);

  const { estimateViewLevel } = structure;
  const {
    showBreakdownColumns,
    descriptionMode,
    descriptionCellWidth,
    titleCellWidth,
  } = structure.settings;

  const {
    id: elementId,
    name,
    content,
    isTurnedOff,
    hasChildren,
    hasFixedPrice,
    hasChildrenHours,
    isOpened,
    status,
    showMoreContent,
    parentHoldsPrice,
    hasDescription,
  } = element;

  const actualDepthLevel = useMemo(() => tableDepth + initialDepth,
    [tableDepth, initialDepth]
  );

  const fullIdPath = useMemo(() => [...parentIds, elementId],
    [parentIds, elementId]
  );

  const openable = useMemo(() => (
    actualDepthLevel < estimateViewLevel && (allowEdition || hasChildren)
  ), [
    actualDepthLevel, estimateViewLevel, allowEdition, hasChildren
  ]);

  const removable = useMemo(() => (
    (structure.hasMultipleSections || actualDepthLevel > BREAKDOWN_TABLE_DEPTHS.SECTION) && allowEdition
  ), [
    structure.hasMultipleSections, allowEdition, actualDepthLevel
  ]);

  const hasEditedDescription = useMemo(() => (
      descriptionMode === DESCRIPTION_MODES.ROW ? hasDescription || descEdition : descEdition
    ), [
      descEdition, descriptionMode, hasDescription
  ]);

  const isAcceptedComponent = useMemo(() => status === 1 && displayLibraryElementStatus,
    [status, displayLibraryElementStatus]
  );

  const opened = useMemo(() => (
    isAcceptedComponent || (openable && (isOpened || blockExpansions))
  ), [
    isAcceptedComponent, openable, isOpened, blockExpansions
  ]);

  const isSectionRow = useMemo(() => (
    actualDepthLevel === BREAKDOWN_TABLE_DEPTHS.SECTION
  ), [
    actualDepthLevel
  ]);

  const toAccept = useMemo(() => (
    displayLibraryElementStatus && allowLibraryElementStatusChange && status === 0
  ), [
    status, displayLibraryElementStatus, allowLibraryElementStatusChange
  ]);

  const showDescriptionCell = useMemo(() => (
    descriptionMode === DESCRIPTION_MODES.COLUMN && descriptionVisible
  ), [
    descriptionMode, descriptionVisible
  ]);

  const hashedPath = useMemo(
    () =>
      [Tables.BREAKDOWN, ...(parentIds || []), elementId]
        .map((id) => hashCode(id))
        .join("/"),
    [parentIds, elementId]
  );

  const handleDescriptionEdition = () => {
    setDescEdition(true);
  };

  const confirmDescriptionEdition = (desc) => {
    if (desc !== content) element.setContent(desc, false);
    setDescEdition(false);
  };

  const handleTurnOff = () => {
    element.setTurnOffState(!isTurnedOff);
  };
  
  const handleHideDescription = (v) => {
    structure.sections.find(s => s.id === fullIdPath[0])?.setHideDescription(v);
  }

  const { handleOpen, ...collapseProps } = useTableExpandHandler(
    openable && !blockExpansions,
    isOpened,
    element.setOpenState
  );

  return (
    <RowGroup
      originTableId={Tables.BREAKDOWN}
      tableDepth={tableDepth}
      isActive={opened}
      isStatic={!allowEdition}
      elementId={elementId}
      parentIds={parentIds}
      elementType={BREAKDOWN_ELEMENT_CODES[actualDepthLevel]}
    >
      <Row
        useExpandCell={useExpandCell}
        expandable={openable}
        expanded={opened}
        onExpand={handleOpen}
        noArrow={actualDepthLevel === BREAKDOWN_TABLE_DEPTHS.MODULE}
        expandOnClick
        useVisibilityCell={useVisibilityCell}
        visible={!isTurnedOff}
        onVisibilityToggle={handleTurnOff}
        isFirstGroupRow={tableDepth <= BREAKDOWN_TABLE_DEPTHS.MODULE}
        isLastGroupRow={
          isSectionRow || (isLastGroupRow && !opened && !hasEditedDescription)
        }
        highlightCellText={isAcceptedComponent}
        tableDepth={tableDepth}
        style={!tableDepth ? { marginBottom: 2 } : undefined}
      >
        <TitleCell
          allowOverflowDisplay={descriptionMode < DESCRIPTION_MODES.COLUMN}
          minWidth={showDescriptionCell ? titleCellWidth : CELL_WIDTHS.TITLE}
          maxWidth={maxTitleCellWidth}
          pinnedLeft={useExpandCell ? tableDepth || 1 : 0}
          actions={
            <BreakdownRowActions
              element={element}
              showDescriptionEditor={
                descriptionMode === DESCRIPTION_MODES.TOOLTIP || (descriptionMode === DESCRIPTION_MODES.ROW && !hasDescription)
              }
              inlineDescriptionEdition={descriptionMode === DESCRIPTION_MODES.ROW}
              tableDepth={tableDepth}
              handleDescriptionEdition={handleDescriptionEdition}
              toAccept={toAccept}
              removable={removable}
              hideBadge={opened || estimateViewLevel === actualDepthLevel}
              useMenu={showDescriptionCell}
              allowDescriptionHide={hasDescription && descriptionMode === DESCRIPTION_MODES.TOOLTIP && visibilityMode}
              fullIdPath={fullIdPath}
            />
          }
        >
          {isLibrary && (allowEdition || typeof elementId !== "number") ? (
            <LibraryTaskNameEditor element={element} />
          ) : allowEdition ? (
            <TaskSelect
              element={element}
              tableDepth={actualDepthLevel}
              parentName={parentName}
            />
          ) : (
            <span
              className={classnames(
                "name wrap-text my-3",
                !isSectionRow && "preset-titleText",
                isSectionRow && "preset-titleTextSection",
                !name?.length && "semi-transparent",
                !useExpandCell && "ml-3"
              )}
            >
              {name?.replace(/<(.|\n)*?>/g, "") || t("common.unnamed")}
            </span>
          )}
        </TitleCell>
        {showDescriptionCell && (
          <DescriptionCell
            allowOverflowDisplay
            isSectionRow={isSectionRow}
            minWidth={descriptionCellWidth}
            className={descriptionClassName}
            left={titleCellWidth}
          >
            <WysiwygEditor
              readOnly={!allowEdition}
              blockContentButton={blockExpansions}
              noEmptyHtmlStrings
              changeOnClickAway
              placeholder={t("views.editor.desc_change")}
              onChange={confirmDescriptionEdition}
              name={`desc${hashCode(elementId)}`}
              value={content}
              toolbarVariant="popup"
              shorten
              useBorder
              placeholderOnFocus
              showMore={showMoreContent}
              onShowMore={element.setShowMoreContent}
              moreButtonClassName={classnames(
                !isSectionRow && "preset-moreDesc",
                isSectionRow && "preset-moreDescSection color-primary-lighter"
              )}
              className={allowEdition ? "my-1-5" : "my-3 ml-0-5"}
              toolbarPlacement="top"
            >
              {!isLibrary && <DescriptionVariantSelector />}
            </WysiwygEditor>
          </DescriptionCell>
        )}
        {
          showBreakdownColumns && !showCombinedHours &&
          visibleWorkTypes?.map((workType) => (
            <TaskWorkType
              key={workType.id}
              element={element}
              workType={workType}
              fullIdPath={fullIdPath}
              emptyValue={
                (hasFixedPrice && !hasChildrenHours) || parentHoldsPrice
              }
              textPresetClass={isSectionRow ? "sectionTitle" : "preset-text"}
              opened={opened}
              tableDepth={tableDepth}
              actualDepthLevel={actualDepthLevel}
            />
          ))
        }
        {
          showBreakdownColumns && showCombinedHours && visibleWorkTypes.length > 0 &&
          <CombinedTaskWorkType
            element={element}
            fullIdPath={fullIdPath}
            emptyValue={
              (hasFixedPrice && !hasChildrenHours) || parentHoldsPrice
            }
            opened={opened}
            tableDepth={tableDepth}
            actualDepthLevel={actualDepthLevel}
            blockFocusAction={blockExpansions}
          />
        }
        {pricesVisible && (
          <TaskPrice
            element={element}
            isSectionRow={isSectionRow}
            actualDepthLevel={actualDepthLevel}
            numOfVisibleWorkTypes={visibleWorkTypes.length}
            opened={opened}
            tableDepth={tableDepth}
            isLastCell={!useVisibilityCell}
            className={priceClassName}
          />
        )}
      </Row>
      {descriptionMode < DESCRIPTION_MODES.COLUMN && descriptionVisible && (
        <DescriptionRow
          show={hasEditedDescription}
          value={content}
          isLastGroupRow={tableDepth <= BREAKDOWN_TABLE_DEPTHS.MODULE && !opened}
          onValueChange={confirmDescriptionEdition}
          tableDepth={tableDepth}
          staticDescription={descriptionMode === DESCRIPTION_MODES.ROW}
          isSectionRow={isSectionRow}
          showBorder={(hasChildren && opened) || !isLastGroupRow}
          className={descriptionClassName}
        >
          {
            visibilityMode && descriptionMode === DESCRIPTION_MODES.ROW &&
            <VisibilityButton
              size="tiny"
              inRow
              hidden={Boolean(descriptionClassName)}
              onChange={handleHideDescription}
            />
          }
        </DescriptionRow>
      )}
      {openable && (
        <Collapse
          in={opened}
          className="w-full"
          mountOnEnter
          unmountOnExit
          data-id={hashCode(`${elementId}_cont`)}
          data-p={hashedPath}
          data-t={BREAKDOWN_ELEMENT_CODES[actualDepthLevel + 1]}
          {...collapseProps}
        >
          <BreakdownRowGroupList
            element={element}
            tableDepth={tableDepth}
            initialDepth={initialDepth}
            isLastGroupRow={
              tableDepth === 0 || (isLastGroupRow && !allowEdition)
            }
            parentIds={fullIdPath}
            parentName={name}
            disabled={!allowEdition}
          />
          {
            allowEdition &&
            <BreakdownNewChildRow
              element={element}
              tableDepth={tableDepth}
              actualDepthLevel={actualDepthLevel}
              showDescriptionCell={showDescriptionCell}
              isFirst={isFirst}
            />
          }
        </Collapse>
      )}
    </RowGroup>
  );
}
);

BreakdownRowGroup.propTypes = {
  element: object.isRequired,
  tableDepth: oneOf([0, 1, 2, 3]),
  initialDepth: oneOf([0, 1, 2, 3]),
  parentIds: arrayOf(oneOfType([number, string])),
  parentName: string,
  isFirst: bool,
};
