import { useEffect, useMemo, useRef } from "react";
import { bool, number, object, oneOf, string } from "prop-types";
import { IconButton } from "@material-ui/core";
import { ArrowDouble } from "@assets";
import classnames from "classnames";

export const ScrollButton = ({
  scrollReachedStart,
  scrollReachedEnd,
  containerRef,
  direction,
  className,
  style,
  scrollSpeed=4,
}) => {
  const timeoutRef = useRef(null);
  const onRef = useRef(null);
  
  const isLeft = useMemo(() => (
    direction === "left"
  ), [direction]);
  
  const handleMouseDown = () => {
    if(!containerRef.current)
      return;
    onRef.current = true;
    timeoutRef.current = setInterval(() => {
      containerRef.current.scrollLeft = containerRef.current.scrollLeft + (isLeft ? -scrollSpeed : scrollSpeed);
      if(containerRef.current.scrollLeft >= containerRef.current.scrollWidth - containerRef.current.offsetWidth) {
        clearInterval(timeoutRef.current);
      }
    }, 10);
  }
  
  const handleClearTimeout = () => {
    if(timeoutRef.current && onRef.current) {
      onRef.current = false;
      setTimeout(() => (
        clearInterval(timeoutRef.current)
      ), 10);
    }
  }
  
  useEffect(() => {
    return handleClearTimeout;
  }, []);
  
  return (
    <IconButton
      style={style}
      onMouseDown={handleMouseDown}
      onMouseUp={handleClearTimeout}
      onMouseOut={handleClearTimeout}
      size="small"
      color="primary"
      disabled={isLeft ? scrollReachedStart : scrollReachedEnd}
      className={classnames(
        "noDrag opaque",
        // (isLeft ? scrollReachedStart : scrollReachedEnd) && classes.hidden,
        className,
      )}
    >
      {
        isLeft
          ? <ArrowDouble className="rotate-180" />
          : <ArrowDouble />
      }
    </IconButton>
  );
};

ScrollButton.propTypes = {
  containerRef: object.isRequired,
  className: string,
  style: object,
  direction: oneOf(["left", "right"]),
  scrollSpeed: number,
  scrollReachedStart: bool,
  scrollReachedEnd: bool,
};