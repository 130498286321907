import { APIMethod, fetchData } from "@client";

export const SORT_BY_TYPES = {
  NAME: "name",
  CREATED: "created",
  UPDATED: "updated",
}
export const LOCAL_SORT_TYPES = {
  ASC: "asc",
  DESC: "desc",
}
export const SORT_TYPES = {
  NEWEST: "newest",
  OLDEST: "oldest",
}

export async function getTemplatesQuery(
  page,
  limit,
  company,
  nameFilter,
  typesFilter,
  sortBy,
) {
  const params = { page, limit  };

  if (company)
    params.type = "company";
  if (nameFilter)
    params.query = nameFilter;
  if (typesFilter?.length)
    params.labels = typesFilter.join(",");
  if (sortBy) {
    params.sortBy = sortBy.split("_")[0];
    params.sort = sortBy.split("_")[1] === LOCAL_SORT_TYPES.ASC ? SORT_TYPES.OLDEST : SORT_TYPES.NEWEST;
  }

  const res =  await fetchData(
    `/v2/template`,
    APIMethod.GET,
    { params }
  );
  
  return {
    paginate: res.paginate,
    results: res.results.map(({ labels, ...r }) => ({
      ...r,
      types: labels?.map(l => l.id)
    }))
  }
}
