import { useMemo } from "react";
import { useCheckEstimateEditorAccess } from "@hooks";
import { useEstimateEditorStore, useEstimateStructure } from "@tools";

export const getUserVisibleWorkTypes = (taskWorkTypes, isProposal, isTimeline) => {
  
  const structure = useEstimateStructure();
  const editorStore = useEstimateEditorStore();
  
  const { apply } = structure.visibility;
  const {
    workTypesWithStatus,
    estimateUserWorkTypeVisibility
  } = editorStore;
  
  const { userId, userUuid, isObserver } = useCheckEstimateEditorAccess(editorStore.estimateUsers);
  
  return useMemo(() => {
    let wt = taskWorkTypes;
    
    if(isProposal && apply)
      wt = taskWorkTypes.filter(wT => (
        !wT.isHidden && !(isTimeline && wT.hideOnTimeline)
      ));
    
    return (
      isObserver && editorStore.hasAssignedWorkTypes(userUuid) && estimateUserWorkTypeVisibility[userId] === 0
        ? wt.filter(({id}) => workTypesWithStatus?.find(w => w.id === id)?.estimatorUuid === userUuid)
        : wt
    );
  }, [userId, editorStore, isObserver, taskWorkTypes, workTypesWithStatus, estimateUserWorkTypeVisibility, apply, isProposal]);
}