import { APIMethod, fetchData } from "@client";

export async function editTemplateVersionComment(
  commentId,
  templateId,
  user, // @note: uuid!!
  version,
  place,
  body,
) {
  await fetchData(
    `v1/ws/template/comments/${commentId}`,
    APIMethod.PUT,
    {
      data: {
        templateId,
        version,
        place,
        body,
        // notify: mentions,
        user
      }
    }
  );
}