import { memo, useMemo, useRef } from "react";
import { arrayOf, bool, number, string, node, oneOfType, oneOf, func } from "prop-types";
import { CELL_WIDTHS } from "@utils";
import Grid from "@material-ui/core/Grid";
import { capitalize } from "@material-ui/core";
import { CellRescale } from "../../other/CellRescale/CellRescale";
import useCellStyle from "../../../../styles/CommonTableCellStyles.style";
import classnames from "classnames";

export const TitleCell = memo(({
  children,
  actions,
  pinnedLeft,
  minWidth: minWidthBase=CELL_WIDTHS.TITLE,
  maxWidth: maxWidthBase,
  stretchToChildrenWidth,
  allowOverflowDisplay,
  highlightCellText,
  className,
  isHeader,
  allowEdition,
  setWidth,
  setNextCellWidth,
  nextCellWidth,
  nextCellMaxWidth,
  column,
  size="large"
}) => {
  const cellClasses = useCellStyle();
  
  const cellRef = useRef();
  
  const useRescale = useMemo(() => (
    isHeader && allowEdition && Boolean(setWidth)
  ), [isHeader, allowEdition, setWidth]);

  const left = useMemo(
    () =>
      typeof pinnedLeft === "number" &&
      (pinnedLeft
        ? CELL_WIDTHS.EXPAND[`DEPTH_${pinnedLeft}`]
        : 0),
    [pinnedLeft]
  );

  const minWidth = useMemo(
    () => minWidthBase ? minWidthBase - left : undefined,
    [minWidthBase, left]
  );

  const maxWidth = useMemo(
    () => maxWidthBase ? Math.max(maxWidthBase - left, minWidth) : undefined,
    [maxWidthBase, left, minWidth]
  );

  return (
    <Grid
      ref={cellRef}
      item
      container
      role="rowitem"
      className={classnames(
        "cell",
        !isHeader && "title",
        cellClasses.cell,
        cellClasses.titleCell,
        cellClasses.bolder,
        typeof pinnedLeft === "number" && cellClasses.isSticky,
        highlightCellText && "color-primary",
        isHeader && cellClasses.headCell,
        stretchToChildrenWidth && "w-max",
        allowOverflowDisplay && "display-overflow",
        cellClasses[`size${capitalize(size)}`],
        className
      )}
      style={{ minWidth, maxWidth, left }}
    >
      <Grid
        item container
        justifyContent={children ? "space-between" : "flex-end"}
        alignItems="center"
        wrap="nowrap"
        direction={column ? "column" : "row"}
        className={classnames(isHeader && "h-full", !isHeader && cellClasses.cellInner)}
      >
        {children}
        {(useRescale || !stretchToChildrenWidth || !!actions) && (
          <Grid
            item
            container
            wrap="nowrap"
            alignItems="center"
            className={classnames("opaque relative w-max h-full ml-2", !isHeader && cellClasses.actionWrapper)}
          >
            {actions}
            {
              useRescale &&
              <CellRescale
                cellRef={cellRef}
                minWidth={CELL_WIDTHS.TITLE_MIN}
                maxWidth={CELL_WIDTHS.TITLE_MAX}
                offset={left}
                currentCellWidth={minWidth}
                setWidth={setWidth}
                setNextCellWidth={setNextCellWidth}
                currentNextCellWidth={nextCellWidth}
                currentNextCellMaxWidth={nextCellMaxWidth}
                minNextCellWidth={CELL_WIDTHS.DESC_MIN}
                maxNextCellWidth={CELL_WIDTHS.DESC_MAX}
              />
            }
          </Grid>
        )}
      </Grid>
    </Grid>
  );
});

TitleCell.propTypes = {
  children: oneOfType([node, string]),
  actions: oneOfType([node, arrayOf(node)]),
  pinnedLeft: oneOf([0, 1, 2, 3]),
  minWidth: oneOfType([number, string]),
  maxWidth: oneOfType([number, string]),
  stretchToChildrenWidth: bool,
  allowOverflowDisplay: bool,
  highlightCellText: bool,
  className: string,
  isHeader: bool,
  allowEdition: bool,
  setWidth: func,
  setNextCellWidth: func,
  nextCellWidth: number,
  nextCellMaxWidth: number,
  column: bool,
  size: oneOf(["xSmall", "small", "medium", "large", "xLarge"])
};
