import { useState } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Totals } from "project-structure";
import { useEstimateStructure } from "@tools";
import { Button, Dialog, InfoLabel } from "@components";
import { Grid } from "@material-ui/core";
import { SettingEntry } from "./components/SettingEntry";
import { Settings } from "@assets";

export const SummarySettings = observer(({ isSellerOrClient }) => {
  const structure = useEstimateStructure();
  const { t } = useTranslation();

  const [visible, show] = useState(false);

  const { usedSummaryColumns, usedTotals, timeModifier } = structure.settings;

  const handleClose = () => show(false);

  return (
    <>
      <Button
        id="summarySettings"
        onClick={() => show(!visible)}
        aria-label={t("views.editor.dialogs.summary_settings.title")}
        color="secondary"
        endIcon={<Settings color="primary" />}
        className="font-normal"
      >
        {t("views.editor.dialogs.summary_settings.title")}
      </Button>
      <Dialog
        open={visible}
        onClose={handleClose}
        width={440}
        title={t("views.editor.dialogs.summary_settings.title")}
      >
        <Grid item container wrap="nowrap" spacing={2}>
          <Grid item container direction="column" wrap="nowrap" spacing={1}>
            <InfoLabel
              label="views.editor.dialogs.summary_settings.columns"
              tooltipText="views.editor.dialogs.summary_settings.columns_tooltip"
            />
            {usedSummaryColumns
              .filter(({ noDev }) => !noDev || isSellerOrClient)
              .map(({ name, visible, setVisibility }) => <SettingEntry
                key={name}
                name={name}
                label={t(`views.editor.summary_table.${name}`)}
                visible={visible}
                setVisibility={setVisibility}
              />)
            }
          </Grid>
          <Grid
            item container
            direction="column"
            wrap="nowrap"
            spacing={1}
          >
            <InfoLabel
              label="views.editor.dialogs.summary_settings.totals"
              tooltipText="views.editor.dialogs.summary_settings.totals_tooltip"
            />
            {usedTotals
              .filter(({ noDev }) => !noDev || isSellerOrClient)
              .map(({ name, visible, setVisibility }) => <SettingEntry
                key={name}
                name={name}
                label={t(`views.editor.summary_table.totals.${name === Totals.HOURS ? (timeModifier === 1 ? "hours" : "days") : name}`)}
                visible={visible}
                setVisibility={setVisibility}
              />)
            }
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
});

SummarySettings.propTypes = {
  isSellerOrClient: bool,
};
