import { zipStructure } from "project-structure";
import { ACCESS_DOMAINS, APIMethod, fetchData } from "@client";

export async function fetchCompanyJiraWorkspaces(userId, companyId) {
  return await fetchData(`api/workspaces`, APIMethod.GET, {
    baseURL: ACCESS_DOMAINS.btApi,
    params: {
      code: zipStructure(JSON.stringify({ user_id: userId, company_id: companyId })),
    }
  });
  
}