import { bool, string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEstimatePresetStore } from "@tools";
import { InteractiveAutocomplete } from "@components";
import { CircularProgress } from "@material-ui/core";
import useStyle from "./Picker.style";

export const PresetPicker = observer(({
  isLoading,
  commitPresetSelection,
  className,
}) => {
    const presetStore = useEstimatePresetStore();
    const { projectUuid } = useParams();
    const { t } = useTranslation();
    const classes = useStyle();

    const { selectedPresetId, presets } = presetStore;

    const handlePresetChange = async (n) => {
      await presetStore.setSelectedPreset(n.id, n.name, n.data, n.font?.id ? n.font : undefined);
      if (commitPresetSelection)
        await presetStore.addPresetToProject(projectUuid);
    };

    const selectedPreset =
      presets.find(({ id }) => id === selectedPresetId) || null;

    return (
      <InteractiveAutocomplete
        label={t("views.editor.preset.font_picker")}
        value={selectedPreset}
        options={presets}
        labelKey="name"
        idKey="id"
        onChange={handlePresetChange}
        // getOptionStyle={getOptionStyle}
        optionClassName="font-normal pl-4 justify-between"
        placeholder={t("views.editor.preset.placeholder_preset")}
        popupIcon={isLoading && (
          <CircularProgress
            aria-label="progress indicator"
            size={24}
            className={classes.progress}
          />
        )}
        pinnedOptionIds={[-1]}
        readOnlyOptions={[-1]}
        className={className}
      />
    );
  }
);

PresetPicker.propTypes = {
  isLoading: bool,
  commitPresetSelection: bool,
  className: string,
};
