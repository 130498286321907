import { string } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CELL_WIDTHS } from "@utils";
import {
  usePresetFieldNames,
  useEstimateStructure,
  HeaderRow,
  TitleCell,
  ValueCell, useEstimateEditorSettings,
} from "@tools";
import { ExpandTitleHourCell } from "./ExpandTitleHourCell.js/ExpandTitleHourCell";
import { ExpandTitleCostCell } from "./ExpandTitleCostCell.js/ExpandTitleCostCell";

export const TableHeader = observer(({ tableIdentifier }) => {
  const { isProposal } = useEstimateEditorSettings();
  const { getFieldName } = usePresetFieldNames(isProposal);
  const { t } = useTranslation();

  const structure = useEstimateStructure();
  const { timeModifier } = structure.settings;

  return (
    <HeaderRow
      expandable
      id={tableIdentifier}
      className="preset-paper"
      altBackground
    >
      <TitleCell
        minWidth={CELL_WIDTHS.TITLE_BT_SUMMARY}
        pinnedLeft={0}
      >
        <span className="preset-summaryHeader pt-work">
          {getFieldName("pt-work")}
        </span>
      </TitleCell>
      <ValueCell widest>
        {t(
          `views.editor.summary_table.rate_${
            timeModifier === 1 ? "hours" : "days"
          }`
        )}
      </ValueCell>
      <ExpandTitleHourCell />
      <ExpandTitleCostCell />
    </HeaderRow>
  );
});

TableHeader.propTypes = {
  tableIdentifier: string,
};
