import { object } from "prop-types";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useEditorWebsocket } from "@hooks";
import {
  useEstimateEditorSettings,
  ChatThread,
  TimelineSettings,
} from "@tools";
import { TooltipIcon } from "@components";
import { Trash } from "@assets";

export const SummaryNestedRowActions = observer(({
  resource,
}) => {
  const socket = useEditorWebsocket();
  const { t } = useTranslation();
  
  const { allowEdition, showChatThread } = useEstimateEditorSettings();
  
  const path = resource.treePath.join("/");
  const timelinePath = "t"+path;
  
  const handleRemoval = async () => {
    resource.removeSelf();
    socket?.requestCommentPathRemove(path);
    socket?.requestCommentPathRemove(timelinePath);
  };
  
  return (
    <>
      {allowEdition && (
        <TooltipIcon
          icon={<Trash color="error" />}
          onClick={handleRemoval}
          className="button-remove hoverIcon"
        >
          {t("views.editor.remove_resource")}
        </TooltipIcon>
      )}
      {
        allowEdition &&
        <TimelineSettings
          selectedResource={resource.id}
          className="button-wta-settings hoverIcon opaque"
        />
      }
      {
        showChatThread &&
        <ChatThread
          rowThread
          path={path}
        />
      }
    </>
  );
});

SummaryNestedRowActions.propTypes = {
  resource: object,
};
