import { useEffect, useMemo } from "react";
import { number, object } from "prop-types";
import { observer } from "mobx-react";
import ResizeObserver from "react-resize-observer";
import { DESCRIPTION_MODES } from "project-structure";
import {
  useTableOverflowChecker,
  useEstimateStructure,
  EstimateTableSettingsProvider,
  useEstimateEditorSettings, ScrollButtonContainer
} from "@tools";
import { Grid, TableContainer } from "@material-ui/core";
import { BudgetTrackingTableHeader } from "./BudgetTrackingTableHeader/BudgetTrackingTableHeader";
import { BudgetTrackingBreakdownRowGroup } from "./BudgetTrackingBreakdownRowGroup"
import useEditorStyle from "@tools/EstimateEditor/styles/EditorContent.style.js";
import classnames from "classnames";
import { isMobile } from "react-device-detect";

export const BudgetTrackingSection = observer(({
  section,
  index,
}) => {
  const {
    isProposal,
    isSellerOrClient,
  } = useEstimateEditorSettings();
  const structure = useEstimateStructure();
  const commonClasses = useEditorStyle();

  const {
    useMinMax,
    titleCellWidth,
    descriptionCellWidth,
    showPrices,
    showBreakdownColumns,
    descriptionMode,
  } = structure.settings;

  const { taskWorkTypes, sectionModulesHaveChildren, childrenWithContent } =
    section;

  const tableContainerIdentifier = useMemo(
    () => `breakdownContainer${index}`,
    []
  );
  const tableIdentifier = useMemo(() => `tableHeader${index}`, []);
  
  const {
    ref,
    onResize,
    onScroll,
    isTableContentOverflowing,
    scrollButtonProps
  } = useTableOverflowChecker(tableIdentifier);
  

  const pricesVisible = useMemo(
    () => showBreakdownColumns && showPrices && isSellerOrClient,
    [showPrices, isSellerOrClient, showBreakdownColumns]
  );

  const useExpandCell = useMemo(
    () => !isProposal || sectionModulesHaveChildren,
    [isProposal, sectionModulesHaveChildren]
  );

  const descriptionVisible = useMemo(
    () => !isProposal || Boolean(section.content) || childrenWithContent > 0,
    [isProposal, childrenWithContent]
  );
  
  const maxTitleCellWidth = useMemo(() => (
    descriptionMode === DESCRIPTION_MODES.COLUMN && descriptionVisible
      ? titleCellWidth
      : undefined
  ), [descriptionMode, descriptionVisible, titleCellWidth]);
  
  useEffect(() => {
    onResize();
  }, [
    pricesVisible, useMinMax, titleCellWidth, descriptionCellWidth, useExpandCell,
    descriptionVisible, maxTitleCellWidth
  ]);

  return (
    <EstimateTableSettingsProvider
      useExpandCell={useExpandCell}
      pricesVisible={pricesVisible}
      descriptionVisible={descriptionVisible}
      maxTitleCellWidth={maxTitleCellWidth}
      visibleWorkTypes={taskWorkTypes}
    >
      <Grid item container>
        {
          isTableContentOverflowing && !isMobile &&
          <ScrollButtonContainer {...scrollButtonProps} />
        }
        <TableContainer
          ref={ref}
          onScroll={onScroll}
          className={classnames(
            "pt-3 mb-4 h-max w-full min-w-full relative overflow-x-auto overflow-y-hidden",
            isTableContentOverflowing && commonClasses.overflows
          )}
          id={tableContainerIdentifier}
        >
          <Grid container role="rowgroup" direction="column">
            <ResizeObserver onResize={onResize} />
            <BudgetTrackingTableHeader
              section={section}
              tableIdentifier={tableIdentifier}
            />
            {
              <BudgetTrackingBreakdownRowGroup
                sectionIndex={index}
                element={section}
                isLastGroupRow
                tableDepth={0}
                isFirst={index === 0}
              />
            }
          </Grid>
        </TableContainer>
      </Grid>
    </EstimateTableSettingsProvider>
  );
});

BudgetTrackingSection.props = {
  section: object.isRequired,
  index: number.isRequired,
};
