import { Fragment } from "react";
import { bool, object, func } from "prop-types";
import { BREAKDOWN_TABLE_DEPTHS, SECTION_FORMATS, isEmptyHtml } from "project-structure";
import { HtmlParser } from "@utils";
import { usePresetFieldNames } from "@tools";
import { ExportTask } from "./ExportTask";

export const ExportTableBreakdown = ({
  editorStore,
  usePreset,
  translate,
  allowShowingPrices,
}) => {
  const { currentEstimateStructure } = editorStore;
  const { workTypes, settings, visibility, sections, estimateViewLevel, estimateValueLevel, usesTwoValues, usesAnyValue } = currentEstimateStructure;
  const { currencyObj, showPrices, sectionFormat, showBreakdownColumns } = settings;
  const { apply } = visibility;
  const { getFieldName } = usePresetFieldNames(usePreset, currentEstimateStructure);

  return (
    <div className="breakdown">
      <h3 className="breakdown">{getFieldName("pt-breakdown")}</h3>
      {sections.map((section) => {
        const {
          id: sectionId,
          children,
          taskWorkTypes,
          name,
          content,
          showCombinedHours,
          hideDescription,
          hidePrice,
        } = section;
        
        const visibleTaskWorkTypes = taskWorkTypes
          .filter(workType => !apply || !workType.isHidden)
        
        const props = {
          translate,
          editorStore,
          settings,
          visibility,
          allowShowingPrices,
          estimateViewLevel,
          estimateValueLevel,
          usesTwoValues,
          usesAnyValue,
          showCombinedHours,
          hideDescription,
          hidePrice,
        };

        return (
          <Fragment key={sectionId}>
            {sectionFormat > SECTION_FORMATS.ROW && (
              <>
                <h3 className="section">
                  {name || translate("common.unnamed")}
                </h3>
                {!isEmptyHtml(content) && (
                  <div className="sectionDesc">{HtmlParser(content)}</div>
                )}
              </>
            )}
            <table>
              <thead>
                <tr>
                  <th style={{ textAlign: "left" }}>
                    {getFieldName("pt-module", sectionFormat === SECTION_FORMATS.ROW ? 0 : 1)}
                  </th>
                  {
                    showBreakdownColumns && !showCombinedHours &&
                    visibleTaskWorkTypes
                      ?.map(({ id }) => (
                      <th key={id}>
                        {workTypes.find((wT) => wT.id === id)?.name ||
                          translate("common.unnamed")}
                      </th>
                    ))
                  }
                  {
                    showBreakdownColumns && showCombinedHours &&
                    <th>
                      { getFieldName("pt-combinedEstimate") }
                    </th>
                  }
                  { showBreakdownColumns && allowShowingPrices && showPrices && (!apply || !hidePrice) && (
                    <th>
                    {`${getFieldName("pt-price")} (${
                        currencyObj.symbolStart || currencyObj.symbolEnd
                      })`}
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {sectionFormat === SECTION_FORMATS.ROW && (
                  <>
                    <ExportTask
                      key={sectionId}
                      tableDepth={BREAKDOWN_TABLE_DEPTHS.SECTION}
                      task={section}
                      noContent
                      {...props}
                    />
                    {!isEmptyHtml(content) && (!apply || !hideDescription) && (
                      <tr>
                        <td
                          className="sectionDesc"
                          colSpan={
                            1 +
                            (showBreakdownColumns
                              ? (showCombinedHours && visibleTaskWorkTypes.length > 0 ? 1 : visibleTaskWorkTypes.length) +
                                (allowShowingPrices && showPrices ? 1 : 0)
                              : 0)
                          }
                        >
                          {HtmlParser(content)}
                        </td>
                      </tr>
                    )}
                  </>
                )}
                {children?.map((t, i) => (
                  <ExportTask
                    key={t.id || t.newId}
                    index={`${i + 1}`}
                    tableDepth={BREAKDOWN_TABLE_DEPTHS.MODULE}
                    task={t}
                    {...props}
                  />
                ))}
                {sectionFormat === SECTION_FORMATS.SUBTOTAL && section.children?.length > 0 && (
                  <>
                    <ExportTask
                      key={sectionId}
                      tableDepth={BREAKDOWN_TABLE_DEPTHS.PROJECT}
                      task={section}
                      isSubtotal
                      noContent
                      {...props}
                    />
                  </>
                )}
              </tbody>
            </table>
          </Fragment>
        );
      })}
    </div>
  );
};

ExportTableBreakdown.propTypes = {
  editorStore: object,
  usePreset: bool,
  allowShowingPrices: bool,
  translate: func,
};
