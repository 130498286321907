import { bool, func, string } from "prop-types";
import { useTranslation } from "react-i18next";
import { Checkbox, DragHandle } from "@components";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import classnames from "classnames";

export const TableCheckbox = ({ name, visible, setVisibility }) => {
  const { t } = useTranslation();
  const classes = useStyle();

  const handleVisibility = () => setVisibility(!visible);

  return (
    <Grid
      item container
      xs={12}
      className={classnames(classes.container, "pl-6") }
    >
      <Grid item container className="p-1 relative">
        <DragHandle
          showOnHover
          absolute
          style={{ left: -24, top: 1 }}
        />
        <Checkbox
          name={name}
          aria-label={name}
          checked={visible}
          onChange={handleVisibility}
          className="mr-2"
          label={t(`proposal_parts.editor_sections.${name}`)}
        />
      </Grid>
    </Grid>
  );
};

TableCheckbox.propTypes = {
  name: string,
  visible: bool,
  setVisibility: func,
};

const useStyle = makeStyles(() => ({
  container: {
    "&:not(:hover) .hoverIcon": {
      zIndex: -1,
      opacity: "0 !important",
    },
  },
}));