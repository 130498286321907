import { useState, useEffect } from "react";
import { bool } from "prop-types";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEditorWebsocket, useCheckEstimateEditorAccess } from "@hooks";
import {
  useEstimateEditorStore,
  PdfExport,
  Share,
  XlsExport,
  EstimateSettings,
} from "@tools"
import { CreateTemplateDialog } from "@dialogs";
import { ActionButton, Alert, TextClearInput } from "@components";
import { Grid } from "@material-ui/core";
import { ProfitabilityMode } from "./components/ProfitabilityMode";
import { VisibilityMode } from "./components/VisibilityMode";
import { FileAdd } from "@assets";
import useStyle from "./TableToolbar.style";
import classnames from "classnames";

export const ProjectTableToolbar = observer(({ isSharedVersion }) => {
  const classes = useStyle();
  const { projectUuid } = useParams();
  const editorStore = useEstimateEditorStore();
  const { t } = useTranslation();
  const socket = useEditorWebsocket();

  const [alertText, setAlertText] = useState("");
  const [titleEditorFocused, focusTitleEditor] = useState(undefined);
  const [editedTitle, setEditedTitle] = useState("");
  const [showTemplateDialog, openTemplateDialog] = useState(false);

  const {
    estimateName,
    estimateDesc,
    estimateUsers,
  } = editorStore;

  const { isBusinessUser, isObserver, isOwner, isMiniUser } =
    useCheckEstimateEditorAccess(estimateUsers);

  useEffect(() => {
    if (estimateName) setEditedTitle(estimateName.replace(/<(.|\n)*?>/g, ""));
  }, [estimateName]);

  const changeProjectName = async () => {
    if (estimateName !== editedTitle) {
      await socket?.requestEstimateRename(projectUuid, editedTitle);
      editorStore.setEstimateName(editedTitle);
    }
    focusTitleEditor(false);
  };

  const handleTitleFocus = () => {
    focusTitleEditor(true);
  };

  return (
    <Grid
      item
      container
      justifyContent="space-between"
      name="table_title"
      className={classnames(isSharedVersion && "mb-5")}
    >
      <Grid item container direction="column" className="w-max max-w-full">
        <h1 className={classnames(
          "preset-title w-max max-w-full mb-2",
          isSharedVersion && classes.titleProposal,
          isSharedVersion && "px-0-5",
        )}>
          {!isSharedVersion && !isMiniUser && isOwner ? (
            <TextClearInput
              onFocus={handleTitleFocus}
              onAccept={changeProjectName}
              onChange={setEditedTitle}
              value={editedTitle}
              confirmOnEnter
              acceptOnClickAway
              focused={titleEditorFocused}
              containerClass={classes.projectTitleEditor}
              className="w-max max-w-full"
            />
          ) : (
            estimateName?.replace(/<(.|\n)*?>/g, "")
          )}
        </h1>
      </Grid>
      {!isSharedVersion && (
        <Grid
          item
          container
          justifyContent="flex-end"
          className={classes.actionContainer}
        >
          { isBusinessUser && isObserver && <VisibilityMode /> }
          { isBusinessUser && isObserver && <ProfitabilityMode /> }
          { isBusinessUser && isObserver && <EstimateSettings /> }
          { isOwner && <>
            <ActionButton
              square
              icon={<FileAdd />}
              color="secondary"
              onClick={() => openTemplateDialog(true)}
              name={t("views.project.project_template.save_as")}
              tooltip
            />
            <CreateTemplateDialog
              projectUuid={projectUuid}
              open={showTemplateDialog}
              onClose={() => openTemplateDialog(false)}
              hasComments={editorStore.hasComments}
              defaultValues={{
                description: estimateDesc,
                name: estimateName
              }}
            />
          </>}
          <PdfExport titleBarButton />
          { !isSharedVersion && <XlsExport /> }
          { isBusinessUser && <Share /> }
          <Alert
            title={alertText}
            isOpen={!!alertText}
            onAccept={() => setAlertText("")}
            acceptText={t("common.close")}
          />
        </Grid>
      )}
    </Grid>
  );
});

ProjectTableToolbar.propTypes = {
  isSharedVersion: bool,
};
