import { useEffect, useRef } from "react";
import { number, string, func, oneOfType, arrayOf, shape, objectOf, object } from "prop-types";
import jspreadsheet from "jspreadsheet-ce";
import { jspreadsheetDefaultSettings } from "../../jspreadsheetDefaultSettings";
import useStyle from "./Spreadsheet.style";
import "jspreadsheet-ce/dist/jspreadsheet.css";
import "jsuites/dist/jsuites.css";

export const Spreadsheet = ({
  spreadsheet,
  title,
  data,
  colWidths,
  columns,
  mergeCells,
  minDimensions,
  onRangeSelect,
  onMouseUp,
  onMouseDown,
  onLoad,
  initialCellStyles,
  setSpreadsheet,
}) => {
  const classes = useStyle();
  
  const spreadsheetRef = useRef(null);

  const currentRangeHandlerRef = useRef(onRangeSelect);
  const setCurrentRangeHandler = (d) => (currentRangeHandlerRef.current = d);

  const loaded = useRef(false);
  const setLoaded = (d) => (loaded.current = d);

  const isMouseDown = useRef(false);
  const setMouseDown = (d) => (isMouseDown.current = d);
  
  useEffect(() => {
    return () => {
      setSpreadsheet(null);
      setLoaded(false);
    };
  }, []);
  
  useEffect(() => {
    if (spreadsheetRef.current && !spreadsheet) {
      setSpreadsheet(
        jspreadsheet(spreadsheetRef.current, {
          title,
          data,
          colWidths,
          columns,
          mergeCells,
          minDimensions,
          onselection: handleSelection,
          onload: handleLoad,
          style: initialCellStyles,
          ...jspreadsheetDefaultSettings,
        })
      );
    }

    window.document.addEventListener("mouseup", handleTextSelection);
    return () => {
      window.document.removeEventListener("mouseup", handleTextSelection);
    };
  }, [spreadsheetRef.current]);

  useEffect(() => {
    setCurrentRangeHandler(onRangeSelect);
  }, [onRangeSelect]);

  const handleTextSelection = () => {
    if (isMouseDown.current) {
      if (onMouseUp) onMouseUp();
      window.getSelection().empty();
      setMouseDown(false);
    }
  };

  const handleMouseDown = () => {
    if (onMouseDown) onMouseDown();
    setMouseDown(true);
  };

  const handleSelection = (instance, x1, y1, x2, y2) => {
    if (currentRangeHandlerRef.current && loaded.current)
      currentRangeHandlerRef.current(instance, x1, y1, x2, y2);
  };

  const handleLoad = (instance) => {
    if (onLoad) onLoad(instance);
    setLoaded(true);
  };

  return (
    <div
      ref={spreadsheetRef}
      className={classes.root}
      onMouseDown={handleMouseDown}
    />
  );
};

Spreadsheet.propTypes = {
  spreadsheet: object,
  setSpreadsheet: func.isRequired,
  data: arrayOf(arrayOf(oneOfType([string, number]))).isRequired,
  columns: arrayOf(
    shape({
      type: string.isRequired,
      title: string,
    })
  ).isRequired,
  title: string,
  colWidths: arrayOf(number),
  mergeCells: objectOf(arrayOf(number)),
  minDimensions: arrayOf(number),
  onRangeSelect: func,
  onLoad: func,
  initialCellStyles: objectOf(string),
  onMouseUp: func,
  onMouseDown: func,
};
