import { makeStyles } from "@material-ui/core/styles";

const useStyle = makeStyles((theme) => ({
  colorCell: {
    minWidth: (noRemoval) => noRemoval ? 48 : 90,
    width: (noRemoval) => noRemoval ? 48 : 90,
    marginLeft: 8,
  },
  labelCell: {
    minWidth: 120,
    width: 120,
    marginRight: 8,
  },
  sticky: {
    position: "sticky",
    bottom: -24,
    backgroundColor: theme.props.backgroundSecondary,
    paddingBottom: 8,
  }
}));

export default useStyle;